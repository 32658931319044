export const giftDappUtilStrings = {
    appDomain : "appDomain",
    appFrontend : "appFrontend",
    processing : "processing",
    processMsg : "processMsg",
    processComponent : "processComponent",
    storageNetworkID : "giftDappNetworkId",
    storageNetworkType : "storageNetworkType",
    storageNetworkWallet : "storageNetworkWallet",
    storageLastUsedProfile : "storageLastUsedProfile",
    refreshCount : "refreshCount",
}
export const giftDappUtilConstants = {
    SET_GIFT_DAPP_UTILS : "SET_GIFT_DAPP_UTILS",
}
export const giftDappUtilReducer = (state={
    [giftDappUtilStrings.refreshCount] : 0
}, action) => {
    const {type, payload} = action;
    switch(type){
        case giftDappUtilConstants.SET_GIFT_DAPP_UTILS : 
            return {...state, ...payload};
        default : return {...state};
    }
}
export const GIFT_DAPP_UTIL_REDUCER_NAME = "giftDappUtilReducer";
export {giftDappUtilReducer as default};