import { makeStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import {landingRouteNames} from '../../../../utils'
import React from 'react'

const style = makeStyles(({colors, breakpoints} ) => ({
    root: {
        color: colors.pageColor,
        background: colors.pageBackground,
        textAlign: "left",
        position: "relative",
        transformStyle: "preserve-3d",
        paddingTop: "4rem",
        boxSizing: "border-box",
        [breakpoints.down('md')]: {
            paddingRight: "2.5rem",
            paddingLeft: "2.5rem",
        },
        [breakpoints.down('sm')]: {
            textAlign: "center",
            paddingRight: "0rem",
            paddingLeft: "0rem",
            paddingTop: "0rem",
        }
    },
    
    featuredContainer :{
        paddingLeft: "5rem",
        paddingRight: "5rem",
        paddingBottom: "0rem",
        margin: 30,
        display: "flex",
        justifyContent: "space-between",
        [breakpoints.down("md")]: {
            flexDirection: "column-reverse",
        },
        [breakpoints.down("sm")]: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
            margin: 0,
        }
    },

    textSection: {
        width: "50%",
        marginTop: "5rem",
        [breakpoints.down("md")]: {
            width: "80%",
        },
        [breakpoints.down("sm")]: {
            width: "90%",
            marginTop: "0rem",
        },
        [breakpoints.down("xs")]: {
            width: "100%",
        }

    },

    ImgSection: {
        width: "50%",
        // height: "40rem",
        marginRight: "2rem",
        [breakpoints.down("md")]: {
            width: "80%",
            margin: "3rem auto 0",
        },
        [breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    launchImg: {
        width: "100%",
        height: "100%"
    },
    journeyBtn: {
        backgroundImage: " linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        boxShadow: "0px 1px 5px rgba(51, 51, 153, 0.1)",
        color: colors.pageColor,
        fontSize: "16px",
        width: "212px",
        height: "48px",
        fonWeight: "700",
        padding: "1.5rem 5rem",
        borderRadius: "6px",
        outline: "none",
        [breakpoints.down('xl')]: {
            fontSize: "16px",
            padding: ".7rem 1.5rem",
            borderRadius: ".5rem",
        }
    },
    projectName:{
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "48px",
        color: colors.pageColor,
        [breakpoints.down("sm")]: {
            fontSize: "28px",
        },
    },
   
    projectDesc:{
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: colors.pageColor,
        minWidth: "510px",
        marginBottom: "40px",
        [breakpoints.down('sm')]: {
            minWidth: "80%"
        }
    },
    smalllogo: {
        height: "24px",
        width: "24px",
        marginLeft: "5px",  
        marginTop: "2px",  
      },

}))

export default function GiftDappInfluencers() {

    const classes = style();
   
    return(
        <section className = {classes.root}>
        <div className={ classes.featuredContainer}>
          
            <div className = {classes.textSection}>
                <p className = {classes.projectName}>Influencers</p>
                <p className = {classes.projectDesc}>
                Your can now reward your users by having them play fun games in order to win tokens. All you have to do is create a game and enter the win and lose criteria, you receive a code which you can share with your users to play the game.
                </p>
                <ButtonBase
                    onClick = {() => window.location.href = landingRouteNames().app}
                    className = {classes.journeyBtn}>
                    Launch DApp
                    <img
                    src="/assets/arrow-right.svg"
                    className={classes.smalllogo}
                    alt={" LaunchPad arrow down"}
                  />
                </ButtonBase>
            </div>
            <div className = {classes.ImgSection}>
                <img className = {classes.launchImg} src = "./assets/Influencers.png" alt = "launch" />
            </div>
        </div>
       
     </section>
   
    )
   
   }