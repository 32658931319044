export const breadCrumbStrings = {
    visible : "visible",
    crumbs : "crumbs",
    state : "state",
    action : "action",
}
export const breadCrumbConstants = {
    SET_BREAD_CRUMB : "SET_BREAD_CRUMB",
}
const initialState = {
    
}
export const breadCrumbReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case breadCrumbConstants.SET_BREAD_CRUMB : 
            return {...state, ...payload}
        default  : return {...state};
    }
}
export const BREAD_CRUMB_REDUCER_NAME = "breadCrumbReducer"