import { withStyles } from '@material-ui/core'
import React from 'react'
import { CustomButton } from '../../../../includes';
const style = ({colors, isDark, breakpoints}) => ({
    root : {
        display : 'flex',
        justifyContent : "flex-end",
        margin : "50px 0"
    },
    btn : {
        marginRight : 10,
        background : !isDark && colors.secondaryDark,
        [breakpoints.down("sm")] : {
            minWidth : 120
        },
        [breakpoints.down("xs")] : {
            minWidth : 90
        },
    },
    continue : {
        width : 200,
        color : !isDark ? colors.text : colors.background,
    }
})
function _GiftDappRewardSetupFooterButtons ({onReset, onContinue, classes, button1={}, button2={}}) {
    return (
        <div className={classes.root}>
            <CustomButton
                type="background"
                onClick={button1.onClick || onReset}
                classes={{
                    btn : classes.btn
                }}
            >
                {button1.text || "Reset"}
            </CustomButton>
            <CustomButton
                type="accent"
                onClick={button2.onClick || onContinue}
                classes={{
                    btn : classes.continue
                }}
            >
                {button2.text || "Save & Continue"}
            </CustomButton>
        </div>
    )
}
export const GiftDappRewardSetupFooterButtons = withStyles(style)(_GiftDappRewardSetupFooterButtons);
export {GiftDappRewardSetupFooterButtons as default}