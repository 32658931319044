import { backButtonReducer } from "./CustomBackNav/config"
import { breadCrumbReducer } from "./CustomBreadCrumb"
import { customPopoverReducer } from "./CustomPopover"
import { customProcessReducer } from "./CustomProcessDisplay/config"
import { customSideDisplayReducer } from "./CustomSideDisplay"
import { snackbarReducer } from "./CustomSnackbar"
export const includeReducers = {
    backButtonReducer,
    customPopoverReducer,
    breadCrumbReducer,
    snackbarReducer,
    customProcessReducer,
    customSideDisplayReducer,
}