import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { CustomButton } from '../../../includes'
import { giftContractActions, SMART_SWAP_URL } from '../../../utils'
import { useSelector } from 'react-redux'
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME, REWARD_FIELD_NAMES } from '..'
import useGAEventsTracker from './../../../hooks/useGAEventsTracker';

const style = makeStyles(({colors, isDark, breakpoints}) => ({
    root : {
        position : "relative",
        background : colors.background,
        color : colors.text,
        padding : 20,
        paddingTop : 70,
        paddingBottom : 50,
        [breakpoints.up("sm")] : {
            minWidth : 400,
        },
        fontSize : 12,
    },
    closeContainer : {
        position : "absolute",
        zIndex : 100,
        top : 20,
        right : 20,
        color : colors.text
    },
    icon : {
        color : colors.text
    },
    content : {
        display : "flex",
        alignItems : "center",
        flexDirection :"column",
        justifyContent : "center",
        textAlign : 'center',
        paddingBottom : 30,
    },
    link : {
        display : "flex",
        justifyContent : "center",
        textAlign :'center',
        marginTop : 20,
    },
    span : {
        padding : "5px !important",
    },
    a : {
        color : colors.accent,
        textDecoration : "none",
        marginLeft : 20,
    },
    btn : {
        background : !isDark && colors.accent,
        color : !isDark && colors.text,
        "&:hover" : {
            background : !isDark && colors.accent,
            color : !isDark && colors.text,
        }
    }
}))
function GiftDappRewardRequestFee({onContinue}) {
    const classes = style(),
        [state, _setState] = useState({
            subscription : 0
        }),
        {subscription} = state,
        setState = _state => _setState(state=>({...state, ..._state})),
    giftRewardReducer = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME]),
    {
        [giftRewardStrings.rewardData] : rewardData
    } = giftRewardReducer,
    {
        [REWARD_FIELD_NAMES.IS_PROJECT] : isProject
    } = rewardData || {};
    useEffect(() => {
        (async () => {
            const subscription = await giftContractActions(isProject).subscriptionFee();
            setState({subscription});
        })()
    }, [isProject]);

    const GAEventsTracker = useGAEventsTracker();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <span className={classes.spin}>To continue this process</span>
                <span className={classes.spin}>you need to pay {subscription} RGP fee</span>
            </div>
            <CustomButton
                classes={{
                    btn : classes.btn,
                }}
                type="secondary" fullWidth onClick={() => { onContinue(); GAEventsTracker("reward_request_fee_continue", "reward page"); }}>
                Continue
            </CustomButton>
            <div className={classes.link}>
                Don't have RGP token? <a target="_blank" href={SMART_SWAP_URL} className={classes.a} onClick={() => { GAEventsTracker("click_on_buy_rgp_token", "reward page"); }}>Buy here</a>
            </div>
        </div>
    )
}
GiftDappRewardRequestFee.propTypes = {

}
export default GiftDappRewardRequestFee;
export {GiftDappRewardRequestFee};
