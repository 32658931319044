import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button, makeStyles, withWidth } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet } from '@fortawesome/free-solid-svg-icons'
const style = makeStyles(theme => ({
    root : {
    },
    btn : {
        textTransform : "capitalize",
        background : theme.colors.walletBtn,
        color : theme.colors.walletText,
        padding : 10,
        paddingLeft : 20,
        paddingRight : 20,
        borderRadius : 5,
        "&:hover" : {
            background : theme.colors.walletBtn,
            color : theme.colors.walletText,
        }
    },
    icon : {
        color : theme.colors.walletText,
        marginLeft : 15,
        fontSize : 25,
    }
}))
const ConnectButton = withWidth()(function({width, active, ...props}) {
    const classes = style();
    return (
        <div className={classes.root}>
            <Button className={classes.btn} {...props}>
                Connect {width !== "xs" && <>wallet <FontAwesomeIcon className={classes.icon} icon={faWallet} size="lg"/></>}
                {active ? <ArrowDropUp fontSize="small"/> : <ArrowDropDown fontSize="small"/>}
            </Button>
        </div>
    )
})
ConnectButton.propTypes = {
    onClick : PropTypes.func,
    active : PropTypes.bool,
}
export default ConnectButton;
export {ConnectButton};