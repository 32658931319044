import { IconButton, makeStyles } from '@material-ui/core'
import { ArrowBack   } from "@material-ui/icons";
import React from 'react'
import { useSelector } from 'react-redux';
import { backButtonStrings, BACK_BUTTON_REDUCER } from './config';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        display : "flex",
        alignItems : "center",
        marginBottom : 50,
        cursor : "pointer",
    },
    text : {
        fontSize : 12,
        color : colors.textSecondary,
    },
    icon : {
        color : colors.textSecondary,
    }
}))
export default function CustomBackNav () {
    const classes = style(),
    backButtonReducer = useSelector(state=>state[BACK_BUTTON_REDUCER]),
    { 
        [backButtonStrings.action] : action, 
        [backButtonStrings.text] :  text,
        [backButtonStrings.visible] :  visible,
    } = backButtonReducer;
    if(!visible) return null;
    return (
        <div className={classes.root} onClick={action}>
            <IconButton /* onClick={action} */>
                <ArrowBack className={classes.icon}/>
            </IconButton> 
            <span className={classes.text}>{text}</span>
        </div>
    )
}