import React from 'react'
import { useDispatch } from 'react-redux'
import walletUtil from '../../../../../utils/web3/walletUtil';
import { List, ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import useGAEventsTracker from './../../../../../hooks/useGAEventsTracker';

const style = makeStyles(theme => ({
    root : {
        color : theme.colors.text,
        padding: 18
    },
    midiumlogo: {
        height: 24,
        width: 24,
      },
    listItem : {
        borderRadius : 5,
        border:`1px solid ${theme.colors.backgroundAccent}`,
        margin:"15px 0",
        "&:hover" : {
            background : theme.colors.backgroundAccent
        }
    },
    selected : {
        color:theme.colors.backgroundAccent
    },
    list : {
        color : theme.colors.textSecondary,
        padding : 4,
        minWidth : 300,
        fontSize:13,
        marginLeft:4
    },
    avatarRoot : {
        minWidth : 25
    },
    link : {
        textDecoration : "none",
        color : theme.colors.text
    },
    profile : {
        color : theme.colors.accent,
    }
}))
function GiftDappNetworks({network,onSelect}) {
    const dispatch = useDispatch(),
    classes = style();
    
    const GAEventsTracker = useGAEventsTracker();

    return (
        <div className={classes.root}>
                <h3>Change Network</h3>
                <p>You are currently on the <span className={classes.selected}>{network}</span> network.</p>
            <List>
                {walletUtil.networkList.map((network, index) => {
                    return     <a href="#" className={classes.link} key={network.networkId}>
                        <ListItem className={classes.listItem} button onClick={() => { onSelect && onSelect(network.networkId); GAEventsTracker(`connect_to_${network.name}`, "header"); }}>
                        <img src={network.image} className={classes.midiumlogo} alt={"Binance"}/>
                                <ListItemText secondary={network.name} secondaryTypographyProps={{
                                    className : classes.list
                                }}/>
                        </ListItem>
                    </a>
                })}
            </List>
        </div>
    )
}
GiftDappNetworks.propTypes = {

}
export default GiftDappNetworks;
export {GiftDappNetworks};
