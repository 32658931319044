import React, { useEffect, useState } from 'react'
export default function GiftWalletTokenAmountSymbol ({getSymbol, getReducedFund}) {
    const [state, _setState] = useState({
            symbol : "", amount : 0
        }),
        {symbol, amount} = state,
        setState = _state => _setState(state=>({...state, ..._state}));
    useEffect(() => {
        (async () => {
            if(getSymbol){
                const symbol = await getSymbol();
                setState({symbol})
            }
            if(getReducedFund){
                const amount = await getReducedFund();
                setState({amount})
            }
        })();
    }, [])
    return parseInt(amount).toLocaleString() + " " + String(symbol).toUpperCase()
}