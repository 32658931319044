import React, { useEffect } from "react";
import PropTypes from "prop-types";

function TelegramButton(props) {
 
  useEffect(() => {
    const {
      botName,
      buttonSize,
      cornerRadius,
      requestAccess,
      usePic,
      dataOnauth,
      dataAuthUrl,
      lang,
      widgetVersion,
    } = props;
    window.TelegramLoginWidget = {
      dataOnauth: (user) => dataOnauth(user),
    };

    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?"+widgetVersion;
    script.setAttribute("data-telegram-login", botName);
    script.setAttribute("data-size", buttonSize);
    if (cornerRadius !== undefined) {
      script.setAttribute("data-radius", cornerRadius);
    }
    script.setAttribute("data-request-access", requestAccess);
    script.setAttribute("data-userpic", usePic);
    script.setAttribute("data-lang", lang);
    if (dataAuthUrl !== undefined) {
      script.setAttribute("data-auth-url", dataAuthUrl);
    } else {
      script.setAttribute(
        "data-onauth",
        "TelegramLoginWidget.dataOnauth(user)"
      );
    }
    script.async = true;
    document.getElementById('tele-bot-button').appendChild(script);
  });

    return (
      <div
        className={props.className}
        id="tele-bot-button"
      >
        {props.children}
      </div>
    );
}

TelegramButton.propTypes = {
  botName: PropTypes.string.isRequired,
  dataOnauth: PropTypes.func,
  buttonSize: PropTypes.oneOf(["large", "medium", "small"]),
  cornerRadius: PropTypes.number,
  requestAccess: PropTypes.string,
  usePic: PropTypes.bool,
  lang: PropTypes.string,
  widgetVersion: PropTypes.number,
};

TelegramButton.defaultProps = {
  botName: "samplebot",
  dataOnauth: () => undefined,
  buttonSize: "medium",
  requestAccess: "write",
  usePic: true,
  lang: "en",
  widgetVersion: 9,
};

export default TelegramButton;