import { walletConstant, WALLET_CONSTANTS } from "./reducer";
export const walletActions = payload => dispatch => dispatch({
    type : walletConstant,
    payload
});
export const walletTokenActions = {
    setTokenSlugs : payload => dispatch => dispatch({
        type : WALLET_CONSTANTS.WALLET_SET_TOKEN_SLUGS,
        payload
    })
}