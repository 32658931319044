import { Button, makeStyles } from '@material-ui/core'
import GiftDappJumbotron from "./GiftDappJumbotron/GiftDappJumbotron"
import GiftDappPIE from "./GiftDappPIE/GiftDappPIE"
import GiftDappNetworks from "./GiftDappNetworks/GiftDappNetworks"
import GiftDappDefiJourney from "./GiftDappDefiJourney/GiftDappDefiJourney"
import GiftDappBluePrint from "./GiftDappBluePrint/GiftDappBluePrint"
import React from 'react'
const style = makeStyles(({colors, breakpoints}) => ({
    root: {
        backgroundColor: colors.pageBackground,
        minHeight: "100vh",
        color: colors.pageColor,
        margin:"0px "
    },
    link : {
        color : colors.linkcolor,

    },
}))
export default function LandingHome () {
    const classes = style();
    return (
        <div className={classes.root}>
           <GiftDappJumbotron />
           <GiftDappPIE />
           <GiftDappNetworks />
           <GiftDappBluePrint />
           <GiftDappDefiJourney />
        </div>
    )
}