import { faTwitter, faTwitterSquare, faYoutube, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useLazyQuery } from "@apollo/client";
import { successToast } from '../../../../../../includes';
import CustomProfileImage from '../../../../../../includes/CustomProfileImage/CustomProfileImage';
import { copyToClipboard, getProfiles } from '../../../../../../utils';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        minWidth : 300,
        padding : 20,
    },
    header : {
        display : "flex",
        alignItems : "center",
        flexDirection : "column",
    },
    logo : {
        height : 50,
        width : 50,
        background : colors.secondary,
    },
    name : {
        fontSize : 14,
        fontWeight : "bold",
    },
    body : {
        marginTop : 30,
    },
    item : {
        display : "flex",
        justifyContent : "space-between",
        fontSize : 11,
        margin : "10px 0px",
    },
    mediaItem : {
        display : "flex",
        fontSize : 11,
        margin : "10px 0px",
    },
    property : {
        color : colors.textSecondary,
    },
    icon : {
        color : colors.textSecondary,
    },
    medialLink : {
        cursor : "pointer",
        marginLeft : 10,
        "&:hover" : {
            textDecoration : "underline",
        }
    },
    creatorContainer : {
        fontSize : 11,
        marginTop : 30,
    },
    creatorHeader : {
        color : colors.textSecondary,
        margin : "10px 0"
    },
    creatorInfo : {
        display : "flex",
        alignItems : "center",
    },
    creatorLogo : {
        width : 30,
        height : 30,
        background : colors.secondary,
    },
    creatorName : {
        fontSize :11,
        fontWeight : "none",
    }
}))
export default function GiftDappEventDescription ({data}) {
    const classes = style(),
    dispatch = useDispatch(),
    {
        description, website, name, image, 
        links, twitterUrl, youtubeUrl, 
        participants : _people, profileId
    } = data || {},
    [_getProfile, {data : profileData}] = useLazyQuery(getProfiles(), {
        variables : {
            filter : {_id : profileId}
        }
    }),
    {profiles} = profileData || {},
    profile = profiles?.[0],
    {lastname, firstname} = profile || {},
    creatorName = (lastname || "") + " " + ((firstname || "")),
    {participants, wins} = _people || {},
    info = [
        {name : "Description", value : description},
        {name : "Website", value : website},
        {name : "Participants", value : `${(wins || 0)} win ${wins > 1 ? "s" : ""}, ${(participants || 0)} participants`},
        {name : "Requires Verification", value : links ? "Yes" : "No"},
    ],
    socialMedia = [
        {name : "Twitter", value : twitterUrl},
        {name : "Youtube", value : youtubeUrl},
    ],
    MediaIcon = ({name}) => {
        switch(name) {
            case "Twitter" : return <FontAwesomeIcon 
                className={classes.icon} icon={faTwitter} size="lg"/>;
            case "Youtube" : return <FontAwesomeIcon 
                className={classes.icon} icon={faYoutube} size="lg"/>;
        }
    }
    useEffect(() => {
        if(profileId){
            _getProfile();
        }
    }, [profileId])
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <CustomProfileImage
                    image={image}
                    name={name}
                    classes={{
                        name : classes.name,
                        logo : classes.logo
                    }}
                />
            </div>
            <Divider/>
            <div className={classes.body}>
                {info && info.map((item, index) => {
                    return <div className={classes.item} key={index}>
                        <div className={classes.property}>{item.name}:</div>
                        <div className={classes.value}>{item.value}</div>
                    </div>
                })}
                {links && <>
                    <Divider/>
                    {socialMedia && socialMedia.map((item, index)=> {
                        return <div key={index} className={classes.mediaItem}>
                            <MediaIcon name={item.name}/>
                            <div onClick={() => {
                                copyToClipboard(item.value);
                                successToast("Copied")(dispatch);
                            }} className={classes.medialLink}>{item.value}</div>
                        </div>
                    })}
                </>}
                <Divider/>
                <div className={classes.creatorContainer}>
                    <div className={classes.creatorHeader}>Creator</div>
                    <div className={classes.creatorInfo}>
                        <CustomProfileImage
                            image={image}
                            name={creatorName}
                            classes={{
                                name : classes.creatorName,
                                logo : classes.creatorLogo
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}