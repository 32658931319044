import React, { useState } from 'react'
import classNames from 'classnames';
import { makeStyles, withWidth } from '@material-ui/core';
import { Filter }  from "@material-ui/icons"
import { DASHBOARD_NAV_TABS } from '../../..';
import { CustomButton, CustomDialog } from '../../../../includes';
import { DASHBOARD_NAV_PROJECT_TYPES } from '../..';
import GiftDappDashboardNavToggleButton from './GiftDappDasboardNavToggleButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
const style = makeStyles(({colors, isDark, breakpoints}) => ({
    root : {
        display : "flex",
        alignItems : "center",
        fontSize : 10,
        justifyContent : "space-between",
        [breakpoints.down("xs")] : {
            flexDirection : "column",
        }
    },
    navContainer : {
        display : "flex",
        [breakpoints.down("xs")] : {
            justifyContent : "space-between",
            width : "100%",
        }
    },
    nav : {
        cursor : "pointer",
        display : 'flex',
        alignItems : "center",
        justifyContent : "space-between",
    }, 
    btnContainer : {
        display : "flex",
        [breakpoints.down("xs")] : {
            width : "100%",
            marginTop : 20,
        }
    },
    btn : {
        margin : 5,
        borderRadius : 5,
        padding : "5px 30px",
        fontSize : 10,
        color : colors.text,
        background : colors.secondary,
    },
    toggleBtn : {
        margin : 5,
        borderRadius : 5,
        padding : "5px 20px",
        fontSize : 10,
        background: isDark ? colors.pageBackground : colors.transparent,
        border: `1px solid ${isDark ? colors.secondary : colors.navBarLinks}`,
        color: isDark ? colors.text : colors.tertiaryTextColor
    },
    icon : {
        marginRight : 3,
    },
    btnActive : {
        border : "solid 1px " + colors.accent,
    },
    active : {
        background : !isDark ? colors.accent : colors.background,
        color : !isDark ? colors.background : colors.text,
        borderRadius : 20,
        // color : colors.accent,
        // fontWeight : "bold",
        minWidth: 90,
        justifyContent: "space-between",
        display: "flex",
    },
    inactive : {
        background : colors.inactive,
        color : colors.textSecondary,
        "&:hover" : {
            color : colors.textSecondary,
            background : colors.inactive
        }
    },
    title : {
        paddingLeft : 10,
    },
    badge : {
        background : !isDark ? colors.accent : colors.secondary,
        borderRadius : 20,
        padding : 5,
        margin : 5,
        height : 25,
        width : 30,
        display : "flex",
        justifyContent : "center",
    },
    badgeActive : {
        background : !isDark ? colors.background : colors.secondary,
        color : colors.text
    },
    dialogPaper : {
        background : colors.selectedCheckBoxColor,
        border : "solid 1px " + colors.accentSecondary,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
}

))
function GiftDappDashboardNav({
    rewardCounts,
    activeTab, 
    projectTypes = [],
    toggleProjectType,
    setTab,
    width
}) {
    let  DASHBOARD_NAV_PROJECT_TYPES_WITH_BOOL = DASHBOARD_NAV_PROJECT_TYPES.map(({_id,label})=>{
        return {
            _id,
            label,
            checked:true
        }
    })
    const classes = style(),
    project = DASHBOARD_NAV_PROJECT_TYPES[0],
    influencer = DASHBOARD_NAV_PROJECT_TYPES[1],
    {active : activeCount, ended : endedCount} = rewardCounts || {},
    allCount  = (activeCount || 0) + (endedCount || 0),
    [openDialog,setOpenDialog] = useState(false),
    [dashboardNavProjectTypeWithBool,setDashboardNavProjectTypeWithBool] = useState(DASHBOARD_NAV_PROJECT_TYPES_WITH_BOOL),
    [allTypesSelected,setAllTypesSelected] = useState(true),
    closeDialog = () => setOpenDialog(false),
    toggleProject = (button) =>{
       let newDashboard= dashboardNavProjectTypeWithBool.map(({_id,label,checked})=>{
           let check;
            if(_id === button._id){
                return {
                    _id,
                    label,
                    checked : !checked
                }
            }else{
                return {
                    _id,
                    label,
                    checked
                }
            }
            
        })
        if(newDashboard.find(item=>item.checked===false)){
setAllTypesSelected(false)
        }else{
            setAllTypesSelected(true)
        }
        setDashboardNavProjectTypeWithBool(newDashboard)
        toggleProjectType(button._id)
        closeDialog()
    },
    selectAll = (check) => {
        setAllTypesSelected(check)
        DASHBOARD_NAV_PROJECT_TYPES_WITH_BOOL = DASHBOARD_NAV_PROJECT_TYPES.map(({_id,label})=>{
            return {
                _id,
                label,
                checked : check
            }
        })
setDashboardNavProjectTypeWithBool(DASHBOARD_NAV_PROJECT_TYPES_WITH_BOOL)
closeDialog()
    }


    return (
        <div className={classes.root}>
            <div className={classes.navContainer}>
                <div 
                    onClick={() => setTab && setTab(DASHBOARD_NAV_TABS[0]._id)}
                    className={classNames(
                        classes.nav, 
                        activeTab === DASHBOARD_NAV_TABS[0]._id ? classes.active : ""
                    )}>
                        <div className={classes.title}>{DASHBOARD_NAV_TABS[0].label}</div>
                        <div className={classNames(classes.badge, activeTab === DASHBOARD_NAV_TABS[0]._id ? classes.badgeActive : "")}>{allCount > 9 ? "9+" : allCount}</div>
                    </div>
                <div 
                    onClick={() => setTab && setTab(DASHBOARD_NAV_TABS[1]._id)}
                    className={classNames(
                        classes.nav, 
                        activeTab === DASHBOARD_NAV_TABS[1]._id ? classes.active : ""
                    )}>
                        <div className={classes.title}>{DASHBOARD_NAV_TABS[1].label}</div>
                        <div className={classNames(
                            classes.badge, 
                            activeTab === DASHBOARD_NAV_TABS[1]._id ? 
                                classes.badgeActive : ""
                        )}>{activeCount > 9 ? "9+" : (activeCount || 0)}</div>
                    </div>
                <div 
                    onClick={() => setTab && setTab(DASHBOARD_NAV_TABS[2]._id)}
                    className={classNames(
                        classes.nav, 
                        activeTab === DASHBOARD_NAV_TABS[2]._id ? classes.active : ""
                    )}>
                        <div className={classes.title}>{DASHBOARD_NAV_TABS[2].label}</div>
                        <div className={classNames(classes.badge, activeTab === DASHBOARD_NAV_TABS[2]._id ? classes.badgeActive : "")}>{endedCount > 9 ? "9+" : (endedCount || 0)}</div>
                    </div>
            </div>
            <div className={classes.btnContainer}>
                {/* <CustomButton
                    classes={{
                        btn : classNames(
                            classes.btn, 
                            projectTypes.indexOf(project._id) === -1 ? 
                                classes.inactive : classes.btnActive
                        ),
                    }}
                    onClick={() => toggleProjectType(project._id)}
                    type="primary"
                >
                    {project.label}
                </CustomButton>
                <CustomButton
                    classes={{
                        btn : classNames(
                            classes.btn,
                            projectTypes.indexOf(influencer._id) === -1 ? 
                                classes.inactive : classes.btnActive
                        )
                    }}
                    onClick={() => toggleProjectType(influencer._id)}
                    type="primary"
                >
                    {influencer.label}
                </CustomButton>
                */}
                <CustomButton
                    classes={{
                        btn : classNames(
                            classes.toggleBtn,
                            // projectTypes.indexOf(influencer._id) === -1 ? 
                            //     classes.inactive : classes.btnActive
                        )
                    }}
                    noBoxShadow
                    fullWidth={width === "xs" ? true :false }
                    onClick={() => setOpenDialog(true)}
                    type="primary"

                >
                   <FontAwesomeIcon icon={faFilter} size="2x" className={classes.icon} /> Filter 
                </CustomButton>
                <CustomDialog
                within
                open={openDialog}
                // close={closeDialog}
                classes={{
                    dialogPaper : classes.dialogPaper,
                    _close : classes.dialogClose
                }}
            >
                <GiftDappDashboardNavToggleButton 
                dashboardNavProjectTypeWithBool ={dashboardNavProjectTypeWithBool}
                toggleProject={toggleProject}
                allTypesSelected={allTypesSelected}
                selectAll={selectAll}
                /> 
            </CustomDialog>
            </div>
        </div>
    )
}
GiftDappDashboardNav.propTypes = {
    
}
export default withWidth()(GiftDappDashboardNav);
export {GiftDappDashboardNav};