import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, List, makeStyles } from '@material-ui/core'
import { CustomButton, CustomDialogBox, errorToast } from '../../../../../includes';
import CustomInputFields from '../../../../../includes/CustomInputFields/CustomInputFields';
import { ArrowDownward } from '@material-ui/icons';
import { walletStrings, WALLET_REDUCER_NAME } from '../../../../structures';
import { useDispatch, useSelector } from 'react-redux';
import {GiftDappTokenSearchAddTokenBox} from './GiftDappTokenSearchAddTokenBox/GiftDappTokenSearchAddTokenBox';
import { getContractInstance, giftContractActions, onEnterPress, tokenContractActions } from '../../../../../utils';
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from '../../../config';
const style = makeStyles(({colors, isDark, breakpoints}) => ({
    root : {
        padding : 20,
        [breakpoints.up("sm")] : {
            minWidth : 400,
        }
    },
    marginTop : {
        marginTop : 20,
    },
    classeNameSort : {
        display : 'flex',
        justifyContent : "space-between",
        color : colors.text,
        marginTop : 20,
    },
    donwIcon : {
        color : colors.text,
    },
    dialogBox : {
        border : "none",
    },
    searchContainer : {
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        justifyContent : "center",
        minHeight : 200,
    },
    progress : {
        marginBottom : 20,
        fontSize : 14,
    },
    searchBtnContainer : {
        marginTop : 10,
    },
    searchBtn : {
        color : !isDark ? colors.text : colors.textDark
    }
}))
const TOKEN_SELECT_TABS = {
    SEARCHING : "SEARCHING",
    LIST : "LIST",
}
function GiftDappTokenSearchAdd({onClose}) {
    const classes = style(),
    dispatch = useDispatch(),
    [state, _setState] = useState({
        tab : TOKEN_SELECT_TABS.LIST,
        fetchingToken : false,
        newToken : null,
        tokens : [],
    }),
    searchRef = useRef(),
    {tab, fetchingToken, newToken, tokens, searchAddress} = state,
    setState = _state => _setState(state=>({...state, ..._state})),
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.web3] : web3,
        [walletStrings.address] : address,
    } = walletReducer,
    giftRewardReducer = useSelector(state => state[GIFT_REWARD_REDUCER_NAME]),
    {
        [giftRewardStrings.rewardTokens] : rewardTokens,
        [giftRewardStrings.defaultTokenLoaded] : defaultTokenLoaded = []
    } = giftRewardReducer,
    searchForToken = useCallback(async () => {
        // if(String(contractAddress).length !== 42) 
        //     return errorToast("Invalid address")(dispatch);
        setState({
            tab : TOKEN_SELECT_TABS.SEARCHING, 
            fetchingToken : true,
            // newToken : {address : contractAddress}
        });
        try{
            const contractActions = tokenContractActions(await getContractInstance(web3, null, searchAddress));
            //TRY TO GET NAME AND SYMBOL TO VERIFY
            //if not, error will be triggered
            await contractActions.name();
            setState({
                fetchingToken : false, 
                newToken : searchAddress, 
            });
        }catch({message}){
            errorToast("Invalid ERC20 Token")(dispatch);
            setState({fetchingToken : false, tab : TOKEN_SELECT_TABS.LIST});
        }
    }, [searchAddress]),
    addToTokens = address => {
        if(Array.isArray(address)){
            for (let i = 0; i < address.length; i++) {
                const _address = address[i];
                addToTokens(_address);
            }
        }
        else if(tokens.indexOf(address) === -1)
            setState({tokens : [...tokens, address]});
    };
    useEffect(() => {
        (async () => {
            //rgp token address is same for both contracts
            const RGPAddress = await giftContractActions().RGPTokenAddress();
            addToTokens(RGPAddress);
            if(rewardTokens) addToTokens(rewardTokens);
        })();
    }, [address, JSON.stringify(rewardTokens)]);
    const allTokens = [...defaultTokenLoaded];
    for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];
        if(!defaultTokenLoaded || !defaultTokenLoaded.find(i=>i.address === token)){
            allTokens.push({address : token});
        }
    }
    return (
        <div className={classes.root}>
            <CustomDialogBox
                title="Select a token"
                onClose={onClose}
                noDivider
                classes={{
                    root : classes.dialogBox
                }}
            >
                <CustomInputFields
                    classes={{
                        root : classes.marginTop,
                    }}
                    field={{
                        name : "token", 
                        label : "Search token", 
                        margin : "dense",
                        onKeyDown : onEnterPress(searchForToken),
                        ref : searchRef,
                        onChange : ({target : {value : searchAddress}}) => setState({searchAddress})
                    }}
                />
                <div className={classes.searchBtnContainer}>
                    <CustomButton
                        type="accent"
                        onClick={searchForToken}
                        classes={{
                            btn : classes.searchBtn
                        }}
                    >
                        Search Token Address
                    </CustomButton>
                </div>
                <div className={classes.classeNameSort}>
                    <span>Token names</span>
                    <ArrowDownward className={classes.donwIcon}/>
                </div>
                <div style={{color : "white"}}>
                    {tab === TOKEN_SELECT_TABS.LIST && <List>
                        {allTokens && allTokens.map((token, index) => {
                            const {address, symbol, decimals, balance} = token;
                            return <GiftDappTokenSearchAddTokenBox
                                key={index}
                                address={address}
                                web3={web3}
                                symbol={symbol}
                                decimals={decimals}
                                balance={balance}
                                onClose={onClose}
                            />
                        })}
                    </List>}
                    {tab === TOKEN_SELECT_TABS.SEARCHING && <div>
                        {fetchingToken ? <div className={classes.searchContainer}>
                                <CircularProgress className={classes.progress}/>
                                <span className={classes.searchText}>
                                    Searching token...
                                </span>
                                <small><u style={{cursor : "pointer"}} 
                                    onClick={()=>setState({
                                        tab : TOKEN_SELECT_TABS.LIST, 
                                        fetchingToken : false
                                    })}>
                                    cancel</u></small>
                            </div> : 
                            <div>
                                {newToken && <GiftDappTokenSearchAddTokenBox
                                    address={newToken}
                                    onClose={onClose}
                                    web3={web3}
                                />}
                        </div>}
                    </div>}
                </div>
            </CustomDialogBox>
        </div>
    )
}
GiftDappTokenSearchAdd.propTypes = {
    
}
export default GiftDappTokenSearchAdd;
export {GiftDappTokenSearchAdd};