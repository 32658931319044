import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, makeStyles } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux';
import { GiftDappFeedbackComponent, GiftDappTestTokenRequestComponent } from '.';
import { CustomButton, customPopoverActions, customPopoverString } from '../../../includes';
import walletUtil from '../../../utils/web3/walletUtil';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        position : "fixed",
        right : 5,
        top : 100,
    },
    iconBtn : {
        background : colors.secondary,
        borderRadius : 10,
        padding : 10,
        "&:hover" : {
            background : colors.secondary,
        },
        [breakpoints.down("sm")] : {
            padding : 5,
        }
    },
    icon : {
        // color : colors.accent
    },
}))
export default function GiftDappTestTokenRequest () {
    const classes = style(),
    dispatch = useDispatch();
    if(walletUtil.isMainNet()) return null;
    const displayComponent = () => {
        return <div className={classes.compContainer}>
            <GiftDappTestTokenRequestComponent/>
            <GiftDappFeedbackComponent/>
        </div>
    }
    return (
        <div className={classes.root}>
            <IconButton onClick={({target : anchorEl}) => {
                customPopoverActions.payload({
                    [customPopoverString.anchorEl] : anchorEl,
                    [customPopoverString.customComponent] : displayComponent,
                })(dispatch);
            }} className={classes.iconBtn}>
                <FontAwesomeIcon className={classes.icon} icon={faInfoCircle}/>
            </IconButton>
        </div>
    )
}