import { PROCESS_DISPLAY_STAGES } from "../../utils";

export const customProcessStrings = {
    list : "list",
    title : "title",
}
export const customProcessConstants = {
    SET_CUSTOM_PROCESS_DISPLAY : "SET_CUSTOM_PROCESS_DISPLAY",
    CUSTOM_PROCESS_DISPLAY_NEXT : "CUSTOM_PROCESS_DISPLAY_NEXT",
    CLOSE_CUSTOM_PROCESS_DISPLAY : "CLOSE_CUSTOM_PROCESS_DISPLAY",
}
export const customProcessReducer = (state={}, action) => {
    const {payload, type} = action;
    switch(type){
        case customProcessConstants.CUSTOM_PROCESS_DISPLAY_NEXT : 
            const {[customProcessStrings.list] : procList} = state;
            const itemIndex = procList && procList.findIndex(item=>item.stage === PROCESS_DISPLAY_STAGES.PROCESSING);
            if(itemIndex !== -1){
                procList[itemIndex].stage = PROCESS_DISPLAY_STAGES.COMPLETE;
                const nextIndex = itemIndex + 1;
                if(procList[nextIndex]){
                    procList[nextIndex].stage = PROCESS_DISPLAY_STAGES.PROCESSING;
                }
            }else if(procList && procList[0]){
                procList[0].stage = PROCESS_DISPLAY_STAGES.PROCESSING;
            }
            return {...state, [customProcessStrings.list] : [...procList.map(i=>({...i}))]};
        case customProcessConstants.CLOSE_CUSTOM_PROCESS_DISPLAY : 
            return {...state, [customProcessStrings.list] : null, [customProcessStrings.title] : null};
        case customProcessConstants.SET_CUSTOM_PROCESS_DISPLAY : 
            return {...state, ...payload};
        default : return state;
    }
}
export const CUSTOM_PROCESS_DISPLAY_REDUCER_NAME = "customProcessReducer";