import { store } from "../helpers";
import { giftDappUtilConstants, giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from "./config";

export const giftDappUtilActions = {
    processDisplay : (show=true, message, component) => dispatch => giftDappUtilActions.payload({
        [giftDappUtilStrings.processing] : show,
        [giftDappUtilStrings.processComponent] : !show ? null : component,
        [giftDappUtilStrings.processMsg] : !show ? null : message,
    })(dispatch),
    quickRefresh : dispatch => giftDappUtilActions.payload({
        [giftDappUtilStrings.refreshCount] : store.getState()[GIFT_DAPP_UTIL_REDUCER_NAME][giftDappUtilStrings.refreshCount] + 1
    })(dispatch),
    payload : payload => dispatch => dispatch({
        type : giftDappUtilConstants.SET_GIFT_DAPP_UTILS,
        payload
    })
}