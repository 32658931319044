import React from 'react'
import classNames from 'classnames';
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import CustomProfileImage from '../../../../../../includes/CustomProfileImage/CustomProfileImage';
import { history } from '../../../../../../helpers';
import { giftDappRouteNames } from '../../../../../../utils';
const style = makeStyles(({colors, isDark}) => ({
    root : {
        fontSize : 12,
        background : !isDark ? colors.secondaryDark : colors.secondary,
        padding : 10,
        borderBottom : `solid 1px ${colors.pageBackground}`
    },
    container : {
        margin : "10px 0",
        fontSize : 12,
        display : 'flex',
        justifyContent : "centent",
        alignItems : "center",
        height : "100%",
        color : colors.textSecondary
    },
    name : {
        padding : 10,
    },
    logo : {
        height : 50,
        width : 50,
        borderRadius : "100%",
        background : colors.secondary,
        display : "flex",
        alignItems: "center",
        justifyContent : "center",
    },
    claimBtn : {
        cursor : "pointer",
        color : colors.accent,
    },
    expired : {
        color : colors.textSecondary,
    },
    clickable : {
        border : `solid 2px ${colors.accent}`
    },
    descriptionBtn : {
        padding : 5,
    },
    description : {
        color : colors.textSecondary,
        "&:hover" : {
            color : colors.text
        }
    },
    adjustRight : {
        display : "flex",
        justifyContent : "flex-end",
        paddingRight : 10,
    }
}))
function GiftDappEventListitem({
    expired, onClaim, name, image, profileId, eventId, 
    rewardRange, network, isMyReward, onEdit, participants,
    onDescription
}) {
    const classes = style(),
    participantString = !participants ? 0 : ((participants.wins || 0 ) + "/" + participants.participants)
    return <Grid container className={classes.root}>
            <Grid xs={12} sm={4} md={4} lg={4} xl={4} item>
                <div className={classes.container}>
                    <CustomProfileImage
                        onClick={() => history.push(giftDappRouteNames().profileView_+profileId, {eventId})}
                        image={image}
                        name={name}
                        classes={{
                            clickable : classes.clickable
                        }}
                    />
                    <IconButton title="Description" onClick={onDescription} className={classes.descriptionBtn}>
                        <Description className={classes.description}/>
                    </IconButton>
                </div>
            </Grid>
            <Grid xs={12} sm={2} md={2} lg={2} xl={2} item>
                <div className={classes.container}>
                    {rewardRange}
                </div>
            </Grid>
            <Grid xs={12} sm={2} md={2} lg={2} xl={2} item>
                <div className={classes.container}>
                    {network}
                </div>
            </Grid>
            <Grid xs={12} sm={2} md={2} lg={2} xl={2} item>
                <div className={classNames(classes.container, classes.adjustRight)}>
                    {participantString}
                </div>
            </Grid>
            <Grid xs={12} sm={2} md={2} lg={2} xl={2} item>
                <div className={classes.container}>
                <div
                        className={classNames(classes.claimBtn, expired ? classes.expired : "")}
                        {...((expired === false) && { onClick: isMyReward ? onEdit : onClaim })}
                    >
                        {isMyReward ? "Edit" : (expired !== undefined) ? (expired ? "Expired" : "Claim") : ""}
                    </div>
                </div>
            </Grid>
        </Grid>        
    
}
GiftDappEventListitem.propTypes = {
    
}
export default GiftDappEventListitem;
export {GiftDappEventListitem};