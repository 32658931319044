//We are using combine reducer, so each reducer event has a name
//event/string  name for wallet reducer
export const walletConstant = "GIFT_WALLET_STATE";
/**
 * Reducer pattern used for just state modification only
 * hence an object is always passed as the payload (key-value pair)
 * simplied the reduced switch actions
 * 
 * ===> this is the key for the payload objects
 */
export const walletStrings = {    
    address : "address",
    networkId : "networkId",
    networkWallet : "networkWallet",
    addresses : "addresses",
    contractAddresses : "contractAddresses",
    web3 : "web3",
    contracts : "contracts",
    totalSupply : "totalSupply",
    hasRequestConn : "hasRequestConn",
    balance : "balance",
    localNetworkSetup : "localNetworkSetup",
    networkType : "networkType",
    listedTokens : "listedTokens",
    giftContractAddress : "giftContractAddress",
}
/**
 * GENERAL REDUCER : To handle the common stuffs like notifications and alerts
 * @param {Object} state - the initial state of the wallet reducer
 * @param {action} action - contains the type and payload of the dispatch event
 */
export const walletReducer = (state={
    [walletStrings.balance] : 0,
}, action) => {
    const {type, payload} = action;
    switch(type){
        case walletConstant : 
            return {...state, ...payload};
        default : return state;
    }
}
export const WALLET_REDUCER_NAME = "walletReducer";


export const walletTokenStrings = {
    tokenSlugs : "tokenSlugs",
}
export const WALLET_CONSTANTS = {
    WALLET_SET_TOKEN_SLUGS : "WALLET_SET_TOKEN_SLUGS",
}
export const walletTokenReducer = (state={
    [walletTokenStrings.tokenSlugs] : {},
}, action) => {
    const {type, payload} = action;
    switch(type){
        case WALLET_CONSTANTS.WALLET_SET_TOKEN_SLUGS : 
            return {...state, [walletTokenStrings.tokenSlugs] : {
                ...state[walletTokenStrings.tokenSlugs], ...payload
            }};
        default : return state;
    }
}
export const WALLET_TOKEN_REDUCER_NAME = "walletTokenReducer";