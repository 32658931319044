import { withStyles } from '@material-ui/core'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React from 'react'
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../../../../utils/config';
const style = ({colors, breakpoints}) => ({
    root : {
        border : `solid 1px ${colors.textLight1}`,
        borderRadius : 5,
        padding : 10,
        position : "relative",
        minHeight : 50,
        fontSize : 12,
        cursor : "pointer",
    },
    unchecked : {
        color : colors.textLight1,
    },
    checked : {
        color : colors.accent,
    },
    selected : {
        color : colors.accent,
        border : `solid 1px ${colors.accent}`,
    },
    checkContainer : {
        position : "absolute",
        right : 10,
        top : 10,
    },
    imageContainer : {
        marginTop : 30,
        display : "flex",
        justifyContent : "center",
    },
    image : {},
    icon : {
        height : 50,
        width : 50,
        color : colors.textLight1,
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
    },
    image : {
        height : 50,
        width : 50,
        borderRadius : "100%",
    },
    labelContainer : {
        display : "flex",
        justifyContent : "center",
        marginTop : 10,
        color : colors.textLight1,
    },
    label : {
    },
    selectedText : {
        color : colors.text,
    },
    bg : {
        background : colors.textLight1,
        color : colors.black,
        borderRadius : "100%",
    }
})
export default withStyles(style)(function GiftDappRewardSetupProfileOptionBox ({
    classes, selected, onSelect, image, icon : Icon, label
}) {
    const appDomain = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME][giftDappUtilStrings.appDomain])
    return (
        <div onClick={onSelect} className={classNames(classes.root, selected ? classes.selected : "")}>
            <div className={classes.checkContainer}>
                {selected ? <RadioButtonCheckedIcon className={classes.checked}/> : 
                <RadioButtonUncheckedIcon className={classes.unchecked}/>}
            </div>
            <div className={classes.imageContainer}>
                {image ? 
                    <img src={/^http/.test(image) ? image : appDomain + image} alt="option" className={classes.image}/> : 
                    (Icon ? <Icon className={classes.icon}/> : 
                    <div className={classNames(classes.icon, classes.bg)}>
                        {label ? String(label).substr(0, 1).toUpperCase() : "P"}
                    </div>)}
            </div>
            <div className={classes.labelContainer}>
                <div className={classNames(classes.label, selected ? classes.selectedText : "")}>{label}</div>
            </div>
        </div>
    )
})