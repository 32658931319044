import { makeStyles } from '@material-ui/core'
import React from 'react'
import { CustomButton } from '../../../../includes';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        color : colors.text,
        padding : 10,
        fontSize : 11,
    },
    header : {
        textDecoration : "underline",
        lineHeight : 3,
    },
    subheader : {
        color : colors.textSecondary,
    },
    btn : {
        fontSize : 11,
        marginTop : 10,
        padding : "5px 10px",
    }
}))
export default function GiftDappSideDisplayBox ({header, subheader, btnText, btnAction}) {
    const classes = style();
    return (
        <div className={classes.root}>
            <div className={classes.header}>{header}</div>
            <div className={classes.subheader}>{subheader}</div>
            <CustomButton
                type="secondary"
                classes={{
                    btn : classes.btn
                }}
                variant="outlined"
                onClick={btnAction}
            >
                {btnText}
            </CustomButton>
        </div>
    )
}