import { faFileImage, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, makeStyles, withWidth } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../helpers';
import { breadCrumbActions, breadCrumbStrings, warningToast } from '../../../../includes';
import { backButtonActions } from '../../../../includes/CustomBackNav/actions';
import { backButtonStrings } from '../../../../includes/CustomBackNav/config';
import { giftDappV1RouteNames, ROUTE_DISPLAY_STRINGS } from '../../../../utils';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../../../utils/config';
import { giftRewardActions } from '../../actions';
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME, REWARD_FIELD_NAMES } from '../../config';
import GiftDappRewardSetupFooterButtons from '../GiftDappRewardSetupFooterButtons/GiftDappRewardSetupFooterButtons';
import GiftDappRewardSetupHeader from '../GiftDappRewardSetupHeader/GiftDappRewardSetupHeader';
import GiftDappRewardSetupInput from '../GiftDappRewardSetupInput/GiftDappRewardSetupInput';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        
    },
    description : {
        color : colors.textSecondary,
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
    },
    des : {
        color : colors.accent,
        marginRight : 5
    },
    addImage : {

    },
    divider : {
        background : colors.borderLines
    }
}));
export const REWARD_DETAILS_FIELD_NAMES = {
    NAME : "name",
    WEBSITE : "website",
    DESCRIPTION : "description",
    IMAGE : "image",
};
const formConfig = (project, width) => {
    const classes = style();
    return [
    {name : REWARD_DETAILS_FIELD_NAMES.NAME, label : project ? "Event Name" : "Event name"},
    {name : REWARD_DETAILS_FIELD_NAMES.WEBSITE, label : "Website", prefix : "https://"},
    {name : REWARD_DETAILS_FIELD_NAMES.DESCRIPTION, label : "Description", multiline : true, rows : 4},
    {name : REWARD_DETAILS_FIELD_NAMES.IMAGE, label : "Image", 
        description : <div className={classes.description}>
                <div className={classes.imageContainer}>
                    {/* <FontAwesomeIcon icon={faImage} size="5x"/> */}
                    <img src="/assets/imgIcon.svg" />
                </div>
                <div style={{display : "flex"}}><span className={classes.des}>Upload an image </span>  drag and drop </div>
                <div>PNG, JPG, GIF up to 1 MB</div>
            </div>, 
        type : "dropzone", input : {
        onChange : e => {
            if(e.target && e.target.files){
                const logo = e.target.files[0];
                
            }
        }
    }},
]}
function _GiftDappRewardSetupProjectDetails ({
    width, onContinue, profileBreadCrumbAction
}) {
    const classes = style(),
        dispatch = useDispatch(),
        appDomain = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME][giftDappUtilStrings.appDomain]),
        giftRewardReducer = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME]),
        {
            [giftRewardStrings.rewardData] : rewardData,
            [giftRewardStrings.isEdit] : isEdit,
        } = giftRewardReducer,
        {
            [REWARD_FIELD_NAMES.IS_PROJECT] : isProject,
        } = rewardData || {},
        onChange = useCallback(name => (prop) => {
            const {IMAGE} = REWARD_DETAILS_FIELD_NAMES;
            if(name === IMAGE){
                const logo = prop && prop[0];
                if(logo.size > 1000000)
                    return warningToast("Image shouldn't be more than 1MB")(dispatch)
                rewardData[name] = {value : logo};
                var reader = new FileReader();
                reader.addEventListener("load", function(){
                    const logoData = reader.result;
                    rewardData[name].data = logoData;
                    giftRewardActions.payload({
                        [giftRewardStrings.rewardData] : rewardData
                    })(dispatch);
                }, false)
                if(logo) reader.readAsDataURL(logo);
            }else {
                const {target : {value}} = prop;
                rewardData[name] = value;
                giftRewardActions.payload({
                    [giftRewardStrings.rewardData] : rewardData
                })(dispatch);
            }
        }, [JSON.stringify(rewardData)]),
        onRemove = useCallback(name => () => {
            giftRewardActions.payload({
                [giftRewardStrings.rewardData] : {
                    ...rewardData, [name] : null
                }
            })(dispatch);
        }, [JSON.stringify(rewardData)]),
        onReset = useCallback(() => {
            giftRewardActions.payload({
                [giftRewardStrings.rewardData] : {
                    [REWARD_DETAILS_FIELD_NAMES.NAME] : "",
                    [REWARD_DETAILS_FIELD_NAMES.WEBSITE] : "",
                    [REWARD_DETAILS_FIELD_NAMES.DESCRIPTION] : "",
                    [REWARD_DETAILS_FIELD_NAMES.IMAGE] : null,
                }
            })(dispatch)
        }, []);
    useEffect(() => {
        const {PROFILE, DETAILS} = ROUTE_DISPLAY_STRINGS.SETUP_STRINGS;
        breadCrumbActions.payload({
            [breadCrumbStrings.visible] : true,
            [breadCrumbStrings.crumbs] : [
                {
                    name : isEdit ? 
                        ROUTE_DISPLAY_STRINGS.DASHBOARD : ROUTE_DISPLAY_STRINGS.REWARD, 
                    path : isEdit ? giftDappV1RouteNames().dashboard : giftDappV1RouteNames().reward
                },
                {name : PROFILE, action : profileBreadCrumbAction},
                {name : DETAILS},
            ]
        })(dispatch);
        backButtonActions.payload({
            [backButtonStrings.visible] : true,
            [backButtonStrings.text] : `Back to ${PROFILE}`,
            [backButtonStrings.action] : profileBreadCrumbAction
        })(dispatch);
    }, [isEdit]);
    return (
        <div className={classes.root}>
            <GiftDappRewardSetupHeader
                header="Project Details"
                subheader="This is information give us a description of your reward"
            />
            {formConfig(isProject, width).map((field, index) => {
                const {name} = field;
                let value;
                if(name === REWARD_DETAILS_FIELD_NAMES.IMAGE){
                    if(rewardData?.[name]?.data){
                        value = {data : rewardData[name].data}
                    }else if(rewardData?.[name]){
                        value = {data : appDomain+rewardData[name]};
                    }
                }else value = rewardData?.[name];
                return <>
                    {width !== "xs" && <Divider className={classes.divider}/>}
                    <GiftDappRewardSetupInput
                        key={index}
                        {...field}
                        onChange={onChange(name)}
                        onRemove={onRemove(name)}  //to remove image
                        value={value}
                    />
                </>
            })}
            <GiftDappRewardSetupFooterButtons
                onContinue={onContinue}
                onReset={onReset}
            />
        </div>
    )
}
export const GiftDappRewardSetupProjectDetails = withWidth()(_GiftDappRewardSetupProjectDetails)
export {GiftDappRewardSetupProjectDetails as default};