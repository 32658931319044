import React, { useEffect } from 'react'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons';
import { breadCrumbActions, breadCrumbStrings } from '../../../includes';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { appValues, history } from '../../../helpers';
import { giftDappRouteNames } from '../../../utils';
import { Helmet } from 'react-helmet';
import createBrowserHistory from 'history/createBrowserHistory';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import useGAEventsTracker from './../../../hooks/useGAEventsTracker';

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #7599BD;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #4CAFFF;
  }

  &:focus {
    color: #7599BD;
    border-radius: 3px;
    outline: 2px solid #4CAFFF;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #4CAFFF;
    color: white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 160px;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #4CAFFF;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const style = makeStyles(({breakpoints, colors}) => ({
    root : {
        // background : colors.background,
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        borderRadius : 10,
        fontFamily : "revert",
        [breakpoints.up("sm")] : {
            padding : 20
            // padding : "20px 50px"
        },
        maxWidth : 1000,
        [breakpoints.up("lg")] : {

        }
    },
    header : {
        fontSize : 24,
        fontWeight : "bold",
        display : 'flex',
        justifyContent : 'center',
        marginBottom : 10,
        marginTop : 50,
        [breakpoints.down("xs")] : {
            justifyContent : "flex-start"
        },
    },
    breaker : {
        marginTop : 50,
        [breakpoints.down("xs")] : {
            marginTop : 50,
        }
    },
    description : {
        display : "flex",
        fontSize : 14,
        flexDirection : "column",
        textAlign : "center",
        alignItems : "center",
        justifyContent : "center",
        lineHeight : 2,
        color : colors.text, // new design color "#DCE5EF",
        // padding : "20px 50px",
        [breakpoints.up("sm")] : {
            padding : "20px 50px",
        },
        [breakpoints.up("md")] : {
            padding : "20px 100px",
        },
        [breakpoints.up("lg")] : {
            padding : "20px 120px",
        },
        [breakpoints.down("xs")] : {
            justifyContent : "flex-start",
            textAlign : "start"
        },
    },
    box : {
        position : "relative",
        fontSize : "smaller",
        border : `solid 1px ${colors.homeBorder}`,
        color : colors.homeText,
        fontSize : 14,
        borderRadius : 5,
        [breakpoints.up("sm")] : {
        }
    },
    itemBody : {
        minHeight : 380,
        padding : 20,
        [breakpoints.down("sm")] : {
            minHeight : 400,

        }
    },
    divider : {
        background : colors.homeBorder,
        marginTop : 20,
        borderTop : `solid .1 gray !important`,
        // boxShadow: "0px 0px 10px"
    },
    _header : {
        fontSize : 18,
        fontWeight : "bold",
        marginBottom : 20,
    },
    homeText : {

    },
    imageContainer : {
        padding : 20,
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        height : 200,
    },
    image : {
        height : 200,
        width : 250,
    },
    icon : {
        color : colors.homeLiner,
        minWidth : 40,
        [breakpoints.down("sm")] : {
            // display : "none",
        }
    },
    info : {
        lineHeight : 2,
        marginBottom : 10,
    },
    _itemContainer : {
        display : "flex",
        alignItems : "center",
        marginBottom : 10,
    },
    btnContainer : {
        position : "absolute",
        bottom : 0,
        left : 0,
        width : "calc(100% - 40px)",
        padding :20,
    },
    button : {
        border : `solid 1px ${colors.homeLiner}`,
        padding : 10,
        display : "flex",
        justifyContent : "center",
        textAlign : "center",
        borderRadius : 5,
        cursor : "pointer",
    },
    footer : {
        display : "flex",
        border : `solid 1px ${colors.homeLiner}`,
        borderRadius : 10,
        padding : "10px 20px",
        cursor : "pointer",
    },
    fotterContainer : {
        marginTop : 50,
        [breakpoints.up("sm")] : {
            padding : 20,
        },
        display : "flex",
        justifyContent : "center",
        alignItems : "cente",
        color : colors.homeLiner,
    }
}))




function GiftDappRewardHome({rewardAction}) {
    const classes = style(),
    dispatch = useDispatch();
    useEffect(() => {
        breadCrumbActions.payload({
            [breadCrumbStrings.visible] : false,
        })(dispatch);
    }, []);
    const isProduction = process.env.NODE_ENV === "production", 
    pageData = [
        {
            header : "For Influencer",
            description : <div>Become a <i><strong>reputable</strong></i> influencer with the opportunity to gift crypto to your followers and subscribers</div>,
            items : [
                "Reward your followers and supporters",
                "Become more reputable",
                "Generate more clicks and popularity"
            ],
            image : "/assets/home/influencer.png",
            clickAction : () => rewardAction(false)
        },{
            header : "For Projects",
            description : <div>
                Reward your users in a funny way, while also saving<i><strong>time</strong></i> and <i><strong>money</strong></i>
            </div>,
            items : [
                "Increase hype, support and userbase",
                "Land major investors by becoming reputable",
                "Generate more clicks and popularity"
            ],
            image : "/assets/home/project.png",
            clickAction : () => rewardAction(true)
        },{
            header : "For Everyone Else",
            description : <div>
                Still curious about what you can accomplish? We let you do it all:
            </div>,
            items : [
                "Claim instant reward from your favourite influencers or projects",
                "Refer your friends for more rewards",
            ],
            image : "/assets/home/everyone.png",
            clickAction : () => history.push(giftDappRouteNames().events)
        }
    ]
    const history = createBrowserHistory({forceRefresh:true});
    const switchVersion = version => {
        if(isProduction)
           window.location.href = version;
        else history.push(version);
    };
    const GAEventsTracker = useGAEventsTracker(),
    v1Link = isProduction ? appValues.v1Url : '/',
    v2Link = isProduction ? appValues.v2Url : '/v2/app';

    return <div className={classes.root}>
        <Grid container>
            <TabsUnstyled defaultValue={1} style={{marginLeft: 'auto'}}>
              <TabsList>
                <Tab onClick={() => { switchVersion(v1Link); GAEventsTracker("switch_to_v1", "reward page"); }}>V1</Tab>
                <Tab onClick={() => switchVersion(v2Link)}>V2</Tab>
              </TabsList>
            </TabsUnstyled>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <>
                    <h1 className={classes.header}>
                        Gift Rewards
                    </h1>
                    <div className={classes.description}>
                        Create an interesting experience for your followers and users to get rewards.
                        You have the opportunity to create a reward as an influencer for your followers or as a project for your users.
                        It is a great way to reward your followers and users for their loyalty!!!
                    </div>
                </>
            </Grid>
        </Grid>
        <Helmet>
        <title>Rigel protocol Gift Application and Gift Rewards</title>
            <meta name="description" content="Create an interesting experience for your followers and users to get rewards.
            You have the opportunity to create a reward as an influencer for your followers or as
            a project for your users. It is a great way to reward your followers and users for their loyalty!!!" />
            <meta name="Keywords" content="Rewards, Gift Rewards, For Influencer, For Projects, For Everyone Else " />
                <link rel="Rigel Protocol" href="http://rigelprotocol.com" />
        </Helmet>
        <div style={{marginTop : 20}}/>
        <Grid container spacing={2}>
            {pageData.map((item, index) => {
                const {header, description, items, image, clickAction} = item;
                return <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <div className={classes.box}>
                        <p className={classes.imageContainer}>
                            <img className={classes.image} src={image} title={header}/>
                        </p>
                        <Divider className={classes.divider}/>
                        <div className={classes.itemBody}>
                            <h1 className={classes._header}>
                                {header}
                            </h1>
                            <div className={classes.info}>
                                {description}
                            </div>
                            <div className={classes.itemContainer}>
                                {items.map((_item, index) =>{
                                    return <div key={index} className={classes._itemContainer}>
                                        <CheckCircleOutline className={classes.icon}/>
                                        <div className={classes._item}>{_item}</div>
                                    </div>
                                })}
                            </div>
                            <div className={classes.btnContainer}>
                                <div className={classes.button} onClick={() => { clickAction(); GAEventsTracker(`get_started_${header}`, "reward page"); }}>Get Started</div>
                            </div>
                        </div>
                    </div>
                </Grid>
            })}
        </Grid>
        <div className={classes.fotterContainer}>
            <div className={classes.footer} onClick={() => { window.open("https://prodocs.rigelprotocol.com", "blank"); GAEventsTracker("click_on_documentation", "reward page"); }}>
                <FontAwesomeIcon size="lg" className={classes.icon} icon={faBook}/>
                See Documentation
            </div>
        </div>
    </div>



}
GiftDappRewardHome.propTypes = {

}

export default GiftDappRewardHome;
export {GiftDappRewardHome};
