import React from 'react';
import ReactDOM, { hydrate, render } from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import { store, ApolloClientConfig } from './helpers';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
// const rootElement = document.getElementById("root");
// const MyApp = () => {
//     return <React.StrictMode>
//         <Provider store={store}>
//             <ApolloProvider client={ApolloClientConfig}>
//                 <App />
//             </ApolloProvider>
//         </Provider>
//     </React.StrictMode>
// }
// if (rootElement.hasChildNodes()) {
//     hydrate(<MyApp />, rootElement);
//   } else {
//     render(<MyApp />, rootElement);
//   }
  // "postbuild": "react-snap", // add this in the package.json file
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
