import Web3 from "web3";
export const GIFT_DAPP_LOCAL_STORAGE_VAR = {
    THEME_TYPE: "giftDappThemeType",
};
export const GIFT_DAPP_THEMES = {
    DARK: "dark",
    LIGHT: "light",
};
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const GIFT_DASHBOARD_VIEW_BREAKPOINTS = ["xs"];
export const ROUTE_DISPLAY_STRINGS = {
    DASHBOARD: "Dashboard",
    REWARD: "Reward",
    EVENTS: "Events",
    SETUP_STRINGS: {
        PROFILE: "Profile",
        DETAILS: "Details",
        SOCIAL: "Social",
        SETUP: "Setup",
    },
    DASHBOARD_STRINGS: {
        REWARD_VIEW: "Reward View",
        REWARD_SLOTS: "Slots",
    },
};
export const DATE_TIME = {
    SECONDS_IN_AN_HOUR: 3600,
};
export const STORAGE_VARIABLE = {
    NETWORK_ID: "giftDappNetworkId",
    NETWORK_TYPE: "giftDappNetworkType",
    NETWORK_WALLET: "giftDappNetworkWallet",
    PROFILE_LASTUSED: "giftDappProfileLastUsed",
    REDDIT_ACCESS_TOKEN: "reddit_access_token", // the gibberish text as key is intentional for security reasons (we don't want user to know it is thier access token key)
    DISCORD_DATA: "discord_access_token",
    TELEGRAM_USER_ID: "telegramUserId",
    TWITTER_USERNAME: "twitterUsername",
};
export const SMART_SWAP_URL = "https://smartswap.rigelprotocol.com";
export const PROCESS_DISPLAY_STAGES = {
    INCOMPLETE: 1,
    PROCESSING: 2,
    COMPLETE: 3,
};
export const REWARD_SETUP_PROFILE_OPTION_NAME = {
    LAST_PROFILE: "lastProfile",
    ANOTHER_PROFILE: "anotherProfile",
    NEW_PROFILE: "newProfile",
};
export const fieldTexts = [
    "Try again",
    "Nice Try",
    "Wao, win almost",
    "Nice one",
    "Lucky on next",
    "0 Reward",
    "Fun?",
    "Smile",
    "Smart spin",
];

export const NETWORKS = {
    bsc: {
        chainId: Web3.utils.toHex(56),
        chainName: "Binance Smart Chain",
        nativeCurrency: {
            name: "Binance",
            symbol: "BNB",
            decimals: 18,
        },
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
        blockExplorerUrls: ["https://bscscan.com"],
    },
    bsc_testnet: {
        chainId: Web3.utils.toHex(97),
        chainName: "Binance Smart Chain Test Network",
        nativeCurrency: {
            name: "Binance",
            symbol: "BNB",
            decimals: 18,
        },
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        blockExplorerUrls: ["https://testnet.bscscan.com"],
    },
    polygon: {
        chainId: Web3.utils.toHex(137),
        chainName: "Polygon Mainnet",
        nativeCurrency: {
            name: "Matic",
            symbol: "MATIC",
            decimals: 18,
        },
        rpcUrls: ["https://polygon-rpc.com/"],
        blockExplorerUrls: ["https://polygonscan.com"],
    },
    polygon_testnet: {
        chainId: Web3.utils.toHex(80001),
        chainName: "Polygon Mumbai",
        nativeCurrency: {
            name: "Matic",
            symbol: "MATIC",
            decimals: 18,
        },
        rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    },
};

export const SupportedChainId = {
    ROPSTEN: 3,
    RINKEBY: 4,
    GOERLI: 5,
    KOVAN: 6,
    POLYGON: 137,
    BINANCE: 56,
    BINANCETEST: 97,
    POLYGONTEST: 80001,
};
