import { Divider, makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { breadCrumbActions, breadCrumbStrings, CustomDialog } from '../../../../includes';
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from '../../config';
import GiftDappTokenSearchAdd from './GiftDappTokenSearchAdd/GiftDappTokenSearchAdd';
import GiftDappRewardSetupFooterButtons from '../GiftDappRewardSetupFooterButtons/GiftDappRewardSetupFooterButtons';
import GiftDappRewardSetupHeader from '../GiftDappRewardSetupHeader/GiftDappRewardSetupHeader';
import GiftDappRewardSetupGameSetupSettings from './GiftDappRewardSetupGameSetupSettings/GiftDappRewardSetupGameSetupSettings';
import GiftDappRewardSetupGameSetupSlot from './GiftDappRewardSetupGameSetupSlot/GiftDappRewardSetupGameSetupSlot';
import GiftDappSpinSetupGameSetupTokenBox from './GiftDappSpinSetupGameSetupTokenBox/GiftDappSpinSetupGameSetupTokenBox';
import { giftDappV1RouteNames, ROUTE_DISPLAY_STRINGS } from '../../../../utils';
import { backButtonActions } from '../../../../includes/CustomBackNav/actions';
import { backButtonStrings } from '../../../../includes/CustomBackNav/config';
const style = makeStyles(({isDark, colors, breakpoints}) => ({
    root : {
        fontSize : 12,
    },
    divider : {
        background : colors.borderLines,
        margin : "20px 0"
    },
    continue : {
        padding : 10,
        background : !isDark ? colors.backgroundDark : colors.accent,
        color : !isDark && colors.background,
    },
    btn : {
        [breakpoints.down("xs")] : {
            width : "50%",
        },
        [breakpoints.up("sm")] : {
            width : 200,
        },
        // padding : "10px 50px",
        background : colors.primary,
    },
    btnRoot : {
        display : "flex",
        justifyContent : "space-between"
    },
    dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.text,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
})),
DIALOG_TYPES = {
    ADD_TOKEN : "ADD_TOKEN",
};
export const REWARD_GAME_FIELD_NAMES = {
    NO_OF_WHEEL : "wheels",
    CLAIMS_PER_USER : "claims",
    CLAIM_PERIOD : 'duration',
    SPINE_FIELD_VALUES : "spinFieldValues",
    TOKEN_VALUES : "tokenValues",
    ACTIVE_PERIOD : "activePeriod",
};
export const settingConfig = (isEdit) => ([
    {
        name : REWARD_GAME_FIELD_NAMES.ACTIVE_PERIOD, 
        label : isEdit ? "Add to active period (weeks)" : "Active Period (Weeks)", 
        hint : isEdit ? 
            `Increment the active period 
            (number of weeks which the event will be active and users can claim rewards)` : 
            `Number of weeks which the event will be active and users can claim rewards`, 
        type : "dropdown",
        options : isEdit ? [
            {_id : 0, name : "0 week"},
            {_id : 1, name : "1 week"},
            {_id : 2, name : "2 week"},
        ] : [
            {_id : 1, name : "1 week"},
            {_id : 2, name : "2 week"},
            {_id : 3, name : "3 week"},
            {_id : 4, name : "4 week"},
        ],
        description : isEdit ? "Add to active period (weeks)" : "Active Period (Weeks)",
    },{
        name : REWARD_GAME_FIELD_NAMES.NO_OF_WHEEL, label : "Type of wheel", type : "dropdown",
        options : [
            {_id : 12, name : "12 spins"},
            {_id : 8, name : "8 spins"},
        ],
        description : `Type of wheel ${isEdit ? '(Can not be edited)' : ''}`,
        hint : `Number of spin field in the spin wheel ${isEdit ? '(can not be edited)' : ''}`,
        buttonProps : {
            disabled : isEdit
        }
    },{
        name : REWARD_GAME_FIELD_NAMES.CLAIMS_PER_USER, label : "Claims", type : "dropdown",
        options : [
            {_id : 1, name : "1 Claim"},
            {_id : 2, name : "2 Claims"},
            {_id : 3, name : "3 Claims"},
        ],
        description : "Number of claims per user.",
        hint : "Number of times a user can claim this reward"
    },{
        name : REWARD_GAME_FIELD_NAMES.CLAIM_PERIOD, label : "Claim period", type : "dropdown",
        options : [
            {_id : 24, name : "24 Hours"},
            {_id : 48, name : "48 Hours"},
            {_id : 72, name : "72 Hours"},
        ],
        description : "User claim time period.",
        hint : "Wait period for a user after claiming a reward"
    }
])
export default function GiftDappRewardSetupGameSetup ({
    onSave, showPreview, profileBreadCrumbAction,
    detailBreadCrumbAction, socialBreadcrumbAction
}) {
    const classes = style(),
        dispatch = useDispatch(),
        [state, _setState] = useState({
            openDialog : false, dialogType : null
        }),
        {openDialog, dialogType} = state,
        setState = _state => _setState(state=>({...state, ..._state})),
        giftRewardReducer = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME]),
        {
            [giftRewardStrings.rewardData] : rewardData,
            [giftRewardStrings.isEdit] : isEdit,
        } = giftRewardReducer,
        {
            [REWARD_GAME_FIELD_NAMES.TOKEN_VALUES] : tokenValues
        } = rewardData,
        closeDialog = () => setState({openDialog : false, dialogType : null});
    useEffect(() => {
        const { PROFILE, DETAILS, SOCIAL, SETUP} = ROUTE_DISPLAY_STRINGS.SETUP_STRINGS;
        breadCrumbActions.payload({
            [breadCrumbStrings.visible] : true,
            [breadCrumbStrings.crumbs] : [
                {
                    name : isEdit ? 
                        ROUTE_DISPLAY_STRINGS.DASHBOARD : ROUTE_DISPLAY_STRINGS.REWARD, 
                    path : isEdit ? giftDappV1RouteNames().dashboard : giftDappV1RouteNames().reward
                },
                {name : PROFILE, action : profileBreadCrumbAction},
                {name : DETAILS, action : detailBreadCrumbAction},
                {name : SOCIAL, action : socialBreadcrumbAction},
                {name : SETUP},
            ]
        })(dispatch);
        backButtonActions.payload({
            [backButtonStrings.visible] : true,
            [backButtonStrings.text] : "Back to project social page",
            [backButtonStrings.action] : socialBreadcrumbAction,
        })(dispatch);
    }, []);
    return (
        <div className={classes.root}>
            <GiftDappRewardSetupHeader
                header="Game Setup"
                subheader="Setup tokens and slot position for users and followers"
            />
            <GiftDappSpinSetupGameSetupTokenBox
                onEditToken={position=>setState({position, openDialog : true, dialogType : DIALOG_TYPES.ADD_TOKEN})}
                values={tokenValues}
            />
            <Divider className={classes.divider}/>
            <GiftDappRewardSetupGameSetupSettings
                settingConfig={settingConfig(isEdit)}
            />
            <Divider className={classes.divider}/>
            <GiftDappRewardSetupGameSetupSlot
            
            />
            <Divider className={classes.divider}/>
            <GiftDappRewardSetupFooterButtons
                button1={{
                    text : "Preview spin",
                    onClick : showPreview
                }}
                button2={{
                    text : `${isEdit ? "Edit" : "Create"} Event`,
                    onClick : onSave
                }}
                classes={{
                    root : classes.btnRoot,
                    continue : classes.continue,
                    btn : classes.btn
                }}
            />
            <CustomDialog
                within
                classes={{
                    dialogPaper : classes.dialogPaper,
                    _close : classes.dialogClose
                }}
                open={openDialog}
                close={closeDialog}
            >
                {dialogType === DIALOG_TYPES.ADD_TOKEN && <GiftDappTokenSearchAdd/>}
            </CustomDialog>
        </div>
    )
}