export const NETWORK_TYPE = {
    ETHEREUM : "Ethereum Smart Chain",
    BINANCE : "Binance Smart Chain"
}
export const EVENT_NAV_TABS = [
    {_id : null, label : "All"},
    {_id : true, label : "Active"},
    {_id : false, label : "Ended"},
]
export const REWARD_TYPES = {
    INFLUENCER : "Influencer",
    PROJECT : "Project",
}
export const EVENT_INTERFACE_TAB = {
    HOME : "home",
    CLAIM : "claim",
    SPIN : "spin",
}
export const EVENT_BREAKPOINTS = ["xs"];
export * from './GIftDappEvents';