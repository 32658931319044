import { makeStyles } from '@material-ui/core'
import React from 'react'
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        display : "flex",
        flexDirection : "column",
        color : colors.textSecondary,
        fontSize : 12,
        paddingBottom : 20,
        borderBottom : "solid 1px " + colors.borderLines,
        [breakpoints.down("sm")] : {
            border : "none"
        }
    },
    header : {
        color : colors.text,
        fontWeight : "bold",
        marginBottom : 10,
    },

}))
export default function GiftDappRewardSetupHeader ({header, subheader}) {
    const classes = style();
    return (
        <div className={classes.root}>
            <div className={classes.header}>{header}</div>
            <div className={classes.subheader}>{subheader}</div>
        </div>
    )
}