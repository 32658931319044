import React from 'react'
import { Divider, IconButton, makeStyles, withStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
const style = ({colors, breakpoints}) => ({
    root : {
        background : colors.background,
        padding : 10,
        border : "solid 1px " + colors.accent,
        minWidth : 250,
        fontSize : 12,
        [breakpoints.up("sm")] : {
            minWidth : 400
        },
        [breakpoints.up("md")] : {

        },
    },
    icon : {
        width : 20,
        height : 20,
        color : colors.textSecondary,
    },
    header : {
        display : "flex",
        justifyContent : "space-between",
        alignItems : "center",
        color : colors.textSecondary,
        fontSize : 14,
        marginBottom : 20,
    },
    iconBtn : {
        padding : 5
    },
    divider : {
        background : colors.textSecondary,
    }
})
function _CustomDialogBox({classes, title, titleComponent, onClose, noDivider, children}) {
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                {titleComponent || <span>{title}</span>}
                {onClose && <IconButton className={classes.iconBtn} onClick={onClose}>
                    <Close fontSize="small" className={classes.icon}/>
                </IconButton>}
            </div>
            {!noDivider && <Divider className={classes.divider}/>}
            {children}
        </div>
    )
}
_CustomDialogBox.propTypes = {
    
}
export const CustomDialogBox = withStyles(style)(_CustomDialogBox);
export {CustomDialogBox as default};