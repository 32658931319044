import { makeStyles } from "@material-ui/core";
import { ButtonBase } from '@material-ui/core';
import {landingRouteNames} from '../../../utils'
import React from 'react'

const styles = makeStyles(({colors, breakpoints})=>({
    root: {
        color: colors.pageColor,
        textAlign: "center",
        position: "relative",
        transformStyle: "preserve-3d",
        paddingTop: "4rem",
        paddingBottom: "4rem",
        boxSizing: "border-box",
        [breakpoints.down('md')]: {
            paddingRight: "2.5rem",
            paddingLeft: "2.5rem",
        },
        [breakpoints.down('sm')]: {
            paddingRight: "1rem",
            paddingLeft: "1rem",
        }
    },
    journeyHeading: {
        fontSize: "40px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        color: colors.pageColor,
        marginBottom: "1rem",
        marginTop: 0,
        [breakpoints.down('lg')]: {
            fontSize: "40px",
        },
        [breakpoints.down('sm')]: {
            fontSize: "32px",
        }
    },
    journeyText: {
        fontSize: "16px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: "normal",
        color: colors.pageColor,
        lineHeight: "24px",
        opacity: .8,
        marginBottom: "2rem",
        maxWidth: "40%",
        margin: "0 auto 1.2rem",
        [breakpoints.down('xl')]: {
            fontSize: "16px",
        },
        [breakpoints.down('sm')]: {
            fontSize: "16px",
            maxWidth: "100%",
        }


    },
    journeyBtn: {
        backgroundImage: " linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        boxShadow: "0px 1px 5px rgba(51, 51, 153, 0.1)",
        color: colors.pageColor,
        fontSize: "18px",
        width: "196px",
        height: "56px",
        fonWeight: "700",
        padding: "1.5rem 5rem",
        borderRadius: "6px",
        outline: "none",
        [breakpoints.down('xl')]: {
            fontSize: "18px",
            padding: ".7rem 1.5rem",
            borderRadius: ".5rem",
        }
    }
}))


export default function GiftDappDefiJourney() {
    const classes = styles();

   

    return (
        <section className = {classes.root}>
            <h1 className = {classes.journeyHeading}>
                Start your defi journey
            </h1>
            <p className = {classes.journeyText}>
                Build your defi portfolio with DApps that guarantee you fast transaction times, low transaction fees and the best user experience.
            </p>
            <ButtonBase 
                className = {classes.journeyBtn}
                onClick = {() => window.location.href = landingRouteNames().app}
            >
                    Launch DApp
            </ButtonBase>
        </section>
    )
}