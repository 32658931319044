
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { appValues } from './appValues'; //configurable values

/**
 * Create apollo link for file upload (** in case **)
 */
const uploadLink = createUploadLink({
    uri : appValues.gql_entery
});
/**
 * Apollo graphql connection to the backend with upload link for file upload
 */
export const ApolloClientConfig = new ApolloClient({
    cache: new InMemoryCache(),
    link : uploadLink,
    uri : appValues.gql_entery,
    onError: ({ networkError, graphQLErrors }) => {
      console.log('graphQLErrors', graphQLErrors)
      console.log('networkError', networkError)
    },
    connectToDevTools: true,
});