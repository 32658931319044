import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from '../../../config'
import { ArrowDropDown } from '@material-ui/icons'
import CustomInputFields from '../../../../../includes/CustomInputFields/CustomInputFields'
import { giftRewardActions } from '../../../actions'
import { walletStrings, WALLET_REDUCER_NAME } from '../../../../structures'
import { useCallback } from 'react'
import { errorToast, warningToast } from '../../../../../includes'
import { REWARD_GAME_FIELD_NAMES } from '../GiftDappRewardSetupGameSetup'
import { getContractInstance, inputDecimalValidator, tokenContractActions } from '../../../../../utils'
import GiftWalletTokenAmountSymbol from '../../../../structures/GiftDappWallets/GiftWalletTokenAmountSymbol/GiftWalletTokenAmountSymbol'
import GiftDappWalletTokenIcon from '../../../../structures/GiftDappWallets/GiftDappWalletTokenIcon/GiftDappWalletTokenIcon'
import useGAEventsTracker from './../../../../../hooks/useGAEventsTracker';

const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        marginTop  : 20,
    },
    boxContainer : {
        display : "flex",
        flexDirection : "row",
        alignItems : "center",
        [breakpoints.down("xs")] : {
            flexDirection : "column",
            alignItems : "flex-start"
        }
    },
    container : {
        position : "relative",
        display : "flex",
        alignItems : "center",
        background : colors?.background,
        width : "fit-content",
        margin : 10,
        borderRadius: 5,
        border: `1.5px solid ${colors.secondary}`,
        borderRight:0
    },
    notchedOutline : {
        border : "none",
    },
    addTokenText : {
        display : "flex",
        alignItems : "center",
        color : colors.text,
        padding : 10,
        background : colors.secondary,
        height : "100%",
        cursor : "pointer",
        borderRadius: "0 5px 5px 0"
    },
    dropdownIcon : {
        color : colors.text
    },
    headerContainer : {
        display : "flex",
        color : colors.textSecondary,
        fontSize : 12,
        [breakpoints.down("sm")] : {
            flexDirection : "column"
        }
    },
    header : {
        fontWeight : "bold",
        color : colors.text,
    },
    max : {

    },
    reset : {
        color : colors.accent,
        cursor : "pointer",
    }
}))
function GiftDappSpinSetupGameSetupTokenBox({onEditToken, values}) {
    const dispatch = useDispatch(),
    GAEventsTracker = useGAEventsTracker(),
    classes = style(),
    giftRewardReducer = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME]),
    {
        [giftRewardStrings.rewardTokens] : rewardTokens,
        [giftRewardStrings.isEdit] : isEdit,
        [giftRewardStrings.rewardData] : rewardData,
        [giftRewardStrings.selectedRewardTokens] : selectedRewardTokens = [],
    } = giftRewardReducer,
    web3 = useSelector(state=>state[WALLET_REDUCER_NAME][walletStrings.web3]),
    getSymbol = (token, web3) => async () => {
        if(!web3) return null;
        try{
            const instance = await getContractInstance(web3, null, token);
            return tokenContractActions(instance).symbol();
        }catch({message}){
            errorToast(message)(dispatch);
        }
    },
    getReducedFund = (amount, token, web3) => async () => {
        if(!web3) return null;
        try{
            const instance = await getContractInstance(web3, null, token);
            return tokenContractActions(instance).reducerNumber(amount);
        }catch({message}){
            errorToast(message)(dispatch);
        }
    },
    onChange = useCallback(address => ({target : {value}}) => {
        if(!address) {
            return errorToast("Token hasn't been selected")(dispatch);
        }else if(!inputDecimalValidator(value)){
            return warningToast("Invalid input")(dispatch);
        }
        const tokenValues = [REWARD_GAME_FIELD_NAMES.TOKEN_VALUES];
        const newData = {
            ...rewardData,
            [tokenValues] : {
                ...rewardData[tokenValues],
                [address] : value
            }
        };
        giftRewardActions.payload({
            [giftRewardStrings.rewardData] : newData
        })(dispatch);
    }, [JSON.stringify(rewardData), JSON.stringify(dispatch), JSON.stringify(rewardTokens)]),
    resetTokens = useCallback(() => !isEdit ? giftRewardActions.payload({
        [giftRewardStrings.rewardTokens] : [],
        [giftRewardStrings.rewardData] : {
            ...rewardData,
            [REWARD_GAME_FIELD_NAMES.TOKEN_VALUES] : null
        }
    })(dispatch) : warningToast("Reset not allowed in edit mode")(dispatch),
    [JSON.stringify(rewardData), JSON.stringify(dispatch), isEdit]);
    const {blockTokenValue} = rewardData || {};
    return (
        <div className={classes.root}>
            <div className={classes.headerContainer}>
                <span className={classes.header}>{isEdit ? "Add to reward token" : "Choose reward tokens"}</span>
                {isEdit ? <span>
                    Rewards Left : {blockTokenValue && blockTokenValue.map((tokenValue, index)=>{
                        const {address : token, balance} = tokenValue;
                        return <>
                            {index !== 0 && " & "}
                            <GiftWalletTokenAmountSymbol
                                key={index}
                                getReducedFund={getReducedFund(balance, token, web3)}
                                getSymbol={getSymbol(token, web3)}
                            />
                        </>
                    })}
                </span> :
                <span className={classes.max}>(Maximum of 2 tokens)</span>}
            </div>
            <div className={classes.boxContainer}>
            {[1,2].map((_, index) => {
                const address = rewardTokens?.[index];
                const token = address && selectedRewardTokens && selectedRewardTokens.find(item=>item.address === address),
                {symbol : label} = token || {};
                return <div key={index} className={classes.container}>
                    <CustomInputFields
                        classes={{
                            notchedOutline : classes.notchedOutline
                        }}
                        field={{
                            name : "name", lable : "Amount",
                            type : "text",
                            margin : "dense",
                            value : values?.[address] || "",
                            onChange : onChange(address),
                            style : { width : 80},
                            placeholder : 0,
                            // disabled : isEdit
                        }}
                    />
                    <div className={classes.addTokenText} onClick={() => {
                        if(isEdit) return errorToast("Token can not be edited. You can increase token amount")(dispatch)
                        onEditToken && onEditToken(index)
                    }}>
                        {token ? <>
                            <GiftDappWalletTokenIcon
                                display
                                imageProps={{
                                    style : {margin : "0px 10px"}
                                }}
                                symbol={label}
                            />
                            {label}
                        </> : `Add token ${index + 1}`}
                        <ArrowDropDown className={classes.dropdownIcon}/>
                    </div>
                </div>
            })}
                <div className={classes.reset} onClick={()=>{resetTokens(); GAEventsTracker("reset_reward_tokens", "event page")}}>reset</div>
            </div>
        </div>
    )
}
GiftDappSpinSetupGameSetupTokenBox.propTypes = {

}
export default GiftDappSpinSetupGameSetupTokenBox;
export {GiftDappSpinSetupGameSetupTokenBox};
