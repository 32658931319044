import React, { useEffect } from 'react';
import { ThemeProvider, useTheme } from '@material-ui/core';
import { Suspense } from 'react';
import { Router, Switch, Route} from 'react-router';
import { GiftDappFallback, Layout } from './components';
import { appTheme, GIFT_DAPP_LOCAL_STORAGE_VAR, GIFT_DAPP_THEMES, headerlessRoutes, routes } from './utils';
import { CustomSnackbar } from './includes';
// import "./components/GIftDappEvents/GiftDappEventClaims/SocialMediaVerify/facebookSDK";
import GiftDappSetup from './components/GiftDappSetup/GiftDappSetup';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from './utils/config';
import CustomProcessDisplay from './includes/CustomProcessDisplay/CustomProcessDisplay';
import CustomSideDisplay from './includes/CustomSideDisplay/CustomSideDisplay';
import { history } from './helpers';
import { makeStyles } from '@material-ui/core'
import { GiftDappHeader } from './components/structures/GiftDappHeader/GiftDappHeader'

// import CustomMusicConsole from './includes/CustomMusicConsole/CustomMusicConsole';
const style = makeStyles(({breakpoints, colors}) => ({
  appPager : {
      margin : "0px 20px",
      minHeight : 300,
  },
}))

function GiftDApp() {
    const classes = style();
    const theme = useTheme();
    const refreshCount = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME][giftDappUtilStrings.refreshCount]);
    const themeType = localStorage.getItem(GIFT_DAPP_LOCAL_STORAGE_VAR.THEME_TYPE),
        selectedTheme = themeType || GIFT_DAPP_THEMES.DARK;
    useEffect(() => {
        $("html").css("background", appTheme[selectedTheme]?.pageBackground);
        $("html").css("color", appTheme[selectedTheme]?.text);
    }, [JSON.stringify(appTheme), themeType, refreshCount]);

    return <Router history={history}>
        <ThemeProvider theme={{
            ...theme, isDark : selectedTheme === GIFT_DAPP_THEMES.DARK,
            colors : appTheme[selectedTheme]
        }}>
            <GiftDappHeader
                classes={{
                    container : classes.appPager
                }}
            />
            <Suspense fallback={<GiftDappFallback/>}>
                <Switch>
                    {routes && routes.map((route, index)=><Layout {...route} key={index}/>)}
                    {headerlessRoutes && headerlessRoutes.map((route, index)=><Route {...route} key={index}/>)}
                </Switch>
            </Suspense>
            <CustomSnackbar/>
            <GiftDappSetup/>
            <CustomProcessDisplay/>
            {/* <CustomSideDisplay/> */}
            {/* <CustomMusicConsole/> */}
        </ThemeProvider>
    </Router>


}

export default GiftDApp;
