export const customSideDisplayStrings = {
    component : "component",
    config : "config",
    show : "show",
    onClose : "onClose",
}
export const customSideDisplayConstant = {
    SET_CUSTOM_DISPLAY : "SET_CUSTOM_DISPLAY"
}
export const customSideDisplayReducer = (state={}, action) => {
    const {type, payload} = action;
    switch(type){
        case customSideDisplayConstant.SET_CUSTOM_DISPLAY : 
            return {...state, ...payload};
        default : return state;
    }
}
export const CUSTOM_SIDE_DISPLAY_REDUCER = "customSideDisplayReducer";