import React from 'react'
import classNames from 'classnames';
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { walletStrings } from './structures'
import { Route } from 'react-router';
import CustomGlobalPopover from '../includes/CustomPopover/CustomPopover';
import GiftDappNetworkStatus from './GiftDappNetworkStatus/GiftDappNetworkStatus';
import { CustomBreadCrumb } from '../includes/CustomBreadCrumb';
import CustomBackNav from '../includes/CustomBackNav/CustomBackNav';
import GiftDappTestTokenRequest from './structures/GiftDappTestTokenRequest/GiftDappTestTokenRequest';
import GiftDappProfileGlobal from './GiftDappProfile/GiftDappProfileGlobal/GiftDappProfileGlobal';
const style = makeStyles(({breakpoints, colors}) => ({
    // customizing the scrollbar
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4rem'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px ' + colors.background
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: colors.borderLines,
            outline: '1px solid ' + colors.textSecondary
        }
    },
    display : {
    },
    appPager : {
        margin : "0px 20px",
        minHeight : 300,
    },
    stage : {
        marginTop : 70,
        marginBottom : 50,
        minHeight : 200,
        [breakpoints.down("sm")] : {
            padding : "20px 10px"
        },
        [breakpoints.up("md")] : {
            padding : "20px 50px"
        },
        [breakpoints.up("lg")] : {
            padding : "20px 80px"
        },
        [breakpoints.up("xl")] : {
            padding : "20px 100px"
        }
    }
}))
function Layout({component : Component, ...props}) {
    const classes = style();
    return (
        <Route {...props} component={routeProps=>{
            return <div className={classes.display}>
                <div className={classNames(classes.appPager, classes.stage)}>
                    <CustomBreadCrumb/>
                    <CustomBackNav/>
                    <Component {...routeProps} />
                    <GiftDappNetworkStatus/>
                </div>
                <CustomGlobalPopover/>
                {/* <GiftDappTestTokenRequest/> */}
                <GiftDappProfileGlobal/>
            </div>
        }}/>
    )
}
Layout.propTypes = {

}
export default Layout;
export {Layout};
