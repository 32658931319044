import React from 'react'
import classNames from 'classnames';
import { Grid, makeStyles } from '@material-ui/core'
import CustomProfileImage from '../../../../../../includes/CustomProfileImage/CustomProfileImage';
const style = makeStyles(({colors, isDark}) => ({
    root : {
        fontSize : 12,
        background : !isDark ? colors.secondaryDark : colors.secondary,
        padding : 10,
        borderBottom : `solid 1px ${colors.pageBackground}`
    },
    container : {
        margin : "10px 0",
        fontSize : 12,
        display : 'flex',
        justifyContent : "centent",
        alignItems : "center",
        height : "100%",
        color : colors.textSecondary
    },
    name : {
        padding : 10,
    },
    logo : {
        height : 50,
        width : 50,
        borderRadius : "100%",
        background : colors.secondary,
        display : "flex",
        alignItems: "center",
        justifyContent : "center",
    },
    claimBtn : {
        cursor : "pointer",
        color : colors.accent,
    },
    expired : {
        color : colors.textSecondary,
    }
}))
function GiftDappEventListitem({expired, onClaim, name, image, rewardRange, network, isMyReward, onEdit}) {

    const classes = style();
    return <Grid container className={classes.root}>
            <Grid xs={12} sm={4} md={4} lg={4} xl={4} item>
                <div className={classes.container}>
                    <CustomProfileImage
                        image={image}
                        name={name}
                    />
                </div>
            </Grid>
            <Grid xs={12} sm={2} md={2} lg={2} xl={2} item>
                <div className={classes.container}>
                    {rewardRange}
                </div>
            </Grid>
            <Grid xs={12} sm={4} md={4} lg={4} xl={4} item>
                <div className={classes.container}>
                    {network}
                </div>
            </Grid>
            <Grid xs={12} sm={2} md={2} lg={2} xl={2} item>
                <div className={classes.container}>
                <div
                        className={classNames(classes.claimBtn, expired ? classes.expired : "")}
                        {...((expired === false) && { onClick: isMyReward ? onEdit : onClaim })}
                    >
                        {isMyReward ? "Edit" : (expired !== undefined) ? (expired ? "Expired" : "Claim") : ""}
                    </div>
                </div>
            </Grid>
        </Grid>        
    
}
GiftDappEventListitem.propTypes = {
    
}
export default GiftDappEventListitem;
export {GiftDappEventListitem};