import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { AppBar, makeStyles, Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
    getRewards,
    giftDappUtilActions,
    GIFT_DAPP_LOCAL_STORAGE_VAR,
    giftDappRouteNames,
} from "../../../utils";
import { history } from "../../../helpers";
import { GiftDappConnection } from "./GiftDappConnection";
import { useDispatch, useSelector } from "react-redux";
import { walletStrings, WALLET_REDUCER_NAME } from "../GiftDappWallets";
import { useLazyQuery } from "@apollo/client";
import { headerStrings, HEADER_REDUCER_NAME } from "./config";
import { headerActions } from "./actions";
import {
    giftRewardStrings,
    GIFT_REWARD_REDUCER_NAME,
} from "../../GiftDappReward";
const style = makeStyles((theme) => ({
    root: {},
    toolbar: {
        backgroundColor: theme.colors.pageBackground,
        borderBottom: `solid 1px ${theme.colors.homeBorder}`,
    },
    container: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
    link: {
        color: theme.colors.text,
        textDecoration: "none",
        fontSize: 14,
        [theme.breakpoints.up("sm")]: {
            padding: "0px 20px",
        },
    },
    gap: {
        display: "flex",
        flexGrow: 1,
    },
    active: {
        color: theme.colors.homeLiner,
    },
    menuContainer: {
        marginLeft: 20,
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "space-around",
            width: "100%",
        },
    },
    logo: {
        height: 25,
        width: 25,
        cursor: "pointer",
    },
    companyNameContainer: {
        marginLeft: 10,
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    companyName: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.colors.text,
    },
    appName: {
        color: theme.colors.homeLiner,
    },
    alignRight: {
        justifyContent: "flex-end",
    },
    sideMargin: {
        margin: "0px 10px",
    },
}));
function GiftDappHeader(props) {
    const classes = style(),
        [initialAccount, setInitialAccount] = useState(null),
        dispatch = useDispatch(),
        path = history.location.pathname,
        walletReducer = useSelector((state) => state[WALLET_REDUCER_NAME]),
        {
            [walletStrings.address]: address,
            [walletStrings.networkId]: networkId,
        } = walletReducer,
        isEdit = useSelector(
            (state) => state[GIFT_REWARD_REDUCER_NAME][giftRewardStrings.isEdit]
        ),
        headerReducer = useSelector((state) => state[HEADER_REDUCER_NAME]),
        { [headerStrings.showDashboard]: showDashboard } = headerReducer;

    const [getUserRewards, { loading, error, data }] = useLazyQuery(
        getRewards("_id")
    );

    useEffect(() => {
        getUserRewards({
            variables: {
                networkId: String(networkId),
                filter: { creator: address },
            },
        });
        if (!initialAccount) {
            return setInitialAccount(address); //the first account to be connected when the page loads;
        }
        if (!address || (address && address !== initialAccount)) {
            // this means, the user loged out or witched into another account
            // if the previous account is on dashboard, or currently viewing an event, redirect to home
            if (
                path.startsWith("/app/dashboard") ||
                path.startsWith("/app/dashboard/view/")
            )
                goHome();
        }
    }, [address]);

    useEffect(() => {
        // do nothing if reward has not been fetched
        if (!data?.rewards) return;
        if (data?.rewards && data.rewards[0]) {
            headerActions.payload({
                [headerStrings.showDashboard]: true,
            })(dispatch);
        } else {
            headerActions.payload({
                [headerStrings.showDashboard]: false,
            })(dispatch);
        }
    }, [data?.rewards]);

    const switchTheme = () => {
        const theme = localStorage.getItem(
            GIFT_DAPP_LOCAL_STORAGE_VAR.THEME_TYPE
        );
        localStorage.setItem(
            GIFT_DAPP_LOCAL_STORAGE_VAR.THEME_TYPE,
            theme === "light" ? "dark" : "light"
        );
        giftDappUtilActions.quickRefresh(dispatch);
    };

    const goHome = () => {
        history.push(giftDappRouteNames().reward);
    };

    return (
        <div className={classes.root}>
            <AppBar
                elevation={0}
                classes={{
                    colorPrimary: classes.toolbar,
                }}
            >
                <Toolbar>
                    <div className={classes.container}>
                        <img
                            src="/rigel_icon.png"
                            className={classes.logo}
                            onClick={goHome}
                        />
                        <div
                            className={classes.companyNameContainer}
                            onClick={goHome}
                        >
                            <div className={classes.companyName}>
                                Rigel Protocol
                            </div>
                            <div className={classes.appName}>GiftDapp</div>
                        </div>
                        <div
                            className={classNames(
                                classes.menuContainer,
                                !showDashboard ? classes.alignRight : ""
                            )}
                        >
                            {showDashboard && (
                                <Link
                                    className={classNames(
                                        classes.link,
                                        (giftDappRouteNames().rewardSetup ===
                                            path &&
                                            isEdit) ||
                                            path.includes(
                                                giftDappRouteNames().rewardView_
                                            ) ||
                                            giftDappRouteNames().dashboard ===
                                                path
                                            ? classes.active
                                            : ""
                                    )}
                                    to={giftDappRouteNames().dashboard}
                                >
                                    Dashboard
                                </Link>
                            )}
                            <Link
                                className={classNames(
                                    classes.link,
                                    !isEdit &&
                                        [
                                            giftDappRouteNames().reward,
                                            giftDappRouteNames().rewardSetup,
                                        ].indexOf(path) !== -1
                                        ? classes.active
                                        : "",

                                    !showDashboard ? classes.sideMargin : ""
                                )}
                                to={giftDappRouteNames().reward}
                            >
                                Reward
                            </Link>
                            <Link
                                className={classNames(
                                    classes.link,
                                    giftDappRouteNames().events === path ||
                                        path.includes(
                                            giftDappRouteNames().eventView_
                                        )
                                        ? classes.active
                                        : "",
                                    !showDashboard ? classes.sideMargin : ""
                                )}
                                to={giftDappRouteNames().events}
                            >
                                Events
                            </Link>
                            <Link
                                className={classNames(
                                    classes.link,
                                    giftDappRouteNames().specialReward ===
                                        path ||
                                        path.includes(
                                            giftDappRouteNames().specialReward
                                        )
                                        ? classes.active
                                        : "",
                                    !showDashboard ? classes.sideMargin : ""
                                )}
                                to={giftDappRouteNames().specialReward}
                            >
                                Reward Card
                            </Link>

                            <Link
                                className={classNames(
                                    classes.link,
                                    giftDappRouteNames().nft === path ||
                                        path.includes(giftDappRouteNames().nft)
                                        ? classes.active
                                        : "",
                                    !showDashboard ? classes.sideMargin : ""
                                )}
                                to={giftDappRouteNames().nft}
                            >
                                NFT
                            </Link>
                        </div>
                        <div className={classes.gap} />
                        <GiftDappConnection switchTheme={switchTheme} />
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
GiftDappHeader.propTypes = {};
export default GiftDappHeader;
export { GiftDappHeader };
