import React, { useState } from 'react'
import { makeStyles, LinearProgress } from '@material-ui/core';
import MetaMaskImage from '../../../../assets/metamask.svg';
import TrustWallet from '../../../../assets/TrustWallet.svg';
import BinanceWallet from "../../../../assets/BNB.svg";
import WalletConnect from "../../../../assets/walletconnect-logo.svg";
import SafePal from '../../../../assets/safepal-sfp.svg';
import classNames from 'classnames';
import useGAEventsTracker from './../../../../hooks/useGAEventsTracker';

const style = makeStyles(({colors, isDark}) => ({
    root : {
      color : colors.text,
      padding: 18
    },
    _box : {
      display : "flex",
      flexDirection : "column",
    },
    box : {
      display: "flex",
      minHeight: "50px",
      cursor: "pointer",
      padding: "10px",
      margin: "14px 0px",
      border: '1px solid',
      borderColor: colors.backgroundAccent,
      borderRadius: '6px',
      "&:hover" : {
        borderColor : isDark ? colors.accent : colors.buttonBorders,
      }
    },
    boxDisabled : {
      display: "flex",
      minHeight: "auto",
      cursor: "pointer",
      padding: "10px",
      margin: "14px 0px",
      border: '1px solid',
      borderColor: colors.backgroundAccent,
      borderRadius: '6px',
      "&:hover" : {
        borderColor : isDark ? colors.accent : colors.buttonBorders,
      },
      pointerEvents : 'none',
     opacity:' 0.7'
    },
    innerBox : {
      display : "flex",
      alignItems : "center",
    },
    img : {
        marginRight: "10px",
        height: 30,
        width: 30,
    },
}))
const WalletItem = ({name,id, image, connect , onLoad}) => {
  const classes = style()

  const [wallet, setWallet] = useState('')

  const GAEventsTracker = useGAEventsTracker();

    return (
      <div
        className={classNames(classes._box, onLoad ?  classes.boxDisabled : classes.box)}
        onClick={() => {
          connect();
          setWallet(id);
          GAEventsTracker(`connect_to_${name}`, "header");
        }}
      >
        <div className={classes.innerBox}>
          <img src={image} className={classes.img} alt="wallet logo" />
          <div>{name}</div>
        </div>
        {onLoad && wallet == id &&
                <LinearProgress style={{width : "100%", marginTop : 10}}/>
              }
      </div>
    )
};

function GiftDappWalletOptions({connect,  onLoad}) {
    const classes = style()

    const walletItems = [{
      name: 'Meta mask',
      image: MetaMaskImage,
      id: 'metamask',
      connect
    }, {
      name: 'Trust Wallet',
      image: TrustWallet,
      id: 'trustWallet',
      connect
    }, {
      name: 'Binance Chain Wallet',
      image: BinanceWallet,
      id: 'binance',
      connect
    }, {
      name: 'Wallet Connect',
      image: WalletConnect,
      id: 'walletconnect',
      connect
    }, {
      name: 'SafePal',
      image: SafePal,
      id: 'metamask',
      connect
    }];
    return (
      <div style={{minWidth: 386, width: "90%"}}>
        <div className={classes.root}>
                <h3>{onLoad ? "Proccesing..." : "Choose a wallet "}</h3>
            <div>
              {walletItems.map((item, i) => (
                  <WalletItem onLoad={onLoad} key={i} id={item.id} name={item.name} image={item.image} connect={() => item.connect(item.id)}/>
              ))}
            </div>
        </div>
      </div>
    )
}

export default GiftDappWalletOptions;
export {GiftDappWalletOptions};
