import { Grid, makeStyles } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { giftRewardActions } from "../../../..";
import {
    CustomDialog,
    customPopoverActions,
    customPopoverString,
    errorToast,
    warningToast,
} from "../../../../../includes";
import { hintComponent } from "../../../../../includes/CustomInputFields/CustomInputFields";
import {
    fieldTexts,
    getContractInstance,
    inputDecimalValidator,
    tokenContractActions,
} from "../../../../../utils";
import { walletStrings, WALLET_REDUCER_NAME } from "../../../../structures";
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from "../../../config";
import { REWARD_GAME_FIELD_NAMES } from "../GiftDappRewardSetupGameSetup";
import GiftDappRewardSetupGameSetupSlotField, {
    spinFieldChances,
    textDiffOptoins,
    tokenDiffOptions,
} from "./GiftDappRewardSetupGameSetupSlotField/GiftDappRewardSetupGameSetupSlotField";
import GiftDappRewardSetupGameSetupSlotWinChance from "./GiftDappRewardSetupGameSetupSlotWinChance/GiftDappRewardSetupGameSetupSlotWinChance";
const style = makeStyles(({ colors, isDark }) => ({
    root: {},
    dialogPaper: {
        background: colors.background,
        border: "solid 1px " + colors.accentSecondary,
        color: colors.text,
        overflow: "visible",
    },
    dialogClose: {
        background: colors.text,
        top: -40,
        padding: 5,
        "&:hover": {
            background: colors.accent,
            color: colors.textSecondary,
        },
    },
    header: {
        color: colors.backgroundAccent,
        fontWeight: "bold",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 30,
    },
    autofilContainer: {
        fontSize: 10,
        fontWeight: 100,
        color: colors.accent,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
    },
    autofill: {
        border: "solid 1px",
        padding: 5,
        borderRadius: 5,
        color: !isDark && colors.textSecondary,
        cursor: "pointer",
    },
    autofill_details: {
        color: !isDark && colors.textSecondary,
    },
    slotSetup: {
        color: !isDark ? colors.accent : "",
        display: "flex",
        alignItems: "center",
    },
}));
export default function GiftDappRewardSetupGameSetupSlot() {
    const classes = style(),
        dispatch = useDispatch(),
        [state, _setState] = useState({
            chance: 0,
            maxSpinAmount: 0,
            openDialog: false,
        }),
        { chance, maxSpinAmount, openDialog } = state,
        setState = (_state) => _setState((state) => ({ ...state, ..._state })),
        closeDialog = () => setState({ openDialog: false }),
        web3 = useSelector(
            (state) => state[WALLET_REDUCER_NAME][walletStrings.web3]
        ),
        giftRewardReducer = useSelector(
            (state) => state[GIFT_REWARD_REDUCER_NAME]
        ),
        {
            [giftRewardStrings.rewardData]: rewardData,
            [giftRewardStrings.rewardTokens]: rewardTokens,
            [giftRewardStrings.isEdit]: isEdit,
        } = giftRewardReducer,
        spinValues = new Array(
            rewardData[REWARD_GAME_FIELD_NAMES.NO_OF_WHEEL]
        ).fill(1),
        setSpinFieldValue = useCallback(
            (spinIndex) =>
                (rewardData, name, value, otherValues = {}, callback) => {
                    const spinFields =
                            rewardData[
                                REWARD_GAME_FIELD_NAMES.SPINE_FIELD_VALUES
                            ],
                        fieldIndex =
                            spinFields &&
                            spinFields.findIndex(
                                (item) => item.spinIndex === spinIndex
                            );
                    if (fieldIndex !== -1) {
                        if (name === "value") {
                            if (!spinFields[fieldIndex].address)
                                return warningToast(
                                    "Select the token/text option (at the right part of the input)"
                                )(dispatch);
                            else if (
                                spinFields[fieldIndex].address !== "text" &&
                                !inputDecimalValidator(value)
                            ) {
                                return warningToast("Invalid input")(dispatch);
                            }
                        }
                        spinFields[fieldIndex] = {
                            ...spinFields[fieldIndex],
                            [name]: value,
                            ...otherValues,
                            isAuto: false,
                        };
                    } else {
                        if (name === "value") {
                            if (
                                spinFields[fieldIndex].address !== "text" &&
                                !inputDecimalValidator(value)
                            ) {
                                return warningToast("Invalid input")(dispatch);
                            }
                        }
                        spinFields.push({
                            [name]: value,
                            spinIndex,
                            ...otherValues,
                            isAuto: false,
                        });
                    }
                    const newData = {
                        ...rewardData,
                        [REWARD_GAME_FIELD_NAMES.SPINE_FIELD_VALUES]:
                            spinFields,
                    };
                    giftRewardActions.payload({
                        [giftRewardStrings.rewardData]: newData,
                    })(dispatch);
                    callback && callback(spinFields);
                },
            []
        ),
        openAutoFillDialog = useCallback(() => {
            if (!rewardTokens || rewardTokens.length === 0) {
                return warningToast(
                    "Reward token not set! Please set at least one reward token."
                )(dispatch);
            } else if (isEdit) {
                return warningToast("Auto-Fill can not be used in edit mode.")(
                    dispatch
                );
            }
            setState({ openDialog: true });
        }, [JSON.stringify(rewardTokens), isEdit]),
        autoFill = useCallback(
            (web3) =>
                async (chance = 50, maxSpinAmount = 2) => {
                    const { [REWARD_GAME_FIELD_NAMES.NO_OF_WHEEL]: wheels } =
                        rewardData;
                    if (!wheels)
                        return errorToast("Wheel type not set")(dispatch);
                    const chancePercent = chance / 100,
                        winFieldNumber = wheels * chancePercent,
                        spinFieldValues = [];
                    let winFieldCount = 0;
                    const difficultyMaxLevels = {
                            low: 1,
                            mid: 2,
                            high: 4,
                        },
                        maxSpinAmountDexPosition =
                            String(maxSpinAmount).indexOf(".");
                    let amountDecimal = 0;
                    if (maxSpinAmountDexPosition !== -1) {
                        //if there is decimal extend the decimal places by 2
                        amountDecimal =
                            String(maxSpinAmount).length -
                            (maxSpinAmountDexPosition + 1) +
                            2;
                    }
                    function getDiffMax(amount) {
                        const chance = (amount / maxSpinAmount) * 100;
                        if (chance < 20) {
                            return difficultyMaxLevels.low;
                        } else if (chance > 20 && chance < 50) {
                            return difficultyMaxLevels.mid;
                        } else if (chance > 50) {
                            return difficultyMaxLevels.high;
                        }
                    }
                    function getDiffMin(amount) {
                        const chance = (amount / maxSpinAmount) * 100;
                        if (chance < 20) {
                            return 0;
                        } else if (chance > 20 && chance < 50) {
                            return difficultyMaxLevels.mid;
                        } else if (chance > 50) {
                            return difficultyMaxLevels.high;
                        }
                    }
                    const minSpinAmount = 0.1;
                    // spinFieldChances
                    let randomTokenValueIndex;
                    for (let i = 0; i < wheels; i++) {
                        let lessThanHalf = 0;
                        if (winFieldCount <= winFieldNumber) {
                            let randomSpinIndex;
                            do {
                                randomSpinIndex = Math.floor(
                                    Math.random() * wheels
                                );
                            } while (spinFieldValues[randomSpinIndex]?.address);
                            // = Math.floor(Math.random() * rewardTokens.length);
                            if (rewardTokens.length === 1) {
                                randomTokenValueIndex = 0;
                            } else {
                                randomTokenValueIndex =
                                    randomTokenValueIndex === 0 ? 1 : 0;
                            }
                            let randomRewardAmount =
                                Math.random() * maxSpinAmount; // - minSpinAmount) + minSpinAmount,
                            /** Making sure that at least one token spin field has less difficulty */
                            if (
                                winFieldCount === winFieldNumber &&
                                lessThanHalf === 0
                            ) {
                                if (maxSpinAmount / 2 > randomRewardAmount) {
                                    randomRewardAmount =
                                        Math.random() * (maxSpinAmount / 2);
                                }
                            }
                            //keeping count of less than half amounts
                            if (maxSpinAmount / 2 > randomRewardAmount) {
                                lessThanHalf++;
                            }
                            const _min = getDiffMin(randomRewardAmount),
                                _max = getDiffMax(randomRewardAmount),
                                _random = Math.floor(
                                    Math.random() * (_max - _min) + _min
                                ),
                                randomDiff =
                                    tokenDiffOptions &&
                                    tokenDiffOptions[_random],
                                address = rewardTokens[randomTokenValueIndex];
                            let data = {
                                spinIndex: randomSpinIndex,
                                address,
                                value: Number(randomRewardAmount).toFixed(
                                    amountDecimal
                                ),
                                difficulty: randomDiff?._id,
                                label: await tokenContractActions(
                                    await getContractInstance(
                                        web3,
                                        null,
                                        address
                                    )
                                ).symbol(),
                            };
                            if (randomSpinIndex === -1)
                                spinFieldValues.push(data);
                            else spinFieldValues[randomSpinIndex] = data;
                            winFieldCount++;
                        } else {
                            const freeIndex = spinFieldValues.findIndex(
                                    (item) => !item?.address
                                ),
                                randomDiff =
                                    textDiffOptoins &&
                                    textDiffOptoins[
                                        Math.floor(
                                            Math.random() *
                                                difficultyMaxLevels.mid
                                        )
                                    ];
                            let randomText, randomTextIndex;
                            do {
                                randomTextIndex = Math.floor(
                                    Math.random() * fieldTexts.length
                                );
                                randomText = fieldTexts[randomTextIndex];
                            } while (
                                spinFieldValues.find(
                                    (item) => item?.value === randomText
                                )
                            );
                            let data = {
                                spinIndex: freeIndex,
                                address: "text",
                                difficulty: randomDiff?._id,
                                value: randomText,
                                textIndex: randomTextIndex,
                                isAuto: true,
                            };
                            if (freeIndex === -1)
                                spinFieldValues.push({
                                    ...data,
                                    spinIndex: spinFieldValues.length,
                                });
                            else spinFieldValues[freeIndex] = data;
                        }
                    }
                    giftRewardActions.payload({
                        [giftRewardStrings.rewardData]: {
                            ...rewardData,
                            [REWARD_GAME_FIELD_NAMES.SPINE_FIELD_VALUES]:
                                spinFieldValues,
                        },
                    })(dispatch);
                    closeDialog();
                },

            [JSON.stringify(rewardData), JSON.stringify(rewardTokens)]
        );
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.slotSetup}>
                    SLOT SETUP
                    <HelpOutline
                        style={{ marginLeft: 5, cursor: "pointer" }}
                        fontSize="small"
                        onClick={({ target: anchorEl }) => {
                            customPopoverActions.payload({
                                [customPopoverString.anchorEl]: anchorEl,
                                [customPopoverString.customComponent]:
                                    hintComponent({
                                        hint: [
                                            {
                                                description: "Spin (n)",
                                                hint: "A spin field in the spin wheel",
                                            },
                                            {
                                                description: "Spin Difficulty",
                                                hint: "The chance of spin arrow landing on a spin field",
                                            },
                                            {
                                                description: "Token",
                                                hint: "This can be token (win spin field) or text (none win spin field)",
                                            },
                                            {
                                                description: "Amount",
                                                hint: "Amount of token for winning spin field (token field), and text for none win field.",
                                            },
                                        ],
                                    }),
                                [customPopoverString.anchorOrigin]: {
                                    horizontal: "left",
                                },
                            })(dispatch);
                        }}
                    />
                </div>
                <div className={classes.autofilContainer}>
                    <div
                        className={classes.autofill}
                        onClick={openAutoFillDialog}
                    >
                        Auto-Fill Slots
                    </div>
                    <HelpOutline
                        className={classes.autofill_details}
                        style={{ marginLeft: 5, cursor: "pointer" }}
                        fontSize="small"
                        onClick={(e) => {
                            const anchorEl = e.target;
                            e.stopPropagation();
                            customPopoverActions.payload({
                                [customPopoverString.anchorEl]: anchorEl,
                                [customPopoverString.customComponent]:
                                    hintComponent({
                                        hint: [
                                            {
                                                description: "Required",
                                                hint: "Select at least one reward token for this game",
                                            },
                                            {
                                                description:
                                                    "Chance of winning",
                                                hint: "The probability of spin arrow landing on a win spin field (token spin field) - 25%, 50% and 70%.",
                                            },
                                            {
                                                description:
                                                    "Max. token spin field amount",
                                                hint: "Maximum amount of token that can alotted to a token spin field (winning field)",
                                            },
                                        ],
                                    }),
                                [customPopoverString.anchorOrigin]: {
                                    horizontal: "left",
                                },
                            })(dispatch);
                        }}
                    />
                </div>
            </div>
            <Grid container spacing={8}>
                {spinValues &&
                    spinValues.map((_, index) => {
                        return (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                            >
                                <div
                                    className={classes.gridItem}
                                    style={{ marginTop: 20, padding: 5 }}
                                >
                                    <GiftDappRewardSetupGameSetupSlotField
                                        spinIndex={index}
                                        setSpinFieldValue={setSpinFieldValue(
                                            index
                                        )}
                                    />
                                </div>
                            </Grid>
                        );
                    })}
            </Grid>

            <CustomDialog
                within
                open={openDialog}
                close={closeDialog}
                classes={{
                    dialogPaper: classes.dialogPaper,
                    _close: classes.dialogClose,
                }}
            >
                <GiftDappRewardSetupGameSetupSlotWinChance
                    onCancel={closeDialog}
                    onContinue={autoFill(web3)}
                />
            </CustomDialog>
        </div>
    );
}
