import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, makeStyles, withWidth } from '@material-ui/core'
import { PowerSettingsNew } from '@material-ui/icons'
import classNames from 'classnames';
import { walletStrings, WALLET_REDUCER_NAME } from '../../../GiftDappWallets'
import walletUtil from '../../../../../utils/web3/walletUtil'
import { CONTRACTS, getContractInstance, giftContractActions, shortAddress, tokenContractActions } from '../../../../../utils'
import { customPopoverActions, customPopoverString, errorToast } from '../../../../../includes'
import { GiftDappConnectedDetails } from './GiftDappConnectedDetails'
import { WALLET_KEYS } from '..'
import { NETWORK_TYPE } from '../../../../GIftDappEvents'
const style = makeStyles(({breakpoints, isDark, colors : {
    secondaryDark, secondary, secondary2, primary, backgroundDark,
    background, textBackground,
}}) => ({
    root : {
        display : 'flex',
        alignItems : "center",
        [breakpoints.down("xs")] : {
            // flexDirection : "column",
            fontSize : 10,
            flexDirection : "row-reverse"
        }
    },
    balance : {
        display : "flex",
        padding: 10,
        background : primary, //"linear-gradient(100deg, " + primary + ", transparent)",
        alignItems : "center",
        borderRadius : 5,
        margin : "0px 5px",
        [breakpoints.down("sm")] : {
            
        },
        [breakpoints.up("md")] : {
            // minWidth : 150,
        },
        [breakpoints.up("lg")] : {
            // minWidth : 200,
        }
    },
    amount_add_holder : {
        padding : 5,
        background : !isDark ? secondaryDark : secondary,
        display : "flex",
        margin : "0px 2px",
        borderRadius : 5,
        alignItems : "center",
    }, 
    eth : {
        margin : "0 10px",
    },
    address : {
        display : "flex",
        alignItems : "center",
        padding : 10,
        background : !isDark ? backgroundDark : secondary2,
        borderRadius : 0,
        cursor : "pointer",
    },
    metamask_img : {
        height : 13,
        width : 13,
        [breakpoints.up("sm")] : {
            marginLeft : 5
        }
    },
    iconBtn : {
        background : !isDark ? secondaryDark : secondary,
        borderRadius : 5,
        padding : 10,
        margin : "0px 2px",
        "&:hover" : {
            background : !isDark ? secondaryDark : background,
        },
        [breakpoints.up("sm")] : {
            margin : "0 5px"
        }
    },
    iconBtnConnected : {
        padding : 12,
    },
    icon : {
        color : textBackground
    },
}))
function _GiftDappConnected({width, ...props}) {
    const classes = style(),
        [state, _setState] = useState({
            mainBalance : 0,
            rigelBalance : 0
        }),
        {mainBalance, rigelBalance} = state,
        setState = _state => _setState(state=>({...state, ..._state})),
    dispatch = useDispatch(),
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.address] : address,
        [walletStrings.networkType] : networkType,
        [walletStrings.networkWallet] : networkWallet,
        [walletStrings.web3] : web3
    } = walletReducer;
    useEffect(()=>{
        (async() => {
            try{
                const [mainBalance, rigelBalance] = 
                    await Promise.all([
                        walletUtil.getBalance(),
                        tokenContractActions(
                            await getContractInstance(
                                web3, null, 
                                await giftContractActions().RGPTokenAddress()
                            )
                        ).myBalance()
                    ])
                    setState({mainBalance, rigelBalance})
            }catch({message}){
                errorToast(message)(dispatch);
            }
        })();
    }, [address])
    const _symbol = networkType === WALLET_KEYS.BINANCE ? " BNB" : " ETH";
    return (
        <div className={classes.root}>
            <div className={classes.balance} title={`${Number(rigelBalance).toLocaleString()} RGP`}>
                {String(rigelBalance).length > 6 ? String(Number(rigelBalance).toLocaleString()).substr(0, 6) + "..." : Number(rigelBalance).toLocaleString()} RGP
            </div>
            <div className={classes.amount_add_holder}>
                <div className={classes.eth}>
                    {Number(mainBalance).toLocaleString()} 
                    {_symbol}
                </div>
                <div className={classes.address}>
                    {width !== "xs" && shortAddress(address)}
                    <img src={`/assets/${networkWallet === WALLET_KEYS.TRUSTWALLET ? "bnb" : "metamask"}.png`} className={classes.metamask_img}/>
                </div>
            </div>
            <div className={classes.off} onClick={({target : anchorEl})=>{
                    //check target first
                    customPopoverActions.payload({
                        [customPopoverString.anchorEl] : anchorEl,
                        [customPopoverString.customComponent] : () => <GiftDappConnectedDetails
                            balance={rigelBalance}
                        />,
                        [customPopoverString.anchorOrigin] : {
                            horizontal : "left",
                        }
                    })(dispatch);
                }}>
                <IconButton className={
                    classNames(classes.iconBtn, address, classes.iconBtnConnected)}
                >
                    <PowerSettingsNew color="secondary"/>
                </IconButton>
            </div>
        </div>
    )
}
_GiftDappConnected.propTypes = {
    
}
export const GiftDappConnected = withWidth()(_GiftDappConnected);
export {GiftDappConnected as default};