import { IconButton, makeStyles } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomButton, customPopoverActions, customPopoverString, successToast } from '../../../../../../includes'
import { copyToClipboard } from '../../../../../../utils'
import walletUtil from '../../../../../../utils/web3/walletUtil'
import { walletStrings, WALLET_REDUCER_NAME } from '../../../../GiftDappWallets'
const style = makeStyles(({colors})=>({
    root : {
        color : colors.text,
        display: "flex",
        flexDirection : "column",
        minWidth : 150,
        padding : 10,
    },
    address : {
        fontSize : 10,
        padding : 5,
        display : 'flex',
        margin : 5,
        alignItems : "center",
        justifyContent : "center",
        overflow : "auto",
    },
    addressLabel : {
        fontSize : 12,
        fontStyle : "italic",
        color : colors.textSecondary,
        padding : "0px 10px"
    },
    btn : {
        borderRadius : 5,
    },
    icon : {
        width : 20,
        height : 20,
        color : colors.textSecondary
    },
    balance : {
        border : "solid 1px " + colors.accent,
        fontSize : 14,
        color : colors.accent,
        display : "flex",
        justifyContent : "center",
        padding : 20,
        margin: 5,
        borderRadius : 5,
    },
    networkName : {
        display : 'flex',
        padding : 10,
        fontSize : 10,
    }
}))
export function GiftDappConnectedDetails({symbol, balance}) {
    const classes = style(),
    dispatch = useDispatch(),
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.address] : address,
        [walletStrings.networkId] : networkId,
    } = walletReducer;
    return (
        <div className={classes.root}>
            <div className={classes.balance}>
                {Number(balance).toLocaleString()} RGP
            </div>
            <div style={{display : "flex", flexDirection : "column"}}>
                <div className={classes.addressLabel}>
                    Network
                </div>
                <div className={classes.networkName}>
                    {walletUtil.getNetNames(networkId)}
                </div>
                <div className={classes.addressLabel}>
                    Address
                </div>
                <div className={classes.address}>
                    {address}
                    <IconButton style={{padding : 2}} onClick={() => {
                        copyToClipboard(address);
                        successToast("Address copied!")(dispatch)
                    }}>
                        <FileCopy className={classes.icon}/>
                    </IconButton>
                </div>
            </div>
            <div>
                <CustomButton
                    fullWidth
                    classes={{
                        btn : classes.btn
                    }}
                    onClick={() => {
                        customPopoverActions.close(dispatch);
                        walletUtil.disconnectUser()
                    }}
                    type="primary"
                >
                    Disconnect
                </CustomButton>
            </div>
        </div>
    )
}
