import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, makeStyles, withWidth } from '@material-ui/core'
import { gql, useQuery } from '@apollo/client';
import { walletStrings, WALLET_REDUCER_NAME } from '../../structures';
import { GiftDappDashboardRewardBoxContainer } from "./GiftDappDashboardRewardBoxContainer";
import { DASHBOARD_NAV_PROJECT_TYPES, DASHBOARD_NAV_TABS,  } from '..';
import GiftDappDashboardNav from './GiftDappDashboardNav/GiftDappDashboardNav';
import { getRewards } from '../../../utils';
const style = makeStyles(({isDark, colors}) => ({
    root : {
        // padding : 20,
    },
    headerContainer : {
        borderRadius:"10px 10px 0 0",
        marginTop :20, 
        padding : 20,
        color : colors.textSecondary,
        fontSize : 12,
        background : !isDark ? colors.backgroundLight : colors.dashboardNavbar,
    },
    header : {
    },
}))
const GET_REWARD_COUNTS = gql`
    query getRewardCounts ($networkId : String, $filter : RewardInput){
        rewardCounts : getRewardCounts (networkId : $networkId, filter : $filter) {
            active ended
        }
    }
`;
function _GiftDappDashboardHome({width, onEditAction, onViewAction}) {
    const classes = style(),
    [state, _setState] = useState({
        tab : DASHBOARD_NAV_TABS[0]._id,
        projectTypes : [
            DASHBOARD_NAV_PROJECT_TYPES[0]._id,
            DASHBOARD_NAV_PROJECT_TYPES[1]._id,
        ]
    }),
    {tab, projectTypes} = state,
    setState = _state => _setState(state=>({...state, ..._state})),
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.address] : address,
        [walletStrings.networkId] : networkId,
    } = walletReducer || {},
    processFilter = ({projectTypes}) => {
        const filter = {status : tab};
        if(tab === null)
            delete filter.status;
        if(projectTypes && projectTypes.length === 1)
            filter.isProject = Boolean(DASHBOARD_NAV_PROJECT_TYPES[0]._id === projectTypes[0]);
        else if(projectTypes && projectTypes.length === 0)
            filter.isProject = null
        return filter;
    },
    {data : rewardCountData} = useQuery(GET_REWARD_COUNTS, {
        variables : {
            networkId : String(networkId), 
            filter : {
                creator : address,
                ...processFilter({projectTypes}),
            }
        },
        fetchPolicy : "network-only",
        skip : !address
    }),
    {data : rewardData} = useQuery(getRewards(), {
        variables : {
            networkId : String(networkId), 
            filter : {
                creator : address,
                ...processFilter({projectTypes}),
            },
            config : { withProfile : true }
        },
        fetchPolicy : "network-only",
        skip : !address
    }),
    {rewards} = rewardData || {},
    {rewardCounts} = rewardCountData || {},
    toggleProjectType = useCallback(type => {
        if(projectTypes.indexOf(type) === -1) projectTypes.push(type);
        else projectTypes.splice(projectTypes.indexOf(type), 1);
        setState({projectTypes});
    }, [JSON.stringify(projectTypes)]);
    return (
        <div className={classes.root}>
            <GiftDappDashboardNav
                rewardCounts={rewardCounts}
                setTab={tab => setState({tab})}
                activeTab={tab}
                toggleProjectType={toggleProjectType}
                projectTypes={projectTypes}
            />
            {["xs"].indexOf(width) === -1 && 
                 <div className={classes.headerContainer}>
                     <Grid container>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <div className={classes.header}>
                                DEPOSIT
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <div className={classes.header}>
                                REWARD AMOUNT
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                            <div className={classes.header}>
                                USER CLAIMS
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                            <div className={classes.header}>
                                
                            </div>
                        </Grid>
                    </Grid>
                </div>}
            {rewards && rewards.map((reward, index) => {
                return <GiftDappDashboardRewardBoxContainer
                    key={index}
                    reward={reward}
                    onEditAction={onEditAction}
                    onViewAction={onViewAction}
                />
            })}
        </div>
    )
}
_GiftDappDashboardHome.propTypes = {
    
}
export const GiftDappDashboardHome = withWidth()(_GiftDappDashboardHome);
export {GiftDappDashboardHome as default};