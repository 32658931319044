import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import walletConnection from '../utils/web3/walletConnection';
import { GiftDappConnectionFallback } from './structures';
import { useDispatch, useSelector } from 'react-redux';
import { walletActions, walletStrings } from './structures';
import { warningToast } from '../includes';
function Web3Container({render, renderLoading, children, ...params}) {
    const propTypes = {
        render : PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
        renderLoading : PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
    },
    dispatch = useDispatch(),
    {walletReducer} = useSelector(state=>state),
    {
        [walletStrings.web3] : web3,
        [walletStrings.hasRequestConn]  : hasRequestConn
    } = walletReducer,
    [state, _setState] = useState({}),
    {web3 : _web3, accounts, contracts, address} = state,
    setState = (_state, callback) => _setState(state=>({...state, ..._state}), callback);
    useEffect(()=>{
        (async ()=>{
            const connection = await walletConnection(),
            { web3, accounts, contracts } = connection || {};
            setState({web3, accounts, contracts});
            /* if(!web3)  */
            walletActions({
                // [walletStrings.web3] : web3,
                [walletStrings.hasRequestConn] : true,
            })(dispatch);
            !web3 && warningToast("Please connect to your metamask!", dispatch);
        })();
    }, []);
    return web3 || hasRequestConn? 
        (
            render ? render({ web3, accounts, contracts }) : 
            children({ web3, accounts, contracts })
        ) : 
        renderLoading()
}
const withWeb3 = Page => props => {
    return <Web3Container
        renderLoading={() => <GiftDappConnectionFallback/> }
    >
        {web3Info => <Page {...props} {...web3Info}/>}
    </Web3Container>
}
export {Web3Container, withWeb3};