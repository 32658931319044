import { customProcessConstants } from "./config";

export const customProcessDisplayActions = {
    next : dispatch => dispatch({
        type : customProcessConstants.CUSTOM_PROCESS_DISPLAY_NEXT,
    }),
    close : dispatch => dispatch({
        type : customProcessConstants.CLOSE_CUSTOM_PROCESS_DISPLAY,
    }),
    payload : payload => dispatch => dispatch({
        type : customProcessConstants.SET_CUSTOM_PROCESS_DISPLAY,
        payload
    })
}