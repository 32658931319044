import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import useGAEventsTracker from './../../hooks/useGAEventsTracker';

const style = ({colors}) => ({
    root : {

    },
    rootNested : {

    },
    tabNavBar_root : {
        backgroundColor : colors?.primary
    },
    appBar : {
        background : "transparent",
        boxShadow : "none",
        borderBottom : `0.92px solid ${colors.secondary}`,
        display : "flex",
        flexDirection : "row",
        justifyContent : "space-between",
        alignItems : "center",
    },
    tabs : {
        width : "fit-content"
    },
    wrapper : {
        textTransform : "capitalize"
    },
    selected : {
        color : colors.homeLiner,
    },
    fitContent : {
        width : "fit-content"
    }
})

function _CustomTabs({
    classes, tabs = {}, tab, pushValus, nested, position, appBarProps,
    tabProps, musicPausePlay, audiocurrentTime, setLoop, audioLoop, rightComponent : RightComponent
}) {
    const tabKeys = (tabs && Object.keys(tabs)) || [],
    setTab = tab => pushValus && pushValus(tab);
    useEffect(() => {
        setTab(tabKeys?.[0])
    }, [JSON.stringify(tabKeys)]);
    const root = nested ? classes.rootNested : classes.root,
    tabValues = Object.values(tabs);
    const GAEventsTracker = useGAEventsTracker();

    return (
        <div className={root}>
            <AppBar
                className={classes.appBar}
                position={position || "sticky"}
                {...appBarProps}>
                <Tabs
                    indicatorColor="primary"
                    variant="standard"
                    value={tab}
                    onChange={(e, tab) => { setTab(tab); GAEventsTracker(`click_on_${tab}_tab`, "reward page");}}
                    className={classes.tabs}
                    {...tabProps}>
                    {tabValues.map(
                        (item, index) => <Tab
                            classes={{
                                wrapper : classes.wrapper,
                                selected : classes.selected,
                            }}
                            key={index} value={tabKeys[index]} label={item}/>
                    )}
                </Tabs>
                {RightComponent && <RightComponent classes={{root : classes.fitContent}} musicPausePlay = { musicPausePlay } audiocurrentTime = {audiocurrentTime} audioLoop = {audioLoop} setLoop = {setLoop}/>}
            </AppBar>
        </div>
    );
};
_CustomTabs.propTypes = {
    classes : PropTypes.object.isRequired,
    pushValus : PropTypes.func.isRequired,
    tabs : PropTypes.object.isRequired,
    nested : PropTypes.bool,
    getContext : PropTypes.func,
    appBarProps : PropTypes.object,
    tabProps : PropTypes.shape(Tabs.propTypes),
    position: PropTypes.oneOf(['fixed','absolute','sticky','static','relative']),
};
const CustomTabs = React.memo(withStyles(style)(_CustomTabs));
export {CustomTabs as default, CustomTabs}
