import React from 'react'
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { breadCrumbStrings, BREAD_CRUMB_REDUCER_NAME } from './config';
import { HomeOutlined, ArrowForwardIos } from '@material-ui/icons';
import {history} from '../../helpers';
import { giftDappRouteNames } from '../../utils';
import { Fragment } from 'react';
const styles = makeStyles(({colors, breakpoints}) => ({
    root : {
        padding : 10, 
        display : "flex",
        alignItems : "center",
        color : colors.textSecondary,
        fontSize : 12,
        overflow : "auto",
    },
    icon : {
        color : colors.textSecondary,
        marginRight:9
    },
    arrowForward : {
        margin:"0 15px"
    },
    steps : {
        display : "flex",
    },
    link : {
        textDecoration : "none",
        color : colors.navBarLinks,
        display : "flex",
        alignItems : "center",
        cursor : "pointer",
    },
    active : {
        color : colors.accent,
    },
    grow : {
        display : "flex",
        flexGrow : 1,
    }
}))
export const CustomBreadCrumb = function ({rightComponent : RightComponent}){
    const classes = styles(),
    dispatch = useDispatch(),
    breadCrumbReducer = useSelector(state=>state[BREAD_CRUMB_REDUCER_NAME]),
    {
        [breadCrumbStrings.crumbs] : crumbs,
        [breadCrumbStrings.visible] : visible,
    } = breadCrumbReducer;
    if(!visible) return null
    return (
        <div className={classes.root}>
            <HomeOutlined style={{cursor : "pointer"}} onClick={() => {
                    history.replace(giftDappRouteNames().reward)
                }}  fontSize="large" className={classes.icon}/>
            {crumbs && <div className={classes.steps}>
                <ArrowForwardIos fontSize="small" className={classes.arrowForward}/>
                {crumbs.map(
                    (p, i) => {
                        const {action} = p;
                        const disabled = i + 1 === crumbs.length;
                        return action ? 
                            <div key={i} onClick={!disabled ? action : null} 
                                className={classNames(classes.link, i === crumbs.length -1 && classes.active)}>
                                <span className={classes.crumb}>{p.name}</span>
                                {i < crumbs.length - 1 && <ArrowForwardIos fontSize="small" className={classes.arrowForward} />}
                            </div> :
                            (disabled ? <div disabled className={classNames(classes.link, i === crumbs.length -1 && classes.active)} 
                                key={i} 
                            >
                                <span className={classes.crumb}>{p.name}</span>
                            </div> : 
                            <Link className={classNames(classes.link, i === crumbs.length -1 && classes.active)} 
                                key={i} 
                                disabled={disabled}
                                to={{pathname : p.path, state : {from : p.from, ...p.state}}}>
                                <span className={classes.crumb}>{p.name}</span>
                                {i < crumbs.length - 1 && <ArrowForwardIos fontSize="small" className={classes.arrowForward}/>}
                            </Link>)
                    }
                )}
                </div>
            }
            {RightComponent && <Fragment>
                <div className={classes.grow}/>
                <RightComponent/>    
            </Fragment>}
        </div>
    )
}