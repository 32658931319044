import React from 'react';
import { giftDappV1RouteNames } from "./routeNames"

const GiftDappReward = React.lazy(() => import("../components/GiftDappReward/GiftDappReward")),
GiftDappProfile = React.lazy(() => import("../components/GiftDappProfile/GiftDappProfile")),
GIftDappEvents = React.lazy(() => import("../components/GIftDappEvents/GIftDappEvents")),
GiftDappRewardSetup_ = React.lazy(() => import("../components/GiftDappReward/GiftDappRewardSetup_/GiftDappRewardSetup_")), 
GiftDappEventClaims = React.lazy(() => import("../components/GIftDappEvents/GiftDappEventClaims/GiftDappEventClaims")),
GiftDappDashboard = React.lazy(() => import("../components/GiftDappDashboard/GiftDappDashboard")),
GiftDappDashboardRewardView = React.lazy(() => import("../components/GiftDappDashboard/GiftDappDashboardRewardView/GiftDappDashboardRewardView")),
GIftDappBuyGRP = React.lazy(() => import("../components/GiftDappBuyGRP/GiftDappBuyGRP")),
GiftDapp404Page = React.lazy(() => import("../components/structures/GiftDapp404Page/GiftDapp404Page"));
    
export const routes = [
    { path : giftDappV1RouteNames().profile, exact : true, component : GiftDappProfile},
    { path : giftDappV1RouteNames().profileView, exact : true, component : GiftDappProfile},
    { path : giftDappV1RouteNames().dashboard, exact : true, component : GiftDappDashboard},
    { path : giftDappV1RouteNames().rewardView, exact : true, component : GiftDappDashboardRewardView},
    { path : giftDappV1RouteNames().rewardSetup, exact : true, component : GiftDappRewardSetup_},
    { path : giftDappV1RouteNames().reward, exact : true, component : GiftDappReward},
    { path : giftDappV1RouteNames().events, exact : true, component : GIftDappEvents},
    { path : giftDappV1RouteNames().eventView, exact : true, component : GiftDappEventClaims},
    { path : giftDappV1RouteNames().buyGRP, exact : true, component : GIftDappBuyGRP},
]
/**
 * 404 page must be the last index in this array and it is without a path
 */
export const headerlessRoutes = [
    { exact : true, component : GiftDapp404Page},
]