import React from 'react'
import { Grid, IconButton, withStyles} from '@material-ui/core'
import { Close } from "@material-ui/icons"
import { CustomButton } from '../CustomButton';
const style = ({breakpoints, colors}) => ({
    root : {
        position : "relative",
    },
    box : {
        padding : 20,
        color : colors.text,
        [breakpoints.up("sm")] : {
            minWidth : 400,
        },
        [breakpoints.only("xs")] : {
            fontSize : 12,
        }
    },
    btn : {
        
    },
    text : {
        marginTop : 20,
        textAlign : 'center',
        [breakpoints.only("xs")] : {
            padding : 20,
        }
    },
    btnContainer : {
        marginTop : 50,
        [breakpoints.only("xs")] : {
            marginTop : 20,
        }
    },
    closeBtn : {
        position : "absolute",
        zIndex : 100,
        right : 10,
        top : -10,
    },
    closeIcon : {
        color : colors.text
    }
});
function _CustomComFirmDialog({classes, onClose, message, onNo, onYes, button1, button2, single, noBoxShadow}) {
    const gridSize = single ? {
        xs : 12, sm : 12, md : 12, lg : 12, xl : 12
    } : {xs : 12, sm : 6, md : 6, lg : 6, xl : 6};
    return (
        <div className={classes.box}>
            {onClose && <IconButton className={classes.closeBtn} onClick={onClose}>
                <Close className={classes.closeIcon}/>
            </IconButton>}
            <div className={classes.text}>
                {message}
            </div>
            <Grid container className={classes.btnContainer}>
                <Grid item {...gridSize}>
                    <div style={{padding : 5}} >
                        <CustomButton
                            type="secondary"
                            fullWidth
                            noBoxShadow
                            classes={{
                                btn : `${classes.btn} ${button1?.classes} ${button1?.className}`
                            }}
                            onClick={button1?.onClick || onNo}
                        >
                            {button1?.text || "No"}
                        </CustomButton>
                    </div>
                </Grid>
                {!single && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div style={{padding : 5}} >
                        <CustomButton
                            type="accent"
                            fullWidth
                            noBoxShadow
                            onClick={button2?.onClick || onYes}
                            classes={{
                                btn : `${classes.btn} ${button2?.classes} ${button2?.className}`
                            }}
                        >
                            {button2?.text || "Yes"}
                        </CustomButton>
                    </div>
                </Grid>}
            </Grid>
        </div>
    )
}
_CustomComFirmDialog.propTypes = {
    
}
export const CustomComFirmDialog = withStyles(style)(_CustomComFirmDialog);
export {CustomComFirmDialog as default};