import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import GiftDappDashboardRewardBoxTokenDisplay from '../GiftDappDashboardRewardBoxTokenDisplay/GiftDappDashboardRewardBoxTokenDisplay'
import { ArrowForward } from '@material-ui/icons'
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../../../../utils/config';
import useGAEventsTracker from './../../../../../hooks/useGAEventsTracker';

const style = makeStyles(({isDark, colors}) => ({
    root : {
        background : isDark ? colors.secondary : colors.background,
        borderRadius:"4px",
        marginBottom : 20,
    },
    box : {
        padding : 20,
        background : "linear-gradient(95deg, " +
            colors.accentSecondary + ", " +
            colors.secondary + ")",
        padding : 10,
        marginTop : 10,
        borderRadius : 10,
        fontSize : 12,
    },
    container : {
        display : "flex",
        width : "100%",
        height : "100%",
        justifyContent : "space-between"
    },
    innerRoot : {
        display : "flex",
        alignItems : "center",
    },
    name : {
        marginTop : 10,
    },
    btnContainer : {
        display : "flex",
        justifyContent : "flex-end",
    },
    title : {
        fontStyle : "italic",
        color : colors.textSecondary,
        marginRight : 20,
    },
    logo : {
        height : 50,
        width : 50,
        borderRadius : "100%",
        background : colors.secondary,
        display : "flex",
        alignItems: "center",
        justifyContent : "center",
        fontSize : 10,
    },
    action : {
        color : colors.accent,
        cursor : "pointer",
        marginTop : 10,
    },
    details : {
        background : colors.primary,
        margin : "5px 10px",
        marginTop : 0,
        padding : 10,
        borderRadius : "0px 0px 10px 10px",
        display : "flex",
        alignItems : "center",
        transition : "max-height 0.5s linear",
    },
    detailContainer : {
        minHeight : 20,
        padding : 10,
        display : 'flex',
        flexDirection : "column",
        height : "100%",
        justifyContent : "center",
    },
    edit : {
        color : colors.text,
        cursor : "pointer",
        display : "flex",
        alignItems : "center",
        fontSize : 12,
    },
    editIcon : {
        color : colors.text,
        marginRight : 5,
    },
    end : {
        color : colors.textSecondary,
        cursor : "pointer",
        display : "flex",
        alignItems : "center",
        fontSize : 12,
    },
    endIcon : {
        color : colors.textSecondary,
        marginRight : 5,
    },
    header : {
        color : colors.textSecondary,
        fontSize : 12,
        marginBottom : 10,
        marginTop : 10,
    },
    link : {
        color : colors.textSecondary,
        fontSize : 10,
        fontStyle : "italic",
    },
    copyIcon : {
        color : colors.textSecondary,
    },
    showSub : {
        display : "flex",
        maxHeight : 600,
    },
    hideSub : {
        maxHeight : 0,
        padding : 0,
        overflow : "hidden"
    },
    nameIcon : {
        display : 'flex',
        alignItems : "center",
        flexDirection : 'column',
        padding : 10,
    },
    action: {
        display : "flex",
        justifyContent : "flex-end",
        background : isDark ? colors.dashboardNavbar : colors.backgroundLight,
        padding : 20,
        color : colors.accent,
        cursor : "pointer",
        borderRadius:"0 0 4px 4px"
    },
    arrow : {
        color : colors.accent
    }
}))
function GiftDappDashboardRewardBoxGrid({
    reward, onAction
}) {
    const classes = style(),
    giftDappUtilReducer = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME]),
    {
        [giftDappUtilStrings.appDomain] : appDomain
    } = giftDappUtilReducer,
    {name, image, tokenValues, status} = reward || {};
    const GAEventsTracker = useGAEventsTracker();

    return (
        <div className={classes.root}>
            <div className={classes.box}>
                <Grid container style={{padding : 10}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={classes.nameIcon}>
                            <div className={classes.image}>
                                {image ?
                                    <img src={/^http/.test(image) ? image : appDomain + image} title="logo" className={classes.logo}/> :
                                    <div className={classes.logo}>logo</div>}
                            </div>
                            <div className={classes.name}>
                                {name}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <div className={classes.container}>
                            <div className={classes.header}>Reward Amount</div>
                            <div style={{display : "flex"}}>
                            {tokenValues && tokenValues.map((tokenValue, index) => {
                                return <div className={classes.innerRoot} key={index}>
                                    <GiftDappDashboardRewardBoxTokenDisplay
                                        key={index}
                                        tokenValue={tokenValue}
                                        classes={{
                                            root : classes.innerRoot
                                        }}
                                    />
                                    {index + 1 !== tokenValues.length && " & "}
                                </div>
                            })}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <div className={classes.container}>
                            <div className={classes.header}>User claims</div>
                            <div className={classes.innerRoot}>{reward?.claimedCount}</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {status && <div className={classes.action} onClick={()=>{onAction(); GAEventsTracker("click_view/edit", "event page");}}>
                View/Edit <ArrowForward className={classes.arrow}/>
            </div>}
        </div>
    )
}

GiftDappDashboardRewardBoxGrid.propTypes = {

}

export default GiftDappDashboardRewardBoxGrid
export {GiftDappDashboardRewardBoxGrid};
