import { history } from '../../helpers';
import { errorToast } from '../../includes';
import { DATE_TIME, getContractInstance, giftDappV1RouteNames, tokenContractActions, ZERO_ADDRESS } from '../../utils';
import { giftRewardActions, giftRewardStrings, REWARD_DETAILS_FIELD_NAMES, REWARD_FIELD_NAMES, REWARD_GAME_FIELD_NAMES, REWARD_SOCIAL_LINK_FIELD_NAMES, SETUP_TABS_CONFIG, SETUP_TAB_STAGE_MAP } from '../GiftDappReward';

export * from './GiftDashboardHome/GiftDappDashboardHome';
export const DASHBOARD_NAV_TABS = [
    {_id : null, label : "All"},
    {_id : true, label : "Active"},
    {_id : false, label : "Ended"},
]
export const DASHBOARD_NAV_PROJECT_TYPES = [
    {_id : 1, label : "Project"},
    {_id : 2, label : "Influencer"},
]
export const onEditEvent = async (networkType, web3, dispatch, reward) => {
    let {
        name, description, website, image,

        tokenValues, retryCount : claimsPerUser, retryPeriod : claimPeriod,
        wheels, isProject, spinFields, networkType : _networkType, profileId
    } = reward;
    /**
     * There is no need to set network type because, you can't edit without being in the right network
     */
    if(networkType !== _networkType) return errorToast(
        `Can't edit reward of different network, change your network to ${_networkType}`
    )(dispatch);
    if(tokenValues) tokenValues = tokenValues.map(
        ({token : address, totalFunds : amount, balance}) => ({address, amount, balance})
    )
    const _tokenValues = {}, selectedTokenRewards = [];
    if(tokenValues) for (let i = 0; i < tokenValues.length; i++) {
        const {address, amount} = tokenValues[i];
        selectedTokenRewards.push({
            address, 
            symbol : await tokenContractActions(
                await getContractInstance(web3, null, address)
            ).symbol()
        })
        // const tokenActions = tokenContractActions(await getContractInstance(web3, null, address));
        _tokenValues[address] = 0; // await tokenActions?.reducerNumber(amount);
    }
    const newSpinFields = [];
    if(spinFields) for (let i = 0; i < spinFields.length; i++) {
        let num = 1;
        const {weight : den, rewardAmount, text, token,
        } = spinFields[i];
        if(token === ZERO_ADDRESS){
            const _sObj = {
                address : "text",
                value : text,
                spinIndex : i,
            }
            if(text || text !== "") _sObj.difficulty = String(num + ":" + den)
            newSpinFields.push(_sObj)
        }else{
            const tokenActions = tokenContractActions(await getContractInstance(web3, null, token));
            const value = await tokenActions?.reducerNumber(rewardAmount);
            const label = await tokenActions?.symbol();
            newSpinFields.push({
                address : token,
                value,
                spinIndex : i, label,
                difficulty : String(num + ":" + den)
            })
        }
    }
    const editingReward = {
        [REWARD_DETAILS_FIELD_NAMES.NAME] : name,
        [REWARD_DETAILS_FIELD_NAMES.DESCRIPTION] : description,
        [REWARD_DETAILS_FIELD_NAMES.WEBSITE] : website,
        [REWARD_DETAILS_FIELD_NAMES.IMAGE] : image,


        [REWARD_GAME_FIELD_NAMES.NO_OF_WHEEL] : wheels,
        [REWARD_GAME_FIELD_NAMES.ACTIVE_PERIOD] : 0,
        [REWARD_GAME_FIELD_NAMES.CLAIMS_PER_USER] : parseInt(claimsPerUser || 1),
        [REWARD_GAME_FIELD_NAMES.CLAIM_PERIOD] : parseInt(claimPeriod) / DATE_TIME.SECONDS_IN_AN_HOUR,
        [REWARD_GAME_FIELD_NAMES.SPINE_FIELD_VALUES] : newSpinFields,
        [REWARD_GAME_FIELD_NAMES.TOKEN_VALUES] : _tokenValues,
        blockTokenValue : tokenValues,

        [REWARD_FIELD_NAMES.IS_PROJECT] : isProject,
    }
    Object.entries(REWARD_SOCIAL_LINK_FIELD_NAMES).map(item=>{
        const name = item[1];
        editingReward[name] = reward[name];
    })
    const pushedState = {
        [giftRewardStrings.rewardData] : editingReward,
        [giftRewardStrings.rewardTokens] : tokenValues ? tokenValues.map(i=>i.address) : [],
        [giftRewardStrings.isEdit] : true,
        [giftRewardStrings.creatorId] : profileId,
        reward,
        [giftRewardStrings.selectedRewardTokens] : selectedTokenRewards,
        [giftRewardStrings.setupTab] : SETUP_TABS_CONFIG.PROFILE,
        [giftRewardStrings.setupPosition] : SETUP_TAB_STAGE_MAP[SETUP_TABS_CONFIG.PROFILE]
    }
    giftRewardActions.payload(pushedState)(dispatch);
    // setPage(TABS_CONFIG.EDIT)
    history.push(giftDappV1RouteNames().rewardSetup, pushedState)
}