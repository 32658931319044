import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import React from 'react'
import { CustomButton } from '..';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        display : "flex",
        flexDirection : "column",
    },
    iconContainer : {
        paddingRight : 10,
        minWidth : 5,
    },
    icon : {
        color : colors.textSecondary
    },
    item : {
        background : colors.secondary,
        marginBottom : 5,
    },
    text : {
        color : colors.textSecondary
    }
}))
export default function CustomFilter ({list, selectedList, onSelect}) {
    const classes = style();
    return (
        <div className={classes.root}>
            <List>
                {list && list.map((item, index) => {
                    const {label, _id} = item;
                    return <ListItem button key={index} className={classes.item} onClick={() => onSelect(_id)}>
                        <ListItemIcon className={classes.iconContainer}>
                            {selectedList?.indexOf(_id) !== -1 ? <CheckBox className={classes.icon}/> : <CheckBoxOutlineBlank className={classes.icon}/>}
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{
                            className : classes.text
                        }}>{label}</ListItemText>
                    </ListItem>
                })}
            </List>
        </div>
    )
}