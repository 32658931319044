import { giftDappProfileConstants } from "./config";

export const giftDappProfileActions = {
    refreshProfiles : dispatch => dispatch({
        type : giftDappProfileConstants.REFRESH_GIFT_DAPP_PROFILES,
    }),
    payload : payload => dispatch => dispatch({
        type : giftDappProfileConstants.SET_GIFT_DAPP_PROFILE,
        payload
    })
}
export const createProfile = (profile) => {
    if(profile?.image){
        
    }
}