export const giftDappEventStrings = {
    TAB : "tab",
    eventToClaim : "eventToClaim",
    eventFilter : "eventFilter",
    eventTab : "eventTab",
    claimTab : "claimTab",
    claimPosition : "claimPosition",
}
export const giftDappEventConstants = {
    SET_GIFT_DAPP_EVENT : "SET_GIFT_DAPP_EVENT"
}
export const EVENT_FILTER_VARIABLE = {
    rewardIndexes : "rewardIndexes",
    networkIndexes : "networkIndexes",
}
export const CLAIM_TABS_CONFIG = {
    VERIFY_ACCOUNT : "VERIFY_ACCOUNT",
    FOLLOW_CREATOR : "FOLLOW_CREATOR",
    VISIT_EVENT : "VISIT_EVENT",
}
export const CLAIM_TAB_STAGE_MAP = {
    [CLAIM_TABS_CONFIG.VERIFY_ACCOUNT] : 1,
    [CLAIM_TABS_CONFIG.FOLLOW_CREATOR] : 2,
    [CLAIM_TABS_CONFIG.VISIT_EVENT] : 3,
}
export const giftDappEventReducer = (state={
    [giftDappEventStrings.eventFilter] : {
        [EVENT_FILTER_VARIABLE.rewardIndexes] : [0, 1],
        [EVENT_FILTER_VARIABLE.networkIndexes] : [0, 1],
    },
    [giftDappEventStrings.eventTab] : null,
    [giftDappEventStrings.claimTab] : CLAIM_TABS_CONFIG.VERIFY_ACCOUNT,
    [giftDappEventStrings.claimPosition] : CLAIM_TAB_STAGE_MAP[CLAIM_TABS_CONFIG.VERIFY_ACCOUNT],
}, action) => {
    const {type, payload} = action;
    switch(type){
        case giftDappEventConstants.SET_GIFT_DAPP_EVENT : 
            return {...state, ...payload};
        default : return {...state};
    }
}
export const GIFT_DAPP_EVENT_REDUCER_NAME = "giftDappEventReducer";

/** SOCIAL MEDIA REDUCER DETAILS */
export const giftDappSocialMedialStrings = {
    youtubeVerify : "youtubeVerify",
    twitterVerify : "twitterVerify",
}
export const GIFT_DAPP_SOCIAL_VERIFY_OPTIONS = {
    HAS_TRIED_VERIFY : "HAS_TRIED_VERIFY",
    VERIFIED : "VERIFIED",
    FOLLOWING : "FOLLOWING",
    HAS_TRIED_FOLLOW : "HAS_TRIED_FOLLOW",
    HAS_TRIED_VERIFY_FOLLOW : "HAS_TRIED_VERIFY_FOLLOW",
    TWITTER_USERNAME : "TWITTER_USERNAME",
}
export const GIFT_DAPP_SOCIAL_MEDIAL_CONSTANT = "GIFT_DAPP_SOCIAL_MEDIAL_CONSTANT";
export const giftDappSocialMediaReducer = (state={
    [giftDappSocialMedialStrings.youtubeVerify] : {},
    [giftDappSocialMedialStrings.twitterVerify] : {},
}, action) => {
    const {type, payload} = action;
    switch(type){
        case GIFT_DAPP_SOCIAL_MEDIAL_CONSTANT : 
            return {...state, ...payload};
        default : return state;
    }
}
export const GIFT_DAPP_SOCIAL_MEDIAL_REDUCER_NAME = "giftDappSocialMediaReducer";
