import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { getContractInstance, tokenContractActions } from '../../../../../utils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { walletStrings, WALLET_REDUCER_NAME } from '../../../../structures';
import { withStyles } from '@material-ui/core';
const style = theme => ({
    root : {

    }
})
function GiftDappDashboardRewardBoxTokenDisplay({tokenValue}) {
    const classes = style(),
    {token : address, totalFunds : amount} = tokenValue || {}, 
    [symbol, setSymbol] = useState(null),
    [reducedAmount, setReducedAmount] = useState(0),
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.web3] : web3,
    } = walletReducer;
    useEffect(() => {
        (async () => {
            try{
                const instance = await getContractInstance(web3, null, address);
                const tokenInstanceAction = tokenContractActions(instance);
                const symbol = await tokenInstanceAction.symbol();
                const tokenActions = tokenContractActions(
                    await getContractInstance(web3, null, address)
                );
                setSymbol(symbol);
                setReducedAmount(
                    await tokenActions.reducerNumber(amount)
                )
            }catch(error){
                console.error(error);
            }
        })();

    }, [JSON.stringify(tokenValue)]);
    return Number(reducedAmount || 0).toLocaleString() + " " + symbol
}

GiftDappDashboardRewardBoxTokenDisplay.propTypes = {

}

export default withStyles(style)(GiftDappDashboardRewardBoxTokenDisplay);

