import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, FormControlLabel, makeStyles, Switch } from '@material-ui/core'
import { NETWORK_TYPE } from '../../..'
import { EVENT_FILTER_VARIABLE, giftDappEventStrings, GIFT_DAPP_EVENT_REDUCER_NAME } from '../../../config';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
    },
    netWorkName : {
        fontStyle : "italic",
        color : colors.textSecondary,
        marginRight : 20,
    },
    networkTypes : {
        color : colors.text,
        display : "flex",
        fontSize : 12,
        alignItems : "center",
        [breakpoints.down("sm")] : {
            flexDirection : "column",
            alignItems : "flex-start",
            marginLeft : 20,
        }
    },
    ntLabel : {
        fontSize : 12
    },
    switchColorSecondary : {
        color : colors.accent + " !important"
    },
    vertical : {
        display : "flex",
        flexDirection : "column",
        alignItems : "flex-start",
        margin : 10,
        marginBottom : 20,
        width : "calc(100% - 40px)",
    }
}))
function GiftDappEventNavFilter({
    rewardTypeList,
    networkTypeList,
    // rewardIndexes,
    setRewardIndex,
    // networkIndexes,
    setNetworkIndex,
    dialog
}) {
    const classes = style(),
    giftEventReducer = useSelector(state=>state[GIFT_DAPP_EVENT_REDUCER_NAME]),
    {
        [giftDappEventStrings.eventFilter] : eventFilter,
    } = giftEventReducer,
    {
        [EVENT_FILTER_VARIABLE.networkIndexes] : networkIndexes,
        [EVENT_FILTER_VARIABLE.rewardIndexes] :rewardIndexes,
    } = eventFilter || {};
    return (
        <Fragment>
            {/* <div className={classNames(classes.networkTypes, dialog ? classes.vertical : "")}>
                <span className={classes.netWorkName}>Network</span>
                <div className={dialog ? classes.vertical : ""}>
                    {networkTypeList.map((networkType, index) => {
                        return <FormControlLabel
                            key={index}
                            label={networkType}
                            classes={{
                                label : classes.ntLabel
                            }}
                            onChange={({target : {checked}}) => setNetworkIndex && setNetworkIndex(index, checked)}
                            checked={networkIndexes && networkIndexes.indexOf(index) !== -1}
                            control={<Checkbox 
                                classes={{
                                    colorSecondary : classes.switchColorSecondary,
                                    checked : classes.switchColorSecondary
                                }}
                                size="small"/>}
                        />
                    })}
                </div>
            </div> */}
            <div className={classNames(classes.networkTypes, dialog ? classes.vertical : "")}>
                <div className={dialog ? classes.vertical : ""}>
                    {rewardTypeList.map((rewardType, index) => {
                        return <FormControlLabel
                            key={index}
                            label={rewardType}
                            classes={{
                                label : classes.ntLabel
                            }}
                            labelPlacement="start"
                            onChange={({target : {checked}}) => setRewardIndex && setRewardIndex(index, checked)}
                            checked={rewardIndexes && rewardIndexes.indexOf(index) !== -1}
                            control={<Switch 
                                classes={{
                                    colorSecondary : classes.switchColorSecondary,
                                    checked : classes.switchColorSecondary
                                }}
                                size="small"/>}
                        />
                    })}
                </div>
            </div>
        </Fragment>
    )
}
GiftDappEventNavFilter.propTypes = {
    
}
export default GiftDappEventNavFilter;
export {GiftDappEventNavFilter};