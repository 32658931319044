import React, { useEffect } from 'react';
import GiftDapp from './applications/giftdapp/App';
import GiftDappV1 from './applications/giftdappv1/App';
import { giftDappPrefix } from './applications/giftdapp/utils';
import { store as v2Store} from './applications/giftdapp/helpers';
import { store as v1Store } from './applications/giftdappv1/helpers';
import { landingPageRoutePrefix } from './applications/landingpage/utils';
import GiftDappLanding from './applications/landingpage/App';
import { history } from './helpers';
import { Helmet } from 'react-helmet';
import { ApolloProvider } from '@apollo/client'
import { ApolloClientConfig } from './helpers';
import { Provider } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga4';

ReactGA.initialize("G-SFZ10H9QDZ", {
  debug: true,
  titleCase: false,
});

const WithApollo = ({component : Component, store, headers}) => () => {
    const isProduction = process.env.NODE_ENV === "production",
    isVersion1 = process.env.REACT_APP_VERSION === "v1";
    if(isProduction && !process.env.REACT_APP_VERSION)
        console.error("Running production without specifying version type");

    return <ApolloProvider client={ApolloClientConfig(headers)}>
        <Provider store={store}>
            <Component/>
        </Provider>
    </ApolloProvider>
}
export default function App(){
    useEffect(() => {
      ReactGA.send(window.location.pathname + window.location.search);
    });

    const {pathname} = history.location,
    // eslint-disable-next-line
    v2Match = new RegExp(`^\/(${giftDappPrefix}\$|${giftDappPrefix}\/)`),
    v2LandMatch = new RegExp(`^\/(${landingPageRoutePrefix}\$|${landingPageRoutePrefix}\/)`);
    let App;
    if(v2Match.test(pathname)) App = WithApollo({
        component : GiftDapp,
        headers : {"appversion" : "v2"},
        store : v2Store
    });
    else if (v2LandMatch.test(pathname)) App = WithApollo({
        component : GiftDappLanding,
        headers : {"appversion" : "v2"},
        store : v2Store
    });
    else App = WithApollo({
        component : GiftDappV1,
        headers : {appversion : "v1"},
        store : v1Store
    })
    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="keywords" content="Gift DApp, Rigel Protocol, Gift application" />
            <meta name="Keywords" content="Rewards, Events" />
            <meta name="KeyWords" content="Gift DApp created by Rigel ProtocolGift application" />
            <meta name="KeyWords" content="Gift DApp created by Rigel ProtocolGift application" />
            <title>Rigel protocol Gift Application</title>
        </Helmet>
        <App/>
    </>
}
export { App };
