import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { GiftDappEventNav } from './GiftDappEventNav'
import {giftDappEventActions}  from "../actions";
import { EVENT_FILTER_VARIABLE, giftDappEventStrings, GIFT_DAPP_EVENT_REDUCER_NAME } from '../config'
import { GiftDappEventBody } from './GiftDappEventBody'
import { Helmet } from 'react-helmet';
const style = makeStyles(theme => ({
    root: {
       
    }
}))


function GiftDappEventHome({events, rewardTypeList, networkTypeList, rewardCounts}) {
    const dispatch = useDispatch(),
        classes = style(),
        giftEventReducer = useSelector(state=>state[GIFT_DAPP_EVENT_REDUCER_NAME]),
        {
            [giftDappEventStrings.eventFilter] : eventFilter,
            [giftDappEventStrings.eventTab] : eventTab
        } = giftEventReducer,
        {
            [EVENT_FILTER_VARIABLE.rewardIndexes] :rewardIndexes,
        } = eventFilter || {},
        setRewardIndex = useCallback((index, checked) => {
            if(rewardIndexes?.indexOf(index) === -1) rewardIndexes.push(index);
            else rewardIndexes.splice(rewardIndexes.indexOf(index), 1);
            giftDappEventActions.payload({
                [giftDappEventStrings.eventFilter] : {...eventFilter, rewardIndexes}
            })(dispatch);
        }, [JSON.stringify(eventFilter), JSON.stringify(rewardIndexes)]),
        setTab = eventTab => {
            giftDappEventActions.payload({
                [giftDappEventStrings.eventTab] : eventTab
            })(dispatch);
        }
    return (
        <div className={classes.root}>
        <Helmet> 
        <title>Rigel protocol Gift Application and Gift Rewards and Events</title>
            <meta name="description" content="Create an interesting experience for your followers and users to get rewards. 
            You have the opportunity to create a reward as an influencer for your followers or as 
            a project for your users. It is a great way to reward your followers and users for their loyalty!!!" />
            <meta name="Keywords" content="Rewards, Events, Gift Rewards, For Influencer, For Projects, For Everyone Else " />
                <link rel="Rigel Protocol" href="http://rigelprotocol.com" />
        </Helmet>
            <GiftDappEventNav
                setTab={setTab}
                activeTab={eventTab}
                rewardCounts={rewardCounts}
                rewardTypeList={rewardTypeList}
                rewardIndexes={rewardIndexes}
                setRewardIndex={setRewardIndex}
            />
            <div style={{marginTop : 20}}/>
            <GiftDappEventBody
                data={events}
            />
            
        </div>
    )
}
GiftDappEventHome.propTypes = {

}
export default GiftDappEventHome;
export { GiftDappEventHome };