import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import { Chart } from 'chart.js';
import { Paper, Typography} from '@material-ui/core';

const style = theme => ({
    root: {
    },
    root1: {
        
    },
    title : {paddingTop: 20, fontWeight : "bold", fontSize : 14},
})
function _CustomChart ({
    classes, canvasId, type, elevation, 
    dataset, label, nested, title, height = 400
}) {
    useEffect(() => {
        var defaultOptions = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    }
                }],
            }
        };
        var ctx = document.getElementById(this.props.canvasId).getContext('2d');
        new Chart(ctx, {
            type: this.props.type || 'line',
            data: {
                labels: this.props.labels,
                datasets: this.props.datasets,
            },
            height : 300
            // options: this.props.options || defaultOptions
        });
    }, []);
    const root = nested ? classes.root1 : classes.root;
    return (
        <Paper elevation={this.props.elevation} className={root}>
            <Typography className={classes.title} align="center" variant="h5">{title}</Typography>
            <canvas style={{width: "100%"}} id={canvasId} height={height}></canvas>
        </Paper>
    );
}


_CustomChart.propTypes = {
    elevation : PropTypes.number,
    title : PropTypes.string.isRequired,
    classes : PropTypes.object.isRequired,
    type : PropTypes.string,
    labels : PropTypes.array.isRequired,
    datasets : PropTypes.array.isRequired,
    options : PropTypes.object,
    canvasId : PropTypes.string.isRequired,
    height: PropTypes.number,
    nested : PropTypes.bool,
};
export const CustomChart = withStyles(style)(_CustomChart);