import { Divider, List, ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../../utils/config';
import { Fragment } from 'react';
import { giftDappProfileActions } from '../actions';
import { giftDappProfileStrings, GIFT_DAPP_PROFILE_REDUCER_NAME } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { history } from '../../../helpers';
import { giftDappV1RouteNames } from '../../../utils';
import { customPopoverActions, customPopoverString } from '../../../includes';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        color : colors.text,
        minWidth : 200,
        padding : 10,
        fontSize : 12,
    },
    currentProfile : {
        marginTop : 30,
    },
    imageContainer : {
        display : "flex",
        justifyContent : "center",
    },
    image : {
        height : 50,
        width : 50,
        borderRadius : "100%",
        background : colors.textLight1,
        display : "flex",
        alignItems : "center",
        justifyContent : "center",
    },
    lineImage : {
        height : 25,
        width : 25,
        borderRadius : "100%",
        background : colors.textLight1,
        display : "flex",
        alignItems : "center",
        justifyContent : "center",
    },
    labelContainer : {
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        marginTop : 10,
        color : colors.textLight1,
    },
    divider : {
        margin : "10px 0",
        background : colors.textLight1,
    },
    otherProfileContainer : {},
    otherProfileHeader : {
        
    },
    list : {
        overflow : "auto",
    },
    secondaryText : {
        fontSize : 12, 
        color : colors.textSecondary,
    },
    label : {
        color : colors.text
    },
    editIcon : {
        margin: 10,
        cursor : "pointer",
    }
}))
export default function GiftDappProfilePopup ({profiles}) {
    const classes = style(),
        dispatch = useDispatch(),
    giftDappUtilReducer = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME]),{
        [giftDappUtilStrings.appDomain] : appDomain,
        [giftDappUtilStrings.storageLastUsedProfile] : storageLastUsedProfile,
    } = giftDappUtilReducer,
    giftDappProfileReducer = useSelector(state=>state[GIFT_DAPP_PROFILE_REDUCER_NAME]),
    {
        [giftDappProfileStrings.lastUsedProfile] : lastUsedProfile
    } = giftDappProfileReducer,
    {image, lastname, firstname, _id : profileId,  username} = lastUsedProfile || {},
    setCurrentProfile = useCallback(profile => {
        giftDappProfileActions.payload({
            [giftDappProfileStrings.lastUsedProfile] : profile
        })(dispatch);
        localStorage.setItem(storageLastUsedProfile, profile?._id)
        // window.trigger("click");
    }, [JSON.stringify(dispatch)]),
    DisplayImage = ({classes, image, appDomain, label}) => <Fragment>
        {image ? 
            <img src={/^http/.test(image) ? image : appDomain + image} alt="option" className={classes.image}/> : 
            <div className={classes.image}>
                {label ? String(label).substr(0, 1).toUpperCase() : "P"}
            </div>}
    </Fragment>
    let label = "";
    if(lastname) label += lastname + " ";
    if(firstname) label += firstname;
    return (
        <div className={classes.root}>
            <div className={classes.currentProfile}>
                <div className={classes.imageContainer}>
                    <DisplayImage classes={{image : classes.image}} label={label} image={image} appDomain={appDomain}/>
                </div>
                <div className={classes.labelContainer}>
                    <div className={classNames(classes.label)}>{label || "Unknown"}</div>
                    <FontAwesomeIcon className={classes.editIcon} size="sm" icon={faEdit} onClick={() => {
                        history.push(giftDappV1RouteNames().profile);
                        customPopoverActions.payload({
                            [customPopoverString.anchorEl] : null,
                            [customPopoverString.customComponent] : null,
                        })(dispatch);
                    }}/>
                </div>
            </div>
            <Divider className={classes.divider}/>
            <div className={classes.otherProfileContainer}>
                <div className={classes.otherProfileHeader}>Other Profiles</div>
                {profiles && <List className={classes.list}>
                    {profiles.map((item, index) => {
                        const {lastname, firstname, image} = item;
                        let label = "";
                        if(lastname) label += lastname + " ";
                        if(firstname) label += firstname;
                        return <ListItem key={index} button onClick={() => setCurrentProfile(item)}>
                            <ListItemAvatar>
                                <DisplayImage classes={{image : classes.lineImage}} label={label} image={image} appDomain={appDomain}/>
                            </ListItemAvatar>
                            <ListItemText secondary={label || "Unknown"} secondaryTypographyProps={{
                                className : classes.secondaryText
                            }}/>
                        </ListItem>
                    })}
                </List> }
            </div>
        </div>
    )
}