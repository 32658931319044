export const customMusicConsoleStrings = {
    show : "show"
}
export const customMusicConsoleConstants = {
    CUSTOM_MUSIC_CONSOLE : "CUSTOM_MUSIC_CONSOLE",
}
export const customMusicConsoleReducer = (state={
    [customMusicConsoleStrings.show] : false
}, action) => {
    const {payload, type} = action;
    switch(type){
        case customMusicConsoleConstants.CUSTOM_MUSIC_CONSOLE : 
            return {...state, ...payload};
        default : return {...state};
    }
}
export const CUSTOM_MUSIC_CONSOLE_REDUCER_NAME = "customMusicConsoleReducer";


// ==================================================
export const MUSIC_ACTION_TYPE = {
    SET_PLAY: "SET_PLAY",
    SET_TRACK: "SET_TRACK",
    SET_LOOP: "SET_LOOP"
}

const musicInitialState = {
    play: false,
    trackSrc: null,
}
export const musicReducer = (state = musicInitialState, action) => {
    const {payload, type} = action;
    switch(type) {
        case MUSIC_ACTION_TYPE.SET_TRACK :
            return {...state, ...payload};
        case MUSIC_ACTION_TYPE.SET_PLAY:
            return {...state, play: !state.play}
        case MUSIC_ACTION_TYPE.SET_LOOP:
            return {...state, loop: !state.loop}
        default: return state;
    }
}

export const MUSIC_REDUCER_NAME = "musicReducer";