import { Grid, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { customPopoverActions, warningToast } from '../../../../../includes';
import CustomInputFields from '../../../../../includes/CustomInputFields/CustomInputFields';
import { giftRewardActions } from '../../../actions';
import { generateSpinFields, giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from '../../../config';
import { REWARD_GAME_FIELD_NAMES } from '../GiftDappRewardSetupGameSetup';
const style = makeStyles(({colors, isDark}) => ({
    root : {
        fontSize : 12
    },
    header : {
        color : colors.backgroundAccent,
        fontWeight : "bold",
        color : !isDark ? colors.accent : "",
    },
    item : {
        padding : 10,
    }
}))
export default function GiftDappRewardSetupGameSetupSettings ({settingConfig}) {
    const classes = style(),
    dispatch = useDispatch(),
    giftRewardReducer = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME]),
    {
        [giftRewardStrings.rewardData] : rewardData,
        [giftRewardStrings.isEdit] : isEdit,
    } = giftRewardReducer,
    onChange = useCallback(name => optionId => {
        const newData = {...rewardData, [name] : optionId};
        if(name === REWARD_GAME_FIELD_NAMES.NO_OF_WHEEL){
            if(isEdit){
                return warningToast("Wheel can not be modified!")(dispatch);
            }
            const spinNumber = newData[name];            
            newData[REWARD_GAME_FIELD_NAMES.SPINE_FIELD_VALUES] = generateSpinFields(
                spinNumber, newData[REWARD_GAME_FIELD_NAMES.SPINE_FIELD_VALUES]
            );
        }
        giftRewardActions.payload({
            [giftRewardStrings.rewardData] : newData,
        })(dispatch);
        customPopoverActions.close(dispatch);
    }, [JSON.stringify(rewardData), isEdit]);
    return (
        <div className={classes.root}>
            <div className={classes.header}>SETTINGS</div>
                <Grid container>
                {settingConfig && settingConfig.map((field, index)=>{
                    const {name} = field;
                    return <Grid key={index} item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div className={classes.item}>
                            <CustomInputFields
                                field={{
                                    buttonProps : {
                                        disabled : isEdit && name === REWARD_GAME_FIELD_NAMES.NO_OF_WHEEL
                                    },
                                    ...field,
                                    onClick : onChange(name),
                                    value : rewardData?.[name]
                                }}
                            />
                        </div>
                    </Grid>
                })}
                </Grid>
        </div>
    )
}