import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import { Button, IconButton } from '@material-ui/core';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
const style = theme => ({
    root: {
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
    },
    btn : {
        padding : 10,
        margin : 5,
        borderRadius : 20,
        fontSize : 12,
        textTransform : "capitalize",
    },
    prevBtn : {

    },
    nextBtn : {

    }
})
export const CustomSimplePaginationFooter = withStyles(style)(function(){

})
const _SimplePagination = function(props){
    const {classes, iconButton} = props;
    return (
        !iconButton ? <div className={classes.root}>
            <Button
                className={classNames(classes.btn, classes.prevBtn)}
                variant="contained"
                color="secondary"
                onClick={this.props.prevBtnClick}
                {...this.props.prevBtnProps}>
                    Previous
                </Button>
            <Button
                className={classNames(classes.btn, classes.nextBtn)}
                variant="contained"
                color="primary"
                onClick={this.props.nextBtnClick}
                {...this.props.nextBtnProps}>
                    Next
                </Button>
        </div> : <div className={classes.root}>
            <IconButton
                className={classNames(classes.btn, classes.prevBtn)}
                variant="contained"
                color="secondary"
                onClick={this.props.prevBtnClick}
                {...props.prevBtnProps}>
                    <ArrowBackIos color="secondary"/>
                </IconButton>
            <IconButton
                className={classNames(classes.btn, classes.nextBtn)}
                variant="contained"
                color="primary"
                onClick={props.nextBtnClick}
                {...props.nextBtnProps}>
                    <ArrowForwardIos color="primary"/>
                </IconButton>
        </div>
    );
};
_SimplePagination.propTypes = {
    classes : PropTypes.object.isRequired,
    nextBtnProps : PropTypes.object,
    prevBtnProps : PropTypes.object,
    prevBtnClick : PropTypes.func,
    nextBtnClick : PropTypes.func,
    iconButton : PropTypes.bool,
};
export const SimplePagination = withStyles(style)(_SimplePagination);