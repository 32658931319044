import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles, withWidth } from '@material-ui/core'
import GiftDappDashboardRewardBoxList from './GiftDappDashboardRewardBoxList/GiftDappDashboardRewardBoxList';
import GiftDappDashboardRewardBoxGrid from './GiftDappDashboardRewardBoxGrid/GiftDappDashboardRewardBoxGrid';
import { useDispatch, useSelector } from 'react-redux';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../../../utils/config';
import { walletStrings, WALLET_REDUCER_NAME } from '../../../structures';
import { generateEventLink, giftContractActions, giftDappRouteNames } from '../../../../utils';
import { CustomComFirmDialog, CustomDialog } from '../../../../includes';
import useGAEventsTracker from './../../../../hooks/useGAEventsTracker';

const style = makeStyles(({colors, breakpoints,isDark})=>({
    root : {
        [breakpoints.down("xs")] : {
            marginTop : 50,
        }
        // background : colors.background,
        // padding : 10,
    },
    dialogPaper : {
        color : isDark ? colors.secondary : colors.background,
        background: isDark ? colors.secondary : colors.textLight,
        border : "solid 1px " + colors.accentSecondary,
        fontSize : 12,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
    confirmContainer : {
        color : colors.text,
        // padding:90,
        display : "flex",
        flexDirection : "column",
    },

    title : {
        fontStyle : "italic",
        color : colors.textSecondary,
        marginRight : 20,
    },
    logo : {
        height : 50,
        width : 50,
        borderRadius : "100%",
        background : colors.checkBoxContainerColor,
        display : "flex",
        alignItems: "center",
        justifyContent : "center",
        fontSize : 10,
    },
    imageContainer : {
        display : "flex",
        justifyContent : "center",
    },
    name : {
        fontWeight : "bold",
        display : "flex",
        textAlign : "center",
        justifyContent : "center",
        marginTop : 50,
    },
    message : {
        fontSize : 12,
        marginTop : 20,
        color : colors.textSecondary
    },
    secondaryBtn : {
        background : isDark ? colors.transparent : colors.secondaryDark,
        border : `solid 1px ${isDark ? colors.text : colors.dashboardBorderLine}`,
        boxSizing:"border-box",
        "&:hover" : {
            background : colors.dashboardNavbar,
        }
    },
    commonBtn : {
        color : colors.textBackground,
        "&:hover" : {
            color : colors.text,
        }
    }
})),
DIALOG_TYPES = {
    EVENT_CONFIRM_ACTION : "confirmAction"
}
function _GiftDappDashboardRewardBoxContainer({width, reward, onEditAction, onViewAction}) {
    const classes = style(),
    {_id : rewardId, isProject, projectIndex, description, textList} = reward || {},
    [state, _setState] = useState({
        claims : 0,
        showSub : false,
        spinFields : null,
        tokenValues : null,
        blockReward : null,
        openDialog : false,
        dialogType : null,
        confrimDialogInfo : {},
    }),
    {
        showSub, spinFields, tokenValues, blockReward,
        openDialog, dialogType, confrimDialogInfo
    } = state,
    setState = _state => _setState(state => ({...state, ..._state})),
    networkId = useSelector(state=>state[WALLET_REDUCER_NAME][walletStrings.networkId]),
    giftDappUtilReducer = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME]),
    {
        [giftDappUtilStrings.appFrontend] : appFrontend,
        [giftDappUtilStrings.appDomain] : appDomain,
    } = giftDappUtilReducer,
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.web3] : web3
    } = walletReducer,
    toggleSubMenu = () => {
        setState({showSub : !showSub});
    },
    closeDialog = () => setState({openDialog : false, dialogType : null});
    useEffect(() => {
        (async () => {
            try{
                if(isProject !== undefined){
                    const blockReward = await giftContractActions(isProject).getRewardById(projectIndex);
                    // const claims = await giftContractActions(i).projectClaimsByProjectId(projectIndex);
                    const spinFields = await giftContractActions(isProject).getProjectTickers(projectIndex, textList);
                    const tokenValues = await giftContractActions(isProject).getProjectTokens(projectIndex);
                    setState({/* claims, */ spinFields, tokenValues, blockReward});
                }
            }catch(error){
                // errorToast(error.message)(dispatch);
                console.error(error)
            }
        })();
    }, [projectIndex, JSON.stringify(textList), isProject]);
    const link = generateEventLink(appFrontend, networkId, rewardId);
    const {
        status,
        projOwner,
        retryCount,
        retryPeriod,
        rewardProjectID,
        claimedCount,
        projectStartTime,
        activePeriodInDays,
        totalSumOfWeights,
        // description,
    } = blockReward || {};
    let _reward = {
        ...reward, spinFields, tokenValues,
        status, retryPeriod, retryCount,
        projectStartTime,
        activePeriodInDays,
        totalSumOfWeights, description, claimedCount, projOwner, rewardProjectID
    };
    const GAEventsTracker = useGAEventsTracker();
    const showConfirmDialog = useCallback(() => {
        const {image, name} =  _reward;
        setState({
            openDialog : true, dialogType : DIALOG_TYPES.EVENT_CONFIRM_ACTION,
            confrimDialogInfo : {
                message : <div className={classes.confirmContainer}>
                        <div className={classes.imageContainer}>
                            {image ?
                                <img src={/^http/.test(image) ? image : appDomain + image} title="logo" className={classes.logo}/> :
                            <div className={classes.logo}>logo</div>}
                        </div>
                        <div className={classes.name}>{name || "Unknown"}</div>
                        <div className={classes.message}>
                            Would you like to view the event created or make some changes to it?
                        </div>
                    </div>,
                button1 : {
                    text : "Edit Event",
                    onClick : () => { onEditAction && onEditAction(_reward); GAEventsTracker("click_on_edit_event_on_modal", "events page");},
                    className : classes.secondaryBtn + " " + classes.commonBtn,
                    noBoxShadow : true
                },
                button2 : {
                    text : "View Event",
                    onClick : () => {onViewAction && onViewAction(_reward); GAEventsTracker("click_on_view_event_on_modal", "events page");},
                    className : classes.commonBtn,
                    noBoxShadow : true
                }
            }
        });
    }, [JSON.stringify(_reward), appDomain]),
    options = {
        reward : _reward, web3, link, showSub, toggleSubMenu,
        onAction  : () => showConfirmDialog()
    }
    return <div className={classes.root}>
        {["xs"].indexOf(width) !== -1 ?
        <GiftDappDashboardRewardBoxGrid
            {...options}
        /> :
        <GiftDappDashboardRewardBoxList
            {...options}
        /> }
        <CustomDialog
            open={openDialog}
            close={closeDialog}
            within
            classes={{
                dialogPaper : classes.dialogPaper,
                _close : classes.dialogClose
            }}
        >
            {dialogType === DIALOG_TYPES.EVENT_CONFIRM_ACTION && <CustomComFirmDialog
                {...confrimDialogInfo}
            />}
        </CustomDialog>
    </div>
}

_GiftDappDashboardRewardBoxContainer.propTypes = {

}

export const GiftDappDashboardRewardBoxContainer = withWidth()(_GiftDappDashboardRewardBoxContainer);
export {GiftDappDashboardRewardBoxContainer as default}
