import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List, ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core'
import { ConnectButton, CustomDialog, CustomPopover, errorToast, successToast } from '../../../../../includes'
import GiftDappEthereumWallets from './GiftDappEthereumWallets/GiftDappEthereumWallets'
import { WALLET_KEYS } from '..'
import GiftDappMetamaskConnectionProgress from './GiftDappMetamaskConnectionProgress/GiftDappMetamaskConnectionProgress'
import walletConnection from '../../../../../utils/web3/walletConnection'
import { walletStrings, WALLET_REDUCER_NAME } from '../../../GiftDappWallets'
import { GiftDappBinanceNetwork } from './GiftDappBinanceNetwork'
import walletUtil from '../../../../../utils/web3/walletUtil'
const style = makeStyles(({colors, isDark}) => ({
    root : {
        display : "flex",
        alignItems : "center",
    },
    rgp : {
        background : !isDark ? colors.backgroundDark : colors.background,
        margin: "0px 5px",
        padding: 13,
        borderRadius : 5,
    },
    list : {
        color : colors.textSecondary,
        "&:hover" : {
            background : colors.backgroundAccent,
        }
    },
    
    dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.text,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
    btn : {
        borderRadius : 5,
    }
}))
function GiftDappConnect() {
    const classes = style(),
    dispatch = useDispatch(),
    [state, _setState] = useState({
        anchorEl : null, openDialog : false, dialogType : "",
    }),
    {anchorEl, openDialog, dialogType} = state,
    setState = (_state, callback) => _setState(state=>({...state, ..._state}), callback),
    walletReducer = useSelector(state => state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.contractAddresses] : contractAddresses,
        [walletStrings.web3] : web3,
    } = walletReducer,
    closePopover = ()=>setState({anchorEl : null}),
    listAction = useCallback(networkType => {
        closePopover();
        setState({openDialog : true, dialogType : networkType});
    }, []),
    connectToWallet = useCallback(async wallet => {
        setState({dialogType : wallet})
        await walletConnection(contractAddresses, wallet, (error) => {
            if(!error){
                successToast("Connected!")(dispatch);
                closeDialog();
            }
        })
    }, [JSON.stringify(contractAddresses)]),
    closeDialog = () => {
        setState({dialogType : "", openDialog : false});
    }
    return (
        <div className={classes.root}>
            <ConnectButton
                onClick={({target : _anchorEl})=>{
                    while(String(_anchorEl.tagName).toUpperCase() !== "BUTTON")
                        _anchorEl = _anchorEl.parentElement
                    setState({anchorEl : anchorEl ? null : _anchorEl})
                }}
                active={Boolean(anchorEl)}
            />
            <div className={classes.rgp}>RGP</div>
            <CustomPopover
                onClose={closePopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal : "left",
                }}
            >
                <List>
                    {[
                        {_id : WALLET_KEYS.BINANCE, name : "Binance Smart Chain Network", avatar : "/assets/bnb.png"},
                        {_id : WALLET_KEYS.ETHEREUM, name : "Ethereum Network", avatar : "/assets/eth.png"},
                    ].map((network, index)=>{
                        const {_id, name, avatar} = network;
                        return <ListItem className={classes.list} key={index} button onClick={()=> listAction(_id)}>
                            <ListItemAvatar>
                                <img src={avatar}/>
                            </ListItemAvatar>
                            <ListItemText secondary={name} secondaryTypographyProps={{
                                className: classes.list
                            }}/>
                        </ListItem>
                    })}
                </List>
            </CustomPopover>
            <CustomDialog
                within
                classes={{
                    dialogPaper : classes.dialogPaper,
                    _close : classes.dialogClose,
                }}
                open={openDialog}
                close={closeDialog}
            >
                {dialogType === WALLET_KEYS.ETHEREUM && <GiftDappEthereumWallets
                    onSelect={connectToWallet}
                />}
                {dialogType === WALLET_KEYS.BINANCE && <GiftDappBinanceNetwork
                    onSelect={connectToWallet}
                    web3={web3}
                />}
                {[WALLET_KEYS.METAMASK, WALLET_KEYS.TRUSTWALLET].indexOf(dialogType) !== -1 && <GiftDappMetamaskConnectionProgress
                    wallet={dialogType}
                    web3={web3}
                />}
            </CustomDialog>
        </div>
    )
}
GiftDappConnect.propTypes = {
    
}
export default GiftDappConnect;
export {GiftDappConnect};