import React from 'react';
import { giftDappRouteNames } from "./routeNames"

const GiftDappReward = React.lazy(() => import("../components/GiftDappReward/GiftDappReward")),
    GiftDappRewardCards = React.lazy(() => import("../components/GiftDappRewardCards/GiftDappRewardCards")),
    GiftDappProfile = React.lazy(() => import("../components/GiftDappProfile/GiftDappProfile")),
    GIftDappEvents = React.lazy(() => import("../components/GIftDappEvents/GIftDappEvents")),
    GiftDappRewardSetup_ = React.lazy(() => import("../components/GiftDappReward/GiftDappRewardSetup_/GiftDappRewardSetup_")),
    GiftDappEventClaims = React.lazy(() => import("../components/GIftDappEvents/GiftDappEventClaims/GiftDappEventClaims")),
    GiftDappDashboard = React.lazy(() => import("../components/GiftDappDashboard/GiftDappDashboard")),
    GiftDappDashboardRewardView = React.lazy(() => import("../components/GiftDappDashboard/GiftDappDashboardRewardView/GiftDappDashboardRewardView")),
    GiftDapp404Page = React.lazy(() => import("../components/structures/GiftDapp404Page/GiftDapp404Page")),
    NftPage = React.lazy(() => import("../components/NFT/Index")),
   NftViewPage = React.lazy(() => import("../components/NFT/NftViewPage/NftViewPage"));


export const routes = [
    { path: giftDappRouteNames().profile, exact: true, component: GiftDappProfile },
    { path: giftDappRouteNames().profileView, exact: true, component: GiftDappProfile },
    { path: giftDappRouteNames().dashboard, exact: true, component: GiftDappDashboard },
    { path: giftDappRouteNames().rewardView, exact: true, component: GiftDappDashboardRewardView },
    { path: giftDappRouteNames().rewardSetup, exact: true, component: GiftDappRewardSetup_ },
    { path: giftDappRouteNames().reward, exact: true, component: GiftDappReward },
    { path: giftDappRouteNames().events, exact: true, component: GIftDappEvents },
    { path: giftDappRouteNames().eventView, exact: true, component: GiftDappEventClaims },
    { path: giftDappRouteNames().specialReward, exact: true, component: GiftDappRewardCards },
    { path: giftDappRouteNames().nft, exact: true, component: NftPage },
    { path: giftDappRouteNames().nftView, exact: true, component: NftViewPage },

]
/**
 * 404 page must be the last index in this array and it is without a path
 */
export const headerlessRoutes = [
    { exact: true, component: GiftDapp404Page },
]