import { Button, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import CustomInputFields from '../../../../../includes/CustomInputFields/CustomInputFields'

const style = makeStyles(({colors, isDark, breakpoints}) => ({
    root : {
        fontSize : 12,
        display : "flex",
        padding : "20px 0px",
        alignItems : 'center',
        [breakpoints.down("sm")] : {
            border : "none"
        }
    },
    inputContainer : {
        margin : 0,
        display : "flex",
        alignItems : "center",
        border : `solid 1px ${colors.background}`,
        width: "100%",
        maxWidth: "420px",
        // [breakpoints.up("sm")] : {
        //     maxWidth : 400 - 100,
        // }
    },
    label : {
        marginBottom : 10,
        display : 'flex',
        alignItems : "center",
        height : "100%"
    },
    prefix : {
        background : colors.background,
        display : "flex",
        alignItems : "center",
        padding : 10,
        color : !isDark ? colors.text : colors.textBackground,
        border : `solid 1px ${colors.borderLines}`,
        fontSize : 12,
        width : 100,
        borderRadius : "5px 0 0 5px",
        // minWidth : 100,
    },
    formField : {
        borderRadius : 5,
        [breakpoints.up("sm")] : {
            maxWidth : 400,
        }
    },
    prefix_formField : {
        [breakpoints.up("sm")] : {
            maxWidth : 400 - 100,
        }
    },
    notchedOutline : {
        borderRadius : 5
    },
    _notchedOutline : {
        borderRadius : "0 5px 5px 0"
    },

    accent: {
        color: colors.accent
    }
}))

const GiftDappRewardTelegramLink = ({verifyChannelOwner, inputValue, onChangeHandler, disabled}) => {
    const classes = style()
    return (
        <div className= {classes.root}>
            <Grid container>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div className={classes.label}>Telegram</div>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                    <div className={classes.inputContainer}>
                        <div className={classes.prefix}>Telegram url/ID</div>
                        <CustomInputFields 
                            field={
                                {
                                    // all input properties goes here
                                    name: "telegramUrl",
                                    label: "input telegram url/ID and click verify",
                                    onChange: (e) => {onChangeHandler(e.target.value)},
                                    value: inputValue,
                                    disabled: disabled
                                }
                            }
                            classes={{
                                formField : classes.prefix_formField,
                                notchedOutline : classes._notchedOutline
                            }}
                        />
                        <Button 
                            variant="text"
                            disabled = {disabled}
                            className={classes.accent}
                            onClick = {verifyChannelOwner}
                        
                        >Verify</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default GiftDappRewardTelegramLink