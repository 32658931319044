import { makeStyles, Slide, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { customSideDisplayStrings, CUSTOM_SIDE_DISPLAY_REDUCER } from './config'
import $ from 'jquery';
import { customSideDisplayActions } from './actions'
import classNames from 'classnames';
const style = makeStyles(({isDark, colors, breakpoints}) => ({
    root : {
        position : "fixed",
        background : /* !isDark ? colors.secondaryDark :  */colors.background,
        border : `solid 1px ${colors.textSecondary}`,
        color : colors.textLight,
        fontSize : 11,
        top : 100,
        right : 5,
        zIndex : 1000,
        padding : 10,
        minWidth : 100,
        borderRadius : 5,
    },
    iconBtn : {
        position : "absolute",
        top : -20,
        border : `solid 1px ${colors.textSecondary}`,
        padding : 5,
        background : colors.background,
        "&:hover" : {
            background : colors.background,
        }
    },
    closeIcon : {
        color : "red"
    }
}))
export default function CustomSideDisplay () {
    const classes = style(),
    dispatch = useDispatch(),
    customSideDisplayReducer = useSelector(state=>state[CUSTOM_SIDE_DISPLAY_REDUCER]),
    {
        [customSideDisplayStrings.component] : Component,
        [customSideDisplayStrings.config] : config = {},
        [customSideDisplayStrings.show] : show,
        [customSideDisplayStrings.onClose] : onClose,
    } = customSideDisplayReducer;
    const _onClose = () => {
        customSideDisplayActions.payload({
            [customSideDisplayStrings.show] : false
        })(dispatch);
    }  
    return (
        <div className={classNames(classes.root, "animate__animated", show ? "animate__slideInRight" : "animate__slideOutRight")} >
            <IconButton className={classes.iconBtn} onClick={_onClose}>
                <Close color="secondary" fontSize="small"/>
            </IconButton>
            {onClose && <Close className={classes.closeIcon} fontSize="small" onClick={onClose}/>}
            {Component && <Component/>}
        </div>
    )
}