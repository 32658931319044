import React, { useEffect } from 'react';
import { ThemeProvider, useTheme } from '@material-ui/core';
import { Suspense } from 'react';
import { Router, Switch, Route} from 'react-router-dom';
import { GiftDappFallback, Layout } from './components';
import { history } from './helpers';
import { appTheme, GIFT_DAPP_LOCAL_STORAGE_VAR, GIFT_DAPP_THEMES, headerlessRoutes, routes } from './utils';
import { CustomSnackbar } from './includes';
// import "./components/GIftDappEvents/GiftDappEventClaims/SocialMediaVerify/facebookSDK";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'crypto-icons/styles.css';
import "crypto-icons/font.css"
import "animate.css";
import GiftDappSetup from './components/GiftDappSetup/GiftDappSetup';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from './utils/config';
import CustomProcessDisplay from './includes/CustomProcessDisplay/CustomProcessDisplay';
import CustomSideDisplay from './includes/CustomSideDisplay/CustomSideDisplay';
function App() {
    const theme = useTheme();
    const refreshCount = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME][giftDappUtilStrings.refreshCount]);
    const themeType = localStorage.getItem(GIFT_DAPP_LOCAL_STORAGE_VAR.THEME_TYPE),
        selectedTheme = themeType || GIFT_DAPP_THEMES.DARK;
    useEffect(() => {
        $("html").css("background", appTheme[selectedTheme]?.pageBackground);
        $("html").css("color", appTheme[selectedTheme]?.text);
    }, [JSON.stringify(appTheme), themeType, refreshCount]);
    return <Router history={history}>
        <ThemeProvider theme={{
            ...theme, isDark : selectedTheme === GIFT_DAPP_THEMES.DARK, 
            colors : appTheme[selectedTheme]
        }}>
            <Suspense fallback={<GiftDappFallback/>}>
                <Switch>
                    {routes && routes.map((route, index)=><Layout {...route} key={index}/>)}
                    {headerlessRoutes && headerlessRoutes.map((route, index)=><Route {...route} key={index}/>)}
                </Switch>
            </Suspense>
            <CustomSnackbar/>
            <GiftDappSetup/>
            <CustomProcessDisplay/>
            {/* <CustomSideDisplay/> */}
        </ThemeProvider>
    </Router>
}

export default App;
