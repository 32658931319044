import { makeStyles, InputBase, CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import classNames from "classnames";
import { Close } from "@material-ui/icons";
import {
    giftDappProfileStrings,
    PROFILE_SOCIAL_MEDIAL_KEYS,
} from "../../config";
import TwitterLogin from "react-twitter-auth";
import { CustomOauthPopup } from "../../../../includes";
import { useDispatch, useSelector } from "react-redux";
import {
    giftDappUtilStrings,
    GIFT_DAPP_UTIL_REDUCER_NAME,
} from "../../../../utils/config";
import { errorToast } from "../../../../includes";
import { giftDappSocialMedialStrings } from "../../../GIftDappEvents/config";
import { MEDIA_NAMES } from "../GiftDappProfileSocialMedia";
// import { parseWithoutProcessing } from 'handlebars';
import {
    getDiscordRedirectURI,
    getReditAuthCredentials,
    getTelegramBotId,
    randomStringGenerator,
} from "../../../../utils";
// import TelegramButton from '../../../GIftDappEvents/GiftDappEventClaims/SocialMediaVerify/TelegramButton';
const style = makeStyles(({ colors, breakpoints }) => ({
    root: {
        display: "flex",
        margin: "20px 0",
    },
    iconContainer: {
        borderRadius: 5,
        border: `solid 1px ${colors.background}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 40,
        height: 40,
    },
    leftIconContainer: {
        marginRight: 10,
    },
    rightIconContainer: {
        marginLeft: 10,
        cursor: "pointer",
    },
    inputVerify: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        border: `solid 1px ${colors.background}`,
        padding: "5px 10px",
    },
    textField: {
        display: "flex",
        flexGrow: 1,
        borderBottom: "none",
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary,
    },
    color: {
        color: colors.textSecondary,
    },
    verify: {
        background: "transparent",
        border: "none",
        fontSize: 10,
        color: colors.accent,
        cursor: "pointer",
    },
    verified: {
        color: colors.verified,
        cursor: "not-allowed",
    },
    disabled: {
        cursor: "not-allowed",
    },
    processing: {
        width: "20px !important",
        height: "20px !important",
    },
    telegramBtn: {
        "& #telegram-login-GiftDApp_bot": {
            // visibility: "hidden",
        },
    },
}));
export default function GiftDappProfileSocialMediaItem({
    verify,
    unverify,
    icon: Icon,
    isNew,
    data = {},
    onChange,
    isProcessing,
    name,
}) {
    const classes = style(),
        dispatch = useDispatch(),
        { IS_VERIFIED, PATH } = PROFILE_SOCIAL_MEDIAL_KEYS,
        { [IS_VERIFIED]: isVerified, [PATH]: path } = data,
        giftDappUtilReducer = useSelector(
            (state) => state[GIFT_DAPP_UTIL_REDUCER_NAME]
        ),
        {
            [giftDappUtilStrings.appFrontend]: appFrontend,
            [giftDappUtilStrings.appDomain]: appDomain,
        } = giftDappUtilReducer,
        onTwitterAuthSuccess = (props) => {
            props.json().then(({ screen_name }) => {
                onChange(PATH, screen_name);
                verify(screen_name);
            });
        },
        onRedditAuthResponse = async (status, data) => {
            if (status !== "success")
                return errorToast("Unable to verify Reddit")(dispatch);

            // the "code", being the last in the parameter list where it was gotten has "#_" at the end, so we need to remove it
            let code = data.code.slice(0, data.code.length - 2);
            const postdata = `grant_type=authorization_code&code=${code}&redirect_uri=${getReditAuthCredentials(
                "REDIRECT_URI"
            )}`;
            const reqOption = {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Basic ${btoa(
                        getReditAuthCredentials("CLIENT_ID") + ":" + ""
                    )}`,
                },
                body: postdata,
            };

            try {
                const access_token_req_response = await fetch(
                    "https://www.reddit.com/api/v1/access_token",
                    reqOption
                );
                const parsed_token_res = await access_token_req_response.json();
                if (!parsed_token_res.access_token)
                    return errorToast("Unable to verify Reddit")(dispatch);

                const user_account_req = await fetch(
                    "https://oauth.reddit.com/api/v1/me",
                    {
                        headers: {
                            Authorization: `bearer ${parsed_token_res.access_token}`,
                        },
                    }
                );

                const parse_account_res = await user_account_req.json();
                verify(parse_account_res.name);
            } catch (err) {
                errorToast("Unable to verify Reddit")(dispatch);
            }
        },
        onDiscordAuthResponse = async (status, data) => {
            if (status !== "success")
                return errorToast("Unable to verify Discord")(dispatch);
            try {
                const user_account_req = await fetch(
                    "https://discord.com/api/users/@me",
                    {
                        headers: {
                            Authorization: `Bearer ${data.access_token}`,
                        },
                    }
                );
                const parse_account_res = await user_account_req.json();
                verify(
                    `${parse_account_res.username}#${parse_account_res.discriminator}`
                );
            } catch (err) {
                errorToast("Unable to verify Discord")(dispatch);
            }
        };

    useEffect(() => {
        // loading the telegam script
        const script = document.createElement("script");
        script.src = "https://telegram.org/js/telegram-widget.js?18";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const onTelegramLogin = async () => {
        await window.Telegram.Login.auth(
            { bot_id: getTelegramBotId(), request_access: true },
            (data) => {
                if (!data) {
                    // authorization failed
                    return errorToast("Unable to verify Telegram")(dispatch);
                }
                verify(`@${data.username}`);
            }
        );
    };

    return (
        <div className={classes.root}>
            <div
                className={classNames(
                    classes.iconContainer,
                    classes.leftIconContainer
                )}
            >
                {Icon && <Icon />}
            </div>
            <div
                className={classNames(
                    classes.inputVerify,
                    isVerified ? classes.disabled : ""
                )}
            >
                <InputBase
                    className={classNames(
                        classes.textField,
                        isVerified ? classes.disabled : ""
                    )}
                    margin="dense"
                    placeholder={
                        name === MEDIA_NAMES.YOUTUBE
                            ? "Insert youtube ID"
                            : "Click on verify"
                    }
                    onChange={({ target: { value } }) => onChange(PATH, value)}
                    value={path || ""}
                    disabled={name !== MEDIA_NAMES.YOUTUBE}
                    classes={{
                        inputHiddenLabel: classes.label,
                        inputTypeSearch: classes.label,
                        disabled: classes.label,
                    }}
                />

                {!isNew && isProcessing && (
                    <CircularProgress className={classes.processing} />
                )}

                {!isNew &&
                    !isProcessing &&
                    name === giftDappProfileStrings.youtube && (
                        <div
                            onClick={!isVerified ? verify : null}
                            className={classNames(
                                classes.verify,
                                isVerified ? classes.verified : ""
                            )}
                        >
                            {isVerified ? "Verified" : "Verify"}
                        </div>
                    )}

                {!isNew &&
                    !isProcessing &&
                    name === giftDappProfileStrings.twitter && (
                        <TwitterLogin
                            loginUrl={`${appDomain}/auth/twitter/final`}
                            onFailure={() =>
                                errorToast("Unable to verify twitter")(dispatch)
                            }
                            onSuccess={onTwitterAuthSuccess}
                            requestTokenUrl={`${appDomain}/auth/twitter/reverse`}
                            text="Verify Twitter Account"
                            className={classNames(
                                classes.verify,
                                isVerified ? classes.verified : ""
                            )}
                        >
                            {isVerified ? "Verified" : "Verify"}
                        </TwitterLogin>
                    )}

                {!isNew &&
                    !isProcessing &&
                    name === giftDappProfileStrings.telegram &&
                    (isVerified ? (
                        <span className={(classes.verify, classes.verified)}>
                            verified
                        </span>
                    ) : (
                        <button
                            className={classes.verify}
                            onClick={onTelegramLogin}
                        >
                            verify
                        </button>
                    ))}

                {!isNew &&
                    !isProcessing &&
                    name === giftDappProfileStrings.reddit && (
                        <CustomOauthPopup
                            title="REDDIT LOGIN"
                            url={`https://www.reddit.com/api/v1/authorize.compact?client_id=${getReditAuthCredentials(
                                "CLIENT_ID"
                            )}&response_type=code&state=${randomStringGenerator()}&redirect_uri=${getReditAuthCredentials(
                                "REDIRECT_URI"
                            )}&duration=temporary&scope=identity`}
                            onResponse={onRedditAuthResponse}
                        >
                            <div
                                className={classNames(
                                    classes.verify,
                                    isVerified ? classes.verified : ""
                                )}
                            >
                                {isVerified ? "Verified" : "Verify"}
                            </div>
                        </CustomOauthPopup>
                    )}

                {!isNew &&
                    !isProcessing &&
                    name === giftDappProfileStrings.discord && (
                        <CustomOauthPopup
                            title="DISCORD LOGIN"
                            url={`https://discord.com/api/oauth2/authorize?response_type=token&client_id=${
                                process.env.REACT_APP_DISCORD_CLIENT_ID
                            }&redirect_uri=${getDiscordRedirectURI()}&state=${randomStringGenerator()}&scope=identify`}
                            onResponse={onDiscordAuthResponse}
                        >
                            <div
                                className={classNames(
                                    classes.verify,
                                    isVerified ? classes.verified : ""
                                )}
                            >
                                {isVerified ? "Verified" : "Verify"}
                            </div>
                        </CustomOauthPopup>
                    )}
            </div>
            {!isNew && isVerified && (
                <div
                    className={classNames(
                        classes.iconContainer,
                        classes.rightIconContainer
                    )}
                    onClick={unverify}
                >
                    <Close color="secondary" className={classes.closeIcon} />
                </div>
            )}
        </div>
    );
}
