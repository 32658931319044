export const prefix = process.env.NODE_ENV === "production" && process.env.REACT_APP_VERSION === "v2" ? "app" : "v2/app";
export const giftDappPrefix = prefix;
export const giftDappRouteNames = () => ({
    reward: `/${giftDappPrefix}`,
    rewardSetup: `/${giftDappPrefix}/setup`,
    rewardView: `/${giftDappPrefix}/dashboard/view/:id`,
    rewardView_: `/${giftDappPrefix}/dashboard/view/`,
    events: `/${giftDappPrefix}/events`,
    eventView: `/${giftDappPrefix}/events/v/:networkId/:id`,
    eventView_: `/${giftDappPrefix}/events/v/`,
    specialReward: `/${giftDappPrefix}/spreward`,
    buyGRP: `/${giftDappPrefix}/buy`,
    dashboard: `/${giftDappPrefix}/dashboard`,
    profile: `/${giftDappPrefix}/profile`,
    profileView: `/${giftDappPrefix}/profile/v/:id`,
    profileView_: `/${giftDappPrefix}/profile/v/`,
    nft: `/${giftDappPrefix}/nft`,
    nftView: `/${giftDappPrefix}/nft/:id`,
})

