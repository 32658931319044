import React from 'react'
import { Button, CircularProgress, makeStyles, withStyles } from '@material-ui/core'
import classNames from 'classnames';
import PropTypes from 'prop-types';
const style = ({colors, isDark}) => ({
    btn : {
        borderRadius : 5,
        color : colors.text,
    },
    small : {
        fontSize : 10,
        color : !isDark && colors.text,
    },
    custom : {
        boxShadow : "5px 5px 2px " + colors?.accent,
        padding : "10px 40px",
        border : "solid 1px " + colors?.accent,
        background : colors?.secondary,
        color : colors?.accent,
        textTransform : "capitalize",
        fontWeight : "bold",    
        "&:hover" : {
            background : colors?.backgroundAccent,
            color : colors.text,
        }    
    },
    primary : {
        textTransform : "capitalize",
        background : colors.primary,
        color : colors.accent,
        paddingLeft : 20,
        paddingRight : 20,
        padding : "10px 40px",
        "&:hover" : {
            background : colors?.backgroundAccent,
            color : colors.textSecondary,
        } 
    },
    secondary : {
        textTransform : "capitalize",
        background : !isDark ? colors?.background : colors.secondary,
        color : colors.accent,
        paddingLeft : 20,
        paddingRight : 20,
        padding : "10px 40px",
        "&:hover" : {
            background : !isDark ? colors?.background : colors.secondary,
            color : colors.accent,
        } 
    },
    accent : {
        textTransform : "capitalize",
        background : colors.accent,
        color : colors.text,
        paddingLeft : 20,
        paddingRight : 20,
        padding : "10px 40px",
        "&:hover" : {
            background : colors?.backgroundAccent,
            color : !isDark ? colors.text : colors.secondary,
        } 
    },
    background : {
        textTransform : "capitalize",
        background : colors.background,
        color : colors.textBackground,
        paddingLeft : 20,
        paddingRight : 20,
        padding : "10px 40px",
        border: "solid 1px " + colors?.customButtonBorder,
        "&:hover" : {
            background : colors?.background,
            color : colors.text,
        } 
    },
    noBoxShadow : {
        boxShadow:"none",
        "&:hover" : {
            boxShadow:"none"
        } 
    },
    danger : {
        textTransform : "capitalize",
        background : colors.backgroundDanger,
        color : colors.danger,
        paddingLeft : 20,
        paddingRight : 20,
        padding : "10px 40px",
        "&:hover" : {
            background : colors?.danger,
            color : colors.text,
        } 
    },
    fullWidth : {
        width : "100%",
    },
    progress : {
        width : "25px !important",
        height : "25px !important",
        color : "white",
        marginRight : 15
    }
})
function _CustomButton({classes, loading, children, type="custom", fullWidth, noBoxShadow, ...props}) {
    return <Button
        className={classNames(classes[type], fullWidth ? classes.fullWidth : "", noBoxShadow ? classes.noBoxShadow : "", classes.btn)}
        variant="contained"
        disabled={loading}
        {...props}
    >
        {loading && <CircularProgress className={classes.progress}/>} {children}
    </Button>
}
_CustomButton.propTypes = {
    type : PropTypes.oneOf(["primary", "secondary", "custom", "danger"]),
    small : PropTypes.bool,
}
export const CustomButton = withStyles(style)(_CustomButton);
export {CustomButton as default};