import React from 'react';
import { store } from '../../../helpers';
import { customSideDisplayActions, customSideDisplayStrings } from '../../../includes/CustomSideDisplay';
import walletUtil from '../../../utils/web3/walletUtil';
import GiftDappSideDisplayBox from './GiftDappSideDisplayBox/GiftDappSideDisplayBox';
const feedbackFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSfO1fOb9oXw0I3N1jIHkRTjRS85SU5cll0y7uFjml19RHcI8w/viewform?usp=sf_link",
tokenRequestFrom = "https://docs.google.com/forms/d/e/1FAIpQLSfgvvt92ENEzOYROxKJ8mtMLuX3EgLGNjvEpD7F4rOk0FhNYQ/viewform?usp=sf_link"
export * from './GiftDappTestTokenRequest';
export const GiftDappTestTokenRequestComponent = () => <GiftDappSideDisplayBox
    header="Test token request"
    subheader={<>
        Use the button to fill a test token request form<br/>
        Token (RGP & BUSD) will be sent to provided address within 6 hours.
    </>}
    btnText="Token Request Form"
    btnAction={() => window.open(tokenRequestFrom, "_blank")}
/>
export const GiftDappFeedbackComponent = () => <GiftDappSideDisplayBox
    header="Feedback"
    subheader={<>
        Give a user feedback on the operation of this <br/> decentralised application.                
    </>}
    btnText="Feedback Form"
    btnAction={() => window.open(feedbackFormUrl, "_blank")}
/>
export const displayFeedbackRequestSideView = () => !walletUtil.isMainNet() && customSideDisplayActions.payload({
    [customSideDisplayStrings.show] : true,
    [customSideDisplayStrings.component] : GiftDappFeedbackComponent
})(store.dispatch);
export const displayTokenRequestSideView = () => !walletUtil.isMainNet() && customSideDisplayActions.payload({
    [customSideDisplayStrings.show] : true,
    [customSideDisplayStrings.component] : GiftDappTestTokenRequestComponent
})(store.dispatch)