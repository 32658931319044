import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Dropzone from 'react-dropzone'
import { FormControlLabel, IconButton, ListItem, ListItemAvatar, ListItemText, makeStyles, Switch, TextField, useTheme, withStyles, withWidth } from '@material-ui/core'
import { CustomButton } from '../CustomButton'
import { ArrowDropDown, ArrowDropUp, Close, AccountCircle, HelpOutline } from '@material-ui/icons'
import { customPopoverActions, customPopoverString } from '../CustomPopover'
import { Fragment } from 'react'
import classNames from 'classnames';
const style = ({colors, isDark}) => ({
    root : {
    },
    input : {
        color : `${colors.textSecondary} !important`,
    },
    inputLabelClass : {
        color : `${colors.textSecondary} !important`,
    },
    notchedOutline : {
        border : `solid 1px ${colors.borderLines}`,
        borderRadius : 5,
        "&:hove" : {
            border : `solid 1px ${colors.textSecondary}`,
        },
        "&:focus" : {
            border : `solid 1px ${colors.textSecondary}`,
        }
    },
    marginDense : {
    },
    formField : {
        margin : 0,
        borderRadius : 5,
        // color : colors.textSecondary,
        // border : "solid 1px " + colors.textSecondary
    },
    inputFieldFocus : {
        // border : "solid 1px " + colors.textSecondary
    },
    switchTrack : {
        backgroundColor : "gray",
    },
    switchText : {
        color : colors.text,
    },
    switchSec : {
        color : "gray",
        "&.Mui-checked + .MuiSwitch-track" : {
            backgroundColor : `${colors.accent} !important`, 
        },
        "&.Mui-checked" : {
            color : `white !important`, 
        }
    },
    switchSecChecked : {
        color : colors.accent,
    },
    dropdownValue : {
        display : 'flex',
        justifyContent : "space-between",
        width : "100%",
        color : colors.text,
        fontSize : 12
    },
    button : {},
    listItem : {
        borderRadius : 0,
        "&:hover" : {
            background : colors.backgroundAccent
        }
    },
    list : {
        color : colors.textSecondary,
        padding : 10,
        minWidth : 100,
    },
    avatarRoot : {
        minWidth : 25
    },
    popover : {
        // width : "calc(100% - 120px)",
        background : !isDark ? colors.background : colors.secondary
    },
    description : {
        color : colors.textSecondary,
        padding : 10,
        fontSize : 10,
    },
    dropzoneContainer : {
        padding : 20,
        border : "dashed 1px " + colors.borderLines,
        display : 'flex',
        justifyContent : "center",
    },
    dropzoneRoot : {
        width : "80%",
        padding : 20, 
        border : `dotted 1px ${colors.borderLines}`,
        cursor:"pointer"
    },
    preview : {
        height : 100,
        width : 100,
    },
    imgContainer : {
        display : "flex",
        position : "relative",
        justifyContent : "center",
    },
    previewBtn : {
        position : "absolute",
        top : -10,
        right : -10,
        zIndex : 100,
        background : colors.secondary,
        "&:hover" : {
            background : colors.accent
        }
    },
    dropzone : {
        // padding : 20,
        // border : `dotted 1px ${colors.textSecondary}`
    },
    imageIcon : {
        fontSize : 70,
        color : colors.textSecondary,
        cursor : "pointer",
        // background : "black"
    },
    imageWrapper : {
        margin : 5,
        display : "flex",
        borderRadius : "100%",
        justifyContent : "center",
        alignItems : "center",
        width : 55,
        height : 55,
        padding : 0,
    },
    imageBtn : {
        border : `solid 1px ${colors.textSecondary}`,
        borderRadius : 5,
        background : !isDark ? colors.transparent: colors.secondary,
        color : colors.textSecondary,
    },
})
export const hintComponent = (field) => () => {
    const classes = makeStyles(({colors}) => ({
        hintComponent : {
            maxWidth : 200, 
            color : colors.text,
            background : colors.background,
            padding : 10,
            fontSize : 12,
        }, 
        hintHeader : {
            textDecoration : "underline",
            marginBottom : 5,
        },
        hintBody : {
            color : colors.textSecondary
        },
        listContainer : {
            marginBottom : 5,
        }
    }))();
    return <div className={classes.hintComponent}>
        {Array.isArray(field.hint) ?  
            (field.hint?.map((item, index) => <div className={classes.listContainer} key={index}>
                <div className={classes.hintHeader}>{item.description}</div>
                <div className={classes.hintBody}>
                    {item.hint}
                </div>
            </div>)) : <>
                <div className={classes.hintHeader}>{field.description}</div>
                <div className={classes.hintBody}>
                    {field.hint}
                </div>
            </>
        }
        </div>;
}
function _CustomInputFields({classes, width, field={}, ...props}) {
    const dispatch = useDispatch(),
    appTheme = useTheme(),
    [state, _setState] = useState({anchorEl : null}),
    {anchorEl} = state,
    fileUplaoder = useRef(),
    customListItem = onClick => ({item : {_id, avatar : Avatar, name}}) => {
        return <ListItem 
            classes={{
                button : classes.button
            }}
            className={classes.listItem} button onClick={()=>{
                onClick && onClick(_id);
            }}
        >
            {Avatar && <ListItemAvatar classes={{
                root : classes.avatarRoot
            }}>
                <Avatar/>
            </ListItemAvatar>}
            <ListItemText secondary={name} secondaryTypographyProps={{
                className : classes.list
            }}/>
        </ListItem>
    }
    switch(field.type){
        case "dropdown" :  
            let dropdownValue;
            if(field.value === 0 || field.value){
                const option = field.options?.find(item => item._id === field.value);
                dropdownValue = option?.name;
            }
            const {buttonProps, ..._a} = field;
            field = _a;
            return <div style={{position : "relative"}}>
                {field.description && <div className={classes.description}>
                    {field.description && <div style={{display : "flex", alignItems : "center"}}>
                        {field.description} 
                        {field.hint && <HelpOutline 
                            style={{marginLeft : 5, cursor : "pointer"}} 
                            fontSize="small"
                            onClick={({target : anchorEl})=>{
                                customPopoverActions.payload({
                                    [customPopoverString.anchorEl] : anchorEl,
                                    [customPopoverString.customComponent] : hintComponent(field),
                                    [customPopoverString.anchorOrigin] : {
                                        horizontal : "left",
                                    }
                                })(dispatch);
                            }}
                        />}
                    </div>}
                </div>}
                <CustomButton noBoxShadow fullWidth type="background" onClick={({target : anchorEl}) => {
                    while(String(anchorEl.tagName).toUpperCase() !== "BUTTON")
                        anchorEl = anchorEl.parentElement
                    customPopoverActions.payload({
                        [customPopoverString.anchorEl] :  anchorEl,
                        [customPopoverString.options] :  field.options,
                        [customPopoverString.customListItem] :  customListItem(field.onClick),
                        [customPopoverString.classes] :  { paper : classes.popover},
                        [customPopoverString.anchorOrigin] : {horizontal : "left"},
                    })(dispatch);
                }} {...buttonProps}>
                    <div className={classes.dropdownValue}>
                        <span>{dropdownValue || field.placeholder || field.label}</span>
                        {anchorEl ? <ArrowDropUp fontSize="small"/> : <ArrowDropDown fontSize="small"/>}
                    </div>
                </CustomButton>
            </div>
        case "switch" : 
            const {onRemove : _, ..._field} = field;
            field = _field;
            return <FormControlLabel
            label={<span className={classes.switchText}>{field.label}</span>}
            control={<Switch
                classes={{
                    track : classes.switchTrack,
                    colorSecondary : classes.switchSec,
                }}
            />}
            {...field}
        />;
        case "dropzone" :        
            const {value : zoneValue, description, onChange, onRemove, mobileView, button} = field;
            return zoneValue?.data ? <div className={classNames(classes.formField, classes.imgContainer)}>
                    <IconButton onClick={onRemove} className={classes.previewBtn}>
                        <Close fontSize="small"/>
                    </IconButton>
                    <img src={zoneValue?.data} className={classes.preview}/>
                </div> : 
                (width === "xs" || mobileView ? <div style={{display : "flex", alignItems : "center"}}>
                    <div className={classes.imageWrapper}>
                        <AccountCircle onClick={() => fileUplaoder.current.click()} className={classes.imageIcon}/>
                    </div>
                    <CustomButton
                        type="secondary"
                        onClick={() => fileUplaoder.current.click()}
                        classes={{
                            btn : classes.imageBtn
                        }}
                    >
                        {button?.name || "Add Image"}
                    </CustomButton> 
                    {/* <label style={{marginLeft : 10}}>{field?.value?.name}</label> */}
                    <input 
                        style={{display : "none"}} 
                        type="file" 
                        ref={fileUplaoder}
                        accept="image/png,image/jpg,image/jpeg,image/gif" 
                        onChange={({target : {files}})=> onChange(files)}
                    />
                </div> : <Dropzone 
                    accept="image/png,image/jpg,image/jpeg,image/gif"  
                    onDrop={acceptedFiles => onChange ? onChange(acceptedFiles) : null}>
                    {({getRootProps, getInputProps}) => (
                        <div className={classNames(classes.formField, classes.dropzoneContainer)}>
                            <div className={classes.dropzoneRoot} {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p className={classes.dropzone}>{description || "Drag 'n' drop some files here, or click to select files"}</p>
                            </div>
                        </div>
                    )}
                </Dropzone>)
        case "file" : 
            const {value, onRemove : __, ...otherFields} = field || {};
            return <Fragment>
            <CustomButton
                type="primary"
                onClick={() => fileUplaoder.current.click()}
                {...field?.button}
            >
                    Upload Image
                </CustomButton> 
                <label style={{marginLeft : 10}}>{field?.value?.name}</label>
                <input 
                    style={{display : "none"}} 
                    type="file" 
                    accept="image/png,image/jpg,image/jpeg,image/gif" 
                    ref={fileUplaoder} 
                    {...otherFields}
                />
        </Fragment>;
        default : 
            const {onRemove : ___, ...__field} = field;
            field = __field;
            return <TextField
                InputLabelProps={{
                    // style : {color : colors.textSecondary},
                    className : classes.inputLabelClass,
                    focused : classes.formField,
                }}
                InputProps={{
                    classes : {
                        input : classes.input,
                        notchedOutline : classes.notchedOutline,
                        marginDense : classes.marginDense,
                        // focused : classes.inputFieldFocus,
                        // inputMarginDense : classes.notchedOutline,
                        ...props.InputProps?.classes
                    }
                }}
                className={classes.formField}
                fullWidth
                variant="outlined"
                placeholder={field.type !== "file" && field.label}
                {...field}
                label={field.type === "file" ? "" : field.label}
            />
    }
}
_CustomInputFields.propTypes = {
    
}
export const CustomInputFields = withStyles(style)(withWidth()(_CustomInputFields));
export {CustomInputFields as default};