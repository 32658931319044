export const backButtonStrings = {
    action : "action",
    text : "text",
    visible : "visible",
}
export const backButtonConstants = {
    SET_BACK_BUTTON : "SET_BACK_BUTTON"
}
export const backButtonReducer = (state={}, action) => {
    const {type, payload} = action;
    switch(type){
        case backButtonConstants.SET_BACK_BUTTON : 
            return {...state, ...payload};
        default : return state;
    }
}
export const BACK_BUTTON_REDUCER = "backButtonReducer";