import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import classNames from 'classnames';

const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        display: "flex",
        justifyContent: "space-between",
        margin: ".8rem 0",
        "&:hover": {
            opacity: .8,
            cursor: "pointer"
        }
    },
    nameAndAvatar: {
        marginTop: 8,
        display: "flex",
        alignItems: "center",
    },
    avatar: {
        width: 60,
        height: 60,
        marginRight: 20,
    },
    name: {
        cursor: "pointer",
    },
    title: {
        color: colors.musicDialogText,
        fontWeight: 700
    },
    artist: {
        color: colors.musicDialogTextSecondary,
        marginTop: 10,
    },
    duration: {
        color: colors.musicDialogText,
    },
    currentTrack: {
        color: colors.accent
    }
    
}))
export function CustomMusicTrack ({musicSrc, avatarSrc, title, artist, onSelect, current, getTrackDuration}) {
    
    const classes = style();

    const [duration, setDuration] = useState("0:0")

    useEffect(() => {
        getTrackDuration(musicSrc, duration => {
            const minutes = String(Math.floor(duration/60));
            const seconds = String(Math.round(duration % 60));
            setDuration(minutes + ":" + seconds);
        });
    }, [musicSrc])

    return (
        <div className={classes.root} onClick={onSelect}>
            <div className={classes.nameAndAvatar}>
                <Avatar alt="music track avatar" src={avatarSrc} className = {classes.avatar}/>
                <div className={classes.name}>
                    <div className= {classNames(classes.title, {[classes.currentTrack]: current})}>{title}</div>
                    <div className= {classNames(classes.artist, {[classes.currentTrack]: current})}>{artist}</div>
                </div>
            </div>
            <div className={classNames(classes.duration, {[classes.currentTrack]: current})}>{duration}</div>
        </div>
    );
}