/**
 * using host type to differentiate db domain
 */
const liveLink = process.env.REACT_APP_HOSTTYPE === "test" ?
"https://gifttest.rigelprotocol.com" : "https://gift.rigelprotocol.com";

const appValues_production = {
    // gql_entery : "", //process.env.NODE_ENV === "production" ? "/graphql" : "http://localhost:8000/graphql",
    ///delete when hosting on main net
    gql_entery : `${liveLink}/graphql`,
    appDomain : liveLink,
    appFrontend : liveLink,
}
const appValues_development = {
    gql_entery : "http://localhost:8001/graphql",
    appDomain : "http://localhost:8001",
    appFrontend : "http://localhost:3000"
}
export const NETWORK_EVNIRONMENT_NAME = "REACT_APP_NETWORK";
export const NETWORK_EVNIRONMENT_VALUES = {
    LOCAL : "local",
    ONLINE : "online"
};
//REMOVE || process.env.REACT_APP_HOSTTYPE to go back to default
const values = process.env.NODE_ENV === "production" || process.env.REACT_APP_HOSTTYPE ? 
    appValues_production : appValues_development;
export const appValues = {
    rgpAddress : "",
    busc_rgp_sales : "",
    usdt_rgp_sales : "",
    usdtAddress : "",
    buscAddress : "",
    v1Url : "https://gift.rigelprotocol.com",
    v2Url : "https://giftdapp.rigelprotocol.com",
    ...values
};
export const listedTokenLabels = {
    busc : "busc",
    usdt : "usdt",
    rgp : "rgp",
    trx : "trx",
}
export const contractLabels = {
    gift : "gift",
    gift_project : "gift_project",
    gift_influencer : "gift_influencer",
    busc : "busc",
    usdt : "usdt",
    rgp : "rgp",
    trx : "trx",
}
export const rinkebyContracts = {
    [contractLabels.gift] : ""
}
const rinkebyTokens = {
    [listedTokenLabels.busc] : "0x1bbd87605fc6f997e00befe8f01da66a8fc86649",
    [listedTokenLabels.usdt] : "0x01547ef97f9140dbdf5ae50f06b77337b95cf4bb",
    [listedTokenLabels.rgp] : "0x78b063d9be87d4b499a06927d7fb4a46e0651f0b",
    [listedTokenLabels.trx] : "0x38f56eb66c2afffc8474920ff666052998f36765",
},
mainNetTokens = {
    [listedTokenLabels.rgp] : "0x80278a0cf536e568a76425b67fb3931dca21535c",
    [listedTokenLabels.usdt] : "0x66f56b882364b2e36e837a1acf964104ca19124f",
    [listedTokenLabels.busc] : "0xd848ed7f625165d7ffa9e3b3b0661d6074902fd4",
}
export const listedTokenAddresses = process.env.NODE_ENV === "development" ? rinkebyTokens : mainNetTokens;