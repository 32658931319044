import React, {useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faReddit, faInstagram, faMedium } from '@fortawesome/free-brands-svg-icons';
import {FacebookShareButton, TwitterShareButton, RedditShareButton} from "react-share";
import {history} from "../../helpers"
import {giftDappRouteNames} from "../../utils"
import Confetti from 'react-confetti'

const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        color : colors.text,
        position : "relative",
        [breakpoints.only("xs")] : {
            minWidth : 250,
            padding: 24,
        },
        minWidth : 350,
        padding: 48,
        overflow: "hidden",
    },
    overlay: {
        color: colors.text,
        position: "absolute",
        top: 24,
        right: 24,
        border: "1px solid " + colors.text,
        borderRadius: 6,
        padding: 2,
    },
    icon : {
        color: colors.text,
    },
    content: {
    },
    header: {
        textAlign: "center",
        color: colors.accent,
        fontSize: 24,
        marginTop: 24,
        
    },
    details: {
        textAlign: "center",
        
    },
    detailsHeading: {
        color: colors.text,
        textAlign: "center",
        letterSpacing: 2,
    },
    detailsText: {
        fontWeight: 100,
        opacity: .7,
    },
    viewCardsBtn: {
        background: colors.accent,
        color: colors.text,
        display: "block",
        width: "100%",
        padding: 12,
        borderRadius: 8,
        border: 0,
        outline: 0,
        margin: "48px 0",
        cursor: "pointer",
    },
    badgeContainer: {
        width: 120,
        margin: "16px auto",
        marginBottom: 24,
    },
    badge: {
        width: "100%",
    },
    socials: {

    },
    socialsText: {
        textDecoration: "underline",
        fontWeight: 600,
        display: "block",
        marginBottom: 16,
    },
    links: {
        display: "flex",
        justifyContent: "space-between",
    },
    link: {
        backgroundColor: colors.secondary,
        color: colors.text,
        padding: 16,
        borderRadius: "50%",
        marginBottom: 8,
    },
    socialBlock: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    socialIcon: {
        fontSize: 16,
    },
    socialName: {
        fontSize: 14,
        [breakpoints.only("xs")] : {
            display: "none",
        },
    }
   
}))


function CustomWinSpinModal({onClose, value, isRewardPad, badgeType, eventLink}) {

    const classes = style();

    const modalContainer = useRef(null)

    const [modalDimension, setModalDimension] = useState({
        width: null,
        height: null
    })
    const screenResizeEvent = () => {
        setModalDimension({
            width: modalContainer.current.offsetWidth,
            height: modalContainer.current.offsetHeight
        });
    }

    useEffect(() => {
        screenResizeEvent(); //run it once manually when the page load, the event listener handles the updating
        window.addEventListener('resize', screenResizeEvent);
        return () => {
            window.removeEventListener('resize', screenResizeEvent);
        }
    }, [])

    const BADGE_TYPES = {
        NORMAL : "NORMAL",
        CHRISTMAS : "CHRISTMAS",
    }

    const getBadgeSrc = (type) => {
        switch (type) {
            case BADGE_TYPES.NORMAL:
                return "/assets/rgp-badge.png";
            case BADGE_TYPES.CHRISTMAS: 
                return "/assets/rgp-badge-christmas.png";
            default:
                return "/assets/rgp-badge.png";
        }
    }
    return (
        <div className={classes.root} ref = {modalContainer}>
            <Confetti 
                width = {modalDimension.width}
                height = {modalDimension.height}
                numberOfPieces = {50}
                tweenDuration = {20}
            />
            <IconButton className={classes.overlay} onClick={onClose}>
                <Close className={classes.icon}/>
            </IconButton>
            <div className={classes.content}>
                <h2 className={classes.header}>
                    {value}
                </h2>
                <div className={classes.details}>
                    <h3 className={classes.detailsHeading}>
                        Congratulations!!!
                    </h3>
                    <p className={classes.detailsText}>
                        You’ve won {value} from this round of spins, You can share to your friends on your social media pages.
                    </p>
                </div>
                {isRewardPad ? 
                    <div className={classes.badgeContainer}>
                        <img src = {getBadgeSrc(badgeType)} alt = "win spin badge" className={classes.badge} />
                    </div> :
                    <button className={classes.viewCardsBtn} onClick={() => history.push(giftDappRouteNames().specialReward)}>
                        View Reward Cards
                    </button>
                }
                <div className = {classes.socials}>
                    <span className={classes.socialsText}>Share with your friends:</span>
                    <div className= {classes.links}>
                        <div className={classes.socialBlock}>
                            <div className={classes.link}>
                                <FacebookShareButton 
                                        url={isRewardPad ? eventLink : (window.location.href.startsWith("http://localhost") ? "https://giftdapp.rigelprotocol.com/events" : window.location.href)}
                                        quote={`I just won ${value} from this round of spins on GiftDapp.`}
                                        hashtag="#GiftDApp"
                                    >
                                    <FontAwesomeIcon icon = {faFacebook} className={classes.socialIcon} />
                                </FacebookShareButton>
                            </div>
                            <span className = {classes.socialName}>Facebook</span>
                        </div>

                        <div className={classes.socialBlock}>
                            <div className={classes.link}>
                                <TwitterShareButton
                                        url={isRewardPad ? eventLink : (window.location.href.startsWith("http://localhost") ? "https://giftdapp.rigelprotocol.com/events" : window.location.href)}
                                        title = {`I just won ${value} from this round of spins on GiftDapp.`}
                                        hashtags= {["GiftDApp", "rigelprotocol", "GiftDAppRigelprotocol"]}
                                        related = {["rigelprotocol"]}
                                    >
                                        <FontAwesomeIcon icon = {faTwitter} className={classes.socialIcon} />
                                </TwitterShareButton>
                            </div>
                            <span className = {classes.socialName}>Twitter</span>
                        </div>
                            
                        <div className={classes.socialBlock}>
                            <div className={classes.link}>
                                <RedditShareButton
                                    url={isRewardPad ? eventLink : (window.location.href.startsWith("http://localhost") ? "https://giftdapp.rigelprotocol.com/events" : window.location.href)}
                                    title = {`I just won ${value} from this round of spins on GiftDapp.`}
                                >
                                    <FontAwesomeIcon icon = {faReddit} className={classes.socialIcon} />
                                </RedditShareButton>
                            </div>
                            <span className = {classes.socialName}>Reddit</span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default CustomWinSpinModal;
export {CustomWinSpinModal};