import React, { useState } from 'react'
import classNames from 'classnames';
import { makeStyles, withWidth } from '@material-ui/core';
import { DASHBOARD_NAV_TABS } from '../../..';
import { CustomButton, customPopoverActions, customPopoverString } from '../../../../includes';
import { DASHBOARD_NAV_PROJECT_TYPES } from '../..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import CustomFilter from '../../../../includes/CustomFilter/CustomFilter';
import { useDispatch } from 'react-redux';
import useGAEventsTracker from './../../../../hooks/useGAEventsTracker';

const style = makeStyles(({colors, isDark, breakpoints}) => ({
    root : {
        display : "flex",
        alignItems : "center",
        fontSize : 10,
        justifyContent : "space-between",
        [breakpoints.down("xs")] : {
            flexDirection : "column",
        }
    },
    navContainer : {
        display : "flex",
        [breakpoints.down("xs")] : {
            justifyContent : "space-between",
            width : "100%",
        }
    },
    nav : {
        cursor : "pointer",
        display : 'flex',
        alignItems : "center",
        justifyContent : "space-between",
    },
    btnContainer : {
        display : "flex",
        [breakpoints.down("xs")] : {
            width : "100%",
            marginTop : 20,
        }
    },
    btn : {
        margin : 5,
        borderRadius : 5,
        padding : "5px 30px",
        fontSize : 10,
        color : colors.text,
        background : colors.secondary,
    },
    toggleBtn : {
        margin : 5,
        borderRadius : 5,
        padding : "5px 20px",
        fontSize : 10,
        background: isDark ? colors.pageBackground : colors.transparent,
        border: `1px solid ${isDark ? colors.secondary : colors.navBarLinks}`,
        color: isDark ? colors.text : colors.tertiaryTextColor
    },
    icon : {
        marginRight : 3,
    },
    btnActive : {
        border : "solid 1px " + colors.accent,
    },
    active : {
        background : !isDark ? colors.accent : colors.background,
        color : !isDark ? colors.background : colors.text,
        borderRadius : 20,
        // color : colors.accent,
        // fontWeight : "bold",
        minWidth: 90,
        justifyContent: "space-between",
        display: "flex",
    },
    inactive : {
        background : colors.inactive,
        color : colors.textSecondary,
        "&:hover" : {
            color : colors.textSecondary,
            background : colors.inactive
        }
    },
    title : {
        paddingLeft : 10,
    },
    badge : {
        background : !isDark ? colors.accent : colors.secondary,
        borderRadius : 20,
        padding : 5,
        margin : 5,
        height : 25,
        width : 25,
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
    },
    badgeActive : {
        background : !isDark ? colors.background : colors.secondary,
        color : colors.text
    },
    dialogPaper : {
        background : colors.selectedCheckBoxColor,
        border : "solid 1px " + colors.accentSecondary,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
}

))
function GiftDappDashboardNav({
    rewardCounts,
    activeTab,
    projectTypes = [],
    toggleProjectType,
    setTab,
    width
}) {
    const classes = style(),
    dispatch = useDispatch(),
    {active : activeCount, ended : endedCount} = rewardCounts || {},
    allCount  = (activeCount || 0) + (endedCount || 0);
    const GAEventsTracker = useGAEventsTracker();

    return (
        <div className={classes.root}>
            <div className={classes.navContainer}>
                <div
                    onClick={() => { setTab && setTab(DASHBOARD_NAV_TABS[0]._id); GAEventsTracker(`switch_to_${DASHBOARD_NAV_TABS[0]._id}_tab`, "event page"); }}
                    className={classNames(
                        classes.nav,
                        activeTab === DASHBOARD_NAV_TABS[0]._id ? classes.active : ""
                    )}>
                        <div className={classes.title}>{DASHBOARD_NAV_TABS[0].label}</div>
                        <div className={classNames(classes.badge, activeTab === DASHBOARD_NAV_TABS[0]._id ? classes.badgeActive : "")}>{allCount > 9 ? "9+" : allCount}</div>
                    </div>
                <div
                    onClick={() => { setTab && setTab(DASHBOARD_NAV_TABS[1]._id); GAEventsTracker(`switch_to_${DASHBOARD_NAV_TABS[1]._id}_tab`, "reward page"); }}
                    className={classNames(
                        classes.nav,
                        activeTab === DASHBOARD_NAV_TABS[1]._id ? classes.active : ""
                    )}>
                        <div className={classes.title}>{DASHBOARD_NAV_TABS[1].label}</div>
                        <div className={classNames(
                            classes.badge,
                            activeTab === DASHBOARD_NAV_TABS[1]._id ?
                                classes.badgeActive : ""
                        )}>{activeCount > 9 ? "9+" : (activeCount || 0)}</div>
                    </div>
                <div
                    onClick={() => { setTab && setTab(DASHBOARD_NAV_TABS[2]._id); GAEventsTracker(`switch_to_${DASHBOARD_NAV_TABS[2]._id}_tab`, "reward page"); }}
                    className={classNames(
                        classes.nav,
                        activeTab === DASHBOARD_NAV_TABS[2]._id ? classes.active : ""
                    )}>
                        <div className={classes.title}>{DASHBOARD_NAV_TABS[2].label}</div>
                        <div className={classNames(classes.badge, activeTab === DASHBOARD_NAV_TABS[2]._id ? classes.badgeActive : "")}>{endedCount > 9 ? "9+" : (endedCount || 0)}</div>
                    </div>
            </div>
            <div className={classes.btnContainer}>
                <CustomButton
                    classes={{
                        btn : classNames(
                            classes.toggleBtn,
                        )
                    }}
                    noBoxShadow
                    fullWidth={width === "xs" ? true :false }
                    onClick={({target}) => {
                        customPopoverActions.payload({
                            [customPopoverString.anchorEl] : target,
                            [customPopoverString.customComponent] : () =>
                                <CustomFilter
                                    list={DASHBOARD_NAV_PROJECT_TYPES}
                                    onSelect={props => {
                                        toggleProjectType(props)
                                        customPopoverActions.payload({
                                            [customPopoverString.anchorEl] : null,
                                            [customPopoverString.customComponent] : null
                                        })(dispatch);
                                    }}
                                    selectedList={projectTypes}
                                />
                        })(dispatch);

                        GAEventsTracker("click_on_dashboardNav_filter", "dashboard page");
                    }}
                    type="primary"
                >
                    <FontAwesomeIcon icon={faFilter} size="2x" className={classes.icon} /> Filter
                </CustomButton>
            </div>
        </div>
    )
}
GiftDappDashboardNav.propTypes = {

}
export default withWidth()(GiftDappDashboardNav);
export {GiftDappDashboardNav};
