import React from 'react'
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core'
import { Route } from 'react-router';
import LandingHeader from './LandingStructure/LandingHeader/LandingHeader';
import LandingFooter from './LandingStructure/LandingFooter/LandingFooter';
const style = makeStyles(({breakpoints, colors}) => ({
    // customizing the scrollbar
    '@global': {
        '*::-webkit-scrollbar': {
            // width: '0.4rem'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px ' + colors.background
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: colors.borderLines,
            outline: '1px solid ' + colors.textSecondary
        }
    },
    root : {
      
    },
    display : {
    },
    appPager : {
        // margin : "0px 20px",
        minHeight : 300,
    },
    stage : {
        // marginTop : 70,
        // marginBottom : 50,
        minHeight : 200,
        [breakpoints.down("sm")] : {
            // padding : "20px 10px"
        },
        [breakpoints.up("md")] : {
            // padding : "20px 50px"
        },
        [breakpoints.up("lg")] : {
            // padding : "20px 80px"
        },
        [breakpoints.up("xl")] : {
            // padding : "20px 100px"
        }
    }
}))
function LandingLayout({component : Component, ...props}) {
    const classes = style();
    return (
        <Route {...props} className={classes.root} component={routeProps=>{
            return <div className={classes.display}>
                <LandingHeader
                    classes={{
                        container : classes.appPager
                    }}
                />
                <div className={classNames(classes.appPager, classes.stage)}>
                    <Component {...routeProps} />
                </div>
                <LandingFooter/>
            </div>
        }}/>
    )
}
LandingLayout.propTypes = {
    
}
export default LandingLayout;
export {LandingLayout};