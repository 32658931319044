import React, { useEffect, useState, useRef } from 'react';

const createPopup = ({
  url, title, height, width,
}) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const externalPopup = window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`,
  );
  return externalPopup;
};

export const CustomOauthPopup = ({
  title = '',
  width = 500,
  height = 500,
  url,
  children,
  onResponse
}) => {
  const [externalWindow, setExternalWindow] = useState();
  const intervalRef = useRef();

  const clearTimer = () => {
    window.clearInterval(intervalRef.current);
  };

  const ClickHandler = () => {
    setExternalWindow(createPopup({
      url, title, width, height,
    }));
  };

  const getParamsObj = (url) => {
    if (!url) return null;
    const paramsObj = {};
    // for handling both code and access_token
    const paramString = url.split("?")[1] || url.split("#")[1];
    const paramsArr = paramString.split("&");
    paramsArr.forEach(param => {
        const keyVal = param.split("=")
        paramsObj[keyVal[0]] = keyVal[1]
    });

    return paramsObj;
}

  useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        try {
          const currentUrl = externalWindow.location.href;
          const paramsObj = getParamsObj(currentUrl);
          if (paramsObj.code || paramsObj.access_token) {
            onResponse("success", paramsObj);
            clearTimer();
          } else {
            onResponse("failed", paramsObj);
            clearTimer();
          }

          externalWindow.close();
          
        } catch (error) {
          // eslint-ignore-line
        } finally {
          if (!externalWindow || externalWindow.closed) {
                clearTimer();
          }
        }
      }, 700);
    }
    return () => {
      if (externalWindow) externalWindow.close();
    };
  }, [externalWindow]);

  return (
    // eslint-disable-next-line
    <div
      onClick={() => {
        ClickHandler();
      }}
    >
      { children }
    </div>
  );
};