export const GIFT_DAPP_LOCAL_STORAGE_VAR = {
    THEME_TYPE : "giftDappV1ThemeType",
}
export const GIFT_DAPP_THEMES = {
    DARK : "dark", LIGHT : "light"
}
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const GIFT_DASHBOARD_VIEW_BREAKPOINTS = ["xs"];
export const ROUTE_DISPLAY_STRINGS = {
    DASHBOARD : "Dashboard",
    REWARD : "Reward",
    EVENTS : "Events",
    SETUP_STRINGS : {
        PROFILE : "Profile",
        DETAILS : "Details",
        SOCIAL : "Social",
        SETUP : "Setup",
    },
    DASHBOARD_STRINGS : {
        REWARD_VIEW : "Reward View",
        REWARD_SLOTS : "Slots"
    }
}
export const DATE_TIME = {
    SECONDS_IN_AN_HOUR : 3600
}
export const STORAGE_VARIABLE = {
    NETWORK_ID : "giftDappV1NetworkId",
    NETWORK_TYPE : "giftDappV1NetworkType",
    NETWORK_WALLET : "giftDappV1NetworkWallet",
    PROFILE_LASTUSED : "giftDappV1ProfileLastUsed",
}
export const SMART_SWAP_URL = "https://smartswap.rigelprotocol.com"
export const PROCESS_DISPLAY_STAGES = {
    INCOMPLETE : 1,
    PROCESSING : 2,
    COMPLETE : 3,
}
export const REWARD_SETUP_PROFILE_OPTION_NAME = {
    LAST_PROFILE : "lastProfile",
    ANOTHER_PROFILE : "anotherProfile",
    NEW_PROFILE : "newProfile",
}