import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles,createMuiTheme } from '@material-ui/core'
import styles from "./buy.module.css"
const style = makeStyles(theme => ({
    root : {
        minHeight : 400,
        // display : 'flex',
        // alignItems : "center",
        // justifyContent : "center",
        background: "#120136",
        padding:15,
        borderRadius: 6,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.secondary.main,
          },
          [theme.breakpoints.up('md')]: {
            // backgroundColor: theme.palette.primary.main,
          },
          [theme.breakpoints.up('lg')]: {
            width: 800,
            margin:"30px auto",
          },
    },
    // heading:{
    //     color: '#fff',
    //     textAlign: 'center',
    //     fontFamily: 'san-serif',
    //     backgroundColor: 'red'
    // }
}))

const theme = createMuiTheme({
    typography: {
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
  });
function GiftDappBuyGRP(props) {
    const dispatch = useDispatch(),
    classes = style();
    return (
        <div className={classes.root}>
            {/* <Typ */}
            {/* <Typography variant="h3">Responsive h3</Typography> */}
            <h4 className={styles.center}>Get RGP tokens to access our Dapps</h4>
            <div className={styles.container}>
              <div className={styles.box}>
                <p className={styles.centerRight}>Balance: 404.25</p>
                <div className={styles.container}>
                  <h4>0.0</h4>
                  <select className={styles.input}>
                    <option>Select token</option>
                  </select>
                </div>
              </div>
              <div className={styles.box}>
              <p className={styles.centerRight}>Balance: 404.25</p>
                <div className={styles.container}>
                  <h4>0.0</h4>
                  <select className={styles.input}>
                    <option>Select token</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={styles.center}>
              <div className={styles.center}>
                <p>Current price: 2 USDT per  RGP</p>
              </div>
              <button className={styles.buyButton}>
                Buy
              </button>
            </div>
        </div>
    )
}
GiftDappBuyGRP.propTypes = {
    
}
export default GiftDappBuyGRP;
export {GiftDappBuyGRP};