import { CircularProgress, Grid, makeStyles, withStyles } from '@material-ui/core'
import {Done, DoneAll} from '@material-ui/icons'
import React from 'react'
import classNames from 'classnames';
import { Fragment } from 'react';
import { PROCESS_DISPLAY_STAGES } from '../../../utils';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        
    },
    left : {
        display : "flex",
        alignItems : "center",
        flexDirection : "column",
    },
    line : {
        width : 1, 
        height : 50, 
        background : "white"
    },
    _header : {
        fontSize : 12,
        lineHeight : 3,
        fontWeight : "bold",
    },
    _subheader : {
        lineHeight : 1,
        fontSize : 10,
        color : colors.textSecondary,
    },
    iconContainer : {
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        borderRadius : 100,
        width : 40,
        height : 40,
        marginBottom : 5,
        background : colors.secondary,
    }
})),
StageIconDisplay = withStyles(({colors}) => ({
    icon : {
        width : 25,
        height : 25,
        color : colors.primary
    },
    incomplete : {
    },
    complete : {
        color : colors.accent,
    },
    processing : {
    },
}))(({classes, stage}) => {
    switch(stage) {
        case PROCESS_DISPLAY_STAGES.COMPLETE : 
            return <DoneAll className={classNames(classes.icon, classes.complete)}/>
        case PROCESS_DISPLAY_STAGES.INCOMPLETE : 
            return <Done className={classNames(classes.icon, classes.incomplete)}/>
        default : return <CircularProgress className={classNames(classes.icon, classes.processing)}/>
    }
});
export default React.memo(function CustomProcessDisplayBox ({header, subheader, stage, end}) {
    const classes = style();
    return <Fragment>
        <Grid className={classes.left} item xs={3} sm={3} md={3} lg={3} xl={3}>
            <div className={classNames(classes.iconContainer)}>
                <StageIconDisplay stage={stage}/>
            </div>
            {!end && <div className={classes.line}/>}
        </Grid>
        <Grid className={classes.right} item xs={9} sm={9} md={9} lg={9} xl={9}>
            <div className={classes._header}>{header}</div>
            <div className={classes._subheader}>{subheader}</div>
        </Grid>
    </Fragment>
})