import React, { Fragment, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core'
import { getContractInstance } from '../../../../../../utils/web3/getContract'
import { walletStrings, WALLET_REDUCER_NAME } from '../../../../../structures'
import { tokenContractActions } from '../../../../../../utils'
import GiftDappTokenSearchAddRmoveAction from '../GiftDappTokenSearchAddRmoveAction/GiftDappTokenSearchAddRmoveAction'
import GiftDappWalletTokenIcon from '../../../../../structures/GiftDappWallets/GiftDappWalletTokenIcon/GiftDappWalletTokenIcon'
import { giftRewardActions } from '../../../../..'
import { giftRewardStrings } from '../../../../config'
import useGAEventsTracker from './../../../../../../hooks/useGAEventsTracker';

const style = makeStyles(({colors, isDark}) => ({
    root : {
        color : !isDark && colors.text
    },
    marginTop : {
        marginTop : 20,
    },
    classeNameSort : {
        display : 'flex',
        justifyContent : "space-between",
        color : colors.text,
        marginTop : 20,
    },
    listItem : {
        marginLeft : "0px !important",
    },
    gutters : {
        paddingLeft : 0
    },
    donwIcon : {
        color : colors.text,
    },
    avatar : {
        minWidth : "auto",
        margin : 5
    },
    label : {
        color : !isDark && colors.text
    },
    tokenImage : {
        width : 15,
        height : 15,
    },
    progress : {
        width : "20px !important",
        height : "20px !important",
        color : colors.accent
    },
    progressContainer : {
        background : colors.background,
    }
}))
function GiftDappTokenSearchAddTokenBox({
    address, onClose, balance : _bal, symbol : _sym, decimals : _deci
}) {
    // const {icon : Icon, address : address} = item || {};
    const GAEventsTracker = useGAEventsTracker();
    const classes = style(),
    dispatch = useDispatch(),
    [state, _setState] = useState({
        balance : _bal || 0, name : "",
        symbol : _sym || "", decimals : _deci || 18
    }),
    {balance, symbol, decimals} = state,
    setState = _state => _setState(state=>({...state, ..._state})),
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.web3] : web3,
    } = walletReducer;
    useEffect(()=>{
        (async () => {
            try{
                if(address && !symbol) {
                    const contractInstance = await getContractInstance(web3,null,address),
                    tokenAction = tokenContractActions(contractInstance);
                    const [balance, name, decimals, symbol] = await
                        Promise.all([
                            tokenAction.myBalance(),
                            tokenAction.name(),
                            tokenAction.decimals(),
                            tokenAction.symbol()
                        ]);
                    giftRewardActions.addToSelectedToken({
                        address, symbol,
                    })(dispatch);
                    setState({balance, name, symbol, decimals});
                }else if (symbol){
                    giftRewardActions.addToSelectedToken({
                        address, symbol,
                    })(dispatch);
                    GAEventsTracker(`added_${symbol}_as_reward_token`, "event page");
                }
            }catch(error){
                console.error(error);
            }
        })();
    }, [address, symbol]);
    return (
        <ListItem classes={{
            root : classes.listItem,
            gutters : classes.gutters
        }}>
            {!symbol ?  <div className={classes.progressContainer}>
                <CircularProgress className={classes.progress}/>
            </div>: <>
                <ListItemAvatar
                    classes={{
                        root : classes.avatar,
                    }}
                >
                    <GiftDappWalletTokenIcon
                        display
                        symbol={symbol}
                    />
                </ListItemAvatar>
                <ListItemText primary={<Fragment>
                    {symbol}
                    <GiftDappTokenSearchAddRmoveAction
                        token={{label : symbol, address, balance}}
                        actionCallback={onClose}
                    />
                </Fragment>}
                primaryTypographyProps={{
                    style: {display : "flex", alignItems : "center"},
                    className : classes.label
                }}/>
                <ListItemSecondaryAction>
                <div className={classes.root}>
                    {Number(balance < 0  ? Number(balance).toPrecision(decimals) : balance).toLocaleString()}
                </div>
                </ListItemSecondaryAction>
            </>}
        </ListItem>
    )
}
GiftDappTokenSearchAddTokenBox.propTypes = {
    address : PropTypes.string,
}
export default GiftDappTokenSearchAddTokenBox;
export {GiftDappTokenSearchAddTokenBox};
