import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { List, ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faFacebook, faTwitter, faTelegram, faMedium, faGithub, faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import useGAEventsTracker from './../../../../../hooks/useGAEventsTracker';

const style = makeStyles(theme => ({
    root : {
        color : theme.colors.text
    },
    listItem : {
        borderRadius : 0,
        "&:hover" : {
            background : theme.colors.backgroundAccent
        }
    },
    list : {
        color : theme.colors.textSecondary,
        padding : 10,
        minWidth : 100,
    },
    avatarRoot : {
        minWidth : 25
    },
    link : {
        textDecoration : "none",
        color : theme.colors.text
    },
    profile : {
        color : theme.colors.accent,
    }
}))
function GiftDappMedias({onSelect, props}) {
    const dispatch = useDispatch(),
    classes = style(),
    getIcon = name => {
        switch(name){
            case "telegram" : return faTelegram
            case "twitter" : return faTwitter
            case "linkedin" : return faLinkedin
            case "github" : return faGithub
            case "facebook" : return faFacebook
            case "medium" : return faMedium
        }
    };
    const GAEventsTracker = useGAEventsTracker();

    return (
        <div className={classes.root}>
            <List>
                {[
                    {_id : "telegram", name : "Telegram", link : "https://www.t.me/rigelprotocol"},
                    {_id : "twitter", name : "Twitter", link : "https://twitter.com/rigelprotocol"},
                    {_id : "linkedin", name : "LinkedIn", link : "https://www.linkedin.com/company/rigelprotocol"},
                    {_id : "github", name : "Github", link : "https://github.com/rigelprotocol"},
                    {_id : "medium", name : "Medium", link : "https://medium.com/rigelprotocol"},
                ].map((social, index) => {
                    return     <a href={social.link} key={index} className={classes.link} target="_blank" rel="noreferrer">
                        <ListItem className={classes.listItem} button onClick={() => { onselect && onselect(social); GAEventsTracker(`click_on_${social.name}`, "header"); }}>
                                <ListItemAvatar classes={{
                                    root : classes.avatarRoot
                                }}>
                                    <FontAwesomeIcon className={classes.icon} icon={getIcon(social._id)}/>
                                </ListItemAvatar>
                                <ListItemText secondary={social.name} secondaryTypographyProps={{
                                    className : classes.list
                                }}/>
                        </ListItem>
                    </a>
                })}
            </List>
        </div>
    )
}
GiftDappMedias.propTypes = {

}
export default GiftDappMedias;
export {GiftDappMedias};
