import React, { useCallback, useState } from 'react'
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux'
import { Grid, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, withWidth } from '@material-ui/core'
import { walletStrings, WALLET_KEYS, WALLET_REDUCER_NAME } from '../../../structures'
import GiftEventListBoxContainer from './GiftEventListBoxContainer/GiftEventListBoxContainer'
import { EVENT_BREAKPOINTS, NETWORK_TYPE } from '../..'
import { history } from '../../../../helpers';
import { giftDappV1RouteNames } from '../../../../utils';
import { CustomSimplePaginationFooter, warningToast } from '../../../../includes';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
    },
    message : {
        padding : 20,
        border : "solid 1px",
        display : "flex",
        justifyContent : "center",
        textAlign : "center",
        margin : 20,
        color : colors.danger,
    },
    headerContainer : {
        background : colors.background,
        padding : 10,
    },
    error : {
        borderColor : colors.danger
    },
    header : {
        color : colors.textSecondary,
        fontSize : 12
    }
}))

function _GiftDappEventBody({width, data}) {
    const dispatch = useDispatch(),
    classes = style(),
    [state, _setState] = useState({message : null, messageType : null}),
    {message, messageType} = state,
    setState = (_state, callback) => _setState(state=>({...state, ..._state}), callback),
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.networkType] : networkType,
        [walletStrings.address] : address,
    } = walletReducer,
    claimReward = useCallback(rewardEvent => () => {
        if(!address) return warningToast("Not connected! Please connect with a network address")(dispatch);
        const {blockReward} = rewardEvent || {},
        {hasExpired} = blockReward || {};
        if(hasExpired) return warningToast("Active period for the event has expired!")(dispatch);
        if(networkType !== rewardEvent["networkType"])
            return setState({
                message : "Wrong network, Please connect " + 
                (WALLET_KEYS.ETHEREUM === networkType ? 
                NETWORK_TYPE.ETHEREUM : NETWORK_TYPE.BINANCE),
                messageType : "error",
            });
        history.push(giftDappV1RouteNames().eventView_+rewardEvent._id, rewardEvent);
    }, [networkType, address]);
    return (
        <div className={classes.root}>
            {message && <div className={classNames(classes.message, classes[messageType])}>
                {message}
            </div>}
            {EVENT_BREAKPOINTS.indexOf(width) === -1 &&  <div className={classes.headerContainer}>
                <Grid container>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <div className={classes.header}>
                            DEPOSIT
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <div className={classes.header}>
                            REWARD
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <div className={classes.header}>
                            NETWORK
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <div className={classes.header}>
                            
                        </div>
                    </Grid>
                </Grid>
            </div>}
            {data && data.map((rewardEvent, index) => {
                return <GiftEventListBoxContainer key={index}
                    rewardEvent={rewardEvent}
                    onClaim={claimReward}
                />
            })}
        </div>
    )
}
_GiftDappEventBody.propTypes = {
    
}
export const GiftDappEventBody = withWidth()(_GiftDappEventBody);
export {GiftDappEventBody as default};