import { makeStyles, InputBase } from '@material-ui/core'
import React from 'react'
import classNames from 'classnames';
import { PROFILE_SOCIAL_MEDIAL_KEYS } from '../../config';
const style = makeStyles(({colors}) => ({
    root : {
        display : "flex",
        margin : "20px 0",
    },
    iconContainer : {
        borderRadius : 5,
        border : `solid 1px ${colors.background}`,
        display : "flex",
        alignItems : "center",
        justifyContent : "center",
        width : 40,
        height : 40,
    },
    leftIconContainer : {
        marginRight : 10,
    },
    rightIconContainer : {
        marginLeft : 10,
        cursor : "pointer",
    },
    inputVerify : {
        display : "flex",
        flexGrow : 1,
        alignItems : "center",
        border : `solid 1px ${colors.background}`,
        padding : "5px 10px"
    },
    textField : {
        display : 'flex',
        flexGrow : 1,
        borderBottom : "none",
        color : colors.textSecondary,
    },
    label : {
        color : colors.textSecondary,
    },
    color : {
        color : colors.textSecondary,
    },
    verify : {
        fontSize : 10,
        color : colors.accent,
        cursor : "pointer",
    },
    verified : {
        color : colors.verified,
        cursor : "not-allowed"
    },
    disabled : {
        cursor : "not-allowed",
    },
    processing : {
        width : "20px !important",
        height : "20px !important",
    }
}))
export default function GiftDappProfileSocialMediaItemView ({
    icon : Icon, data = {}}) {
    const classes = style(),
        { IS_VERIFIED, PATH } = PROFILE_SOCIAL_MEDIAL_KEYS,
        {
            [IS_VERIFIED] : isVerified, 
            [PATH] : path, 
        } = data;
    return (
        <div className={classes.root}>
            <div className={classNames(classes.iconContainer, classes.leftIconContainer)}>
                {Icon && <Icon/>}
            </div>
            <div className={classNames(classes.inputVerify, isVerified ? classes.disabled : "")}>
                <InputBase
                    className={classNames(classes.textField, isVerified ? classes.disabled : "")}
                    margin="dense"
                    placeholder="@username"
                    value={path || ""}
                    classes={{
                        inputHiddenLabel : classes.label,
                        inputTypeSearch : classes.label,
                        disabled : classes.label
                    }}
                    disabled={true}
                />
                {isVerified && <div className={classNames(classes.verify, classes.verified)}>
                    Verified
                </div>}
            </div>
        </div>
    )
}