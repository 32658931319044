import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import GiftDappDashboardRewardBoxTokenDisplay from '../GiftDappDashboardRewardBoxTokenDisplay/GiftDappDashboardRewardBoxTokenDisplay'
import { Fragment } from 'react'
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../../../../utils/config';
const style = makeStyles(({colors, isDark}) => ({
    root : {
        background : isDark ? colors.secondary : colors.background,
        // padding : 20,
    },
    wrapper : {
         borderBottom : `solid 1px ${!isDark ? colors.borderLines : colors.dashboardBorderLine}`
    },
    box : {
        background : "linear-gradient(95deg, " + 
            colors.accentSecondary + ", " + 
            colors.secondary + ")",
        padding : 20,
        fontSize : 12,
       
    },
    container : {
        display : "flex",
        alignItems : "center",
        width : "100%",
        height : "100%",
    },
    lastContainer : {
        display : "flex",
        justifyContent:"flex-end",
        alignItems : "center",
        width : "100%",
        height : "100%",
    },
    name : {
        marginLeft : 10,
    },
    btnContainer : {
        display : "flex",
        justifyContent : "flex-end",
    },
    title : {
        fontStyle : "italic",
        color : colors.textSecondary,
        marginRight : 20,
    },
    logo : {
        height : 50,
        width : 50,
        borderRadius : "100%",
        background : colors.secondary,
        display : "flex",
        alignItems: "center",
        justifyContent : "center",
        fontSize : 10,
    },
    action : {
        color : colors.accent,
        cursor : "pointer",
    },
    details : {
        background : colors.primary,
        margin : "5px 50px",
        marginTop : 0,
        padding : 10,
        borderRadius : "0px 0px 10px 10px",
        display : "flex",
        alignItems : "center",
        transition : "max-height 0.5s linear",
    },
    detailContainer : {
        minHeight : 20,
        padding : 10,
        display : 'flex',
        flexDirection : "column",
        height : "100%",
        justifyContent : "center",
    },
    edit : {
        color : colors.text,
        cursor : "pointer",
        display : "flex",
        alignItems : "center",
        fontSize : 12,
    },
    editIcon : {
        color : colors.text,
        marginRight : 5,
    },
    end : {
        color : colors.textSecondary,
        cursor : "pointer",
        display : "flex",
        alignItems : "center",
        fontSize : 12,
    },
    endIcon : {
        color : colors.textSecondary,
        marginRight : 5,
    },
    header : {
        color : colors.textSecondary,
        fontSize : 14,
    },
    link : {
        color : colors.textSecondary,
        fontSize : 10,
        fontStyle : "italic",
    },
    copyIcon : {
        color : colors.textSecondary,
    },
    showSub : {
        display : "flex",
        maxHeight : 600,
    },
    hideSub : {
        maxHeight : 0,
        padding : 0,
        overflow : "hidden"
    }
}))
function GiftDappDashboardRewardBoxList({
    reward, onAction
}) {
    const classes = style(),
    giftDappUtilReducer = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME]),
    {
        [giftDappUtilStrings.appDomain] : appDomain
    } = giftDappUtilReducer,
    {name, image, tokenValues, status} = reward || {};
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.box}>
                 <Grid container>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <div className={classes.container}>
                            {image ? 
                                <img src={/^http/.test(image) ? image : appDomain + image} title="logo" className={classes.logo}/> : 
                                <div className={classes.logo}>logo</div>}
                            <div className={classes.name}>
                                {name}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <div className={classes.container}>
                            {tokenValues && tokenValues.map((tokenValue, index) => {
                                return <Fragment key={index}>
                                    <GiftDappDashboardRewardBoxTokenDisplay
                                        key={index}
                                        tokenValue={tokenValue}
                                    />
                                    {index + 1 !== tokenValues.length && <span style={{margin : "0px 5px"}}>&</span>}
                                </Fragment>
                            })}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        <div className={classes.container}>
                            {reward?.claimedCount}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                        {status && <div className={classes.lastContainer}>
                            <span className={classes.action} onClick={onAction}>
                                View/Edit
                            </span>
                        </div>}
                    </Grid>
                </Grid>     
                </div>
               </div>
        </div>
    )
}

GiftDappDashboardRewardBoxList.propTypes = {

}

export default GiftDappDashboardRewardBoxList

