import { makeStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import { landingRouteNames } from '../../../utils'
import React from 'react'


const styles = makeStyles(({colors, breakpoints})=>({
    
    root: {
        color: colors.pageColor,
        textAlign: "center",
        position: "relative",
        transformStyle: "preserve-3d",
        minHeight: "801px",
        paddingTop: "3rem",
        paddingBottom: "3rem",
        boxSizing: "border-box",
        backgroundColor:colors.pageBackground,
        [breakpoints.down('md')]: {
            paddingRight: "2.5rem",
            paddingLeft: "2.5rem",
        },
        [breakpoints.down('sm')]: {
            paddingRight: "1rem",
            paddingLeft: "1rem",
            minHeight: "501px",
        }
    },
    jumboHeading: {
        fontSize: "56px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        color: colors.pageColor,
        marginBottom: "1rem",
        marginTop: 0,
        [breakpoints.down('lg')]: {
            fontSize: "56px",
        },
        [breakpoints.down('sm')]: {
            fontSize: "32px",
        }
    },
    jumboText: {
        fontSize: "18px",
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: colors.pageColor,
        lineHeight: "28px",
        opacity: .8,
        marginBottom: "4rem",
        maxWidth: "53%",
        margin: "0 auto 1.2rem",
        [breakpoints.down('xl')]: {
            fontSize: "20px",
        },
        [breakpoints.down('sm')]: {
            fontSize: "16px",
            maxWidth: "100%",
        }
    },
    jumboBtn: {
        backgroundImage: " linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        color: colors.textDefault,
        fontSize: "18px",
        width: "196px",
        height: "56px",
        fonWeight: "700",
        padding: "1.5rem 4rem",
        borderRadius: "6px",
        outline: "none",
        [breakpoints.down('xl')]: {
            fontSize: "18px",
            padding: ".7rem 1.5rem",
            borderRadius: ".5rem",
        }
    },

    imgContainer:{
        paddingTop: "40px",
        [breakpoints.down('md')]: {
            maxWidth: "100%",
        },
        [breakpoints.down('sm')]: {
            maxWidth: "100%",
        }
    },
    image:{
        [breakpoints.down('md')]: {
            maxWidth: "100%",
        },
        [breakpoints.down('sm')]: {
            maxWidth: "100%",
        }
    },
    funColor:{
        color: colors.funColor
    }
    
}))


export default function GiftDappJumbotron() {
    
    const classes = styles();
    return(
        <header className = {classes.root}>
                <h1 className = {classes.jumboHeading}>
                Gift your users in <span className={classes.funColor}>fun</span> ways
                </h1>
                <p className = {classes.jumboText}>
                Reward your users or followers in a fun way by having them win tokens through games
                </p>
                <ButtonBase 
                    onClick={() => window.location.href = landingRouteNames().app}
                    className = {classes.jumboBtn}>
                        Launch DApp
                </ButtonBase>
                <div className={classes.imgContainer}>
                    <img className={classes.image} src={`./GiftDapp.png`} />
                </div>
            </header>
    );
}