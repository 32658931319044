import { makeStyles } from '@material-ui/core'
import React, {  } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { giftRewardActions } from '../..';
import CustomProcessStepNav from '../../../includes/CustomProcessStepNav/CustomProcessStepNav';
import {
    giftRewardStrings, GIFT_REWARD_REDUCER_NAME,
    SETUP_TABS_CONFIG as TABS_CONFIG,
    SETUP_TAB_STAGE_MAP as TAB_STAGE_MAP
} from '../config';
import { GiftDappRewardSpin } from '../GiftDappRewardSpin';
import GiftDappRewardSetupGameSetup from './GiftDappRewardSetupGameSetup/GiftDappRewardSetupGameSetup';
import GiftDappRewardSetupProfile from './GiftDappRewardSetupProfile/GiftDappRewardSetupProfile';
import GiftDappRewardSetupProjectDetails from './GiftDappRewardSetupProjectDetails/GiftDappRewardSetupProjectDetails';
import GiftDappRewardSetupSocialLinks from './GiftDappRewardSetupSocialLinks/GiftDappRewardSetupSocialLinks';
import useGAEventsTracker from './../../../hooks/useGAEventsTracker';

const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        margin : "20px 0"
    },
    body : {
        marginTop : 50,
    }
})),
PROCESS_STAGES = [
    {_id : 1, name : "STEP 1", description : "Profile"},
    {_id : 2, name : "STEP 2", description : "Event Details"},
    {_id : 3, name : "STEP 3", description : "Social Links"},
    {_id : 4, name : "STEP 4", description : "Game Setup"},
]
export default function GiftDappRewardSetup ({backToReward, onCreateReward, parentTab}) {
    const classes = style(),
        dispatch = useDispatch(),
        tab = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME][giftRewardStrings.setupTab]),
        position = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME][giftRewardStrings.setupPosition]),
        setPage = tab => {
            giftRewardActions.payload({
                [giftRewardStrings.setupTab] : tab,
                [giftRewardStrings.setupPosition] : TAB_STAGE_MAP[tab],
            })(dispatch);
            window.scrollTo(0, 0);
        },
        subComponentSetPage = tab => () => setPage(tab);
        const GAEventsTracker = useGAEventsTracker();

    return (
        <div className={classes.root}>
            <CustomProcessStepNav
                stages={PROCESS_STAGES}
                position={position}
            />
            <div className={classes.body}>
                {tab === TABS_CONFIG.PROFILE && <GiftDappRewardSetupProfile
                    onBack={backToReward}
                    onContinue={() => setPage(TABS_CONFIG.PROJECT_DETAILS)}e1
                />}
                {tab === TABS_CONFIG.PROJECT_DETAILS && <GiftDappRewardSetupProjectDetails
                    onBack={() => setPage(TABS_CONFIG.PROFILE)}
                    onContinue={() => setPage(TABS_CONFIG.SOCIAL_LINKS)}
                    profileBreadCrumbAction={subComponentSetPage(TABS_CONFIG.PROFILE)}
                />}
                {tab === TABS_CONFIG.SOCIAL_LINKS && <GiftDappRewardSetupSocialLinks
                    onBack={() => setPage(TABS_CONFIG.PROJECT_DETAILS)}
                    onContinue={() => setPage(TABS_CONFIG.GAME_SETUP)}
                    profileBreadCrumbAction={subComponentSetPage(TABS_CONFIG.PROFILE)}
                    detailBreadCrumbAction={subComponentSetPage(TABS_CONFIG.PROJECT_DETAILS)}
                    />}
                {tab === TABS_CONFIG.GAME_SETUP && <GiftDappRewardSetupGameSetup
                    onBack={() => setPage(TABS_CONFIG.SOCIAL_LINKS)}
                    showPreview={() => {setPage(TABS_CONFIG.SPIN_PREVIEW); GAEventsTracker("preview_spin", "event page");}}
                    onSave={()=> {onCreateReward(); GAEventsTracker("save_event", "event page");}}
                    profileBreadCrumbAction={subComponentSetPage(TABS_CONFIG.PROFILE)}
                    detailBreadCrumbAction={subComponentSetPage(TABS_CONFIG.PROJECT_DETAILS)}
                    socialBreadcrumbAction={subComponentSetPage(TABS_CONFIG.SOCIAL_LINKS)}
                />}
                {tab === TABS_CONFIG.SPIN_PREVIEW && <GiftDappRewardSpin
                    onBack={() => setPage(TABS_CONFIG.GAME_SETUP)}
                />}
            </div>
        </div>
    )
}
