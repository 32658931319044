import { giftDappEventReducer, giftDappSocialMediaReducer } from "./GIftDappEvents/config"
import { giftDappProfileReducer } from "./GiftDappProfile/config"
import { giftRewardReducer } from "./GiftDappReward/config"
import { structuralReducers } from "./structures/reducers"

export const componentReducers = {
    ...structuralReducers,
    giftRewardReducer,
    giftDappEventReducer,
    giftDappSocialMediaReducer,
    giftDappProfileReducer,
}