import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, makeStyles } from '@material-ui/core'
import { giftDappEventStrings, GIFT_DAPP_EVENT_REDUCER_NAME } from './config'
import { NETWORK_TYPE, REWARD_TYPES } from '.'
import { ArrowBackIos, ArrowForwardIos} from '@material-ui/icons'; 
import { GiftDappEventHome } from './GiftDappEventHome'
import { useQuery } from '@apollo/client'
import { walletStrings, WALLET_KEYS, WALLET_REDUCER_NAME } from '../structures'
import { breadCrumbActions, breadCrumbStrings } from '../../includes'
import { getRewards, giftDappV1RouteNames, getRewardMaxMinId, getRewardCounts } from '../../utils'
import { backButtonActions } from '../../includes/CustomBackNav/actions'
import { backButtonStrings } from '../../includes/CustomBackNav/config'

const style = makeStyles(({colors}) => ({
    root : {
        color : colors.text,
        marginTop : 20,
    },
    pageContainer : {
        display : 'flex',
        justifyContent : "flex-end",
        marginTop : 20,
    },
    pageBtn : {
        background : colors.secondary,
        padding : "10px 20px",
        borderRadius : 10,
        margin: 5,
        "&:hover" : {
            background : colors.accent
        }
    }
})),
TABS = {
    HOME : "HOME",
    CLAIM : "CLAIM",
}
function GIftDappEvents(props) {
    const dispatch = useDispatch(),
    classes = style(),
        rewardTypeList = [
            REWARD_TYPES.INFLUENCER,
            REWARD_TYPES.PROJECT
        ],
        networkTypeList = [
            NETWORK_TYPE.BINANCE,
            NETWORK_TYPE.ETHEREUM
        ],
        [state, _setState] = useState({
            limit : 20,
            skip : 0, lastEventId : null
        }),
        {limit, skip, lastEventId, firstEventId} = state,
        setState = _state => _setState(state=>({...state, ..._state})),
        networkId = useSelector(state=>state[WALLET_REDUCER_NAME][walletStrings.networkId]),
        giftEventReducer = useSelector(state=>state[GIFT_DAPP_EVENT_REDUCER_NAME]),
        {
            [giftDappEventStrings.TAB] : tab,
            [giftDappEventStrings.eventFilter] : eventFilter,
            [giftDappEventStrings.eventTab] : eventTab,
        } = giftEventReducer,
        processFilter = (eventFilter, eventTab) => {
            const {networkIndexes, rewardIndexes} = eventFilter;
            const filter = {status : eventTab};
            if(eventTab === null) delete filter.status;
            if(networkIndexes && networkIndexes.length === 1){
                filter.networkType = String(networkTypeList[networkIndexes[0]]).toLowerCase().includes("ethereum") ? WALLET_KEYS.ETHEREUM : WALLET_KEYS.BINANCE;
            }else if(!networkIndexes || networkIndexes.length === 0){
                filter.networkType = null;
            }
            if(rewardIndexes && rewardIndexes.length === 1){
                filter.isProject = rewardTypeList[rewardIndexes[0]] === REWARD_TYPES.PROJECT;
            }else if (!rewardIndexes || rewardIndexes.length === 0){
                filter.isProject = null;
            }
            return filter;
        },
        {data : countData} = useQuery(getRewardCounts(), {
            variables : {
                networkId : String(networkId),
                filter : {...processFilter(eventFilter, eventTab)}
            }
        }),
        {data, laoding, refetch : refetchEvent} = useQuery(getRewards(), {
            variables : {
                networkId : String(networkId),
                filter : {
                    ...processFilter(eventFilter, eventTab)
                },
                pagination : {
                    limit, skip
                }
            },
            skip : !networkId,
            fetchPolicy : "network-only",
        }),
        {data : eventMinMaxData, refetch : refetchEventMinMax} = useQuery(getRewardMaxMinId(), {
            variables : {
                networkId : String(networkId)
            },
            skip : !networkId,
            fetchPolicy : "network-only",
        }), 
        {rewards} = data || {},
        {rewardCounts} = countData || {},
        {getRewardMaxMinId : eventMinMax} = eventMinMaxData || {},
        {maxId} = eventMinMax || {},
        onNextBtnClick = useCallback(() => {
            refetchEvent({
                networkId : String(networkId),
                filter : {
                    ...processFilter(eventFilter, eventTab)
                },
                pagination : {
                    limit, skip : skip + limit
                }
            });
            setState({skip : skip + limit})
        }, [limit, skip, lastEventId, JSON.stringify(eventMinMax)]),
        onPreviousBtnClick = useCallback(() => {
            refetchEvent({
                networkId : String(networkId),
                filter : {
                    ...processFilter(eventFilter, eventTab)
                },
                pagination : {
                    limit, skip : skip - limit
                }
            })
            setState({skip : skip - limit})
        }, [limit, skip, lastEventId, JSON.stringify(eventMinMax)]);

    useEffect(() => {
        if(rewards && rewards.length > 0){
            const lastEventId = rewards[rewards.length - 1]._id;
            setState({lastEventId});
        }
    }, [JSON.stringify(rewards)])
    useEffect(() => {
        breadCrumbActions.payload({
            [breadCrumbStrings.visible] : true,
            [breadCrumbStrings.crumbs] : [
                {name : "Events", path : giftDappV1RouteNames().events}
            ],
        })(dispatch);
        backButtonActions.payload({
            [backButtonStrings.visible] : false,
        })(dispatch);
    }, []);
    return (
        <div className={classes.root}>
            <GiftDappEventHome
                events={rewards}
                rewardCounts={rewardCounts}
                rewardTypeList={rewardTypeList}
                networkTypeList={networkTypeList}
            />

            <div className={classes.pageContainer}>
                <IconButton disabled={skip === 0} className={classes.pageBtn} onClick={onPreviousBtnClick}>
                    <ArrowBackIos fontSize="small"/>
                </IconButton>
                <IconButton disabled={lastEventId === maxId} className={classes.pageBtn} onClick={onNextBtnClick}>
                    <ArrowForwardIos fontSize="small"/>
                </IconButton>
            </div>
        </div>
    )
}
GIftDappEvents.propTypes = {
    
}
export default GIftDappEvents;
export {GIftDappEvents};