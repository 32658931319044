import { Divider, Grid, makeStyles, withWidth } from "@material-ui/core";
import { People, AccountCircle } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../../helpers";
import {
    breadCrumbActions,
    breadCrumbStrings,
    errorToast,
    warningToast,
} from "../../../../includes";
import { backButtonActions } from "../../../../includes/CustomBackNav/actions";
import { backButtonStrings } from "../../../../includes/CustomBackNav/config";
import {
    getProfiles,
    getTwitterAccountValidation,
    REWARD_SETUP_PROFILE_OPTION_NAME,
    giftDappRouteNames,
    ROUTE_DISPLAY_STRINGS,
} from "../../../../utils";
import { useLazyQuery } from "@apollo/client";
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from "../../config";
import GiftDappRewardSetupHeader from "../GiftDappRewardSetupHeader/GiftDappRewardSetupHeader";
import GiftDappRewardSetupProfileOptionBox from "./GiftDappRewardSetupProfileOptionBox/GiftDappRewardSetupProfileOptionBox";
import { walletStrings, WALLET_REDUCER_NAME } from "../../../structures";
import GiftDappProfileProfile from "../../../GiftDappProfile/GiftDappProfileProfile/GiftDappProfileProfile";
import GiftDappProfileSocialMedia from "../../../GiftDappProfile/GiftDappProfileSocialMedia/GiftDappProfileSocialMedia";
import GiftDappRewardSetupFooterButtons from "../GiftDappRewardSetupFooterButtons/GiftDappRewardSetupFooterButtons";
import {
    giftDappProfileStrings,
    GIFT_DAPP_PROFILE_REDUCER_NAME,
    PROFILE_SOCIAL_MEDIAL_KEYS,
} from "../../../GiftDappProfile/config";
import { giftRewardActions } from "../../actions";
import {
    youtubeAuth,
    youtubeInit,
    youtubeLoadClient,
    youtubeSearchChannel,
} from "../../../GIftDappEvents/GiftDappEventClaims/SocialMediaVerify/verifyYoutube";
import { Fragment } from "react";
import {
    giftDappUtilStrings,
    GIFT_DAPP_UTIL_REDUCER_NAME,
} from "../../../../utils/config";
import GiftDappRewardSetupProfileList from "./GiftDappRewardSetupProfileList/GiftDappRewardSetupProfileList";
import useGAEventsTracker from "./../../../../hooks/useGAEventsTracker";

const style = makeStyles(({ colors, breakpoints }) => ({
    root: {},
    container: {
        border: "dashed 1px " + colors.borderLines,
        minHeight: 50,
        padding: 50,
        borderRadius: 5,
        [breakpoints.down("sm")]: {
            padding: "50px 20px",
        },
    },
    optionHeaderContainer: {
        display: "flex",
        justifyContent: "center",
    },
    optionHeader: {
        color: colors.text,
        fontSize: 12,
    },
    optionBtns: {
        marginTop: 30,
    },
    notchedOutline: {
        borderColor: colors.borderLines,
    },
    content: {
        marginTop: 50,
    },
    divider: {
        background: colors.borderLines,
    },
    label: {
        margin: "20px 0",
    },
    mediaRoot: {
        marginTop: 50,
    },
}));
function _GiftDappRewardSetupProfile({ onContinue }) {
    const GAEventsTracker = useGAEventsTracker();
    const classes = style(),
        { LAST_PROFILE, ANOTHER_PROFILE, NEW_PROFILE } =
            REWARD_SETUP_PROFILE_OPTION_NAME,
        PROFILE_OPTIONS = [
            {
                name: LAST_PROFILE,
                label: "",
                image: "",
            },
            {
                name: ANOTHER_PROFILE,
                label: "Login with another profile",
                icon: (props) => <People {...props} />,
            },
            {
                name: NEW_PROFILE,
                label: "Create New Profile",
                icon: (props) => <AccountCircle {...props} />,
            },
        ],
        dispatch = useDispatch(),
        [twitterVerifyAccount, { data: twitterValidData, error }] =
            useLazyQuery(getTwitterAccountValidation()),
        { validTwitterAccount } = twitterValidData || {},
        [state, _setState] = useState({
            processingObject: {
                youtube: false,
                twitter: false,
                reddit: false,
            },
        }),
        setState = (_state) => _setState((state) => ({ ...state, ..._state })),
        { processingObject } = state,
        giftRewardReducer = useSelector(
            (state) => state[GIFT_REWARD_REDUCER_NAME]
        ),
        {
            [giftRewardStrings.rewardCreator]: rewardCreator,
            [giftRewardStrings.creatorId]: creatorId,
            [giftRewardStrings.isEdit]: isEdit,
            [giftRewardStrings.profileOption]: option,
        } = giftRewardReducer,
        walletReducer = useSelector((state) => state[WALLET_REDUCER_NAME]),
        { [walletStrings.address]: address } = walletReducer,
        giftDappProfileReducer = useSelector(
            (state) => state[GIFT_DAPP_PROFILE_REDUCER_NAME]
        ),
        {
            [giftDappProfileStrings.lastUsedProfile]: lastUsedProfile,
            [giftDappProfileStrings.profiles]: profiles,
        } = giftDappProfileReducer,
        hasProfile = profiles && profiles.length > 0,
        onSelect = useCallback(
            (option) => () => {
                giftRewardActions.payload({
                    [giftRewardStrings.profileOption]: option,
                })(dispatch);
            },
            [JSON.stringify(dispatch)]
        ),
        onChange = useCallback(
            (name, value) => {
                if (name === giftDappProfileStrings.image) {
                    const image = value && value[0];
                    if (image.size > 1000000)
                        return warningToast("Image shouldn't be more than 1MB")(
                            dispatch
                        );
                    rewardCreator[name] = { value: image };
                    var reader = new FileReader();
                    reader.addEventListener(
                        "load",
                        function () {
                            const imageData = reader.result;
                            rewardCreator[name].data = imageData;
                            giftRewardActions.payload({
                                [giftRewardStrings.rewardCreator]:
                                    rewardCreator,
                            })(dispatch);
                        },
                        false
                    );
                    if (image) reader.readAsDataURL(image);
                } else
                    giftRewardActions.payload({
                        [giftRewardStrings.rewardCreator]: {
                            ...rewardCreator,
                            [name]: value,
                        },
                    })(dispatch);
            },
            [JSON.stringify(rewardCreator)]
        ),
        onRemove = useCallback(
            (name) => {
                giftRewardActions.payload({
                    [giftRewardStrings.rewardCreator]: {
                        ...rewardCreator,
                        [name]: null,
                    },
                })(dispatch);
            },
            [JSON.stringify(rewardCreator)]
        ),
        onMediaChange = useCallback(
            (name) => (key, value) => {
                giftRewardActions.payload({
                    [giftRewardStrings.rewardCreator]: {
                        ...rewardCreator,
                        [name]: rewardCreator[name]
                            ? {
                                  ...rewardCreator[name],
                                  [key]: value,
                              }
                            : {
                                  [key]: value,
                              },
                    },
                })(dispatch);
            },
            [JSON.stringify(rewardCreator)]
        ),
        onReset = useCallback(() => {
            giftRewardActions.payload({
                [giftRewardStrings.rewardCreator]: {},
            })(dispatch);
            GAEventsTracker("reset_profile_setup", "events page");
        }, []),
        verify = useCallback(
            (name, username) => {
                switch (name) {
                    case giftDappProfileStrings.youtube:
                        return onYoutubeVerify(name);
                    case giftDappProfileStrings.twitter:
                        return onVerifyTwitter(username);
                    case giftDappProfileStrings.reddit:
                        return onVerifyReddit(name, username);
                    case giftDappProfileStrings.discord:
                        return onVerifyDiscord(name, username);
                    case giftDappProfileStrings.telegram:
                        return onVerifyTelegram(name, username);
                }
            },
            [
                JSON.stringify(rewardCreator),
                JSON.stringify(processingObject),
                JSON.stringify(dispatch),
            ]
        ),
        onYoutubeVerify = useCallback(
            (name) => {
                (async () => {
                    if (processingObject.youtube) return;
                    const channelId =
                        rewardCreator[name]?.[PROFILE_SOCIAL_MEDIAL_KEYS.PATH]; //"UCdywisLfbW5KWhxMAw4Wqtw"
                    try {
                        setState({
                            processingObject: {
                                ...processingObject,
                                youtube: true,
                            },
                        });
                        await youtubeAuth();
                        await youtubeLoadClient();
                        const result = await youtubeSearchChannel();
                        const { result: _result } = result || {},
                            { items } = _result || {};
                        if (!items || items.length === 0)
                            throw new Error(
                                "No channel exists for this account!"
                            );
                        const myChannel = items.find(
                            (item) => item.id === channelId
                        );
                        if (!myChannel)
                            throw new Error(
                                "We couldn't find this channel in your youtube account!"
                            );
                        setState({
                            processingObject: {
                                ...processingObject,
                                youtube: false,
                            },
                        });
                        giftRewardActions.payload({
                            [giftRewardStrings.rewardCreator]: {
                                ...rewardCreator,
                                [name]: rewardCreator[name]
                                    ? {
                                          ...rewardCreator[name],
                                          [PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]: true,
                                      }
                                    : {
                                          [PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]: true,
                                      },
                            },
                        })(dispatch);
                      GAEventsTracker("profile_YT_verification_successful", "events page");
                    } catch (error) {
                        setState({
                            processingObject: {
                                ...processingObject,
                                youtube: false,
                            },
                        });
                        errorToast(error.message)(dispatch);
                        GAEventsTracker(`profile_YT_verification_failed-${error.message}`, "events page");
                    }
                })();
            },
            [
                JSON.stringify(rewardCreator),
                JSON.stringify(processingObject),
                JSON.stringify(dispatch),
            ]
        ),
        onVerifyTwitter = useCallback(
            (username) => {
                // if(!twitterUsername || String(twitterUsername).trim() === "")
                //     return warningToast("Please input your twitter username")(dispatch);

                setState({
                    processingObject: { ...processingObject, twitter: true },
                });
                twitterVerifyAccount({
                    variables: {
                        username,
                    },
                });
            },
            [
                JSON.stringify(rewardCreator),
                JSON.stringify(processingObject),
                JSON.stringify(dispatch),
            ]
        ),
        onVerifyReddit = useCallback(
            (name, username) => {
                giftRewardActions.payload({
                    [giftRewardStrings.rewardCreator]: {
                        ...rewardCreator,
                        [name]: {
                            [PROFILE_SOCIAL_MEDIAL_KEYS.PATH]: username,
                            [PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]: true,
                        },
                    },
                })(dispatch);
                GAEventsTracker("profile_reddit_verification", "events page");
            },
            [
                JSON.stringify(rewardCreator),
                JSON.stringify(processingObject),
                JSON.stringify(dispatch),
            ]
        ),
        onVerifyDiscord = useCallback(
            (name, username) => {
                giftRewardActions.payload({
                    [giftRewardStrings.rewardCreator]: {
                        ...rewardCreator,
                        [name]: {
                            [PROFILE_SOCIAL_MEDIAL_KEYS.PATH]: username,
                            [PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]: true,
                        },
                    },
                })(dispatch);
                GAEventsTracker("profile_discord_verification", "events page");
            },
            [
                JSON.stringify(rewardCreator),
                JSON.stringify(processingObject),
                JSON.stringify(dispatch),
            ]
        ),
        onVerifyTelegram = useCallback(
            (name, username) => {
                giftRewardActions.payload({
                    [giftRewardStrings.rewardCreator]: {
                        ...rewardCreator,
                        [name]: {
                            [PROFILE_SOCIAL_MEDIAL_KEYS.PATH]: username,
                            [PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]: true,
                        },
                    },
                })(dispatch);
                GAEventsTracker("profile_telegram_verification", "events page");
            },
            [
                JSON.stringify(rewardCreator),
                JSON.stringify(processingObject),
                JSON.stringify(dispatch),
            ]
        ),
        unverify = useCallback(
            (name) => {
                giftRewardActions.payload({
                    [giftRewardStrings.rewardCreator]: {
                        ...rewardCreator,
                        [name]: rewardCreator[name]
                            ? {
                                  ...rewardCreator[name],
                                  [PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]: false,
                              }
                            : {
                                  [PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]: false,
                              },
                    },
                })(dispatch);
                GAEventsTracker("unVerify_social_acct_for_profile", "events page");
            },
            [JSON.stringify(rewardCreator)]
        ),
        _onContinue = useCallback(() => {
            const { [giftDappProfileStrings.lastname]: lastname } =
                rewardCreator;
            if (option === NEW_PROFILE && !lastname)
                return warningToast("Profile must have lastname at least")(
                    dispatch
                );
            onContinue();
            GAEventsTracker("save&continue_profile_setup", "events page");
        }, [option, JSON.stringify(rewardCreator)]);
    if (creatorId) {
        const creator =
            profiles && profiles.find((item) => item._id === creatorId);
        const { lastname, firstname, image } = creator || {};
        PROFILE_OPTIONS[0].label =
            /* "Agu Chijioke"; // */ lastname + " " + firstname;
        PROFILE_OPTIONS[0].image =
            /* "/image/logos/logo-1630396264904.png"; // */ image;
    }
    //twitter error handler
    useEffect(() => {
        if (error) {
            errorToast(error.message)(dispatch);
            giftRewardActions.payload({
                [giftRewardStrings.rewardCreator]: {
                    ...rewardCreator,
                    [giftDappProfileStrings.twitter]: {
                        ...rewardCreator[giftDappProfileStrings.twitter],
                        [PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]: false,
                    },
                },
            })(dispatch);
            setState({
                processingObject: { ...processingObject, twitter: false },
            });
        }
    }, [
        JSON.stringify(error),
        JSON.stringify(rewardCreator),
        JSON.stringify(dispatch),
    ]);
    //twitter on success handler
    useEffect(() => {
        if (validTwitterAccount) {
            giftRewardActions.payload({
                [giftRewardStrings.rewardCreator]: {
                    ...rewardCreator,
                    [giftDappProfileStrings.twitter]: rewardCreator[
                        giftDappProfileStrings.twitter
                    ]
                        ? {
                              ...rewardCreator[giftDappProfileStrings.twitter],
                              [PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]: true,
                          }
                        : {
                              [PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]: true,
                          },
                },
            })(dispatch);
            setState({
                processingObject: { ...processingObject, twitter: false },
            });
        }
    }, [
        JSON.stringify(validTwitterAccount),
        JSON.stringify(rewardCreator),
        JSON.stringify(dispatch),
    ]);
    useEffect(() => {
        if (lastUsedProfile) {
            giftRewardActions.payload({
                [giftRewardStrings.profileOption]: LAST_PROFILE,
            })(dispatch);
        }
    }, [JSON.stringify(dispatch), JSON.stringify(lastUsedProfile)]);
    useEffect(() => {
        if (!isEdit && option === LAST_PROFILE) {
            giftRewardActions.payload({
                [giftRewardStrings.creatorId]: lastUsedProfile?._id,
            })(dispatch);
        }
    }, [
        option,
        JSON.stringify(lastUsedProfile),
        JSON.stringify(dispatch),
        isEdit,
    ]);
    useEffect(() => {
        breadCrumbActions.payload({
            [breadCrumbStrings.visible]: true,
            [breadCrumbStrings.crumbs]: [
                {
                    name: isEdit
                        ? ROUTE_DISPLAY_STRINGS.DASHBOARD
                        : ROUTE_DISPLAY_STRINGS.REWARD,
                    path: isEdit
                        ? giftDappRouteNames().dashboard
                        : giftDappRouteNames().reward,
                },
                { name: ROUTE_DISPLAY_STRINGS.SETUP_STRINGS.PROFILE },
            ],
        })(dispatch);
        backButtonActions.payload({
            [backButtonStrings.visible]: true,
            [backButtonStrings.text]: `Back to ${
                isEdit ? "Dashboard" : "home"
            }`,
            [backButtonStrings.action]: (
                (isEdit) => () =>
                    history.replace(
                        isEdit
                            ? giftDappRouteNames().dashboard
                            : giftDappRouteNames().reward
                    )
            )(isEdit),
        })(dispatch);
    }, [isEdit]);
    useEffect(() => {
        youtubeInit();
    }, []);

    return (
        <div className={classes.root}>
            <GiftDappRewardSetupHeader
                header="Profile"
                subheader="Setup your profile to continue to the next step"
            />
            <div className={classes.container}>
                {hasProfile && (
                    <div className={classes.optionHeaderContainer}>
                        <div className={classes.optionHeader}>
                            Continue with last profile associated with your
                            wallet or create a new profile
                        </div>
                    </div>
                )}
                {hasProfile && (
                    <div className={classes.optionBtns}>
                        <Grid container spacing={2}>
                            {PROFILE_OPTIONS.map((item, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                >
                                    <GiftDappRewardSetupProfileOptionBox
                                        {...item}
                                        onSelect={onSelect(item.name)}
                                        selected={option === item.name}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                )}

                {[ANOTHER_PROFILE, NEW_PROFILE].indexOf(option) !== -1 && (
                    <>
                        <div className={classes.label}>Profile</div>
                        <Divider className={classes.divider} />
                    </>
                )}

                <div className={classes.options}>
                    {!hasProfile || option === NEW_PROFILE ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <GiftDappProfileProfile
                                    isNew
                                    classes={{
                                        notchedOutline: classes.notchedOutline,
                                    }}
                                    data={rewardCreator}
                                    onChange={onChange}
                                    onRemove={onRemove}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <GiftDappProfileSocialMedia
                                    classes={{
                                        root: classes.mediaRoot,
                                    }}
                                    isNew
                                    onChange={onMediaChange}
                                    data={rewardCreator}
                                    verify={verify}
                                    unverify={unverify}
                                    processes={processingObject}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <div className={classes.content}>
                            {option === ANOTHER_PROFILE && (
                                <GiftDappRewardSetupProfileList
                                    profiles={profiles}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            <GiftDappRewardSetupFooterButtons
                onContinue={_onContinue}
                onReset={onReset}
            />
        </div>
    );
}
export const GiftDappRewardSetupProfile = withWidth()(
    _GiftDappRewardSetupProfile
);
export { GiftDappRewardSetupProfile as default };
