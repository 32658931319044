const YOUR_API_KEY = "AIzaSyDezLl_meUfYyLbZ2pYSCKO6a8UCLP-SKo",
// mikeApiKey = "AIzaSyD2R5OLPwKtANeO4XDDvTZxFiZXMDvhN9o",
    //"AIzaSyAZ_pcTF3WQnBqc9LNR8QeBTc3Xgpm9-YQ",
    YOUR_CLIENT_ID = "1003011020383-1mugkorufjuhtab2og7n5lpb03n1osh7.apps.googleusercontent.com", 
    //"570720008754-8crhg72apva9utkvkpv5lb7logd8nqqn.apps.googleusercontent.com",
    testChannel = "UCW5YeuERMmlnqo4oq8vwUpg";
    const mikeClientID = "749337909021-lq20k0e4cuungfk7rh4honj7kn0uit06.apps.googleusercontent.com";
export const youtubeInit = function(){
    // const YOUR_CLIENT_ID = YOUR_CLIENT_ID;
        if(window.gapi) window.gapi.load("client:auth2", function() {
            window.gapi.auth2.init({client_id: YOUR_CLIENT_ID});
        });
}
function authenticate(callback) {
    return window.gapi.auth2.getAuthInstance()
        .signIn({scope: "https://www.googleapis.com/auth/youtube.readonly"})
        .then(function(data) {
            callback && callback(data);
        }, function(err) { 
            console.error("Error signing in", err); 
            callback && callback(err);
        });
}

function loadClient() {
    window.gapi.client.setApiKey(YOUR_API_KEY);
    return window.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest")
        .then(function() { console.log("GAPI client loaded for API"); },
                function(err) { console.error("Error loading GAPI client for API", err); });
}


// export const extractYoutubeChannelId = channelId => {
//     if(/channel\//.test(channelId)){
//         channelId = String(channelId).split("channel/")[1];
//     }
//     return channelId;
// }
export function youtubeSearchChannel(){
    return window.gapi.client.youtube.channels.list({
        "part" : [
            "contentOwnerDetails"
        ], 
        "mine" : true,
        // "id": [extractYoutubeChannelId(id)]
    });
}
// Make sure the client is loaded and sign-in is complete before calling this method.
// function findChannel(channelId) {
//     return window.gapi.client.youtube.channels.list({
//       "part": [
//         "snippet"
//       ],
//       "id": [
//         channelId //"UCW5YeuERMmlnqo4oq8vwUpg"
//       ]
//     });
// }
// function getChannel (response, channelId, _nextPageToken) {
//     const {result} = response || {};
//     const {items, nextPageToken} = result || {};
//     const channel = items && items.find(item=>item?.snippet?.resourceId?.channelId === channelId)
//     if(channel) {
//         _nextPageToken = null;
//     } else _nextPageToken = nextPageToken;
//     return {nextPageToken : _nextPageToken, channel};
// }
// export const channelInfo = async (channelId) => {
//     const response = await findChannel(channelId);
//     const {result} = response;
//     const {items} = result || {};
//     const channel = items && items.find(item=>item?.snippet?.resourceId?.channelId === channelId)
//     return channel; //channel.snippet?.title;
// }
export {authenticate as youtubeAuth};
export {loadClient as youtubeLoadClient};
// export async function youtubeVerifyFollow(channelId){
//     let nextPageToken = null, channel;
//     do{
//         const response = await execute(nextPageToken);
//         const result = getChannel(response, channelId, null);
//         const {nextPageToken : _nextPageToken, channel : _channel} = result || {};
//         nextPageToken = _nextPageToken;
//         channel = _channel;
//     }while(nextPageToken)
//     return channel
// }
// export const verifyYoutube = async (channelId) => {
//     await authenticate();
//     await loadClient();
//     let nextPageToken = null, channel;

//     do{
//         const response = await execute(nextPageToken);
//         const result = getChannel(response, channelId, null);
//         const {nextPageToken : _nextPageToken, channel : _channel} = result || {};
//         nextPageToken = _nextPageToken;
//         channel = _channel;
//     }while(nextPageToken)
//     return channel
// }










export async function getYoutubeChannelDetailsByID(channelID) {
    return window.gapi.client.youtube.channels.list({
      "part": ["snippet"],
      "id": [channelID]
    })
    .then(response => response)
    .catch(err => err)
}




// Make sure the client is loaded and sign-in is complete before calling this method.
export function execute(channelID, callback) {
return window.gapi.client.youtube.subscriptions.list({
    "part": [
    "snippet,contentDetails"
    ],
    "forChannelId": channelID,
    "mine": true
})
    .then(function(response) {
        // Handle the results here (response.result has the parsed body).
        callback(response);
    },
    function(err) { console.error("Execute error", err); });
}

export const verifyYoutubeSubscription = (channelID, callback) => {
    // the user must have been authenticated before getting to the step where this function is called. so we can just load client and then execute
    loadClient().then(() => {
        execute(channelID, callback);
    }).catch(err => {callback(err)})
}