import React from 'react';
import { listedTokenAddresses } from '../../helpers';

export * from './GiftDappReward';
export * from './config'
export * from './actions';
export * from "./GiftDappRewardSetup";
export const listedTokens = (() => {
    const list = [
        {label : "BNB", icon : props => <span {...props} className="icon icon-btc"></span>},
        {label : "ETH", icon : props => <span {...props} className="icon icon-eth"></span>},
        {label : "RGP", icon : props => <span {...props} className="icon icon-rgp"><img height={15} width={15} src="/assets/rgp.png"/></span>},
        {label : "TRX", icon : props => <span {...props} className="icon icon-trx"></span>},
        {label : "USDT", icon : props => <span {...props} className="icon icon-usdt"></span>},
        {label : "BUSC", icon : props => <span {...props} className="icon icon-busc"></span>},
    ]
    return list
})();