import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { GiftDappEventNav } from './GiftDappEventNav'
import {giftDappEventActions}  from "../actions";
import { EVENT_FILTER_VARIABLE, giftDappEventStrings, GIFT_DAPP_EVENT_REDUCER_NAME } from '../config'
import { GiftDappEventBody } from './GiftDappEventBody'
const style = makeStyles(theme => ({
    root: {
       
    }
}))
function GiftDappEventHome({events, rewardTypeList, networkTypeList, rewardCounts}) {
    const dispatch = useDispatch(),
        classes = style(),
        giftEventReducer = useSelector(state=>state[GIFT_DAPP_EVENT_REDUCER_NAME]),
        {
            [giftDappEventStrings.eventFilter] : eventFilter,
            [giftDappEventStrings.eventTab] : eventTab
        } = giftEventReducer,
        {
            [EVENT_FILTER_VARIABLE.networkIndexes] : networkIndexes,
            [EVENT_FILTER_VARIABLE.rewardIndexes] :rewardIndexes,
        } = eventFilter || {},
        setRewardIndex = useCallback((index, checked) => {
            if(checked) rewardIndexes.push(index);
            else rewardIndexes.splice(rewardIndexes.indexOf(index), 1);
            giftDappEventActions.payload({
                [giftDappEventStrings.eventFilter] : {...eventFilter, rewardIndexes}
            })(dispatch);
        }, [JSON.stringify(eventFilter)]),
        setNetworkIndex = useCallback((index, checked) => {
            if(checked) networkIndexes.push(index);
            else networkIndexes.splice(networkIndexes.indexOf(index), 1);
            giftDappEventActions.payload({
                [giftDappEventStrings.eventFilter] : {...eventFilter, networkIndexes}
            })(dispatch);
        }, [JSON.stringify(eventFilter)]),
        setTab = eventTab => {
            giftDappEventActions.payload({
                [giftDappEventStrings.eventTab] : eventTab
            })(dispatch);
        }
    return (
        <div className={classes.root}>
            <GiftDappEventNav
                setTab={setTab}
                activeTab={eventTab}
                rewardCounts={rewardCounts}
                rewardTypeList={rewardTypeList}
                networkTypeList={networkTypeList}
                setRewardIndex={setRewardIndex}
                setNetworkIndex={setNetworkIndex}
            />
            <div style={{marginTop : 20}}/>
            <GiftDappEventBody
                data={events}
            />
        </div>
    )
}
GiftDappEventHome.propTypes = {

}
export default GiftDappEventHome;
export { GiftDappEventHome };