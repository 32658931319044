import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core'
const style = makeStyles(theme => ({
    root : {
        minHeight : 200,
        display : 'flex',
        alignItems : "center",
        justifyContent : "center",
    }
}))
function GiftDappHome(props) {
    const dispatch = useDispatch(),
    classes = style();
    return (
        <div className={classes.root}>
            this is the home page
        </div>
    )
}
GiftDappHome.propTypes = {
    
}
export default GiftDappHome;
export {GiftDappHome};