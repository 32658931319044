import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles, withStyles } from '@material-ui/core';
import GiftDappProfileSocialMediaItem from './GiftDappProfileSocialMediaItem/GiftDappProfileSocialMediaItem';
import React, { useCallback, useState } from 'react'
import { faInstagram, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { PROFILE_SOCIAL_MEDIAL_KEYS } from '../config';
import GiftDappProfileSocialMediaItemView from './GiftDappProfileSocialMediaItemView/GiftDappProfileSocialMediaItemView';
const style = ({colors, breakpoints}) => ({
    root : {
        
    },
    icon : {
        color : colors.accent,
    },
    header : {},
});
export const MEDIA_NAMES = {
    TWITTER : "twitter",
    INSTAGRAM : "instagram",
    TELEGRAM : "telegram",
    YOUTUBE : "youtube",
},
{TWITTER, INSTAGRAM, TELEGRAM, YOUTUBE} = MEDIA_NAMES;

export default withStyles(style)(function GiftDappProfileSocialMedia ({
    classes, data={}, onChange=()=>{}, verify=()=>{}, unverify=()=>{}, 
    processes={}, isView
}) {
    const getIcon = name => {
            switch(name){
                case TWITTER : return faTwitter;
                case TELEGRAM : return faTelegram;
                case INSTAGRAM : return faInstagram;
                case YOUTUBE : return faYoutube
            }
        },
        mediaIcon = name => () => <FontAwesomeIcon size="lg" className={classes.icon} icon={getIcon(name)}/>;
    return (
        <div className={classes.root}>
            <div className={classes.headerContainer}>
                <div className={classes.header}>Social Media</div>
            </div>
            <div className={classes.body}>
                {[/* INSTAGRAM,  */TWITTER, /* TELEGRAM, */ YOUTUBE].map((name, index) => {
                    return isView ? <GiftDappProfileSocialMediaItemView
                            key={index}
                            data={data[name]}
                            icon={mediaIcon(name)}
                            isVerified={data[name]?.[PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]}
                        /> : <GiftDappProfileSocialMediaItem
                            key={index}
                            data={data[name]}
                            icon={mediaIcon(name)}
                            isVerified={data[name]?.[PROFILE_SOCIAL_MEDIAL_KEYS.IS_VERIFIED]}
                            verify={() => verify(name)}
                            unverify={() => unverify(name)}
                            onChange={onChange(name)}
                            isProcessing={processes[name]}
                            name={name}
                    />
                })}
            </div>
        </div>
    )
})