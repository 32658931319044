import { withStyles, withWidth } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { CustomButton } from '../../../includes';
import { appTheme, customSuperSetState } from '../../../utils';
import './index.css';
import { giftRewardStrings, REWARD_FIELD_NAMES } from '../config';
import { connect } from 'react-redux';
const style = ({colors, breakpoints, isDark}) => ({
    root : {
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        fontSize : 12,
        [breakpoints.only("xs")] : {
            fontSize : 9
        },
        [breakpoints.only("sm")] : {
            fontSize : 11
        },
    },
    stand : {
        marginTop: -130,
        [breakpoints.up("md")] : {
            maxWidth : 350,
            width: "70%",
        }
    },
    standsub : {
        paddingTop : 200,
        padding : 20,
        display : "flex",
        justifyContent : "center",
        borderRadius : 20,
        background : colors.primary,
        paddingBottom : 80,
    },
    spinInfo : {
        border : "solid 1px " + colors.accent,
        borderRadius : 20,
        background : colors.background,
        fontSize : 12,
        color : colors.accent,
        padding : 20,
        display : "flex",
        justifyContent : "center",
        textAlign :"center",
        // margin : 20,
        // marginTop : 150,
    },
    btnContainer : {
        marginTop : 20,
    },
    btn : {
        background : !isDark && colors.backgroundDark
    }
})
class _GiftDappRewardSpin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            selectedField : null,
            spinDuration : "4s",
            spinTurn : 5,
        };  
        this.selectItem = this.selectItem.bind(this);
    }
    componentDidMount() {
        this.rootElement.scrollTop = 0;
    }
    startSpin = (selectedItem, selectedField) => this.setState(
        { selectedItem}, () => setTimeout(
            () => {
                this.setState({selectedField})
                if (this.props.onSelectItem) {
                    this.props.onSelectItem(selectedField);
                }
            }
        , 4000)
    );
    selectItem() {
        const { 
            [giftRewardStrings.rewardData] : rewardData,
            claim, claimSpinFields, preSpin
        } = this.props;
        if(preSpin && !preSpin(customSuperSetState(this))) return;
        const items = claim ? claimSpinFields : (rewardData && rewardData[REWARD_FIELD_NAMES.SPINE_FIELD_VALUES]);
        if (this.state.selectedItem === null) {
            const maxDif = {max : 0, min : 0};
            const mItems = items ? items.map(item=>{
                const {difficulty} = item, 
                dif = difficulty && difficulty.split(":"),
                max = dif && dif[1];
                if(!isNaN(parseInt(max)) && parseInt(max) > maxDif.max)
                    maxDif.max = parseInt(max);
                return {...item, probability : parseInt(dif?.[1])};
            }) : [];
            if(maxDif.max === 0) maxDif.max = 10000;
            let probabilityArray = [];
            for (let i = 0; i < mItems.length; i++) {
                const {probability, spinIndex} = mItems[i];
                const length = maxDif.max / (probability || maxDif.max / 2);
                probabilityArray = probabilityArray.concat(new Array(Math.floor(length)).fill(spinIndex));
            }
            const selectedSpinIndex = probabilityArray[Math.floor(Math.random() * probabilityArray.length)];
            const selectedItem = items && items.findIndex(item=>item.spinIndex === selectedSpinIndex);
            const selectedField = items && items[selectedItem];
            if(this.props.onStartSpin) this.props.onStartSpin(this.startSpin.bind(this), selectedItem, selectedField);
            else this.startSpin(selectedItem, selectedField);
            
        } else {
            this.setState({ selectedItem: null, selectedField : null });
            setTimeout(this.selectItem, 500);
        }
    }
    getWheelSize(width){
        switch(width){
            case "xs" : return "200px"
            case "sm" : return "300px";
        }
    }
    render() {
        const {classes, width, claim, claimSpinFields, preview, content : Content, footer : Footer} = this.props; 
        const { selectedItem, selectedField, spinDuration, spinTurn } = this.state,
        _value = selectedField?.value ? selectedField.value : "",
        _label = selectedField?.label ? selectedField.label : "",
        rewardLabel = selectedField && (_value + (selectedField?.address !== "text" ? " " + _label : "")),
        boxDisplay = selectedItem && !selectedField ? "Spinning ..." : (rewardLabel || "Tap the spin to play"),
        { [giftRewardStrings.rewardData] : rewardData } = this.props,
        items = claim ? claimSpinFields :
            rewardData && rewardData[REWARD_FIELD_NAMES.SPINE_FIELD_VALUES],
        wheelSize = this.getWheelSize(width),
        wheelVars = {
            // "--wheel-color" : appTheme.secondary,
            "--wheel-size" : wheelSize,
            '--nb-item': items.length,
            '--selected-item': selectedItem,
            '--nb-turn' : claim && spinTurn,
            '--spinning-duration' : claim && spinDuration,
            // backgroundColor : "#06044e"
        },
        spinning = selectedItem !== null ? 'spinning' : '';
        /**
         * --nb-turn: 45000;
         * --spinning-duration: 20000s;
         */
        return (
            <div className={classes.root} ref={e=>this.rootElement = e}>
                <div className="wheel-container" style={{
                    "--wheel-size" : wheelSize,
                }}>
                    <div className={`wheel ${spinning}`} style={wheelVars} onClick={this.selectItem}>
                    {items.map((item, index) => {
                        const {address, value, spinIndex, label} = item,
                        _value = value ? value : "",
                        _label = label ? label : "",
                        display = address === "text" ? _value : _value + " " + _label;
                        return <div className="wheel-item" key={index} style={{
                            "--wheel-size" : wheelSize, 
                            '--item-nb': index,
                            '--item-background' : index % 2 === 1 ?  "#004BA8": "#002F68"
                        }}>
                            {display}
                        </div>
                    })}
                    </div>
                </div>
                <div className={classes.stand} style={{width : wheelSize}}>
                    <div className={classes.standsub}>
                        <div className={classes.spinInfo}>
                            {claim && /* rewardLabel && */ Content ? <Content 
                                selectItem={selectedItem}
                                selectedField={selectedField}
                                boxDisplay={boxDisplay}
                            /> : (boxDisplay)}
                        </div>
                        {Footer && <Footer 
                            selectItem={selectedItem}
                            selectedField={selectedField}
                            rewardLabel={rewardLabel}
                        />}
                    </div>
                    <div className={classes.btnContainer}>
                        <CustomButton
                            fullWidth
                            onClick={this.props.onBack}
                            type="background"
                            classes={{
                                btn : classes.btn
                            }}>
                                Back
                            </CustomButton>
                    </div>
                </div>
            </div>
        );
    }
}
_GiftDappRewardSpin.propTypes = {
    classes : PropTypes.object,
    items : PropTypes.array,
    onSelectItem : PropTypes.func,
    onBack : PropTypes.func,
    claim : PropTypes.bool,
    claimSpinFields : PropTypes.array,
    content : PropTypes.func,
    preSpin : PropTypes.func,
    footer : PropTypes.func,
    onStartSpin : PropTypes.func,
    preview : PropTypes.func,
}
const mapStateToProps = ({giftRewardReducer}) => ({
    [giftRewardStrings.rewardData] : giftRewardReducer[giftRewardStrings.rewardData],
})  
export const GiftDappRewardSpin = connect(mapStateToProps)(withWidth()(withStyles(style, {withTheme : true})(_GiftDappRewardSpin)));
export {GiftDappRewardSpin as default};