import { giftRewardConstants } from "./config";

export const giftRewardActions = {
    addToSelectedToken : payload => dispatch => dispatch({
        type : giftRewardConstants.SET_SELECTED_TOKEN,
        payload
    }),
    resetRewardData : payload => dispatch => dispatch({
        type : giftRewardConstants.RESET_GIFT_REWARD_SPIN_DATA,
        payload
    }),
    payload : payload => dispatch => dispatch({
        type : giftRewardConstants.SET_GIFT_REWARDS,
        payload
    })
}