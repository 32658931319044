import { Grid, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { giftDappProfileActions } from '../../../../GiftDappProfile/actions';
import { giftDappProfileStrings, GIFT_DAPP_PROFILE_REDUCER_NAME } from '../../../../GiftDappProfile/config';
import { giftRewardActions } from '../../../actions';
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from '../../../config';
import GiftDappRewardSetupProfileOptionBox from '../GiftDappRewardSetupProfileOptionBox/GiftDappRewardSetupProfileOptionBox';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        
    },
    boxRoot : {
        minHeight : 100,
        display : "flex",
        flexDirection : "row",
        paddingLeft : 50,
        alignItems : "center",
        // justifyContent : "center",
    },
    checkContainer : {
        left : 10,
        top : 40,
    },
    imageContainer : {
        margin : 5,
    },
    labelContainer : {
        margin : 5,
    },
    image : {
        width : 30,
        height : 30,
    }
}))
export default function GiftDappRewardSetupProfileList ({profiles, lastUsedProfile}) {
    const classes = style(),
    dispatch = useDispatch(),
    giftRewardReducer = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME]),
    {
        [giftRewardStrings.creatorId] : creatorId,
    } = giftRewardReducer,
    onSelect = useCallback(profileId => {
        giftRewardActions.payload({
            [giftRewardStrings.creatorId] : profileId
        })(dispatch);
    }, [JSON.stringify(dispatch)]);
    return (
        <div className={classes.root}>
            <Grid  container spacing={2}>
                {profiles?.map((profile, index) => {
                    const {_id : profileId, image, lastname, firstname} = profile;
                    let label = "";
                    if(lastname) label += lastname + " ";
                    if(firstname) label+= firstname;
                    return <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
                        <GiftDappRewardSetupProfileOptionBox
                            label={label}
                            image={image}
                            onSelect={() => onSelect(profileId)}
                            selected={Boolean(profileId === creatorId)}
                            classes={{
                                root : classes.boxRoot,
                                checkContainer : classes.checkContainer,
                                imageContainer : classes.imageContainer,
                                labelContainer : classes.labelContainer,
                                image : classes.image,
                                icon : classes.image,
                            }}
                        />
                    </Grid>
                })}
            </Grid>
        </div>
    )
}