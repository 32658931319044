import { ContactSupportOutlined } from "@material-ui/icons";
import { ZERO_ADDRESS } from "@openzeppelin/test-helpers/src/constants";
import BigNumber from "bignumber.js";
import { useDispatch, useSelector, useStore } from "react-redux"
import Web3 from "web3";
import { CONTRACTS } from ".";
import { fieldTexts } from "..";
import { giftRewardActions, giftRewardStrings, GIFT_REWARD_REDUCER_NAME, walletActions, walletStrings, WALLET_REDUCER_NAME } from "../../components";
import { contractLabels, store } from "../../helpers";
import { errorToast } from "../../includes";
import { DATE_TIME } from "../constants";
import { getContractInstance } from "./getContract";
import tokenContractActions from "./tokenContractActions";
export default function giftContractActions (isProject){
    const {
        [WALLET_REDUCER_NAME] :  walletReducer,
        [GIFT_REWARD_REDUCER_NAME] : giftRewardReducer,
    } = store.getState() || {},
    {
        [walletStrings.contracts] : contracts,
        [walletStrings.address] : address,
        [walletStrings.web3] : web3,
        [walletStrings.addresses] : addresses,
    } = walletReducer || {}, 
    {
        [giftRewardStrings.rewardData] : rewardData,
    } = giftRewardReducer, 
    { isProject : _isProject } = rewardData || {},
    {
        [CONTRACTS.GIFT_PROJECT] : giftProjectContract,
        [CONTRACTS.GIFT_INFLUENCER] : giftInfluencerContract
    } = contracts || {};
    if(isProject === undefined) isProject = _isProject;
    const instance = isProject ? giftProjectContract : giftInfluencerContract,
    _decimals = 18,
    dispatch = store.dispatch,
    reducerNumber = amount => {
        return amount / (10 ** _decimals);
    },
    expandNumber = amount => {
        return amount * 10 ** _decimals;
    },
    reverseDenominator = (value) => {
        const backwardValues = [10, 25, 50, 100, 250],
        forwardValues = [250, 100, 50, 25, 10];
        const valueIndex = backwardValues.findIndex(item=>item === parseInt(value));
        return forwardValues[valueIndex];
    },
    sendOptions = {
        from : address,
        // gasPrice : web3.utils.toWei("10", "gwei"), //you can use gasnow api
        // gasLimit : // metamask and wallet already does this
    }
    return {
        switchContract : () => walletActions({
            ...walletReducer,
            [walletStrings.contracts] : {
                ...contracts,
                [CONTRACTS.GIFT] : instance
            },
            [walletStrings.giftContractAddress] : addresses && addresses[isProject ? contractLabels.gift_project : contractLabels.gift_influencer]
        })(dispatch),
        createRewardProject : async (
            retryCount=0, 
            retryPeroid=0, 
            activePeriodInWeeks=0,
            tokenValues=[], 
            tokenSpins=[]
        ) => {
            const rewardBudget = [],
            spinInfo = [],
            activePeriodInDays = activePeriodInWeeks * 7;
            retryPeroid *= DATE_TIME.SECONDS_IN_AN_HOUR;
            if(tokenValues) for (let i = 0; i < tokenValues.length; i++) {
                let {address, amount} = tokenValues[i];
                const tokenActions = tokenContractActions(await getContractInstance(web3, null, address));
                const tokenDecimal = await tokenActions.decimals();
                amount = new BigNumber(String(amount * 10 ** parseInt(tokenDecimal))).toFixed(0);
                rewardBudget.push(web3.eth.abi.encodeParameters(
                    ["address", "uint256"], 
                    [address, amount]
                ));
            }
            if(tokenSpins) for (let i = 0; i < tokenSpins.length; i++) {
                let {address, amount=0, denominator, textIndex=0} = tokenSpins[i];
                if(address !== ZERO_ADDRESS){
                    const tokenActions = tokenContractActions(await getContractInstance(web3, null, address));
                    const tokenDecimal = await tokenActions.decimals();
                    amount = new BigNumber(String(amount * 10 ** parseInt(tokenDecimal))).toFixed(0);
                }
                spinInfo.push(web3.eth.abi.encodeParameters(
                    ["uint8", "uint8", "address", "uint256"],
                    [textIndex, reverseDenominator(denominator), address, amount]
                ));
            }
            return await instance?.methods?.createRewardProject(
                retryCount, retryPeroid, activePeriodInDays, rewardBudget, spinInfo
            ).send({from : address})
        },
        editRewardProject : async (
            projectIndex, 
            retryCount=0, 
            retryPeroid=0, 
            activePeriodInWeeks=0, 
            tokenValues=[],
            tokenSpins=[]
        ) => {
            const rewardBudget = [],
            spinInfo = [],
            activePeriodInDays = activePeriodInWeeks * 7;
            retryPeroid *= DATE_TIME.SECONDS_IN_AN_HOUR;
            if(tokenValues) for (let i = 0; i < tokenValues.length; i++) {
                let {address, amount} = tokenValues[i];
                const tokenActions = tokenContractActions(await getContractInstance(web3, null, address));
                const tokenDecimal = await tokenActions.decimals();
                amount = new BigNumber(String(amount * 10 ** parseInt(tokenDecimal))).toFixed(0);
                rewardBudget.push(web3.eth.abi.encodeParameters(
                    ["address", "uint256"], 
                    [address, amount]
                ));
            }
            if(tokenSpins) for (let i = 0; i < tokenSpins.length; i++) {
                let {address, amount, denominator, textIndex} = tokenSpins[i];
                if(address !== ZERO_ADDRESS){
                    const tokenActions = tokenContractActions(await getContractInstance(web3, null, address));
                    const tokenDecimal = await tokenActions.decimals();
                    amount = new BigNumber(String(amount * 10 ** tokenDecimal)).toFixed(0);
                }
                const encoded = web3.eth.abi.encodeParameters(
                    ["uint8", "uint8", "address", "uint256"],
                    [textIndex || 0, reverseDenominator(denominator), address, amount]
                );
                spinInfo.push(encoded);
            }
            return await instance?.methods?.editRewardProject(
                projectIndex, retryCount, retryPeroid, activePeriodInDays, 
                rewardBudget, spinInfo
            ).send(sendOptions)

        },
        spinAndClaim : async (projectIndex, linkCreator) => {
            return instance?.methods?.spinAndClaim(projectIndex, linkCreator).send(sendOptions)
        },
        claimReward : async (projectIndex, tickerIndex) => {
            return instance?.methods?.claimReward(projectIndex, tickerIndex).send(sendOptions)
        },
        claimAndAddReferralSpin : async (projectIndex, tickerNum, linkCreatorAddress) => {
            return instance?.methods?.claimAndAddReferralSpin(projectIndex, tickerNum, linkCreatorAddress).send({
                from : address
            })
        },
        projectClaimsByProjectId : async projectIndex => {
            return await instance?.methods?.projectClaimsByProjectId(projectIndex).call()
        },
        //get rewardProject.claimCounts
        getProjectTokenBalance : async (projectIndex, tokenAddress) => {
            const tokenActions = tokenContractActions(await getContractInstance(web3, null, tokenAddress));
            return tokenActions.reducerNumber(await instance?.methods?.getProjectTokenBalance(projectIndex, tokenAddress).call());
        },
        //claimCount projectOwner retryCount retryPeroid rewardProjectID status
        getRewardById : async projectIndex => instance?.methods?.rewardProjMapping(projectIndex).call(),
        getUserClaimData : async (projectIndex, userAddress=address) => {
            return instance?.methods?.projectClaims(projectIndex, userAddress).call();
        },
        getProjectTokens : async projectIndex => instance?.methods?.getProjectTokens(projectIndex).call(),
        getProjectTickers : async (projectIndex, textList) => {
            const tickers = await instance?.methods?.getProjectTickers(projectIndex).call();
            return tickers && tickers.map((i, index)=>{
                let value, isAuto;
                if(i.token === ZERO_ADDRESS){
                    if(textList?.length > 0){
                        const text = textList.find(item=>item.spinIndex === index)
                        if(text) {
                            value = text.value;
                            isAuto = false;
                        }
                        else {
                            value = fieldTexts[i.textIndex]
                            isAuto = true;
                        }
                    } else {
                        value = fieldTexts[i.textIndex];
                        isAuto = true;
                    }
                    i = {...i, value, isAuto};
                }
                return {...i, weight : reverseDenominator(i["weight"])}
            });
        },
        RGPTokenAddress : async () => instance?.methods?._RGPTokenAddress().call(),
        subscriptionFee : async () => reducerNumber(await instance?.methods?._subscriptionFee().call()),
        perSpinFee : async () => reducerNumber(await instance?.methods?._perSpinFee().call()),
        maxBuyableSpins : async () => instance?.methods?._maxBuyableSpins().call(),
        maxReferralSpins : async () => instance?.methods?._maxReferralSpins().call(),
        buySpin : async (projectIndex, spinCount) => instance?.methods?.buySpin(projectIndex, spinCount).send(sendOptions),
        closeProjectWithdrawTokens : async projectIndex => instance?.methods?.closeProjectWithdrawTokens(projectIndex).send(sendOptions),
        getPastEvents : async (eventName, option) => instance?.getPastEvents(eventName, option),
    };
}
export {giftContractActions};