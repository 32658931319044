import { withStyles } from '@material-ui/core'
import React from 'react'
const style = ({colors, breakpoints}) => ({
    root : {
        background : colors.primary,
        color : colors.text,
        borderRadius : "100%",
        fontSize : 7,
        height : 25,
        width : 25,
        margin : 5,
        display : 'flex',
        alignItems : "center",
        justifyContent : "center",
    }
})
export default withStyles(style)(function GiftDappWalletTextIcon ({classes}) {
    return (
        <div className={classes.root}>
            TEXT
        </div>
    )
})