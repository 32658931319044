import { makeStyles, useMediaQuery, useTheme, ButtonBase, MenuItem, ListItemIcon, ListItemText, Menu, Paper, MenuList, Typography, ListItem, Box, Grid } from '@material-ui/core'
import React from 'react'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from 'react-router-dom';
import DrawerComponent from './DrawerComponent/DrawerComponent';


const style = makeStyles(({ colors, breakpoints }) => ({
  root: {
    backgroundColor: "#000C15",
    padding: "0.5rem 2.5rem",
    [breakpoints.down('sm')]: {
      padding: "1.5rem",
    }

  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoContainer: {
    height: '3rem',
    display: "flex",
    alignItems: "flex-start",
    [breakpoints.down('sm')]: {

    }
  },
  logoImage: {
    width : 20,
    height : 20,
    marginRight: ".5rem",
  },
  logoText: {

  },
  brand: {
    display: "block",
    fontSize: "2rem",
    lineHeight: "2rem",
    verticalAlign: "bottom",
    fontWeight: 700,
    marginTop: 0,
    [breakpoints.down('sm')]: {
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
    },
  },
  appName: {
    display: "none",
    [breakpoints.down('sm')]: {
      display: "block",
      color: "#4cafff",
      fontSize: "1rem",
      weight: 400,
    },
  },
  navlinks: {
    display: 'inline - block'
  },
  link: {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    margin: "0 1.5rem",
    color: "#fff",
    textDecoration: "none",
    cursor: "pointer"

  },
  headerBtn: {
    backgroundImage: "linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
    color: "#fff",
    fonWeight: "700",
    borderRadius: ".5rem",
    border: "none",
    outline: "none",
    width: "215px",
    height: "48px"
  },
  smalllogo: {
    height: 16,
    width: 16,
    marginLeft: "2px",
    marginTop: "2px",
  },
  DColor: {
    color: "#9BD0FD"
  },
  popoverPaper: {
    width: 'auto',
    height: '80%',
    maxHeight: 'unset',
    left: '5% !important',
  },
}))
export default function LandingHeader() {
  const classes = style();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorDap, setAnchorDap] = React.useState(null);
  const [anchorCompany, setAnchorCompany] = React.useState(null)


  const handleClickOnDaps = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClickOnDaps1 = (event) => {
    setAnchorDap(event.currentTarget);
  }

  const handleClickOnCompany = (event) => {
    setAnchorCompany(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleClosedap1 = () => {
    setAnchorDap(null);
  }

  const handleCloseCompany = () => {
    setAnchorCompany(null);
  }

  const dappsDropDownItems = [
    { text: "SmartSwap", title: "Swap tokens directly", link: "https://smartswap.rigelprotocol.com/" },
    { text: "Gift DApp", title: "Gifts token in fun way.", link: "https://gift.rigelprotocol.com/" },
    { text: "SmartBid", title: "Bid on tokens", link: "#" },
    { text: "Leverage Exchange", title: "Trade using decentralized tokens", link: "#" },
    { text: "Launchpad", title: "Join Project hosted on RigelProtocol", link: "#" },
  ]

  const companyDropDownItems = [
    { title: "Learn more about rigelProtocol", text: "About us", link: "https://www.rigelprotocol.com/about" },
    { title: "See Available positions", text: "Career", link: "https://www.rigelprotocol.com/career" },
    { title: "Press & media", text: "Press resources", link: "https://www.rigelprotocol.com/press" },
  ]

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <img
            src="rigel_icon.png"
            className={classes.logoImage}
            alt="LaunchPad Rigel Protocol"
          />
        </div>
        {isMobile ?
          (
            <DrawerComponent />
          ) : (
            <>
              <div className={classes.navlinks}>


                <a className={classes.link} onClick={handleClickOnCompany}>
                  Company

                  <img
                    src="/header/arrow-down.svg"
                    className={classes.smalllogo}
                    alt={" LaunchPad arrow down"}
                  />
                </a>


                <a onClick={handleClickOnDaps1} className={classes.link}>
                  <span className={classes.DColor}>
                    DApps
                    <img
                      src="/header/arrow-down.svg"
                      className={classes.smalllogo}
                      alt={" LaunchPad arrow down"}
                    />
                  </span>
                </a>
                <a href='https://www.rigelprotocol.com/press.html' target='_blank' className={classes.link}>
                  Events
                </a>
                <a href='https://medium.com/rigelprotocol' target='_blank' className={classes.link}>
                  Blogs
                  <img
                    src="/header/export.svg"
                    className={classes.smalllogo}
                    alt={" LaunchPad arrow down"}
                  />
                </a>
              </div>

              <div>
                <ButtonBase
                  className={classes.headerBtn} onClick={handleClickOnDaps}>
                  Launch DApp
                  <img
                    src="/header/arrow-down.svg"
                    className={classes.smalllogo}
                    alt={" LaunchPad arrow down"}
                  />
                </ButtonBase>

                <Menu

                  id="basic-button"
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'bottom' }}

                  transformOrigin={{ vertical: "top" }}
                  getContentAnchorEl={null}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >


                  <MenuList dense>
                    {dappsDropDownItems.map((item, index) => (
                      <MenuItem key={index}>
                        <ListItem >
                          <ListItemText>
                            <a style={{ textDecoration: "none", color: "#000C15" }} target='blank' href={item.link}><Box fontWeight="fontWeightBold" fontSize={14} >{item.text}  </Box></a>
                            <Box fontWeight="fontWeightLight" fontSize={12} paddingTop={1}> {item.title}</Box>
                          </ListItemText>
                        </ListItem>

                      </MenuItem>
                    ))}

                  </MenuList>

                </Menu>
                <Menu

                  id="basic-button"
                  anchorEl={anchorDap}
                  anchorOrigin={{ vertical: 'bottom' }}

                  transformOrigin={{ vertical: "top" }}
                  getContentAnchorEl={null}
                  open={Boolean(anchorDap)}
                  onClose={handleClosedap1}
                >


                  <MenuList dense>
                    {dappsDropDownItems.map((item, index) => (
                      <MenuItem key={index}>
                        <ListItem >
                          <ListItemText>
                            <a style={{ textDecoration: "none", color: "#000C15" }} target='blank' href={item.link}><Box fontWeight="fontWeightBold" fontSize={14} >{item.text}  </Box></a>
                            <Box fontWeight="fontWeightLight" fontSize={12} paddingTop={1}> {item.title}</Box>
                          </ListItemText>
                        </ListItem>

                      </MenuItem>
                    ))}

                  </MenuList>

                </Menu>


                <Menu

                  id="basic-button"
                  anchorEl={anchorCompany}
                  anchorOrigin={{ vertical: 'bottom' }}

                  transformOrigin={{ vertical: "top" }}
                  getContentAnchorEl={null}
                  open={Boolean(anchorCompany)}
                  onClose={handleCloseCompany}
                >


                  <MenuList dense>
                    {companyDropDownItems.map((item, index) => (
                      <MenuItem key={index}>
                        <ListItem >
                          <ListItemText>
                            <a style={{ textDecoration: "none", color: "#000C15" }} target='blank' href={item.link}><Box fontWeight="fontWeightBold" fontSize={14} >{item.text}  </Box></a>
                            <Box fontWeight="fontWeightLight" fontSize={12} paddingTop={1}> {item.title}</Box>
                          </ListItemText>
                        </ListItem>

                      </MenuItem>
                    ))}

                  </MenuList>

                </Menu>
              </div>
            </>
          )
        }

      </Toolbar>
    </AppBar>
  )
}