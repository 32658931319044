export * from './accountListeners';
export * from './getContract';
export * from './getWeb3';
export * from './tokenContractActions';
export * from './walletConnection';
export * from './walletUtil';
export * from './giftContract';
export const CONTRACTS = {
    GIFT : "rigelGiftContract",
    GIFT_INFLUENCER : "rigelGiftInfluencerContract",
    GIFT_PROJECT : "rigelGiftProjectContract",
    RIGEL_TOKEN : "rigelTokenContract",
    BUSC_TOKEN : "buscTokenContract",
    USDT_TOKEN : "usdTokenContract",
    BUSC_RGP_SALE : "busc_rgp_TokenSalesContract",
    USDT_RGP_SALE : "usdt_rgp_TokenSalesContract",
}