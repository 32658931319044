import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames';
import { ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core'
import CustomInputFields from '../../../../../../includes/CustomInputFields/CustomInputFields'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { customPopoverActions, customPopoverString, CUSTOM_POPOVER_REDUCER_NAME, errorToast, warningToast } from '../../../../../../includes'
import { GIFT_REWARD_REDUCER_NAME, giftRewardStrings, REWARD_FIELD_NAMES } from "../../../../config";
import GiftDappWalletTextIcon from '../../../../../structures/GiftDappWallets/GiftDappWalletTextIcon/GiftDappWalletTextIcon'
import GiftDappWalletTokenIcon from '../../../../../structures/GiftDappWallets/GiftDappWalletTokenIcon/GiftDappWalletTokenIcon'
const style = makeStyles(({breakpoints, colors, isDark, transitions}) => ({
    root : {
        fontSize : 10,
        color : colors.textSecondary,
    },
    listItem : {
        minWidth : 100,
        "&:hover" : {
            background : colors.backgroundAccent,
            color : isDark && colors.textDark,
        }
    },
    list : {
        color : colors.text,
        padding : 5,
        "&:hover" : {
            color : isDark && colors.textDark,
        }
    },
    button : {
        padding : 0,
    },
    topRow : {
        display : "flex",
        justifyContent : "space-between",
    },
    difficultyContainer : {
        borderRadius : "5px 5px 0 0",
        display : "flex",
        alignItems : "flex-start",
        background : colors.background,
        // padding : "10px 20px 0px 20px",
        padding : "5px 10px",
    },
    difficultyTextSpan : {
        paddingTop: "3px",
        color:colors.tertiaryTextColor
    },
    dropdownValue : {
        padding : 8,
        display : 'flex',
        alignItems : "center",
        borderRadius:"0px 0px 4px 0px",
        border : `solid 1px ${!isDark ? colors.setupLiner : ""}`,
        background : isDark && colors.tertiaryColor ,
    },
    diffDropdown : {
        paddingLeft : 10,
        display : 'flex',
        alignItems : "center",
    },
    buttomRow : {
        display : "flex",
    },
    tokenContainer : {
        display : "flex",
        cursor : "pointer",
        alignItems : "center",
    },
    avatarRoot : {
        minWidth : 30
    },
    avatar : {
        color : colors.text
    },
    error : {
        color : "red",
        fontSize : 10,
        marginLeft : 10
    }, 
    formField : {
        marginTop : 0,
    },
    notchedOutline : {
        borderRadius : "5px 0 0 5px",
    },
    textIcon : {
        width : 20,
        height : 20,
        fontSize : 9,
        margin : 0,
    },
    textIconInline : {
        margin : "0px 5px",
        padding : 0,
        width : 21,
        height : 19,
    },
    numberContainer : {
        [breakpoints.down("sm")] : {
            display : "flex",
            flexDirection : "column",
        }
    },
    blink : {
        animationName : "$blinkAnimation",
        animationDuration : "500ms",
        animationIterationCount : "infinite",
        animationDirection : "alternate",
        animationTimingFunction : "ease-in-out"
    },
    "@keyframes blinkAnimation" : {
        from : {
            border : "solid 1px red",
        },
        to : {
            border : "none",
        },
    }
}))
const generalOptions = [
    // {_id : "1:10000", name : "1:10000"},
    // {_id : "1:5000", name : "1:5000"},
    // {_id : "1:2500", name : "1:2500"},
    // {_id : "1:1000", name : "1:1000"},
    {_id : "1:500", name : "1:500"},
    {_id : "1:250", name : "1:250"},
    {_id : "1:100", name : "1:100"},
    {_id : "1:50", name : "1:50"},
    {_id : "1:25", name : "1:25"},
    {_id : "1:10", name : "1:10"},
];
export const tokenDiffOptions = [
    {_id : "1:10", name : "1:10"},
    {_id : "1:25", name : "1:25"},
    {_id : "1:50", name : "1:50"},
    {_id : "1:100", name : "1:100"},
    {_id : "1:250", name : "1:250"},
];
export const textDiffOptoins = [
    {_id : "1:25", name : "1:25"},
    {_id : "1:10", name : "1:10"},
]
export const spinFieldChances = generalOptions;
function GiftDappRewardSetupGameSetupSlotField({spinIndex, setSpinFieldValue}) {
    const dispatch = useDispatch(),
    classes = style(),
        [state, _setState] = useState({
            highlightSpinType : false
        }),
        {highlightSpinType} = state,
        setState = _state => _setState(state=>({...state, ..._state})),
    giftRewardReducer = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME]),
    {
        [giftRewardStrings.rewardTokens] : rewardTokens,
        [giftRewardStrings.rewardData] : rewardData,
        [giftRewardStrings.isEdit] : isEdit,
        [giftRewardStrings.selectedRewardTokens] : selectedRewardTokens,
    } = giftRewardReducer,
    fieldValues = rewardData && rewardData[REWARD_FIELD_NAMES.SPINE_FIELD_VALUES],
    data = fieldValues && fieldValues.find(item=>item?.spinIndex === spinIndex),
    {difficulty, address, value} = data || {};
    let token, TokenName, TokenIcon, options;
    if(address === "text") {
        TokenName = "Text";
        TokenIcon = () => <GiftDappWalletTextIcon classes={{root : classes.textIconInline}}/>
        options = textDiffOptoins;
    }else {
        token = address && selectedRewardTokens.find(item=>item.address === address);
        TokenIcon = () => <GiftDappWalletTokenIcon display symbol={token.symbol}/> // token?.icon;
        TokenName = token?.symbol;
        options = tokenDiffOptions;
    };
    const popoverId = useSelector(state=>state[CUSTOM_POPOVER_REDUCER_NAME][customPopoverString.popoverId]),
    spinDiffId = "spinDiff:"+spinIndex,
    spinTokenId = "spinToken:"+spinIndex,
    closePopover = useCallback(() => customPopoverActions.close(dispatch), []),
    customListItem = useCallback(onClick => ({item : {_id, name}}) => {
        return <ListItem
            classes={{
                button : classes.button
            }}
            className={classes.listItem} button onClick={()=>{
                onClick && onClick(rewardData, "difficulty", _id, null, closePopover);
            }}
        >
            <ListItemText secondary={name} secondaryTypographyProps={{
                className : classes.list
            }}/>
        </ListItem>
    }, [JSON.stringify(rewardData)]),
    showDifficultPopover = useCallback(({target : anchorEl}) => {
        if(!address) {
            setState({highlightSpinType : true});
            warningToast("Select a slot type")(dispatch);
            setTimeout(
                () => setState({highlightSpinType : false}),
                5000
            ); 
            return;
        }
        while(String(anchorEl.tagName).toUpperCase() !== "DIV")
            anchorEl = anchorEl.parentElement
        customPopoverActions.payload({
            [customPopoverString.popoverId] :  spinDiffId,
            [customPopoverString.anchorEl] :  anchorEl, 
            [customPopoverString.options] : options,
            [customPopoverString.customListItem] :  customListItem(setSpinFieldValue),
            [customPopoverString.anchorOrigin] : {horizontal : "center"},
        })(dispatch);
    }, [address]),
    tokenCustomListItem = useCallback(onClick => ({ item : address}) => {
        let label, Avatar, isText;
        if(address === "text") {
            isText = true;
            label = "Text";
            Avatar = () => <GiftDappWalletTextIcon classes={{root : ""/* classes.textIcon */}}/>
        }
        else {
            const token = selectedRewardTokens.find(item=>item.address === address);
            label = token?.symbol; 
            Avatar = () => <GiftDappWalletTokenIcon display symbol={token.symbol}/>
        }
        return <ListItem
            classes={{
                button : classes.button
            }}
            className={classes.listItem} button onClick={()=>{
                onClick && onClick(rewardData, "address", address, !isText ? {label} : null, closePopover);
            }}
        >
            {Avatar && <ListItemAvatar classes={{
                root : classes.avatarRoot
            }}>
                <Avatar style={{color : "white"}}/>
            </ListItemAvatar>}
            <ListItemText secondary={label} secondaryTypographyProps={{
                className : classes.list
            }}/>
        </ListItem>
    }, [JSON.stringify(rewardData), JSON.stringify(selectedRewardTokens)]),
    showTokens = useCallback(({target : anchorEl}) => {
        if(isEdit) return errorToast(
            "Token type can not be changed in edit mode"
        )(dispatch);
        // if(!rewardTokens || rewardTokens.length === 0) return warningToast("Select reward tokens")(dispatch);
        while(String(anchorEl.tagName).toUpperCase() !== "DIV")
            anchorEl = anchorEl.parentElement
        customPopoverActions.payload({
            [customPopoverString.popoverId] :  spinTokenId,
            [customPopoverString.anchorEl] :  anchorEl,
            [customPopoverString.options] :  rewardTokens ? [...rewardTokens, "text"] : ["text"],
            [customPopoverString.customListItem] :  tokenCustomListItem(setSpinFieldValue),
            [customPopoverString.anchorOrigin] : {horizontal : "left"},
        })(dispatch);
    }, [isEdit, JSON.stringify(rewardTokens), JSON.stringify(rewardData)]);
    // const tokenIsRemoved = address && address !== "text" && (!rewardTokens || rewardTokens.indexOf(address) === -1);
    const tokenIsRemoved = (difficulty && !address);
    return (
        <div className={classes.root}>
            <div className={classes.topRow}>
                <div className={classes.numberContainer}>
                    Slot: {spinIndex + 1} {tokenIsRemoved && <small className={classes.error}>
                        Token has be removed
                    </small>}
                </div>
                <div className={classes.difficultyContainer}>
                    <span className={classes.difficultyTextSpan}>
                    Spin difficulty:    
                    </span>
                    
                    <div className={classes.diffDropdown} onClick={showDifficultPopover}>
                        {difficulty || "Diff"}
                        {popoverId === spinDiffId ? <ArrowDropUp fontSize="small"/> : <ArrowDropDown fontSize="small"/>}
                    </div>
                </div>
            </div>
            <div className={classes.buttomRow}>
                <CustomInputFields
                    field={{
                        name : "spin-" + spinIndex,
                        label : "Amount", margin : "dense",
                        value : value || "",
                        // type : address !== "text" ? "number" : "text",
                        onChange : ({target : {value}}) => setSpinFieldValue(rewardData, "value", value),
                    }}
                    classes={{
                        formField : classes.formField,
                        notchedOutline : classes.notchedOutline
                    }}
                />
                <div className={classNames(
                    classes.dropdownValue, 
                    highlightSpinType ? classes.blink : ""
                )} onClick={showTokens}>
                    {TokenName ? <div className={classes.tokenContainer}>
                        {TokenIcon && <TokenIcon 
                            className={classes.avatar} 
                            style={{margin : "0px 10px"}}/>}
                        {TokenName}
                    </div>: "Token"}
                    {popoverId === spinTokenId ? <ArrowDropUp fontSize="small"/> : <ArrowDropDown fontSize="small"/>}
                </div>
            </div>
        </div>
    )
}
GiftDappRewardSetupGameSetupSlotField.propTypes = {
    
}
export default GiftDappRewardSetupGameSetupSlotField;
export {GiftDappRewardSetupGameSetupSlotField};