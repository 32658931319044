import { LaptopWindowsO, LocalS } from '@material-ui/icons';
import { headerActions, headerStrings, walletActions, walletStrings } from '../../components';
import { history, store } from '../../helpers';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../config';
import { giftDappV1RouteNames } from '../routeNames';

const walletUtil = {
    isMainNet : () => {
        const giftDappUtilReducer = store.getState()[GIFT_DAPP_UTIL_REDUCER_NAME],
        {
            [giftDappUtilStrings.storageNetworkID] : storageNetworkID,
        } = giftDappUtilReducer,
        networkId = localStorage.getItem(storageNetworkID);
        return walletUtil.MAIN_NETS.indexOf(parseInt(networkId)) !== -1;
    },
    disconnectUser : async () => {
        const giftDappUtilReducer = store.getState()[GIFT_DAPP_UTIL_REDUCER_NAME],
        {
            [giftDappUtilStrings.storageNetworkID] : storageNetworkID,
            [giftDappUtilStrings.storageNetworkType] : storageNetworkType,
            [giftDappUtilStrings.storageNetworkWallet] : storageNetworkWallet,
        } = giftDappUtilReducer;
        localStorage.removeItem(storageNetworkID);
        localStorage.removeItem(storageNetworkType);
        localStorage.removeItem(storageNetworkWallet);
        walletActions({
            [walletStrings.address] : null,
            [walletStrings.web3] : null,
            [walletStrings.balance] : null,
            [walletStrings.contracts] : null,
            [walletStrings.networkId] : null,
            [walletStrings.networkType] : null,
            [walletStrings.networkWallet] : null,
        })(store.dispatch);
        headerActions.payload({
            [headerStrings.showDashboard] : false
        })(store.dispatch);
        history.replace(giftDappV1RouteNames().reward);
    },
    getAccounts : async () => {
        return await window.ethereum?.request({ method : "eth_requestAccounts"});
    }, 
    getChainId : async () => {
        return await window.ethereum.request({ method: 'eth_chainId' });
    }, 
    getBalance : async () => {
        const {walletReducer} = store.getState(),
        {
            [walletStrings.web3] : web3,
            [walletStrings.address] : address,
        } = walletReducer;
        const balance =  web3 && await web3.eth.getBalance(address);
        return balance / 10 ** 18;
    },
    etherScan : () => ({
        KEY : "CGG3QYHUENRNCA6ECW57PZBFR9S75APAJF",
        etherBalance : (address) => {
            fetch(`https://api.etherscan.io/api?module=account&action=balance&address=${address}&tag=latest&apikey=${walletUtil.etherScan().KEY}`).
                then(res=>res.json()).
                then(resp => {
                    //perform action
                }).
                catch(error => console.error(error));
        },
        balanceInToken : (contractAddress, address) => {
            fetch(`https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${contractAddress}&address=${address}&tag=latest&apikey=${walletUtil.etherScan().KEY}`)
        }
    }),
    isValidTransactionHash : async transactionHash => {
        const {walletReducer} = store.getState(),
        {
            [walletStrings.web3] : web3,
        } = walletReducer;
        var txR = await web3.eth.getTransactionReceipt(transactionHash);
        return Boolean(txR.blockNumber);
    },
    SUPPORTED_NETWOR_IDS : [
        97, 56
    ],
    ETHEREUM_NETWORK_IDS : [
        1,3,4,
    ],
    BINANCE_NETWORK_IDS : [
        97,56
    ],
    MAIN_NETS : [
        1,56
    ],
    isSupportedNetwork : (networkId) => {
        return walletUtil.SUPPORTED_NETWOR_IDS.indexOf(networkId) !== -1 || (process.env.NODE_ENV === "development" /* && String(networkId).length >=13 */)
    },
    getNetNames : (networkId) => {
        switch(networkId) {
            case 1 : return "Ethereum Mainnet"; 
            case 3 : return "Ropsten Test Network";
            case 4 : return "Rinkeby Test Network";
            case 5 : return "Goerli Test Network";
            case 42 : return "Kovan Test Network";
            case 56 : return "Binance Smart Chain Network";
            case 97 : return "Binance Smart Chain Test Network";
            default : return "Unknown"
        }
    },
    TRUFFLE_NETWORK_NAMES : {
        MAIN : "main", 
        ROPSTEN : "ropsten",
        RINKEBY : "rinkeby",
        GOERLI : "goerli",
        KOVAN : "kovan",
        BSC : "bsc",
        BSC_TESTNET : "bsc_testnet",
        DEVELOPMENT : "development",
    },
    getTruffleNetworkName : (networkId) => {
        switch(networkId) {
            case 1 : return walletUtil.TRUFFLE_NETWORK_NAMES.MAIN;
            case 3 : return walletUtil.TRUFFLE_NETWORK_NAMES.ROPSTEN; 
            case 4 : return walletUtil.TRUFFLE_NETWORK_NAMES.RINKEBY;
            case 5 : return walletUtil.TRUFFLE_NETWORK_NAMES.GOERLI;
            case 42 : return walletUtil.TRUFFLE_NETWORK_NAMES.KOVAN;
            case 56 : return walletUtil.TRUFFLE_NETWORK_NAMES.BSC;
            case 97 : return walletUtil.TRUFFLE_NETWORK_NAMES.BSC_TESTNET;
            default : return walletUtil.TRUFFLE_NETWORK_NAMES.DEVELOPMENT;
        }
    },
    WALLETS : {
        METAMASK : "metamask",
        TRUST_WALLET  : "trust",
        GO_WALLET : "goWallet",
        ALPHA_WALLET : "alphaWallet",
        STATUS : "status",
        COINBASE : "coinbase",
        CIPHER : "cipher",
        MIST : "mist",
        PARITY : "parity",
        INFURA : "infura",
        LOCAL_HOST : "wallet",
    },
    getWalletNames : (wallet) => walletUtil.walletNameMapping()[wallet],
    walletNameMapping : () => ({
        [walletUtil.WALLETS.METAMASK] : "Metamask",
        [walletUtil.WALLETS.TRUST_WALLET] : "Trust Wallet",
        [walletUtil.WALLETS.GO_WALLET] : "Go Wallet",
        [walletUtil.WALLETS.ALPHA_WALLET] : "Alpha Wallet",
        [walletUtil.WALLETS.STATUS] : "Status Wallet",
        [walletUtil.WALLETS.COINBASE] : "Coinbase Wallet",
        [walletUtil.WALLETS.CIPHER] : "Cipher Wallet",
        [walletUtil.WALLETS.MIST] : "Mist Wallet",
        [walletUtil.WALLETS.PARITY] : "Parity Wallet",
        [walletUtil.WALLETS.INFURA] : "Infura Wallet",
        [walletUtil.WALLETS.LOCAL_HOST] : "Local Wallet",
    }),
    getCurrentProvider : () => {
        const web3 = window.ethereum || window.web3;
        if(!web3) return "wallet";
        else if(web3?.isMetaMask)
            return walletUtil.WALLETS.METAMASK;
        else if(web3?.isTrust)
            return walletUtil.WALLETS.TRUST_WALLET;
        else if(web3?.isGoWallet)
            return walletUtil.WALLETS.GO_WALLET;
        else if(web3?.isAlphaWallet)
            return walletUtil.WALLETS.ALPHA_WALLET;
        else if(web3?.isStatus)
            return walletUtil.WALLETS.STATUS;
        else if(web3?.isToshi)
            return walletUtil.WALLETS.COINBASE;
        else if(typeof window.__CIPHER__ !== "undefined")
            return walletUtil.WALLETS.CIPHER;
        else if(web3.currentProvider?.currentProvider?.constructor?.name === "EthereumProvider")
            return walletUtil.WALLETS.PARITY;
        else if(web3.currentProvider?.currentProvider?.host?.indexOf("infura") !== -1)
            return walletUtil.WALLETS.INFURA;
        else if(web3.currentProvider?.currentProvider?.host?.indexOf("localhost") !== -1)
            return walletUtil.WALLETS.LOCAL_HOST;
    }
}
export default walletUtil;
/**
 * Sample return of token info API:

    {
      "status": "1",
      "message": "OK",
      "result": [
        {
          "contractAddress": "0x...",
          "tokenName": "Token Name",
          "symbol": "Token Symbol",
          "divisor": "18",
          "tokenType": "ERC20",
          "totalSupply": "1000000000000000",
          "blueCheckmark": "true",
          "description": "Token Description",
          "website": "https://token.website",
          "email": "email@token.website",
          "blog": "https://blog.token.website/",
          "reddit": "https://www.reddit.com/r/tokenwebsite/",
          "slack": "https://chat.token.website/",
          "facebook": "https://facebook.com/tokenwebsite",
          "twitter": "https://twitter.com/tokenwebsite",
          "bitcointalk": "https://www.bitcointalk.org/index.php?topic=xxxxx",
          "github": "https://github.com/tokenwebsite",
          "telegram": "https://t.me/tokenwebsite",
          "wechat": "https://token.website/wechat",
          "linkedin": "https://www.linkedin.com/tokenwebsite/",
          "discord": "https://discord.com/tokenwebsite",
          "whitepaper": "https://token.website/documents/document.pdf"
        }
      ]
    }
 */