import { Grid, makeStyles, withWidth } from '@material-ui/core'
import React from 'react'
import classNames from 'classnames';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        fontSize : 12,
        color : colors.textSecondary,
    },
    container : {
        display : 'flex',
        flexDirection : "column",
        paddingRight : 10,
        [breakpoints.down("xs")] : {
            padding : 0,
        }
    },
    markContainer : {
        width : "100%",
    },
    mark : {
        width : "100%",
        height : 3,        
    },
    active : {
        background : colors.accent,
    },
    inactive : {
        background : colors.textSecondary
    },
    activeText : {
        color : colors.accent,
    },
    inactiveText : {
        color : colors.textSecondary,
    },
    body : {
        marginTop : 20,
    },
    header : {
    },
    description : {
        marginTop : 5,
        color : colors.text,
    }
}))
export default withWidth()(function CustomProcessStepNav ({width, stages, position}) {
    const classes = style(),
    size = stages?.length === 4 ? 3 : 4;
    return (
        <div className={classes.root}>
            <Grid container>
                {stages && stages.map((item, index) => {
                    const {_id, name, description} = item,
                    isCurrent =  _id <= position ;
                    return <Grid key={index} item xs={size} sm={size} md={size} lg={size} xl={size}>
                        <div className={classes.container}>
                            <div className={classes.markContainer}>
                                <div 
                                    className={classNames(classes.mark, isCurrent ? classes.active : classes.inactive)} 
                                    // style={{width : isCurrent? "100%" : 0}}
                                />
                            </div>
                            {width !== "xs" && <div className={classes.body}>
                                <div className={classNames(classes.header, isCurrent ? classes.activeText : classes.inactiveText)}>
                                {name}
                                </div>
                                <div className={classes.description}>
                                    {description}
                                </div>
                            </div>}
                        </div>
                    </Grid>
                })}
            </Grid>
        </div>
    )
})