import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from 'react-redux';
import { customPopoverActions, customPopoverString } from '../../../includes';
import { getProfiles } from '../../../utils';
import { walletStrings, WALLET_REDUCER_NAME } from '../../structures';
import GiftDappProfilePopup from '../GiftDappProfilePopup/GiftDappProfilePopup';
import { giftDappProfileActions } from '../actions';
import { giftDappProfileStrings, GIFT_DAPP_PROFILE_REDUCER_NAME } from '../config';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../../utils/config';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        position : "fixed",
        right : 5,
        top : 150,
        [breakpoints.down("sm")] : {
            top : 140,
        }
    },
    iconBtn : {
        background : colors.secondary,
        borderRadius : 10,
        padding : 11,
        "&:hover" : {
            background : colors.secondary,
        },
        [breakpoints.down("sm")] : {
            padding : 6,
        }
    },
}))
export default function GiftDappProfileGlobal () {
    const classes = style(),
    dispatch = useDispatch(),
    address = useSelector(state=>state[WALLET_REDUCER_NAME][walletStrings.address]),
    giftDappProfileReducer = useSelector(state=>state[GIFT_DAPP_PROFILE_REDUCER_NAME]),
    storageLastUsedProfile = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME][giftDappUtilStrings.storageLastUsedProfile]),
    {
        [giftDappProfileStrings.refreshCount] : refreshCount,
    } = giftDappProfileReducer,
    {data : profileData, refetch : _getProfile}= useQuery(getProfiles(), {
        variables : {
            filter : { address }
        },
        skip : !address,
        fetchPolicy : "network-only"
    }),
    {profiles} = profileData || {},
    lastUsedProfileId = localStorage.getItem(storageLastUsedProfile),
    lastUsedProfile = profiles && profiles.find(item => item.latest || item._id === lastUsedProfileId);
    useEffect(() => {
        if(refreshCount > 0){
            _getProfile();
        }
    }, [refreshCount]);
    useEffect(() => {
        if(lastUsedProfile){
            giftDappProfileActions.payload({
                [giftDappProfileStrings.lastUsedProfile] : lastUsedProfile
            })(dispatch);
        }
    }, [JSON.stringify(lastUsedProfile), JSON.stringify(dispatch)])
    useEffect(() => {
        if(profiles){
            giftDappProfileActions.payload({
                [giftDappProfileStrings.profiles] : profiles
            })(dispatch);
        }
    }, [JSON.stringify(profiles), JSON.stringify(dispatch)]);
    if(!profiles || profiles.length === 0) return null;
    return (
        <div className={classes.root}>
            <IconButton onClick={({target : anchorEl}) => {
                customPopoverActions.payload({
                    [customPopoverString.anchorEl] : anchorEl,
                    [customPopoverString.customComponent] : () => <GiftDappProfilePopup 
                        profiles={profiles}
                        lastUsedProfile={lastUsedProfile}
                    />,
                })(dispatch);
            }} className={classes.iconBtn}>
                <FontAwesomeIcon className={classes.icon} icon={faUser}/>
            </IconButton>
        </div>
    )
}