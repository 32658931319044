import { Divider, makeStyles, withWidth } from '@material-ui/core'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { breadCrumbActions, breadCrumbStrings } from '../../../../includes';
import { backButtonActions } from '../../../../includes/CustomBackNav/actions';
import { backButtonStrings } from '../../../../includes/CustomBackNav/config';
import { giftDappV1RouteNames, ROUTE_DISPLAY_STRINGS } from '../../../../utils';
import { giftRewardActions } from '../../actions';
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from '../../config';
import GiftDappRewardSetupFooterButtons from '../GiftDappRewardSetupFooterButtons/GiftDappRewardSetupFooterButtons';
import GiftDappRewardSetupHeader from '../GiftDappRewardSetupHeader/GiftDappRewardSetupHeader';
import GiftDappRewardSetupInput from '../GiftDappRewardSetupInput/GiftDappRewardSetupInput';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        
    },
    divider : {
        background : colors.borderLines
    }
}));
export const REWARD_SOCIAL_LINK_FIELD_NAMES = {
    LINKS : "links",
    FACEBOOK_URL : "facebookUrl",
    TWITTER_URL : "twitterUrl",
    YOUTUBE_URL : "youtubeUrl",
    TELEGRAM_URL : "telegramUrl",
    DISCORD_URL : "discordUrl",
}
const formConfig = [
    {name : REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS, label : "Links", type : "switch"},
    {name : REWARD_SOCIAL_LINK_FIELD_NAMES.TWITTER_URL, label : "Twitter Account URL", prefix : "Username"},
    {name : REWARD_SOCIAL_LINK_FIELD_NAMES.YOUTUBE_URL, label : "Youtube Channel URL/ID", prefix : "Channel link/ID"},
    // {name : REWARD_SOCIAL_LINK_FIELD_NAMES.FACEBOOK_URL, label : "Facebook Page URL/ID", prefix : "Username"},
]
function _GiftDappRewardSetupSocialLinks ({
    width, onContinue, 
    profileBreadCrumbAction,
    detailBreadCrumbAction, 
}) {
    const classes = style(),
    dispatch = useDispatch(),
        giftRewardReducer = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME]),
        {
            [giftRewardStrings.rewardData] : rewardData,
            [giftRewardStrings.isEdit] : isEdit,
        } = giftRewardReducer,
        {
            [REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS] : links
        } = rewardData || {},
        onChange = useCallback(name => ({target : {value, checked}}) => {
            const {LINKS} = REWARD_SOCIAL_LINK_FIELD_NAMES;
            giftRewardActions.payload({
                [giftRewardStrings.rewardData] : {
                    ...rewardData,
                    [name] : name === LINKS ? checked : value
                }
            })(dispatch);
        }, [JSON.stringify(rewardData)]),
        onReset = useCallback(() => {
            giftRewardActions.payload({
                [giftRewardStrings.rewardData] : {
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS] : "",
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.TWITTER_URL] : "",
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.YOUTUBE_URL] : "",
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.FACEBOOK_URL] : null,
                }
            })(dispatch)
        }, []);
    useEffect(() => {
        const {PROFILE, DETAILS, SOCIAL} = ROUTE_DISPLAY_STRINGS.SETUP_STRINGS;
        breadCrumbActions.payload({
            [breadCrumbStrings.visible] : true,
            [breadCrumbStrings.crumbs] : [
                {
                    name : isEdit ? 
                        ROUTE_DISPLAY_STRINGS.DASHBOARD : ROUTE_DISPLAY_STRINGS.REWARD, 
                    path : isEdit ? giftDappV1RouteNames().dashboard : giftDappV1RouteNames().reward
                },
                {name : PROFILE, action : profileBreadCrumbAction},
                {name : DETAILS, action : detailBreadCrumbAction},
                {name : SOCIAL},
            ]
        })(dispatch);
        backButtonActions.payload({
            [backButtonStrings.visible] : true,
            [backButtonStrings.text] : "Back to project detail page",
            [backButtonStrings.action] : detailBreadCrumbAction,
        })(dispatch);
    }, [/* JSON.stringify(breadCrumbReducer) */]);
    return (
        <div className={classes.root}>
            <GiftDappRewardSetupHeader
                header="Social links"
                subheader="List your social media links, and verify game players follow you"
            />
            {formConfig.map((field, index) => {
                const {name} = field;
                const otherValues = {};
                if(name === REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS){
                    otherValues.checked = links;
                }
                return <div key={index}>
                    {width !== "xs" && <Divider className={classes.divider}/>}
                    <GiftDappRewardSetupInput
                        key={index}
                        {...field}
                        disabled={name !== REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS && !links}
                        onChange={onChange(name)}
                        value={rewardData?.[name]}
                        {...otherValues}
                    />
                </div>
            })}
            <GiftDappRewardSetupFooterButtons
                onContinue={onContinue}
                onReset={onReset}
            />
        </div>
    )
}
export const GiftDappRewardSetupSocialLinks = withWidth()(_GiftDappRewardSetupSocialLinks);
export {GiftDappRewardSetupSocialLinks as default}