import { giftDappEventConstants, GIFT_DAPP_SOCIAL_MEDIAL_CONSTANT } from "./config";

export const giftDappEventActions = {
    payload : payload => dispatch => dispatch({
        type : giftDappEventConstants.SET_GIFT_DAPP_EVENT,
        payload
    })
}
export const giftDappSocialMediaActions = {
    payload : payload => dispatch => dispatch({
        type : GIFT_DAPP_SOCIAL_MEDIAL_CONSTANT,
        payload
    })
}