import { makeStyles, withStyles } from '@material-ui/core'
import React from 'react'
import { Fragment } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../utils/config';
const style = ({colors}) => ({
    container : {
        margin : "10px 0",
        fontSize : 12,
        display : 'flex',
        justifyContent : "centent",
        alignItems : "center",
        height : "100%",
        color : colors.textSecondary
    },
    name : {
        padding : 10,
    },
    logo : {
        height : 50,
        width : 50,
        borderRadius : "100%",
        background : colors.background,
        display : "flex",
        alignItems: "center",
        justifyContent : "center",
    },
    clickable : {
        cursor : "pointer",
    }
});
export default withStyles(style)(function CustomProfileImage ({
    classes, image, name, defaultImage : DefaultImage,
    onClick
}) {
    const giftDappUtilReducer = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME]),
    {
        [giftDappUtilStrings.appDomain] : appDomain
    } = giftDappUtilReducer;
    return <Fragment>
        {image ? 
            <img 
                alt="profileImage" 
                src={/^http/.test(image) ? image : appDomain + image} 
                title="logo" 
                onClick={onClick}
                className={classNames(
                    classes.logo, onClick ? classes.clickable : ""
                )}/> : 
            <div className={classNames(
                    classes.logo, onClick ? classes.clickable : ""
                )} onClick={onClick}>{DefaultImage ? <DefaultImage/> : 'logo'}</div>}
        {name && <span className={classes.name}>
            {name}
        </span>}
    </Fragment>
})