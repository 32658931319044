import React from 'react';
import { ThemeProvider, useTheme } from '@material-ui/core';
import { Suspense } from 'react';
import { Router, Switch, Route} from 'react-router-dom';
import { landingHeaderlessRoutes as headerlessRoutes, landingRoutes as routes, landingTheme } from './utils';
import { history } from './helpers';
import LandingLayout from './components/Layout';
// import CustomMusicConsole from './includes/CustomMusicConsole/CustomMusicConsole';


function GiftDappLanding() {
    const theme = useTheme();
    return <Router history={history}>
        <ThemeProvider theme={{
            ...theme, colors : landingTheme.dark
        }}>
            <Suspense fallback={<>Loading</>}>
                <Switch>
                    {routes && routes.map((route, index)=><LandingLayout {...route} key={index}/>)}
                    {headerlessRoutes && headerlessRoutes.map((route, index)=><Route {...route} key={index}/>)}
                </Switch>
            </Suspense>
        </ThemeProvider>
    </Router>

    
}

export default GiftDappLanding;
