import { makeStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import classNames from 'classnames';
import { CustomButton, errorToast } from '../../../../../../includes';
import CustomInputFields from '../../../../../../includes/CustomInputFields/CustomInputFields';
import { useDispatch } from 'react-redux';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        padding : 20,
        color : colors.text,
        position : "relative",
        [breakpoints.only("xs")] : {
            minWidth : 250,
        },
        minWidth : 350,
    },
    dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.text,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
    header : {
        textAlign : 'center',
        margin : 20,
    },
    spinMax : {
        color : colors.textSecondary,
        marginTop : 10,
        fontSize : 12,
        marginBottom : 5,
    },
    numberContainer : {
        display : "flex",
        justifyContent :"space-between",
        borderRadius : 10,
        background : colors.secondary,
        padding : 5,
    },
    spin : {
        borderRadius : 10,
        border : "solid 1px " + colors.primary,
        padding : 20,
        cursor : "pointer",
        "&:hover" : {
            borderColor : colors.accent
        }
    },
    active : {
        borderColor : colors.accent
    },
    btnContainer : {
        display : 'flex',
        marginTop : 20,
        justifyContent : "space-between",
    },
    btn : {
        
    }
}))
export default function GiftDappRewardSetupGameSetupSlotWinChance ({onContinue, onCancel, suggestedMaxAmount}) {
    const classes = style(),
        dispatch = useDispatch(),
        [state, _setState] = useState({
            chance : 0, maxAmount : 0, loading : false
        }),
        {chance, maxAmount, loading} = state,
        setState = _state => _setState(state=>({...state, ..._state})),
        _onContinue = useCallback(() => {
            if(!chance) return errorToast("Select a percentage chance")(dispatch);
            else if(!maxAmount) return errorToast("max reward spin field amount not set")(dispatch);
            setState({loading : true});
            onContinue && onContinue(chance, parseInt(maxAmount), () => setState({loading : false}));
        }, [chance, maxAmount]);
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                Chance of winning
            </div>
            <div className={classes.spinMax}>Percentage winning chance (max. 75%)</div>
            <div className={classes.numberContainer}>
                {[25,50,75].map((number, index) => {
                    return <div 
                        key={index} 
                        className={classNames(classes.spin, number === chance ? classes.active : "")} 
                        onClick={() => setState({chance : number})}>{number}%</div>
                })}
            </div>
            <div className={classes.spinMax}>Max. amount in reward spin field {suggestedMaxAmount ? `(max. ${suggestedMaxAmount})` : ""}</div>
            <div>
                <CustomInputFields
                    field={{
                        name : "maxAmount", placeholder : "max. spin field amount", 
                        margin : "dense", type : "number", onChange : ({target : {value}}) => setState({maxAmount : value})
                    }}
                />
            </div>
            <div className={classes.btnContainer}>
                    <CustomButton
                        type="secondary"
                        classes={{btn : classes.btn}}
                        onClick={onCancel}
                    >
                        Cancel
                    </CustomButton>
                    <CustomButton
                        loading={loading}
                        type="primary"
                        classes={classes.btn}
                        onClick={_onContinue}
                    >
                        Continue
                    </CustomButton>
                </div>
        </div>
    )
}