import { gql } from "@apollo/client";

export const socialMediaStrings = "path isVerified";
export const profileQueryStrings = `_id username lastname firstname image twitter {${socialMediaStrings}} youtube {${socialMediaStrings}}`
export const rewardQueryString = `_id  projectIndex name image description links youtubeUrl twitterUrl facebookUrl isProject networkType wheels profileId profile {${profileQueryStrings}}`
export const getRewardCounts = () => gql`
    query getRewardCounts ($networkId : String, $filter : RewardInput){
        rewardCounts : getRewardCounts (networkId : $networkId, filter : $filter) {
            active ended
        }
    }`;
export const getRewards = query => gql`
    query getRewards($networkId : String, $filter : RewardInput, $config : ConfigInput, $pagination : PaginationInput) {
        rewards : getRewards(networkId : $networkId, filter : $filter, config : $config, pagination : $pagination) {
            ${query || rewardQueryString}
        }
    }`;
// export const getRewards = query => gql`
//     query getRewards($networkId : String, $filter : RewardInput) {
//         rewards : getRewards(networkId : $networkId, filter : $filter) {
//             ${query || "_id creator"}
//         }  
//     }`;
export const getRewardMaxMinId = () => gql`
    query getRewardMaxMinId ($networkId : String) {
        getRewardMaxMinId (networkId : $networkId) {
            minId maxId
        }
    }`;
export const getProfiles = query => gql`
    query getProfiles ($filter : ProfileInput, $pagination : PaginationInput) {
        profiles : getProfiles (filter : $filter, pagination : $pagination){
            ${query || profileQueryStrings}
        }
    }`;
export const getTwitterAccountValidation = query => gql`
    query twitterAccountValidation ($username : String) {
        validTwitterAccount : twitterAccountValidation (username : $username){
            ${query || "username name id"}
        }
    }`;
export const getTwitterFellowship = () => gql`
    query twitterFellowshipCheck ($source_screen_name : String, $target_screen_name : String) {
        twitterFollow : twitterFellowshipCheck (source_screen_name : $source_screen_name, target_screen_name : $target_screen_name)
    }`;
export const gql_createProfile = (query) => gql`
    mutation createProfile ($profile : ProfileInput) {
        createdProfile : createProfile (profile : $profile) {
            ${query || '_id'}
        }
    }`;
export const gql_updateProfile = (query) => gql`
    mutation updateProfile ($profileId : String, $profile : ProfileInput) {
        updatedProfile : updateProfile (profileId : $profileId, profile : $profile) {
            ${query || '_id'}
        }
    }`;