import { faWindows } from "@fortawesome/free-brands-svg-icons";

export const mergeComponentClasses = (classObject, overideObject) => {
    if(classObject) for (const name in classObject) {
        if (Object.hasOwnProperty.call(classObject, name)) {
            const _class = classObject[name];
            if(overideObject && overideObject[name])
            classObject[name] = overideObject[name] + " " + _class;
        }
    }
    return classObject
}
export const shortAddress = address => {
    if(!address) return "...";
    return String(address).substr(0, 4) +
        "..." +
    String(address).substr(address.length - 4, 4)
}
export const onEnterPress = action => event => {
    if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        if(action)
            action(event);
    }
}
export const copyToClipboard = (text, keepTabs) => {
    let input;
    if(keepTabs) {
        input = document.createElement("textarea");
        input.innerHMTL = text;
    }else{
        input = document.createElement("input");
        input.setAttribute("value", text);
    }
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    navigator.clipboard.writeText(text);
    document.body.removeChild(input);
    return result;
}
export const customSuperSetState = context=>{
    return function(state, callback){
        if(state instanceof Function){
            if(!context.state)
                throw("The component has no state");
            else{
                var newStateObject = state(context.state);
                context.setState(newStateObject)
            }
        }else {
            context.setState(state);
            if(callback) callback();
        }
    }
}
export const isFunction = function(obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
};