import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import { AppBar, Hidden, makeStyles, Toolbar } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { getRewards, giftDappUtilActions, GIFT_DAPP_LOCAL_STORAGE_VAR, giftDappV1RouteNames } from '../../../utils';
import { GiftDappConnection } from './GiftDappConnection';
import { history } from '../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { walletStrings, WALLET_REDUCER_NAME } from '../GiftDappWallets';
import { gql, useQuery } from '@apollo/client';
import { LocalS, SettingsInputAntenna } from '@material-ui/icons';
import { headerStrings, HEADER_REDUCER_NAME } from './config';
import { headerActions } from './actions';
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from '../../GiftDappReward';
import { customSideDisplayActions, customSideDisplayStrings } from '../../../includes/CustomSideDisplay';
import { GiftDappTestTokenRequestComponent } from '..';
const style = makeStyles(theme => ({
    root : {
        
    },
    toolbar : {
        backgroundColor : theme.colors.background,
    },
    container : {
        display : "flex",
        width : "100%",
        alignItems : "center",
    }, 
    link : {
        color : theme.colors.text,
        textDecoration : "none",
        fontSize : 14,
        [theme.breakpoints.up("sm")] : {
            padding : "0px 20px"
        }
    },
    gap : {
        display : "flex",
        flexGrow : 1
    },
    active : {
        color : theme.colors.accent
    },
    menuContainer : {
        marginLeft: 20,
        display: "flex",
        [theme.breakpoints.down("xs")] : {
            justifyContent: "space-around",
            width : "100%",
        }
    },
    logo : {
        height : 25,
        width : 25,
    },
    companyName : {
        marginLeft : 10,
        fontSize : 18,
        fontWeight : "bold",
        color : theme.colors.text,
        [theme.breakpoints.down("sm")] : {
            display : "none"
        }
    },
    alignRight : {
        justifyContent : "flex-end"
    },
    sideMargin : {
        margin : "0px 10px"
    }
}));
/**
 * @desc 
 * please make use of theme.breakpoints in style for media query
 * the theme of the application can be located in "theme.colors", eg. theme.colors.primary,
 * 
 * @param {*} props 
 */
 const GET_REWARDS = gql`
 query getRewards($networkId : String, $filter : RewardInput) {
     rewards : getRewards(networkId : $networkId, filter : $filter) {
         _id creator
     }
 }
`;
function GiftDappHeader(props) {
    const classes = style(),
    dispatch = useDispatch(),
    path = history.location.pathname,
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.address] : address,
        [walletStrings.networkId] : networkId,
    } = walletReducer,
    isEdit = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME][giftRewardStrings.isEdit]),
    headerReducer = useSelector(state=>state[HEADER_REDUCER_NAME]),
    {
        [headerStrings.showDashboard] : showDashboard
    } = headerReducer;
    useQuery(getRewards("_id"), {
        variables : {
            networkId : String(networkId),
            filter : { creator : address},
        },
        skip : !address || showDashboard,
        onCompleted : ({rewards}) => {
            if(rewards && rewards[0]) headerActions.payload({
                [headerStrings.showDashboard] : true
            })(dispatch);
            else {
                customSideDisplayActions.payload({
                    [customSideDisplayStrings.show] : true,
                    // [customSideDisplayStrings.component] : GiftDappTestTokenRequestComponent
                })(dispatch);
                headerActions.payload({
                    [headerStrings.showDashboard] : false
                })(dispatch);
            }
        }
    });
    const switchTheme = () => {
        const theme = localStorage.getItem(GIFT_DAPP_LOCAL_STORAGE_VAR.THEME_TYPE);
        localStorage.setItem(GIFT_DAPP_LOCAL_STORAGE_VAR.THEME_TYPE, theme === "light" ? "dark" : "light");
        giftDappUtilActions.quickRefresh(dispatch); 
    }
    return (
        <div className={classes.root}>
            <AppBar elevation={0} classes={{
                colorPrimary : classes.toolbar
            }}>
                <Toolbar>
                    <div className={classes.container}>
                        <img src="/rigel_icon.png" className={classes.logo}/>
                        <div className={classes.companyName}>Rigel Protocol</div>
                        <div className={classNames(classes.menuContainer, !showDashboard ? classes.alignRight : "")}>
                            {showDashboard && <Link className={classNames(
                                classes.link, 
                                (giftDappV1RouteNames().rewardSetup === path && isEdit) || 
                                path.includes(giftDappV1RouteNames().rewardView_) ||
                                giftDappV1RouteNames().dashboard === path ? 
                                    classes.active : ""
                                )
                            } to={giftDappV1RouteNames().dashboard}>Dashboard</Link>}
                            <Link className={classNames(
                                classes.link, 
                                !isEdit && 
                                    [giftDappV1RouteNames().reward, giftDappV1RouteNames().rewardSetup].indexOf(path) !== -1 ? 
                                        classes.active : "",
                                
                                !showDashboard ? classes.sideMargin : ""
                                )} to={giftDappV1RouteNames().reward}>Reward</Link>
                            <Link className={classNames(
                                classes.link, 
                                giftDappV1RouteNames().events === path ||
                                path.includes(giftDappV1RouteNames().eventView_) ? 
                                classes.active : "",
                                !showDashboard ? classes.sideMargin : ""
                            )} to={giftDappV1RouteNames().events}>Events</Link>
                        </div>
                        <div className={classes.gap}/>
                        <GiftDappConnection
                            switchTheme={switchTheme}
                        />
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}
GiftDappHeader.propTypes = {
    
}
export default GiftDappHeader;
export {GiftDappHeader};