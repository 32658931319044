import { store } from "../helpers";
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from "./config";

export const isMainNet = () => {};
export const isTestNet = () => process.env.REACT_APP_NETWORK === "online";
export const isLocalNet = () => process.env.REACT_APP_NETWORK === "local";

export const fetchGet = async (owner, action, stringQuery, callback)=>{
    return new Promise((resolve, reject)=>{
        const appDomain = store.getState()[GIFT_DAPP_UTIL_REDUCER_NAME][giftDappUtilStrings.appDomain];
        let count = 0;
        let build = "";
        for (const i in stringQuery) {
            if (stringQuery.hasOwnProperty(i)) {
                const elem = stringQuery[i];
                if(count === 0){
                    build += "?"+i+"="+elem;
                }else build += "&"+i+"="+elem;
                count++;
            }
        }
        fetch(appDomain+"/api/v1/"+owner+"/"+action+build).then(
            response=> response.json()
        ).then(response=>{
            if(callback) callback(null, response)
            else resolve(response);
        }).catch(error => {
            if(callback) callback(error);
            else reject(error);
        });
    }) 
}
export const fetchPost = async (owner, action, options={}, callback, query = {})=>{
    return new Promise((resolve, reject)=>{
        const appDomain = store.getState()[GIFT_DAPP_UTIL_REDUCER_NAME][giftDappUtilStrings.appDomain];
        const stringQuery = {...query};
        let count = 0;
        let build = "";
        for (const i in stringQuery) {
            if (stringQuery.hasOwnProperty(i)) {
                const elem = stringQuery[i];
                if(count === 0){
                    build += "?"+i+"="+elem;
                }else build += "&"+i+"="+elem;
                count++;
            }
        }
        const url = appDomain+"/api/v1/"+owner+"/"+action+build;
        fetch(url, {
            ...options,
            "--X-RIGEL--Barear" : "KDJ7sih3ilsnkjnhhs898384jsjdmnsgaNHksns8J23scsy9wjsJCM92"
        }).then(
            response=> response.json()
        ).then(response=> callback ? callback(null, response) : resolve(response))
        .catch(e => {
            console.error(e);
            callback ? callback(e) : reject(e);
        });
    });
};
export const post = (data, owner, action, callback, query)=>{
    return fetchPost(owner, action, {
        method : "post",
        headers : {
            "Content-Type" : "Application/json",
            "--X-RIGEL--Barear" : "KDJ7sih3ilsnkjnhhs898384jsjdmnsgaNHksns8J23scsy9wjsJCM92"
        },
        body : JSON.stringify(data)
    }, callback, query);
}
export const getAddressBook = () => require("../addresses.json")
