import React from 'react'
import { makeStyles } from '@material-ui/core'
import { CustomButton } from '../../../../../../includes'
import { ArrowForward } from '@material-ui/icons';
import CustomProfileImage from '../../../../../../includes/CustomProfileImage/CustomProfileImage';
const style = makeStyles(({colors, isDark}) => ({
    root : {
    },
    box : {
        fontSize : 12,
        marginBottom : 10,
        background : !isDark ? colors.secondaryDark : colors.secondary
    },
    header : {
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        flexDirection : "column",
        padding : 10,
        textAlign : "center",
    },
    container : {
        display : "flex",
        justifyContent : "space-between",
        padding : 10,
    },
    name : {
        fontWeight : "bold",
        padding : 10,
    },
    logoContainer : {
        display : "flex",
    },
    logo : {
        height : 50,
        width : 50,
        borderRadius : "100%",
        background : colors.secondary,
        display : "flex",
        alignItems: "center",
        justifyContent : "center",
    },
    arrowRt : {
        color : colors.accent
    },
    secondary : {
        justifyContent : "flex-end",
        background : !isDark && colors.backgroundLight,
        border : "none"
    },
    title : {
        color : colors.textSecondary
    },
    expired : {
        color : colors.textSecondary
    },
}))
function GiftDappEventListBoxGrid({expired, onClaim, name, image, rewardRange, network, onEdit, isMyReward}) {
    const classes = style();
    return <div className={classes.box}>
        <div className={classes.header}>
            <CustomProfileImage
                image={image}
                name={name}
            />
        </div>
        <div className={classes.container}>
            <span className={classes.title}>
                Reward: 
            </span>
            <span className={classes.value}>
                {rewardRange}
            </span>
        </div>
        <div className={classes.container}>
            <span className={classes.title}>
                Network: 
            </span>
            <span className={classes.value}>
                {network}
            </span>
        </div>
        <div className={classes.btnContainer}>
            <CustomButton
                variant="outlined"
                type="secondary"
                fullWidth
                classes={{
                    secondary : classes.secondary,
                }}
                {...((expired === false) && { onClick: isMyReward ? onEdit : onClaim })}
            >
                {isMyReward ? "Edit" : (expired !== undefined) ? (expired ? <span className={classes.expired}>Expired</span> : 
                    <>
                        Claim
                        <ArrowForward className={classes.arrowRt}/>
                    </>) : ""}
            </CustomButton>
        </div>
    </div>        
    
}
GiftDappEventListBoxGrid.propTypes = {
    
}
export default GiftDappEventListBoxGrid;
export {GiftDappEventListBoxGrid};