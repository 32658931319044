import React from 'react';
import { landingRouteNames } from "./routeNames"

const landingHome = React.lazy(() => import("../components/LandingHome/LandingHome"));
export const landingRoutes = [
    { path : landingRouteNames().home, exact : true, component : landingHome}
]
/**
 * 404 page must be the last index in this array and it is without a path
 */
export const landingHeaderlessRoutes = [
    { exact : true, component : () => <div>404 page</div>},
]