import { useDispatch, useSelector } from "react-redux"
import { CONTRACTS } from ".";
import { walletStrings, WALLET_REDUCER_NAME } from "../../components";
import { store } from "../../helpers";
import BigNumber from "bignumber.js";
export default function tokenContractActions (instance){
    const {[WALLET_REDUCER_NAME] : walletReducer} = store.getState(),
    {
        [walletStrings.address] : address,
        [walletStrings.web3] : web3,
    } = walletReducer,
    decimals = async () => parseInt(await instance?.methods?.decimals().call()),
    reducerNumber = async amount => {
        const _decimals = await decimals();
        return parseFloat(amount) / (10 ** _decimals);
    },
    expandNumber = async amount => {
        const _decimals = await decimals();
        BigNumber.config({DECIMAL_PLACES : _decimals});
        return new BigNumber(amount * 10 ** _decimals).toFixed();
    }
    return {
        reducerNumber,
        decimals,
        name : async () => await instance?.methods?.name().call(),
        symbol : async () => await instance?.methods?.symbol().call(),
        myBalance : async () => {
            const _decimals = await decimals();
            return reducerNumber(await instance?.methods?.balanceOf(address).call())
        },
        balanceOf : async (address) => reducerNumber(await instance?.methods?.balanceOf(address).call()),
        totalSupply : async () => await instance?.methods?.totalSupply().call(),
        approve : async (recieverAddress, value) => {
            return await instance?.methods?.approve(
                recieverAddress, String(await expandNumber(value))
            ).send({from : address})
        },
        transfer : async (recieverAddress, value) => {
            return await instance?.methods?.transfer(
                recieverAddress, String(await expandNumber(value))
            ).send({from : address, gasLimit: 500000})
        },
        increaseAllowance : async (spender, value) => {
            return instance?.methods?.increaseAllowance(spender, value).send({from : address})
        }
    };
}
export {tokenContractActions};