import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { appValues, contractLabels } from '../../helpers';
import { 
    createMetadata, getMetadata, 
    giftDappUtilActions, STORAGE_VARIABLE, 
    getAddressBook 
} from '../../utils';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../utils/config';
import walletConnection from '../../utils/web3/walletConnection';
import { walletActions, walletStrings, WALLET_REDUCER_NAME } from '../structures';
import { CustomDialog, errorToast } from '../../includes';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/client'
import { useMutation }  from "@apollo/client";
const style = makeStyles(({colors}) => ({
    dialogPaper : {
        background : colors.background,
        borderRadius : 20,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.text,
        padding : 20,
    },
}));
const GiftDappSetup = React.memo(function(props) {
    const dispatch = useDispatch(),
    classes = style(),
    giftDappUtilReducer = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME]),
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    { 
        [walletStrings.addresses] : addresses,
    } = walletReducer,
    { 
        [contractLabels.gift_influencer] : influencerAddress,
        [contractLabels.gift_project] : projectAddress,
    } = addresses || {},
    {
        [giftDappUtilStrings.processing] : processing,
        [giftDappUtilStrings.processMsg] : processMsg,
    } = giftDappUtilReducer,
    {data : metaData} = useQuery(getMetadata(), {
        variables : {
            addresses : [influencerAddress, projectAddress]
        },
        skip : !influencerAddress
    }),
    {metadata : _metadata} = metaData || {},
    metadata = _metadata?.map(({address, birthBlock})=>({address, birthBlock})),
    [createContractMetadata] = useMutation(createMetadata())
    // useEffect(() => {
    //     (async() => {
    //         if(!address || !metadata) return null;
    //         if(metadata && metadata.length == 0){
    //             const influencerMD = metadata?.find(item => item.address === influencerAddress);
    //             const projectMD = metadata?.find(item => item.address === projectAddress),
    //             latestBlock = await web3.eth.getBlockNumber(),
    //             MAX_BLOCK_SIZE = 4500,
    //             loopToGenesisBlock = Math.round(latestBlock / MAX_BLOCK_SIZE)
    //             if(!influencerMD){
    //                 let fromBlock = latestBlock - MAX_BLOCK_SIZE > 0 ? latestBlock - MAX_BLOCK_SIZE : 0,
    //                  toBlock = latestBlock
    //                 for (let i = 0; i < loopToGenesisBlock; i++) {
    //                     let events = await influencerContract.getPastEvents(
    //                         "RewardProjectCreate", 
    //                         {
    //                             fromBlock,
    //                             toBlock
    //                         }
    //                     );
    //                     let {blockNumber} = events?.[0] || {};
    //                    if(blockNumber){
    //                         let { data } = await createContractMetadata({
    //                             variables : {
    //                                 metadataArray : [{
    //                                     address : influencerAddress,
    //                                     birthBlock : blockNumber
    //                                 }]
    //                             }
    //                         }),
    //                         {createMetadata} = data || {},
    //                         result = createMetadata?.[0]
    //                         metadata.push(result);
    //                         break;
    //                     }
    //                     if(fromBlock === 0) break;
    //                     toBlock = toBlock - MAX_BLOCK_SIZE < 0 ? MAX_BLOCK_SIZE - toBlock : toBlock - MAX_BLOCK_SIZE;
    //                     fromBlock = fromBlock - MAX_BLOCK_SIZE < 0 ? 0 :fromBlock - MAX_BLOCK_SIZE;
    //                 }
    //             }
    //             if(!projectMD){
    //                 let fromBlock = latestBlock - MAX_BLOCK_SIZE, toBlock = latestBlock
    //                 for (let i = 0; i < loopToGenesisBlock; i++) {
    //                     let events = await influencerContract.getPastEvents(
    //                         "RewardProjectCreate", 
    //                         {
    //                             fromBlock: 0,
    //                         }
    //                     );
    //                     let {blockNumber} = events?.[0] || {};
    //                     if(blockNumber){
    //                         let { data } = await createContractMetadata({
    //                             variables : {
    //                                 metadataArray : [{
    //                                     address : projectAddress,
    //                                     birthBlock : blockNumber
    //                                 }]
    //                             }
    //                         }),
    //                         {createMetadata} = data || {},
    //                         result = createMetadata?.[0];
    //                         metadata.push(result);
    //                         break;
    //                     }
    //                 }
    //             }
    //         }
    //         if(metadata?.length > 0) giftDappUtilActions.payload({
    //             [giftDappUtilStrings.contractMetadata] : metadata
    //         })(dispatch);
    //     })()
    // }, [JSON.stringify(metadata), address]);
    useEffect(() => {
        giftDappUtilActions.payload({
            [giftDappUtilStrings.appDomain] : appValues.appDomain,
            [giftDappUtilStrings.appFrontend] : appValues.appFrontend,
            [giftDappUtilStrings.storageNetworkID] : STORAGE_VARIABLE.NETWORK_ID,
            [giftDappUtilStrings.storageNetworkType] : STORAGE_VARIABLE.NETWORK_TYPE,
            [giftDappUtilStrings.storageNetworkWallet] : STORAGE_VARIABLE.NETWORK_WALLET,
            [giftDappUtilStrings.storageLastUsedProfile] : STORAGE_VARIABLE.PROFILE_LASTUSED,
        })(dispatch);
        (async()=>{
            try{
                const contractAddresses = getAddressBook();
                walletActions({
                    [walletStrings.contractAddresses] : contractAddresses
                })(dispatch);
                const networkWallet = localStorage.getItem(STORAGE_VARIABLE.NETWORK_WALLET);
                //if there no networktype in the storage, don't login, allow use to choice network
                if(networkWallet){
                    walletConnection(contractAddresses, networkWallet, false)
                    // .then(() => successToast("Connected!")(dispatch))
                    // .catch(({message})=> errorToast(message)(dispatch));
                }
            }catch(error) {
                errorToast(error.message)(dispatch);
            }
            
        })();
    }, [])
    return <CustomDialog
        open={processing}
        classes={{
            dialogPaper : classes.dialogPaper,
        }}
    >
        <div className={classes.container}>
            <span>{processMsg || "Processing block transaction ...."}</span>
            <span>
                <LinearProgress/>
            </span>
        </div>
    </CustomDialog>;
})
export default GiftDappSetup;
export {GiftDappSetup};