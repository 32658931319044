import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames';
import { giftRewardActions } from '../../../../actions'
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from '../../../../config'
import { errorToast } from '../../../../../../includes'
const style = makeStyles(({colors}) => ({
    root : {
        fontSize : 10,
        marginLeft : 20,
        cursor : "pointer"
    },
    add : {
        color : colors.accent,
    },
    remove : {
        color : colors.danger,
    },
    inactive : {
        cursor : "not-allowed",
        color : colors.textSecondary,
    }
}))
function GiftDappTokenSearchAddRmoveAction({token : {address, balance}, actionCallback}) {
    const dispatch = useDispatch(),
    classes = style(),
    giftRewardReducer = useSelector(state => state[GIFT_REWARD_REDUCER_NAME]),
    {
        [giftRewardStrings.rewardTokens] : rewardTokens,
    } = giftRewardReducer,
    isAdded = rewardTokens && rewardTokens.indexOf(address) !== -1,
    onAdd = useCallback(() => {
        if(balance === 0) return null;
        const tokens = rewardTokens ? [...rewardTokens] : [];
        if(tokens.length === 2) return errorToast("Can't add more than 2 token types")(dispatch);
        //this is not meant to happen
        else if (tokens.indexOf(address) !== -1) errorToast("Token already added!")(dispatch);
        tokens.push(address);
        giftRewardActions.payload({
            [giftRewardStrings.rewardTokens] : tokens
        })(dispatch);
        actionCallback && actionCallback(true)
    }, [JSON.stringify(rewardTokens), address, balance]),
    onRemove = useCallback(() => {
        const token = [...rewardTokens];
        token.splice(token.indexOf(address), 1);
        giftRewardActions.payload({
            [giftRewardStrings.rewardTokens] : token
        })(dispatch);
        actionCallback && actionCallback();
    }, [JSON.stringify(rewardTokens), address]);
    return (
        <div className={classes.root}>
            {!isAdded ? 
                <div className={classNames(classes.add, balance===0 ? classes.inactive : "")} onClick={onAdd}>Add</div> : 
                <div className={classes.remove} onClick={onRemove}>Remove</div>
            }
        </div>
    )
}
GiftDappTokenSearchAddRmoveAction.propTypes = {
    
}
export default GiftDappTokenSearchAddRmoveAction;
export {GiftDappTokenSearchAddRmoveAction};