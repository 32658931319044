import React from 'react'
import { makeStyles } from '@material-ui/core'
import { CustomButton } from '../../../../includes'
import classNames from 'classnames';
import './index.css';
const style = makeStyles(({colors, isDark, breakpoints}) => ({
    root : {
        position : "relative",
        background : colors.checkboxContainer,
        color : colors.text,
        padding : 15,
        [breakpoints.up("sm")] : {
            width : 240,
        },
        fontSize : 16,
    },
    checkBox : {
        marginRight : 8
    },
    btn : {
        background : colors.checkBoxContainer,
        color : colors.checkBoxContainerColor,
        textAlign:"left !important",
        margin:"5px 0",
        "&:hover" : {
            background : colors.checkBoxContainer,
        color : colors.checkBoxContainerColor,
        }
    },
    active : {
        background : colors.selectedCheckBox,
        color : colors.selectedCheckBoxColor
    },
    // btn : {
    //     background : !isDark && colors.accent,
    //     color : !isDark && colors.text,
    //     textAlign:"left !important",
    //     margin:"5px 0",
    //     "&:hover" : {
    //         background : !isDark && colors.accent,
    //         color : !isDark && colors.text,
    //     }
    // }
}))
function GiftDappDashboardNavToggleButton({toggleProject, dashboardNavProjectTypeWithBool, selectAll, allTypesSelected}) {
    const classes = style();
    return (
        <div className={classes.root}>
                  <CustomButton
                  classes={{
                    btn : classNames(
                        classes.btn, 
                        allTypesSelected ? classes.active : ""
                    ),
                }}
                fullWidth
                noBoxShadow
                type="secondary" 
                onClick={()=> {
                    let id = document.getElementById("ALL")
                    id.checked = !id.checked
                    selectAll(id.checked)
                }}
                
                >
                    <label className="container">
  <input 
  type="checkbox" 
  name="ALL" 
  onClick={(e) =>selectAll(e.target)}
  id="ALL"
  checked={allTypesSelected}
  />
  <span className="checkmark"></span>
</label> ALL
            </CustomButton>
            {dashboardNavProjectTypeWithBool.map(button =>
                 <CustomButton
                 classes={{
                    btn : classNames(
                        classes.btn, 
                        button.checked ? classes.active : ""
                    ),
                }}
                fullWidth
                noBoxShadow
                type="secondary" 
                onClick={()=> {
                    let id = document.getElementById(button.label)
                    id.checked = !id.checked
                    toggleProject(button)
                }}
                
                >
                    <label className="container">
  <input 
  type="checkbox"
  name={button.label} 
  onChange={() => toggleProject(button)} 
  checked={button.checked}
  id={button.label}
  />
  <span className="checkmark"></span>
</label> {button.label.toUpperCase()}
            </CustomButton>
            )}
           
        </div>
    )
}
GiftDappDashboardNavToggleButton.propTypes = {
    
}
export default GiftDappDashboardNavToggleButton;
export {GiftDappDashboardNavToggleButton};