// export const prefix = "";
export const prefix = process.env.NODE_ENV !== "production" || 
    !process.env.REACT_APP_VERSION || 
    process.env.REACT_APP_VERSION === "v1" ? "" : "v2/app";
export const giftDappV1Prefix = prefix;
export const giftDappV1RouteNames = () => ({
    reward : `${giftDappV1Prefix}/`,
    rewardSetup: `${giftDappV1Prefix}/setup`,
    rewardView :`${giftDappV1Prefix}/dashboard/view/:id`,
    rewardView_ : `${giftDappV1Prefix}/dashboard/view/`,
    events: `${giftDappV1Prefix}/events`,
    eventView : `${giftDappV1Prefix}/events/v/:id`,
    eventView_ : `${giftDappV1Prefix}/events/v/`,
    buyGRP: `${giftDappV1Prefix}/buy`,
    dashboard: `${giftDappV1Prefix}/dashboard`,
    profile : `${giftDappV1Prefix}/profile`,
    profileView : `${giftDappV1Prefix}/profile/v/:id`,
    profileView_ : `${giftDappV1Prefix}/profile/v/`,
})