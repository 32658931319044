import { gql, useLazyQuery } from '@apollo/client';
import { makeStyles, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { errorToast } from '../../../../includes';
import { fetchGet } from '../../../../utils';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../../../utils/config';
import { walletActions, walletMainActions, walletTokenActions } from '../actions';
import { walletStrings, walletTokenStrings, WALLET_REDUCER_NAME, WALLET_TOKEN_REDUCER_NAME } from '../reducer';
const style = ({colors, breakpoints}) => ({
    root : {
        
    },
    tokenImage : {
        width : 20,
        height : 20,
        margin : "0px 10px"
    }
}),
GET_TOKEN_SLUG = gql`
    query getTokenSlug ($symbol : String) {
        tokenSlug : getTokenSlug(symbol : $symbol) {
            result
        }
    }
`;
export default React.memo(withStyles(style)(function GiftDappWalletTokenIcon ({classes, symbol, display, imageProps}) {
    const dispatch = useDispatch(),
        [getTokenSlug, {data}] = useLazyQuery(GET_TOKEN_SLUG, {
            variables : {
                symbol
            },
            skip : !symbol
        }),
        {tokenSlug : tokenSlugData} = data || {},
        {result : tokenSlug} = tokenSlugData || {},
        // [state, _setState] = useState({slug : ""}),
        // {slug} = state,
        // setState = _state => _setState(state=>({...state, ..._state})),
        appDomain = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME][giftDappUtilStrings.appDomain]),
        slugs = useSelector(state=>state[WALLET_TOKEN_REDUCER_NAME][walletTokenStrings.tokenSlugs]),
        slug = slugs && slugs[symbol];
        useEffect(() => {
            (async () => {
                try{
                    // if(symbol && String(symbol).toLocaleLowerCase() !== "rgp"){
                    //     const slugResult = await fetchGet("slug", symbol),
                    //     {result} = slugResult || {};
                    //     setState({slug : result})
                    // }
                }catch(error){
                    console.error(error);
                    errorToast(error?.message)(dispatch);
                }
            })()
        }, [symbol]);
        useEffect(() => {
            if(symbol && slugs && !slugs[symbol]){
                if(String(symbol).toLocaleLowerCase() !== "rgp")
                    getTokenSlug({
                        variables : { symbol}
                    })
            }
        }, [symbol, JSON.stringify(slugs)]);
        useEffect(() => {
            if(tokenSlug) walletTokenActions.setTokenSlugs({[symbol] : tokenSlug})(dispatch);
        }, [tokenSlug]);
    return display ? <img {...imageProps} className={classes.tokenImage} 
            src={String(symbol).toLocaleLowerCase() === "rgp" ? 
            "/assets/rgp.png" : `${appDomain}/icons/${slug}.png`}
        /> : String(symbol).toLocaleLowerCase() === "rgp" ? 
        "/assets/rgp.png" : `${appDomain}/icons/${slug}.png`
        
}))