import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import walletUtil from '../../utils/web3/walletUtil'
import { walletStrings, WALLET_REDUCER_NAME } from '../structures'
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        display : "flex",
        justifyContent : "flex-end",
    },
    container : {
        fontSize : 10,
        fontStyle : "italic",
        color : colors.textSecondary,
    }
}))
export default function GiftDappNetworkStatus() {
    const classes = style(),
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.networkId] : networkId
    } = walletReducer;
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {walletUtil.getNetNames(networkId)}
            </div>
        </div>
    )
}
