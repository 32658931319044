import { makeStyles } from '@material-ui/styles';
import React from 'react'


const style = makeStyles(({colors, breakpoints}) => ({
    root: {
        position: "relative",
       
       
        display: "flex", 
        justifyContent: "center",
        alignItems: "center",
        [breakpoints.down('xl')]: {
            width: "220px",
            height: "128px",
        },
        [breakpoints.down('lg')]: {
            width: "220px",
            height: "128px",
        },
        [breakpoints.down('sm')]: {
            marginTop: "4rem",
            width: "20%",
            maxWidth: "22rem",
            
        },
    },
    networkLogo: {
        width: "80px",
        height: "80px",
        position: "absolute",
        top: "0",
        [breakpoints.down('sm')]: {
            width: "50px",
            height: "50px",
        },
    },
   
}))

export default function GiftDappNetworkItem({networkName, logoName}) {
    const classes = style();

    return(
        <div className = {classes.root}>
            <img className = {classes.networkLogo} src = {`./assets/${logoName}.png`} alt = {networkName + " logo"} />
        </div>
    );
}