import React from 'react'
import classNames from 'classnames';
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core';
import { EVENT_NAV_TABS } from '../..';
import { CustomButton, customPopoverActions, customPopoverString } from '../../../../includes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import CustomFilter from '../../../../includes/CustomFilter/CustomFilter';
const style = makeStyles(({colors, isDark, breakpoints}) => ({
    root : {
        display : "flex",
        justifyContent : "space-between",
        alignItems : "center",
        fontSize : 10,
        [breakpoints.down("xs")] : {
            flexDirection : "column",
        }
    }, 
    navContainer : {
        display : "flex",
        [breakpoints.down("xs")] : {
            justifyContent : "space-between",
            width : "100%",
        }
    },
    nav : {
        // padding : "0px 15px",
        cursor : "pointer",
        display : 'flex',
        alignItems : "center",
        justifyContent : "space-between",
    }, 
    active : {
        background : !isDark ? colors.accent : colors.background,
        color : colors.text,
        borderRadius : 20,
        // color : colors.accent,
        // fontWeight : "bold",
        minWidth: 90,
        justifyContent: "space-between",
        display: "flex",
    },
    filter : {
        color : colors.textSecondary,
        cursor : "pointer",
        [breakpoints.down("xs")] : {
            display : "flex",
            justifyContent : "center",
            marginTop : 20,
            border : `solid 2px ${colors.secondary}`,
            width : "100%",
            padding : 20,
            background : colors.background,
            color : colors.text,
        }
    },
    title : {
        paddingLeft : 10,
    },
    badge : {
        background : !isDark ? colors.accent : colors.secondary,
        borderRadius : 20,
        padding : 5,
        margin : 5,
        height : 30,
        width : 25,
        display : "flex",
        justifyContent : "center",
        alignItems : 'center',
        textAlign : "center",
    },
    badgeActive : {
        background : !isDark ? colors.background : colors.secondary,
        color : colors.text
    },
    toggleBtn : {
        margin : 5,
        borderRadius : 5,
        padding : "5px 20px",
        fontSize : 10,
        background: isDark ? colors.pageBackground : colors.transparent,
        border: `1px solid ${isDark ? colors.secondary : colors.navBarLinks}`,
        color: isDark ? colors.text : colors.tertiaryTextColor
    },
    icon : {
        marginRight : 3,
    },
}))
function GiftDappEventNav({
    rewardCounts,
    activeTab, 
    setTab, 

    rewardTypeList,
    rewardIndexes,
    setRewardIndex,
}) {
    const dispatch = useDispatch(),
    classes = style(),
    {active : activeCount, ended : endedCount} = rewardCounts || {},
    allCount = (activeCount || 0) + (endedCount || 0);
    return (
        <div className={classes.root}>
            <div className={classes.navContainer}>
                <div 
                    onClick={() => setTab && setTab(EVENT_NAV_TABS[0]._id)}
                    className={classNames(
                        classes.nav, 
                        activeTab === EVENT_NAV_TABS[0]._id ? classes.active : ""
                    )}>
                        <div className={classes.title}>{EVENT_NAV_TABS[0].label}</div>
                        <div className={classNames(
                            classes.badge,
                            activeTab === EVENT_NAV_TABS[0]._id ? 
                            classes.badgeActive : ""
                        )}>{allCount > 9 ? "9+" : allCount}</div>
                    </div>
                <div 
                    onClick={() => setTab && setTab(EVENT_NAV_TABS[1]._id)}
                    className={classNames(
                        classes.nav, 
                        activeTab === EVENT_NAV_TABS[1]._id ? classes.active : ""
                    )}>
                        <div className={classes.title}>{EVENT_NAV_TABS[1].label}</div>
                        <div className={classNames(
                            classes.badge,
                            activeTab === EVENT_NAV_TABS[1]._id ? 
                            classes.badgeActive : ""
                        )}>{activeCount > 9 ? "9+" : (activeCount || 0)}</div>
                    </div>
                <div 
                    onClick={() => setTab && setTab(EVENT_NAV_TABS[2]._id)}
                    className={classNames(
                        classes.nav, 
                        activeTab === EVENT_NAV_TABS[2]._id ? classes.active : ""
                    )}>
                        <div className={classes.title}>{EVENT_NAV_TABS[2].label}</div>
                        <div className={classNames(
                            classes.badge,
                            activeTab === EVENT_NAV_TABS[2]._id ? 
                            classes.badgeActive : ""
                        )}>{endedCount > 9 ? "9+" : (endedCount || 0)}</div>
                    </div>
            </div>
                <CustomButton
                    classes={{
                        btn : classNames(
                            classes.toggleBtn,
                        )
                    }}
                    noBoxShadow
                    // fullWidth={width === "xs" ? true :false }
                    onClick={({target : anchorEl})=>{
                        customPopoverActions.payload({
                            [customPopoverString.anchorEl] : anchorEl,
                            [customPopoverString.customComponent] : () => <CustomFilter
                                list={rewardTypeList.map((item, index)=>({_id : index, label : item}))}                
                                onSelect={props => {
                                    setRewardIndex(props)
                                    customPopoverActions.payload({
                                        [customPopoverString.anchorEl] : null,
                                        [customPopoverString.customComponent] : null
                                    })(dispatch);
                                }}
                                selectedList={rewardIndexes}
                            />,
                            [customPopoverString.anchorOrigin] : {
                                horizontal : "left",
                            }
                        })(dispatch);
                    }}                    
                    type="primary"
                >
                    <FontAwesomeIcon icon={faFilter} size="2x" className={classes.icon} /> Filter 
                </CustomButton>
            {/* </Hidden> */}
        </div>
    )
}
GiftDappEventNav.propTypes = {
    
}
export default GiftDappEventNav;
export {GiftDappEventNav};