import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, makeStyles, withWidth } from '@material-ui/core'
import { PowerSettingsNew } from '@material-ui/icons'
import classNames from 'classnames';
import { walletStrings, WALLET_REDUCER_NAME } from '../../../GiftDappWallets'
import walletUtil from '../../../../../utils/web3/walletUtil'
import { CONTRACTS, getContractInstance, giftContractActions, shortAddress, tokenContractActions,NETWORKS } from '../../../../../utils'
import { customPopoverActions, customPopoverString,CustomDialog, errorToast } from '../../../../../includes'
import { GiftDappConnectedDetails } from './GiftDappConnectedDetails'
import { GiftDappNetworks } from '../GiftDappNetworks';
import { WALLET_KEYS } from '..'
import walletConnection from '../../../../../utils/web3/walletConnection';
import GiftDappWalletSwitchModal from './../GiftDappWalletSwitchModal/GiftDappWalletSwitchModal';
import useGAEventsTracker from './../../../../../hooks/useGAEventsTracker';

const style = makeStyles(({breakpoints, isDark, colors : {
    secondaryDark, secondary, secondary2, primary, backgroundDark,
    background, textBackground,text, accentSecondary, accent,
    textSecondary
}}) => ({
    root : {
        display : 'flex',
        alignItems : "center",
        [breakpoints.down("xs")] : {
            // flexDirection : "column",
            fontSize : 10,
            flexDirection : "row-reverse"
        }
    },
    balance : {
        display : "flex",
        padding: 13,
        background : primary, //"linear-gradient(100deg, " + primary + ", transparent)",
        alignItems : "center",
        borderRadius : 5,
        margin : "0px 5px",
        [breakpoints.down("sm")] : {

        },
        [breakpoints.up("md")] : {
            // minWidth : 150,
        },
        [breakpoints.up("lg")] : {
            // minWidth : 200,
        }
    },
    midiumlogo: {
        height: 18,
        width: 18,
        marginRight:5
      },
    amount_add_holder : {
        padding : 5,
        background : !isDark ? secondaryDark : secondary,
        display : "flex",
        margin : "0px 2px",
        borderRadius : 5,
        alignItems : "center",
    },
    eth : {
        margin : "0 10px",
    },
    address : {
        display : "flex",
        alignItems : "center",
        padding : 10,
        background : !isDark ? backgroundDark : secondary2,
        borderRadius : 0,
        cursor : "pointer",
    },
    metamask_img : {
        height : 13,
        width : 13,
        [breakpoints.up("sm")] : {
            marginLeft : 5
        }
    },
    iconBtn : {
        background : !isDark ? secondaryDark : secondary,
        borderRadius : 5,
        padding : 10,
        margin : "0px 2px",
        "&:hover" : {
            background : !isDark ? secondaryDark : background,
        },
        [breakpoints.up("sm")] : {
            margin : "0 5px"
        }
    },
    iconBtnConnected : {
        padding : 12,
    },
    icon : {
        color : textBackground
    },
    cursor : {
        cursor:"pointer"
    },
    dialogPaper : {
        background : background,
        border : "solid 1px " + accentSecondary,
        color : text,
        overflow : "visible",
    },
    dialogClose : {
        background : text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : accent,
            color : textSecondary
        }
    },
}))
function _GiftDappConnected({width, ...props}) {
    const classes = style(),
        [state, _setState] = useState({
            mainBalance : 0,
            rigelBalance : 0,
            openDialog : false,
            logo:""
        }),
        { mainBalance, rigelBalance, openDialog,logo } = state,

        setState = _state => _setState(state=>({...state, ..._state})),
    dispatch = useDispatch(),
    closeDialog = () => {
        setState({...state,dialogType : "", openDialog : false});
    },
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.address] : address,
        [walletStrings.networkType] : networkType,
        [walletStrings.networkWallet] : networkWallet,
        [walletStrings.web3] : web3,
        [walletStrings.contractAddresses] : contractAddresses
    } = walletReducer,
    onNetworkSelect = useCallback(async(networkId)=>{
        if(!address) return;
        try{
            const _id = walletUtil.getTruffleNetworkName(networkId);
            const provider = web3._provider;
            if(!NETWORKS[_id]) throw new Error("Network metadata not configured");
            else{
      await walletUtil.switchNetwork(provider, NETWORKS[_id].chainId, NETWORKS[_id]);
      await walletConnection(contractAddresses, networkWallet, walletUtil.getContractType(null), null, null, true);
            }

        }catch(e){

        }
    },[]);
    useEffect(()=>{
        (async() => {
            try{
                const [mainBalance, rigelBalance] =
                    await Promise.all([
                        walletUtil.getBalance(),
                        tokenContractActions(
                            await getContractInstance(
                                web3, null,
                                await giftContractActions().RGPTokenAddress()
                            )
                        ).myBalance()
                    ])
                   let logo =networkType ? walletUtil.networkLogos[networkType] :""
                    setState({mainBalance, rigelBalance,logo})
            }catch({message}){
                errorToast(message)(dispatch);
            }
        })();
    }, [address])
    const _symbol = networkType === WALLET_KEYS.BINANCE ? " BNB" : " ETH";
    const [displayModal, setDisplayModal] = useState(false);
    const GAEventsTracker = useGAEventsTracker();
    return (
        <div className={classes.root}>
            <div className={classNames(classes.balance, classes.cursor)} title={networkType} onClick={()=> { setState({...state,dialogType : "", openDialog : true}); GAEventsTracker("display_network_modal", "header"); }}>
           {logo && <img src={logo} className={classes.midiumlogo} alt={"Binance"}/>}  {networkType}
            </div>
            <div className={classes.balance} style={{padding: "14px"}} title={`${Number(rigelBalance).toLocaleString()} RGP`}>
                {String(rigelBalance).length > 6 ? String(Number(rigelBalance).toLocaleString()).substr(0, 6) + "..." : Number(rigelBalance).toLocaleString()} RGP
            </div>
            <div className={classes.amount_add_holder}>
                <div className={classes.eth}>
                    {Number(mainBalance).toLocaleString()}
                    {_symbol}
                </div>
                <div className={classes.address} onClick={()=> { setDisplayModal((state) => !state); GAEventsTracker("display_switch_modal", "header"); }}>
                    {width !== "xs" && shortAddress(address)}
                    <img src={`/assets/${networkWallet === WALLET_KEYS.TRUSTWALLET ? "bnb" : "metamask"}.png`} className={classes.metamask_img}/>
                </div>
            </div>
            <GiftDappWalletSwitchModal
              displayModal={displayModal}
              setDisplayModal={setDisplayModal}
            />
            <CustomDialog
                within
                classes={{
                    dialogPaper : classes.dialogPaper,
                    _close : classes.dialogClose,
                }}
                open={openDialog}
                close={closeDialog}
            >
               <GiftDappNetworks
               network={networkType}
                onSelect={onNetworkSelect}
               />
            </CustomDialog>
        </div>
    )
}
_GiftDappConnected.propTypes = {

}
export const GiftDappConnected = withWidth()(_GiftDappConnected);
export {GiftDappConnected as default};
