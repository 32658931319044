import React, { useState } from 'react'
import classNames from 'classnames';
import { useSelector } from 'react-redux'
import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import { walletStrings } from '../../GiftDappWallets';
import GiftDappConnect from './GiftDappConnect/GiftDappConnect';
import GiftDappConnected from './GiftDappConnected/GiftDappConnected';
import { Brightness6, MoreHoriz } from '@material-ui/icons';
import { CustomPopover } from '../../../../includes';
import { GiftDappMedias } from './GiftDappMedias';
const style = makeStyles(theme => ({
    root : {
        display : "flex",
        fontSize : 12,
        [theme.breakpoints.down("sm")] : {
            position : "fixed",
            border : `solid 1px ${theme.colors.background}`,
            borderRadius : "20px 20px 0 0",
            bottom : 0,
            left : 0,
            zIndex : 200,
            width : "100%",
            display : "flex",
            justifyContent : "center",
            alignItems : "flex-end",
            padding : 10,
            background : theme.colors.background,
            borderRadius : "20px 20px 0 0"
        }
    },
    connecting : {
        padding : "10px 20px",
        background : theme.colors.backgroundAccent,
        color : theme.colors.textSecondary,
        borderRadius : 20,
        fontSize : 12,
    },
    iconBtn : {
        background : !theme.isDark ? theme.colors.secondaryDark : theme.colors.secondary,
        borderRadius : 5,
        padding : 10,
        margin : "0px 2px",
        "&:hover" : {
            background : !theme.isDark ? theme.colors.secondaryDark : theme.colors.background,
        },
        [theme.breakpoints.up("sm")] : {
            margin : "0 5px"
        }
    },
    iconBtnConnected : {
        padding : 12,
    },
    icon : {
        color : theme.colors.textBackground
    },
    extraBtn : {
        display : "flex", 
        alignItems : "center",
    }
}))
function GiftDappConnection({switchTheme}) {
    const classes = style(),
    [state, _setState] = useState({anchorEl : null}),
    {anchorEl} = state,
    setState = (_state, callback) => _setState(state=>({...state, ..._state}), callback),
    {walletReducer} = useSelector(({walletReducer})=>({walletReducer})),
    {
        [walletStrings.address] : address,
    } = walletReducer;
    return (
        <div className={classes.root}>
            {address ? <GiftDappConnected/> : <GiftDappConnect/>}
            <div className={classes.extraBtn}>
            <Tooltip title="Night mode" arrow>
                <IconButton className={classNames(classes.iconBtn, address ? classes.iconBtnConnected : "")} onClick={() => switchTheme && switchTheme()}>
                    <Brightness6 className={classes.icon}/>
                </IconButton>
            </Tooltip>
             
                <IconButton className={classNames(classes.iconBtn, address ? classes.iconBtnConnected : "")} onClick={({target : anchorEl})=>setState({anchorEl})}>
                    <MoreHoriz className={classes.icon}/>
                </IconButton>
            </div>
            <CustomPopover
                anchorEl={anchorEl}
                onClose={() => setState({anchorEl : null})}
            >
                <GiftDappMedias/>
            </CustomPopover>
        </div>
    )
}
GiftDappConnection.propTypes = {
    
}
export default GiftDappConnection;
export {GiftDappConnection};