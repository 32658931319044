export const giftDappProfileStrings = {
    image : "image",
    username : "username",
    lastname : "lastname",
    firstname : "firstname",
    about : "about",
    instagram : "intagram",
    telegram : "telegram",
    twitter : "twitter",
    youtube : "youtube",
    reddit : "reddit",
    discord : "discord",
    
    lastUsedProfile : "lastUsedProfile",
    profiles : "profiles",
    refreshCount : "refreshCount",
}
export const giftDappProfileConstants = {
    SET_GIFT_DAPP_PROFILE : "SET_GIFT_DAPP_PROFILE",
    REFRESH_GIFT_DAPP_PROFILES : "REFRESH_GIFT_DAPP_PROFILES",
}
export const PROFILE_SOCIAL_MEDIAL_KEYS = {
    PATH : "path",
    IS_VERIFIED : "isVerified",
}
export const giftDappProfileReducer = (state={
    [giftDappProfileStrings.refreshCount] : 0
}, action) => {
    const {payload, type} = action;
    switch(type){
        case giftDappProfileConstants.SET_GIFT_DAPP_PROFILE : 
            return {...state, ...payload};
        case giftDappProfileConstants.REFRESH_GIFT_DAPP_PROFILES : 
            return {
                ...state, 
                [giftDappProfileStrings.refreshCount] : ++state[giftDappProfileStrings.refreshCount]
            };
        default : return state;
    }
}
export const GIFT_DAPP_PROFILE_REDUCER_NAME = "giftDappProfileReducer";