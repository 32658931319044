import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Divider, LinearProgress, makeStyles } from '@material-ui/core'
import { gql, useQuery } from '@apollo/client'
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons'
const style = makeStyles(({colors}) => ({
    root : {
        minHeight : 300,
        padding : 20,
        display : "flex",
        alignItems : 'center',
    },
    progress : {
        width : "100%",
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        justifyContent : "center",
    },
    setupContainer : {
        border : "solid 1px " + colors.accent,
        width : "100%",
        height : "100%",
        padding : 20,
        borderRadius : 0,
        minHeight : 200,
    },
    header : {
        fontSize : 14,
        fontWeight : "bold",
        display : "flex",
        justifyContent : "center",
    },
    divider : {
        background : colors.accent,
    },
    boxContainer : {
        marginTop : 20,
        display : "flex",
        alignItems : "center",
    }
}))
const HAS_GANACHE = gql`
    query {
        hasGanache
    }
`;
function GiftDappConnectionFallback({setup, ...props}) {
    const dispatch = useDispatch(),
    classes = style(),
    {data : ganacheData } = useQuery(HAS_GANACHE),
    {hasGanache} = ganacheData || {};
    return (
        <div className={classes.root}>
            {!setup ? <div className={classes.progress}>
                Connecting to wallet ...
                <LinearProgress style={{width : "60%"}}/>
            </div> : 
            <div className={classes.setupContainer}>
                <div className={classes.header}>Local Network Dev Environment Wizard</div>
                <Divider className={classes.divider}/>
                <div>
                    {!hasGanache ? <div className={classes.boxContainer}>
                        <CheckBoxOutlineBlankOutlined/>
                        <span className={classes.boxText}>
                            Ganache-cli not installed!
                        </span> 
                    </div> : <div className={classes.boxContainer}>
                        <CheckBoxOutlined/>
                        <span className={classes.boxText}>
                            Ganache-cli installed.
                        </span>
                    </div>}
                </div>
            </div>}
        </div>
    )
}
GiftDappConnectionFallback.propTypes = {
    
}
export default GiftDappConnectionFallback;
export {GiftDappConnectionFallback};