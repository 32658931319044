import { makeStyles, InputBase, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import classNames from "classnames";
import { Close } from "@material-ui/icons";
import {
    giftDappProfileStrings,
    PROFILE_SOCIAL_MEDIAL_KEYS,
} from "../../config";
import TwitterLogin from "react-twitter-auth";
import { useDispatch, useSelector } from "react-redux";
import {
    giftDappUtilStrings,
    GIFT_DAPP_UTIL_REDUCER_NAME,
} from "../../../../utils/config";
import { errorToast } from "../../../../includes";
import { giftDappSocialMedialStrings } from "../../../GIftDappEvents/config";
import { MEDIA_NAMES } from "../GiftDappProfileSocialMedia";
const style = makeStyles(({ colors, breakpoints }) => ({
    root: {
        display: "flex",
        margin: "20px 0",
    },
    iconContainer: {
        borderRadius: 5,
        border: `solid 1px ${colors.background}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 40,
        height: 40,
    },
    leftIconContainer: {
        marginRight: 10,
    },
    rightIconContainer: {
        marginLeft: 10,
        cursor: "pointer",
    },
    inputVerify: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        border: `solid 1px ${colors.background}`,
        padding: "5px 10px",
    },
    textField: {
        display: "flex",
        flexGrow: 1,
        borderBottom: "none",
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary,
    },
    color: {
        color: colors.textSecondary,
    },
    verify: {
        background: "transparent",
        border: "none",
        fontSize: 10,
        color: colors.accent,
        cursor: "pointer",
    },
    verified: {
        color: colors.verified,
        cursor: "not-allowed",
    },
    disabled: {
        cursor: "not-allowed",
    },
    processing: {
        width: "20px !important",
        height: "20px !important",
    },
}));
export default function GiftDappProfileSocialMediaItem({
    verify,
    unverify,
    icon: Icon,
    isNew,
    data = {},
    onChange,
    isProcessing,
    name,
}) {
    const classes = style(),
        dispatch = useDispatch(),
        { IS_VERIFIED, PATH } = PROFILE_SOCIAL_MEDIAL_KEYS,
        { [IS_VERIFIED]: isVerified, [PATH]: path } = data,
        giftDappUtilReducer = useSelector(
            (state) => state[GIFT_DAPP_UTIL_REDUCER_NAME]
        ),
        {
            [giftDappUtilStrings.appFrontend]: appFrontend,
            [giftDappUtilStrings.appDomain]: appDomain,
        } = giftDappUtilReducer,
        onTwitterAuthSuccess = (props) => {
            props.json().then(({ screen_name }) => {
                onChange(PATH, screen_name);
                verify();
            });
        };
    return (
        <div className={classes.root}>
            <div
                className={classNames(
                    classes.iconContainer,
                    classes.leftIconContainer
                )}
            >
                {Icon && <Icon />}
            </div>
            <div
                className={classNames(
                    classes.inputVerify,
                    isVerified ? classes.disabled : ""
                )}
            >
                <InputBase
                    className={classNames(
                        classes.textField,
                        isVerified ? classes.disabled : ""
                    )}
                    margin="dense"
                    placeholder={
                        name === MEDIA_NAMES.TWITTER
                            ? "Click on verify"
                            : "Insert youtube ID"
                    }
                    onChange={({ target: { value } }) => onChange(PATH, value)}
                    value={path || ""}
                    disabled={name === MEDIA_NAMES.TWITTER}
                    classes={{
                        inputHiddenLabel: classes.label,
                        inputTypeSearch: classes.label,
                        disabled: classes.label,
                    }}
                    // disabled={isVerified}
                />
                {!isNew &&
                    (isProcessing ? (
                        <CircularProgress className={classes.processing} />
                    ) : name !== giftDappProfileStrings.twitter ? (
                        <div
                            onClick={!isVerified ? verify : null}
                            className={classNames(
                                classes.verify,
                                isVerified ? classes.verified : ""
                            )}
                        >
                            {isVerified ? "Verified" : "Verify"}
                        </div>
                    ) : (
                        <TwitterLogin
                            loginUrl={`${appDomain}/auth/twitter/final`}
                            onFailure={() =>
                                errorToast("Unable to verify twitter")(dispatch)
                            }
                            onSuccess={onTwitterAuthSuccess}
                            requestTokenUrl={`${appDomain}/auth/twitter/reverse`}
                            text="Verify Twitter Account"
                            className={classNames(
                                classes.verify,
                                isVerified ? classes.verified : ""
                            )}
                        >
                            {isVerified ? "Verified" : "Verify"}
                        </TwitterLogin>
                    ))}
            </div>
            {!isNew && isVerified && (
                <div
                    className={classNames(
                        classes.iconContainer,
                        classes.rightIconContainer
                    )}
                    onClick={unverify}
                >
                    <Close color="secondary" className={classes.closeIcon} />
                </div>
            )}
        </div>
    );
}
