import React, { useCallback, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import {
    CustomDialog,
    successToast,
    errorToast,
} from "../../../../../includes";
import { WALLET_KEYS } from "..";
import { useDispatch, useSelector } from "react-redux";
import walletConnection from "../../../../../utils/web3/walletConnection";
import { walletStrings, WALLET_REDUCER_NAME } from "../../../GiftDappWallets";
import GiftDappWalletOptions from "./../../../GiftDappWallets/GiftDappWalletOptions/GiftDappWalletOptions";

const style = makeStyles(({ colors, isDark }) => ({
    root: {
        color: colors.text,
        padding: 18,
    },
    dialogPaper: {
        background: colors.background,
        border: "solid 1px " + colors.accentSecondary,
        color: colors.text,
        overflow: "visible",
    },
    dialogClose: {
        background: colors.text,
        top: -40,
        padding: 5,
        "&:hover": {
            background: colors.accent,
            color: colors.textSecondary,
        },
    },
}));

function GiftDappWalletModal({ displayWallet, setDisplayWallet }) {
    const classes = style();
    const dispatch = useDispatch();
    const [state, _setState] = useState({
        anchorEl: null,
        openDialog: false,
        dialogType: "",
        onload: false,
    });
    const setState = (_state, callback) =>
        _setState((state) => ({ ...state, ..._state }), callback);
    const walletReducer = useSelector((state) => state[WALLET_REDUCER_NAME]);
    const {
        [walletStrings.contractAddresses]: contractAddresses,
        [walletStrings.web3]: web3,
    } = walletReducer;
    const connectToWallet = useCallback(
        (wallet) => {
            setState({ onload: true });
            let networkType;
            if (wallet === WALLET_KEYS.TRUSTWALLET)
                networkType = WALLET_KEYS.BINANCE;
            else networkType = WALLET_KEYS.ETHEREUM;
            walletConnection(contractAddresses, wallet, (error) => {
                if (!error) {
                    setState({ onload: false, dialogType: wallet });
                    setDisplayWallet(false);
                    successToast("Connected!")(dispatch);

                    return;
                }

                setState({ onload: false, dialogType: wallet });
                setDisplayWallet(false);
                errorToast("Not Connected!")(dispatch);
            });
        },
        [JSON.stringify(contractAddresses)]
    );

    return (
        <CustomDialog
            within
            classes={{
                dialogPaper: classes.dialogPaper,
                _close: classes.dialogClose,
            }}
            open={displayWallet}
            close={() => setDisplayWallet(false)}
        >
            <GiftDappWalletOptions
                onLoad={state.onload}
                connect={connectToWallet}
            />
        </CustomDialog>
    );
}

export default GiftDappWalletModal;
export { GiftDappWalletModal };
