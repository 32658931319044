import getWeb3 from "./getWeb3";
import { walletActions, walletStrings, WALLET_KEYS } from "../../components";
import { contractLabels, listedTokenAddresses, store } from "../../helpers";
import { errorToast, warningToast } from "../../includes";
import accountListeners, { binanceListeners } from "./accountListeners";
import getContracts from "./getContract";
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from "../config";
import walletUtil from "./walletUtil";
export default async function walletConnection(
    contractAddresses,
    networkWallet,
    callback,
    newConnection = false
) {
    const dispatch = store.dispatch,
        ethereum = window.ethereum;

    function errorMessages(code, message) {
        switch (String(code)) {
            case "-32603":
                return "Network seems offline. ensure the network setting are correct!";
            case "-32002":
                return "Please check your metamask notification, for connection request";
            case "4001":
                return "You canceled network connection... This will limit application function";
            default:
                // if(process.env.NODE_ENV === "development" && process.env.REACT_APP_NETWORK === "local"){
                //     walletActions({
                //         [walletStrings.localNetworkSetup] : true
                //     })(dispatch);
                // }
                return `Check network connection! - ${message}`;
        }
    }
    try {
        let web3 = await getWeb3(networkWallet),
            networkId = await web3.eth.net.getId();
        if (!walletUtil.isSupportedNetwork(parseInt(networkId))) {
            return errorToast(
                "Network not support for now! Please switch to Binance Smart Network"
            )(dispatch);
        }
        const addresses =
            contractAddresses &&
            contractAddresses[walletUtil.getTruffleNetworkName(networkId)];
        // process.env[NETWORK_EVNIRONMENT_NAME] === NETWORK_EVNIRONMENT_VALUES.LOCAL ?
        //     development : rinkeby;
        const accounts = await web3.eth.getAccounts(),
            contracts = await getContracts(web3, addresses),
            address = accounts && accounts[0];
        const _listedTokenAddresses = {};
        for (const name in listedTokenAddresses) {
            if (Object.hasOwnProperty.call(listedTokenAddresses, name)) {
                const _address = listedTokenAddresses[name];
                _listedTokenAddresses[name] = addresses?.[name] || _address;
            }
        }
        const giftDappUtilReducer =
                store.getState()[GIFT_DAPP_UTIL_REDUCER_NAME],
            {
                [giftDappUtilStrings.storageNetworkID]: storageNetworkID,
                [giftDappUtilStrings.storageNetworkType]: storageNetworkType,
                [giftDappUtilStrings.storageNetworkWallet]:
                    storageNetworkWallet,
            } = giftDappUtilReducer;
        walletActions({
            [walletStrings.web3]: web3,
            [walletStrings.listedTokens]: _listedTokenAddresses, // remove this
            [walletStrings.addresses]: addresses,
            [walletStrings.giftContractAddress]:
                addresses[contractLabels.gift_influencer],
        })(dispatch);
        if (!address) {
            if (ethereum) {
                accounts = await ethereum.enable();
                // accounts =  await ethereum.request({ method: 'eth_requestAccounts' });
                address = accounts && accounts[0];
            }
        }
        const {
            ETHEREUM_NETWORK_IDS,
            BINANCE_NETWORK_IDS,
            POLYGON_NETWORK_IDS,
        } = walletUtil;
        // let networkType = walletUtil.ETHEREUM_NETWORK_IDS.indexOf(networkId) !== -1 ||
        //     (walletUtil.BINANCE_NETWORK_IDS.indexOf(networkId) === -1) ? /* if not binance it is development so default to ether */
        //     WALLET_KEYS.ETHEREUM : WALLET_KEYS.BINANCE;

        let networkType = WALLET_KEYS.ETHEREUM;

        if (BINANCE_NETWORK_IDS.indexOf(networkId) !== -1)
            networkType = WALLET_KEYS.BINANCE;
        else if (POLYGON_NETWORK_IDS.indexOf(networkId) !== -1)
            networkType = WALLET_KEYS.POLYGON;

        walletActions({
            [walletStrings.address]: address,
            [walletStrings.contracts]: contracts,
            [walletStrings.networkId]: networkId,
            [walletStrings.networkType]: networkType,
            [walletStrings.networkWallet]: networkWallet,
            [walletStrings.hasRequestConn]: true,
        })(dispatch);
        localStorage.setItem(storageNetworkID, networkId);
        localStorage.setItem(storageNetworkType, networkType);
        localStorage.setItem(storageNetworkWallet, networkWallet);
        accountListeners();
        binanceListeners();
        callback && callback();
        return { web3, accounts, contracts };
    } catch ({ code, message }) {
        warningToast(errorMessages(code, message))(dispatch);
        // console.error(code, message);
        await walletUtil.disconnectUser();
        walletActions({
            [walletStrings.hasRequestConn]: true,
        })(dispatch);
        callback && callback(message);
    }
}
