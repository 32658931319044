import { Grid, makeStyles, Step, StepIcon, StepLabel, Stepper } from '@material-ui/core'
import { Done } from '@material-ui/icons';
import React from 'react'
import classNames from 'classnames';
import { CustomDialog } from '../CustomDialog';
import CustomProcessDisplayBox from './CustomProcessDisplayBox/CustomProcessDisplayBox';
import { PROCESS_DISPLAY_STAGES } from '../../utils';
import { useSelector } from 'react-redux';
import { customProcessStrings, CUSTOM_PROCESS_DISPLAY_REDUCER_NAME } from './config';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        padding : 20,
        paddingBottom : 50,
    },
    dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.text,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
    body : {
        marginTop : 20,
        minWidth : 500,
        [breakpoints.down("xs")] : {
            minWidth : 250,
        }
    },
    header : {
        fontSize : 14,
        lineHeight : 3,
        fontWeight : "bold",
        textAlign : "center",
        color : colors.textSecondary,
    },
}));
export default function CustomProcessDisplay () {
    const classes = style(),
    customProcessReducer = useSelector(state=>state[CUSTOM_PROCESS_DISPLAY_REDUCER_NAME]), 
    {
        [customProcessStrings.list] : list, 
        [customProcessStrings.title] : title, 
    } = customProcessReducer;
    if(!list) return null;
    return (
        <CustomDialog
            open={true}
            classes={{
                dialogPaper : classes.dialogPaper,
                _close : classes.dialogClose,
            }}
        >
            <div className={classes.root}>
                <div className={classes.header}>{title || "Block Transaction Processes"}</div>
                <div className={classes.body}>
                    <Grid container>
                        {list && list.map((item, index) => {
                            return <CustomProcessDisplayBox
                                key={index} {...item} end={index === list.length - 1}
                        />})}
                    </Grid>
                </div>
            </div>
        </CustomDialog>
    )
}