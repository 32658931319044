import { gql } from "@apollo/client";

export const socialMediaStrings = "path isVerified";
export const profileQueryStrings = `_id username lastname firstname image twitter {${socialMediaStrings}} youtube {${socialMediaStrings}}`;
export const rewardQueryString = `_id  projectIndex name image description 
    links youtubeUrl twitterUrl redditUrl facebookUrl discordUrl discordInviteUrl telegramUrl website 
    textList {spinIndex value}
    isProject networkType wheels description 
    profileId profile {${profileQueryStrings}}
    _rewardId participants { participants wins }`;
export const getRewardCounts = () => gql`
    query getRewardCounts($networkId: String, $filter: RewardInput) {
        rewardCounts: getRewardCounts(networkId: $networkId, filter: $filter) {
            active
            ended
        }
    }
`;
export const getRewards = (query) => gql`
    query getRewards($networkId : String, $filter : RewardInput, $config : ConfigInput, $pagination : PaginationInput) {
        rewards : getRewards(networkId : $networkId, filter : $filter, config : $config, pagination : $pagination) {
            ${query || rewardQueryString}
        }
    }`;
// export const getRewards = query => gql`
//     query getRewards($networkId : String, $filter : RewardInput) {
//         rewards : getRewards(networkId : $networkId, filter : $filter) {
//             ${query || "_id creator"}
//         }
//     }`;
export const getRewardMaxMinId = () => gql`
    query getRewardMaxMinId($networkId: String) {
        getRewardMaxMinId(networkId: $networkId) {
            minId
            maxId
        }
    }
`;
export const getProfiles = (query) => gql`
    query getProfiles ($filter : ProfileInput, $pagination : PaginationInput) {
        profiles : getProfiles (filter : $filter, pagination : $pagination){
            ${query || profileQueryStrings}
        }
    }`;
export const getTwitterAccountValidation = (query) => gql`
    query twitterAccountValidation ($username : String) {
        validTwitterAccount : twitterAccountValidation (username : $username){
            ${query || "username name id"}
        }
    }`;
export const getTwitterFellowship = () => gql`
    query twitterFellowshipCheck(
        $source_screen_name: String
        $target_screen_name: String
    ) {
        twitterFollow: twitterFellowshipCheck(
            source_screen_name: $source_screen_name
            target_screen_name: $target_screen_name
        )
    }
`;
export const gql_createProfile = (query) => gql`
    mutation createProfile ($profile : ProfileInput) {
        createdProfile : createProfile (profile : $profile) {
            ${query || "_id"}
        }
    }`;
export const gql_updateProfile = (query) => gql`
    mutation updateProfile ($profileId : String, $profile : ProfileInput) {
        updatedProfile : updateProfile (profileId : $profileId, profile : $profile) {
            ${query || "_id"}
        }
    }`;
export const updateParticipant = (query) => gql`
    mutation updateParticipant ($projectIndex : String, $isWin : Boolean) {
        updateParticipant (projectIndex : $projectIndex, isWin : $isWin) {
            ${query || "_id"}
        }
    }`;
export const getReferralId = (query) => gql`
    query getUserReferralByAddress ($networkId : String, $address : String) {
        referralId : getUserReferralByAddress (networkId : $networkId, address : $address) {
            ${query || "_id"}
        }
    }
`;
export const getMetadata = (query) => gql`
    query metadata ($addresses : [String]) {
        metadata : metadata (addresses : $addresses) {
            ${query || "_id address birthBlock"}
        }
    }
`;
export const createMetadata = (query) => gql`
    mutation createMetadata ($metadataArray : [MetadataInput]) {
        createMetadata (metadataArray : $metadataArray) {
            ${query || "_id address birthBlock"}
        }
    }
`;
export const getRewardEarned = (query) => gql`
    query getRewardEarned ($networkId : String, $address : String, $name : String, $pagination : PaginationInput) {
        getRewardEarned (networkId : $networkId, address : $address, name : $name, pagination : $pagination) {
            ${
                query ||
                "_id address contractAddress networkId event data { projectIndex winner ticker }"
            }
        }
    }
`;
export const getSpinEarned = (query) => gql`
    query getSpinEarned ($networkId : String, $address : String, $name : String, $pagination : PaginationInput) {
        getSpinEarned (networkId : $networkId, address : $address, name : $name, pagination : $pagination) {
            ${
                query ||
                "_id contractAddress address networkId event data { projectIndex linkCreator linkUser }"
            }
        }
    }
`;
export const getHashHistories = (query) => gql`
    query getHashHistories ($filter : HashHistoryInput) {
        getHashHistories (filter : $filter) {
            ${
                query ||
                "_id networkId event address networkId blockNumber isProject"
            }
        }
    }
`;
export const addHashHistory = (query) => gql`
    mutation addHashHistory ($data : HashHistoryInput) {
        addHashHistory (data : $data) {
            ${
                query ||
                "_id networkId event address networkId blockNumber isProject"
            }
        }
    }
`;
